import React from 'react'
import IconButton from '@mui/material/IconButton'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

const SUPPORT_URL =
  'https://www.wrike.com/form/eyJhY2NvdW50SWQiOjIwNzYzNzUsInRhc2tGb3JtSWQiOjExMzcxNTN9CTQ4NDUxMDQyNzcxMzYJMzQ4ZDVmZTVjNTVkZmVlZmM1NWY2Y2M5MmU1YWY5Zjg4YzNjOTBmNjQxMTkxNTU4MDU2ZDZhMzY0OTFiZDYxNA=='

const SupportButton: React.FC = () => {
  return (
    <IconButton
      color="primary"
      aria-label="Get support"
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '30px',
        height: '30px',
        backgroundColor: 'secondary.main',
      }}
      target="_blank"
      href={SUPPORT_URL}
    >
      <QuestionMarkIcon
        sx={{
          width: '20px',
          height: '20px',
          fontWeight: '700',
          color: 'primary.dark',
        }}
      />
    </IconButton>
  )
}

export default SupportButton
