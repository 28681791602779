import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'

import { axiosHelper } from 'utils'

interface Props {
  results: any
  onClose: () => void
  open: boolean
  onUpdate: () => void
}

const modalStyle = {
  bgcolor: 'background.paper',
  borderRadius: '3%',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
}

const RenameProjectResultsModal = ({
  results,
  onUpdate,
  onClose = () => {},
  open,
}: Props) => {
  const [projectName, setProjectName] = useState<string>()
  const id = results?.id

  // Get project name for input if it failed to load
  useEffect(() => {
    if (!projectName && results?.name) setProjectName(results?.name)
  }, [results, projectName])

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose()
      }}
    >
      <Box
        component="form"
        sx={modalStyle}
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CreateNewFolderIcon
            sx={{ fontSize: '32px', color: 'secondary.main', mr: 2 }}
          />
          <Typography variant="h6" sx={{ fontWeight: 700, mt: 0.5 }}>
            Rename Project
          </Typography>
        </Box>
        <TextField
          required
          sx={{ width: '100%' }}
          label=""
          name="project-name"
          value={projectName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e?.target?.value
            setProjectName(value)
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="outlined"
            disableElevation
            size="large"
            sx={{
              backgroundColor: 'white',
              py: 1.5,
              mr: 1,
              fontFamily: 'industry-inc',
              '&:hover': {
                backgroundColor: '#A9A9A9',
                color: '#fff',
              },
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            disableElevation
            type="submit"
            size="large"
            sx={{
              backgroundColor: 'secondary.main',
              py: 1.5,
              px: 4,
              fontFamily: 'industry-inc',
            }}
            onClick={() => {
              // Update project name
              if (projectName) {
                axiosHelper({
                  url: `/project-results/${id}`,
                  method: 'patch',
                  data: {
                    name: projectName,
                  },
                  success: () => {
                    onUpdate()
                    onClose()
                  },
                })
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RenameProjectResultsModal
