import { Text, View, StyleSheet } from '@react-pdf/renderer'

const PdfUsageTable = ({ results = {} }: { results: any }) => {
  const styles = StyleSheet.create({
    value: {
      fontWeight: 'bold',
      fontSize: '8px',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '8px',
      fontWeight: 'bold',
      color: '#525659',
    },
  })

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  return (
    <View style={{ flexDirection: 'column', width: '50%' }}>
      <Text style={{ ...styles.label, marginBottom: '8px' }}>
        Annual Energy Usage & Cost - Existing Fans
      </Text>

      <View
        style={{ flexDirection: 'row', width: '100%', marginBottom: '4px' }}
      >
        <View style={{ width: '50%' }}>
          <Text style={styles.tableHeaderLabel}>Usage (kWh)</Text>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={styles.tableHeaderLabel}>Cost ($)</Text>
        </View>
      </View>

      <View
        style={{ flexDirection: 'row', width: '100%', marginBottom: '4px' }}
      >
        <View style={{ width: '50%' }}>
          <Text style={styles.value}>
            {round(results?.existingFans?.energyTotal, 1)}
          </Text>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={styles.value}>
            {`$${round(results?.existingFans?.totalCost, 100)}`}
          </Text>
        </View>
      </View>

      <View
        style={{ flexDirection: 'row', width: '100%', marginBottom: '4px' }}
      >
        <View style={{ width: '50%' }}>
          <Text style={styles.value}>{`${round(
            results?.existingFans?.usagePerDay
          )} / Day`}</Text>
        </View>
        <View style={{ width: '50%' }}>
          <Text style={styles.value}>{`$${round(
            results?.existingFans?.costPerDay
          )} / Day`}</Text>
        </View>
      </View>
    </View>
  )
}

export default PdfUsageTable
