import { Text, View, StyleSheet } from '@react-pdf/renderer'

const PdfSavingsEstimate = ({ results = {} }: { results: any }) => {
  const styles = StyleSheet.create({
    value: {
      fontWeight: 'bold',
      fontSize: '8px',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    largeValue: {
      fontWeight: 'bold',
      fontSize: '12px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '8px',
      fontWeight: 'bold',
      color: '#525659',
    },
  })

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const energy = round(results?.savings?.energy, 1)
  const cost = results?.savings?.cost?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <View
      style={{
        flexDirection: 'column',
        border: '2px solid black',
        padding: '8px',
      }}
    >
      <Text style={{ ...styles.label, marginBottom: '8px' }}>
        Annual Energy Savings Estimate
      </Text>

      <View style={{ flexDirection: 'column', width: '100%' }}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.largeValue}>{`${energy}`}</Text>
          <Text style={{ ...styles.largeValue, marginLeft: '4px' }}>kWh</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.largeValue}>{`${cost}`}</Text>
          <Text style={{ ...styles.largeValue, marginLeft: '4px' }}>
            per year
          </Text>
        </View>
      </View>
    </View>
  )
}

export default PdfSavingsEstimate
