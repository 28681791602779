const AVAILABLE_SQUARE_FOOTAGES = new Map([
  ['C1', [5000, 10000, 50000, 200000]],
  ['C2', [30000, 100000, 200000]],
  ['C3', [5000, 10000, 20000, 50000]],
  ['C4', [1000, 5000, 10000, 20000]],
  ['C5', [1000, 5000, 10000]],
  ['C6', [1000, 5000, 10000]],
  ['C7', [2000, 15000, 40000, 60000]],
])

const getInterpolatedSqftMinMaxByLoadClass = (
  loadClass: string,
  sqft: number
): [number, number] => {
  let interpolatedMinSqft = 0
  let interpolatedMaxSqft = 0
  const availableSqftArr = AVAILABLE_SQUARE_FOOTAGES.get(loadClass) || []
  const maxValue = availableSqftArr[availableSqftArr.length - 1]

  // Iterate through available sqft until one is found
  availableSqftArr.every((s: number, i: number) => {
    // Size is over max so return max
    if (sqft > maxValue) {
      interpolatedMaxSqft = maxValue
      interpolatedMinSqft = maxValue
      return false
    }

    // Size is in range so return range
    if (sqft <= s) {
      interpolatedMaxSqft = s
      interpolatedMinSqft = availableSqftArr?.[i - 1] || interpolatedMaxSqft
      return false
    }

    return true
  })

  return [interpolatedMinSqft, interpolatedMaxSqft]
}

export default getInterpolatedSqftMinMaxByLoadClass
