import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns-tz'

import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import DeleteProjectModal from './DeleteProjectModal'
import { axiosHelper } from 'utils'

interface Props {
  isLast?: boolean
  project: any
  onUpdate: (message: string) => void
}

const ArchiveProjectRow = ({ project, onUpdate, isLast }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const menuOpen = Boolean(anchorEl)
  const id = project?.id

  // Menu click handlers
  const handleClick = (event: React.MouseEvent<SVGElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: isLast ? 'none' : '1px solid lightgray',
        alignItems: 'center',
        p: 2,
      }}
      key={project?.name}
    >
      <Box sx={{ mt: 1 }} key={project?.name}>
        <Link
          to={`/projects/${id}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Typography variant="h5" sx={{ mb: 0 }}>
            {project?.name}
          </Typography>
        </Link>
        <Typography
          variant="body2"
          sx={{ mt: 0, mb: 1, color: 'grey', fontSize: '12px' }}
        >
          {format(new Date(project?.created_at || Date.now()), 'Pp')?.replace(
            ',',
            ''
          )}
        </Typography>
      </Box>

      <Box sx={{ mr: 1, mt: 1, cursor: 'pointer' }}>
        <MoreHorizIcon
          sx={{ fontSize: '36px', color: 'grey' }}
          onClick={e => handleClick(e)}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            setDeleteModalOpen(true)
            handleClose()
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (id) {
              axiosHelper({
                url: `/projects/${id}`,
                method: 'patch',
                data: {
                  id,
                  archived: 0,
                },
                success: () => onUpdate && onUpdate('Unarchived!'),
              })
            }
            handleClose()
          }}
        >
          Unarchive
        </MenuItem>
      </Menu>

      <DeleteProjectModal
        project={project}
        onUpdate={() => {
          onUpdate && onUpdate('Deleted!')
        }}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
    </Box>
  )
}

export default ArchiveProjectRow
