import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import ProjectDetails from '../components/ProjectDetails'
import ProductDetails from '../components/ProductDetails'
import Logo from '../components/Logo'
import Notes from '../components/Notes'
import MountingHardware from '../components/MountingHardware'
import ColorOptions from '../components/ColorOptions'
import Warranty from '../components/Warranty'
import HaikuModule from '../components/HaikuModule'
import CriticalDimensions from '../components/CriticalDimensions'
import Additions from '../components/Additions'
import Features from '../components/Features'
import TechnicalDrawings from '../components/TechnicalDrawings'
import TechnicalNotes from '../components/TechnicalNotes'
import Links from 'components/Links'
import { useSubmittalsState } from 'store/salesToolsState'

const HaikuTemplate = () => {
  const { fan, productData, sizeData, colorDetails } =
    useSubmittalsState().getState()
  const [finish, setFinish] = useState<string | null>(colorDetails.value.finish)
  return (
    <Stack spacing={0} display="block">
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Logo name={fan.name} />
        <Links links={productData.links} mt={0} />
      </Stack>
      <Grid container spacing={2}>
        <ProjectDetails />
        {productData.id !== -1 && <ProductDetails finish={finish} />}
        {productData.id !== -1 && <Features />}
        {sizeData.size !== '' &&
          (sizeData.mounts.length > 0 || sizeData.drop_tubes.length > 0) && (
            <MountingHardware />
          )}
        {productData.id !== -1 && <ColorOptions setFinish={setFinish} />}
        {productData.id !== -1 && productData.warranty.length > 0 && (
          <Warranty />
        )}
        <TechnicalDrawings />
        {productData.id !== -1 && productData.addons.length > 0 && (
          <Additions />
        )}
        {sizeData.size !== '' && <CriticalDimensions />}
        {productData.id !== -1 && <HaikuModule />}
        {productData.id !== -1 && productData.technicalNotes.length > 0 && (
          <TechnicalNotes />
        )}
        {productData.id !== -1 && <Notes />}
      </Grid>
    </Stack>
  )
}

export default HaikuTemplate
