import { Tooltip } from 'react-tooltip'

import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import InfoIcon from '@mui/icons-material/Info'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import FormCard from 'components/FormCard'

import { useFanEnergyEstimatorState } from 'store'
import { STATES, FACILITY_TYPES } from 'config/constants'

interface Option {
  value: string
  id: string
}

const AdditionalDetailsTile = ({ isAireyeMode }: { isAireyeMode: boolean }) => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const {
    electricCost,
    existingFans,
    facilityType,
    hoursPerWeek,
    selectedState,
    fansLeftOn,
    aeosEquipped,
    locationCosts,
    breaks15Min,
    breaks60Min,
  } = fanEnergyEstimatorState.get()

  const stateOptions = STATES?.map((state: string) => ({
    value: state,
    id: state,
  }))
  const facilityOptions = FACILITY_TYPES?.map((type: string) => ({
    value: type,
    id: type,
  }))

  return (
    <FormCard mr={0.01}>
      <Box sx={{ minHeight: mediumBreakpoint ? '380px' : 'auto' }}>
        <Box display="inline-flex">
          <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
            Additional Details
            <InfoIcon
              data-tooltip-id="standard-tooltip"
              data-tooltip-content={
                mediumBreakpoint
                  ? 'Calculations are based on population averages for the information selected and are only an estimate of actual savings. Actual Costs and savings will vary depending on weather conditions, usage, location and local utility rates. This information is intended as an example for comparison purposes only. BAF does not gurantee the accuracy of these calculations. No promise of performance is implied by us or should be inferred by you.'
                  : ''
              }
              sx={{ ml: 0.5, pt: 0.5, mb: -0.25 }}
            />
          </Typography>
        </Box>

        <Box
          display="inline-flex"
          sx={{ width: '100%', mt: 1, justifyContent: 'space-between' }}
        >
          <TextField
            required
            type="number"
            InputProps={{ inputProps: { min: 0, max: 168 } }}
            label="Operating Hours Per Week"
            name="hours"
            sx={{ width: '50%' }}
            value={hoursPerWeek || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const count = parseInt(e?.target?.value)
              fanEnergyEstimatorState.setHoursPerWeek(count)
            }}
          />
          <FormControl sx={{ width: '50%', ml: 1 }}>
            <InputLabel id="state-label">Install Location (State)</InputLabel>
            <Select
              required
              labelId="state-label"
              label="Install Location (State)"
              value={selectedState}
              onChange={(e: any) => {
                const value = e?.target?.value
                fanEnergyEstimatorState.setSelectedState(value)

                // Set a default facility type if one isn't selected
                if (!facilityType) {
                  fanEnergyEstimatorState.setFacilityType('Industrial')
                }

                // Update electric cost based on chosen location
                const location = locationCosts?.find(
                  (location: any) => location?.location === value
                )
                const locationCost =
                  facilityType === 'Residential'
                    ? location?.residential
                    : facilityType === 'Commercial'
                    ? location?.commercial
                    : location?.industrial

                if (locationCost) {
                  const roundedCost =
                    Math.round(locationCost * 0.01 * 10000) / 10000
                  fanEnergyEstimatorState.setElectricCost(roundedCost)
                }
              }}
            >
              {stateOptions?.map((option: Option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          display="inline-flex"
          sx={{ width: '100%', mt: 3, justifyContent: 'space-between' }}
        >
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="facility-type-label">Facility Type</InputLabel>
            <Select
              required
              labelId="facility-type-label"
              label="Facility Type"
              value={facilityType}
              onChange={(e: any) => {
                const value = e?.target?.value
                fanEnergyEstimatorState.setFacilityType(value)

                // Update electric cost based on chosen location
                const location = locationCosts?.find(
                  (location: any) => location?.location === selectedState
                )
                const locationCost =
                  value === 'Residential'
                    ? location?.residential
                    : value === 'Commercial'
                    ? location?.commercial
                    : location?.industrial

                if (locationCost) {
                  const roundedCost =
                    Math.round(locationCost * 0.01 * 10000) / 10000
                  fanEnergyEstimatorState.setElectricCost(roundedCost)
                }
              }}
            >
              {facilityOptions?.map((option: Option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            required
            type="number"
            InputProps={{ inputProps: { step: 0.0001, min: 0, max: 168 } }}
            label="Electric Cost ($/kWh)"
            name="cost"
            sx={{ width: '50%', ml: 1 }}
            value={electricCost || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = parseFloat(e?.target?.value)
              fanEnergyEstimatorState.setElectricCost(value)
            }}
          />
        </Box>

        {isAireyeMode && (
          <>
            <Box sx={{ mt: 3 }}>Scheduled Employee Breaks Per Week</Box>
            <Box display="inline-flex" sx={{ width: '100%', mt: 1 }}>
              <TextField
                required
                type="number"
                InputProps={{ inputProps: { min: 0, max: 168 } }}
                label="15 Min"
                name="breaks-15-min"
                sx={{ width: '15%' }}
                value={breaks15Min || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const count = e?.target?.value
                  fanEnergyEstimatorState.setBreaks15Min(parseInt(count))
                }}
              />
              <TextField
                required
                type="number"
                InputProps={{ inputProps: { min: 0, max: 168 } }}
                label="60 Min"
                name="breaks-60-min"
                sx={{ width: '15%', ml: 1 }}
                value={breaks60Min || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const count = e?.target?.value
                  fanEnergyEstimatorState.setBreaks60Min(parseInt(count))
                }}
              />
            </Box>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            mt: 1,
            justifyContent: 'space-between',
          }}
        >
          {isAireyeMode && (
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    fanEnergyEstimatorState.setAeosEquipped(
                      event.target.checked
                    )
                  }}
                  checked={aeosEquipped || false}
                />
              }
              label="AEOS Equipped"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                disabled={!existingFans?.length}
                onChange={event => {
                  fanEnergyEstimatorState.setFansLeftOn(event.target.checked)
                }}
                checked={fansLeftOn || false}
              />
            }
            label="Fans Left On During Unoccupied Hours"
          />
        </Box>
      </Box>
      <Tooltip id="standard-tooltip" />
    </FormCard>
  )
}

export default AdditionalDetailsTile
