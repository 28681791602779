import { useState } from 'react'
import sortBy from 'lodash/sortBy'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Search from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import CircularProgress from '@mui/material/CircularProgress'

import DirectoryCard from './directory-card'
import RequiredPermission from 'components/RequiredPermission'

import { useToolConfigs } from 'hooks/useToolConfigs'

const TABS = ['All Tools', 'Featured', 'Calculators', 'Design Tools']

const Directory = () => {
  const smallBreakpoint = useMediaQuery('(min-width:600px)')
  const mediumBreakpoint = useMediaQuery('(min-width:920px)')
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const { tools, loading } = useToolConfigs()

  // Filter tools based on selected category and search query
  const filteredTools = tools
    ?.filter(
      tool =>
        !selectedTab ||
        tool?.category === TABS[selectedTab] ||
        (TABS[selectedTab] === 'Featured' && !!tool?.is_featured)
    )
    .filter(
      tool =>
        !searchQuery ||
        tool.tool.toLowerCase().includes(searchQuery) ||
        tool.description?.split(' ')?.includes(searchQuery) ||
        tool.tags?.split(',')?.includes(searchQuery)
    )

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    setSelectedTab(newValue)

  const handleDropDownChange = (e: SelectChangeEvent) =>
    setSelectedTab(parseInt(e.target.value))

  const renderTabs = () => {
    return TABS.map((tab, index) => (
      <Tab
        key={tab}
        label={tab}
        sx={{
          textTransform: 'capitalize',
          backgroundColor: selectedTab === index ? 'primary.main' : '#f7f7f7',
          color: selectedTab === index ? 'white !important' : 'primary.main',
          borderRadius: '28px',
          fontWeight: 700,
          minHeight: '32px',
          height: '32px',
        }}
      />
    ))
  }

  const renderSearch = () => (
    <TextField
      placeholder="Search..."
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ ml: 1, mt: '3px', color: '#BCBEC0' }} />
          </InputAdornment>
        ),
      }}
      onChange={e => setSearchQuery(e.target.value || '')}
      variant="standard"
      sx={{
        border: '1px solid #BCBEC0',
        width: mediumBreakpoint ? '375px' : '120px',
        borderRadius: '16px',
        paddingBottom: 0,
        marginTop: 0,
        fontSize: '14px',
        height: '35px',
        ml: 1,
        '.MuiInput-input': {
          paddingTop: '6px',
          fontSize: '14px',
        },
      }}
    />
  )

  return (
    <Container sx={{ backgroundColor: '#F7F7F7', px: smallBreakpoint ? 0 : 3 }}>
      {/* Desktop tabs */}
      {smallBreakpoint && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 4,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              '.MuiTabs-indicator': {
                backgroundColor: 'transparent',
                height: '10px !important',
              },
            }}
          >
            {renderTabs()}
          </Tabs>
          {renderSearch()}
        </Box>
      )}

      {/* Mobile drop down */}
      {!smallBreakpoint && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 4,
          }}
        >
          <Select
            variant="standard"
            value={selectedTab as any}
            onChange={handleDropDownChange}
            sx={{
              borderRadius: '24px',
              pb: 0,
              pt: 0.5,
              px: 2,
              fontWeight: 700,
              fontSize: '17px',
              width: '170px',
              backgroundColor: '#D1D3D4',
              outline: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            disableUnderline
          >
            <MenuItem value={0}>All Tools</MenuItem>
            <MenuItem value={1}>Featured</MenuItem>
            <MenuItem value={2}>Calculators</MenuItem>
            <MenuItem value={3}>Design Tools</MenuItem>
          </Select>
          {renderSearch()}
        </Box>
      )}

      {/* Tool card grid */}
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '416px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            my: 2,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* Uses `sortBy` to sort by the title, and then the `isFeatured` boolean. */}
          {[...sortBy(filteredTools, 'tool')]
            ?.sort((a: any, b: any) => b.is_featured - a.is_featured)
            .map(tool => {
              if (tool.required_permission) {
                return (
                  <RequiredPermission
                    permission={tool.required_permission}
                    key={tool?.tool}
                  >
                    <DirectoryCard
                      icon={tool?.icon}
                      type={tool?.category}
                      label={tool?.tool}
                      to={tool?.link}
                      description={tool?.description}
                      isFeatured={!!tool?.is_featured}
                    />
                  </RequiredPermission>
                )
              }
              return (
                <DirectoryCard
                  key={tool?.tool}
                  icon={tool?.icon}
                  type={tool?.category}
                  label={tool?.tool}
                  to={tool?.link}
                  description={tool?.description}
                  isFeatured={!!tool?.is_featured}
                />
              )
            })}
        </Box>
      )}
    </Container>
  )
}

export default Directory
