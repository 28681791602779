import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

const Info = () => {
  return (
    <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
      <Typography variant="h5" sx={{ mt: -1 }}>
        Information
      </Typography>
      <Box sx={{ mt: 1 }}>
        Calculations are based on averages for the information selected and are
        only an estimate of actual savings. Actual loss and savings will vary
        depending on weather conditions, usage, location and business type. This
        information is intended as an example for comparison purposes only. BAF
        does not guarantee the accuracy of these calculations. No promise of
        performance is implied by us or should be inferred by you.
      </Box>
    </Card>
  )
}

export default Info
