import cloneDeep from 'lodash/cloneDeep'
import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import getInterpolatedSqftByLoadClass from 'utils/getInterpolatedSqftByLoadClass'
import { LOCAL_STORAGE_KEY } from 'config/constants'

const LOAD_CLASSES = {
  Agriculture: 'C1',
  'Automotive Service': 'C1',
  Aviation: 'C7',
  Education: 'C3',
  Fitness: 'C6',
  Government: 'C3',
  Grocery: 'C5',
  Hospitality: 'C3',
  Manufacturing: 'C1',
  Office: 'C3',
  'Public Spaces': 'C4',
  'Restaurant/Bar': 'C5',
  Retail: 'C3',
  'Warehouse/Distribution': 'C1',
  'Worship Facilities': 'C3',
}

// Environmental impact multipliers
const EQ_BBTU_MULTI = 3.412
const CO2 = 0.9905
const CO2E = 0.996
const PARTICULATES = 2744
const CARS = 0.002
const ACRES = 0.0009
const BULBS = 0.025

export interface Product {
  name?: string
  product_id?: number
  type?: string
  kwh_day?: string
  cool: number
  max_cool: number
  heat: number
  count: number
  size?: string
}

interface ProductType {
  name?: string
  type?: string
}

interface Location {
  location?: string
  type?: string
}

interface LocationCost {
  location?: string
  cents_kwh?: string
}

interface CitiesAgroZone {
  city_state?: string
  city?: string
  state?: string
  agro_climatic_zone: string
}

interface ClimateRegion {
  max?: number
  heat?: number
  cool?: number
  climate_region?: number
  location?: string
}

interface PrimaryUse {
  name?: string
  type?: string
}

interface EmData {
  climate_zone?: string
  type?: string
}

export interface AcSavingsState {
  bafFans: Product[]
  existingFans: Product[]
  leadSaved: boolean
  results: any

  // Data
  citiesAgroZonesData: CitiesAgroZone[]
  climateRegionsData: ClimateRegion[]
  locationCostsData: LocationCost[]
  emData: EmData[]
  locationData: Location[]
  primaryUsesData: PrimaryUse[]
  productTypes: ProductType[]
  products: Product[]

  // Input values
  companyName: string
  installLocation: any
  primaryUse: any
  ceilingHeight: any
  thermostatOffset: any
  airConditioned: any
  floorArea: any
  operatingHours: any
  energyDataSource: any
}

const initialAcSavingsState = {
  bafFans: [] as Product[],
  existingFans: [] as Product[],
  leadSaved: false,
  results: null,

  // Data
  citiesAgroZonesData: [] as CitiesAgroZone[],
  climateRegionsData: [] as ClimateRegion[],
  locationCostsData: [] as LocationCost[],
  locationData: [] as Location[],
  emData: [] as EmData[],
  primaryUsesData: [] as PrimaryUse[],
  productTypes: [] as ProductType[],
  products: [] as Product[],

  // Input values
  companyName: '',
  installLocation: null,
  floorArea: null,
  ceilingHeight: null,
  primaryUse: null,
  thermostatOffset: null,
  airConditioned: 'Yes - AHU - Electricity',
  operatingHours: null,
  energyDataSource: null,
}

const acSavingsState = createState(initialAcSavingsState)

const wrapAcSavingsState = (s: State<AcSavingsState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialAcSavingsState,
      products: p?.products,
      locationData: p?.locationData,
      primaryUsesData: p?.primaryUsesData,
      bafFans: [p?.products?.[0]],
    })),
  setBafFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index] = value
      return { ...p, bafFans: fans, results: null }
    })
  },
  setBafFanQuantity: (quantity: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index].count = quantity
      return { ...p, bafFans: fans, results: null }
    })
  },
  removeBafFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.bafFans]
      delete fans[index]
      return { ...p, bafFans: fans.filter(Boolean), results: null }
    })
  },
  setExistingFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.existingFans)
      fans[index] = value
      return { ...p, existingFans: fans, results: null }
    })
  },
  resetExistingFans: () => {
    s.set((p: any) => {
      return { ...p, existingFans: [], results: null }
    })
  },
  setExistingFanQuantity: (quantity: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.existingFans)
      fans[index].count = quantity
      return { ...p, existingFans: fans, results: null }
    })
  },
  removeExistingFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.existingFans]
      delete fans[index]
      return { ...p, existingFans: fans.filter(Boolean), results: null }
    })
  },
  setCompanyName: (value: string) => {
    s.set((p: any) => ({ ...p, companyName: value, results: null }))
  },
  setFloorArea: (value: number) => {
    s.set((p: any) => ({ ...p, floorArea: value, results: null }))
  },
  setThermostatOffset: (value: number) => {
    s.set((p: any) => ({ ...p, thermostatOffset: value, results: null }))
  },
  setOperatingHours: (value: number) => {
    s.set((p: any) => ({ ...p, operatingHours: value, results: null }))
  },
  setPrimaryUse: (value: string) => {
    s.set((p: any) => ({ ...p, primaryUse: value, results: null }))
  },
  setEnergyDataSource: (value: string) => {
    s.set((p: any) => ({ ...p, energyDataSource: value, results: null }))
  },
  setAirConditioned: (value: string) => {
    s.set((p: any) => ({ ...p, airConditioned: value, results: null }))
  },
  setCeilingHeight: (value: number) => {
    s.set((p: any) => ({ ...p, ceilingHeight: value, results: null }))
  },
  setInstallLocation: (value: string) => {
    s.set((p: any) => ({ ...p, installLocation: value, results: null }))
  },
  setProducts: (value: any) => {
    s.set((p: any) => ({ ...p, products: value }))
  },
  setLocationData: (value: any) => {
    s.set((p: any) => ({ ...p, locationData: value }))
  },
  setLocationCostsData: (value: any) => {
    s.set((p: any) => ({ ...p, locationCostsData: value }))
  },
  setPrimaryUsesData: (value: any) => {
    s.set((p: any) => ({ ...p, primaryUsesData: value }))
  },
  setClimateRegionsData: (value: any) => {
    s.set((p: any) => ({ ...p, climateRegionsData: value }))
  },
  setEmData: (value: any) => {
    s.set((p: any) => ({ ...p, emData: value }))
  },
  setCitiesAgroZonesData: (value: any) => {
    s.set((p: any) => ({ ...p, citiesAgroZonesData: value }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setResults: () => {
    s.set((p: any) => {
      // Get estimated annual usage kWh
      const isAirConditioned = p?.airConditioned === 'Yes - AHU - Electricity'
      const primaryUse = p?.primaryUsesData.find(
        (use: any) => use?.primary_use === p?.primaryUse
      )
      const coolingKwhSqft = primaryUse?.cooling_kwh_sqft || 0
      const agroZoneData = p?.citiesAgroZonesData?.find(
        (agroData: any) => agroData?.city_state === p?.installLocation
      )
      const locationData = p?.locationData?.find(
        (location: any) => location?.location === agroZoneData?.state
      )
      const locationMultiplier = locationData?.multiplier || 0
      const estimatedAnnualUsageKwh = isAirConditioned
        ? p?.floorArea * coolingKwhSqft * locationMultiplier
        : 0

      // Get estimated annual usage cost
      const locationCostData = p?.locationCostsData?.find(
        (location: any) => location?.location === agroZoneData?.state
      )
      const centsKwh = locationCostData?.cents_kwh || 0
      const estimatedAnnualUsageCost =
        (estimatedAnnualUsageKwh * centsKwh) / 100

      // Get estimated annual savings cost
      const reportedThermostatSavings = (p?.thermostatOffset * 3) / 100
      const estimatedAnnualSavingsCost =
        estimatedAnnualUsageCost * reportedThermostatSavings

      // Get estimated annual savings kwh
      const estimatedAnnualSavingsKwh =
        estimatedAnnualUsageKwh * reportedThermostatSavings

      // Get total cost for all existing fan
      const fanTotals = p?.existingFans?.map((fan: Product) => {
        const regionData = p?.climateRegionsData?.find(
          (region: any) => region?.location === agroZoneData?.state
        )
        const maxCoolTotal =
          (fan.max_cool * regionData?.max * p?.operatingHours * fan?.count) /
          1000
        const coolTotal =
          (fan.cool * regionData?.cool * p?.operatingHours * fan?.count) / 1000
        const heatTotal =
          (fan.heat * regionData?.heat * p?.operatingHours * fan?.count) / 1000
        return maxCoolTotal + coolTotal + heatTotal
      })
      const existingFansTotal = fanTotals.reduce(
        (runningTotal: number, x: number) => runningTotal + x,
        0
      )
      const existingFanTotalCost = (centsKwh / 100) * existingFansTotal || 0

      // Get total cost for all BAF fan
      const bafFanTotals = p?.bafFans?.map((fan: Product) => {
        const count = fan?.count || 1
        const regionData = p?.climateRegionsData?.find(
          (region: any) => region?.location === agroZoneData?.state
        )
        const maxCoolTotal =
          (fan.max_cool * regionData?.max * p?.operatingHours * count) / 1000

        const coolTotal =
          (fan.cool * regionData?.cool * p?.operatingHours * count) / 1000

        const heatTotal = (fan.heat * regionData?.heat * 168 * count) / 1000
        return maxCoolTotal + coolTotal + heatTotal
      })
      const bafFansTotal = bafFanTotals.reduce(
        (runningTotal: number, x: number) => runningTotal + x,
        0
      )
      const bafFanTotalCost = (centsKwh / 100) * bafFansTotal || 0

      // Get BAF method EM calculation
      const loadClass = LOAD_CLASSES[p?.primaryUse as keyof typeof LOAD_CLASSES]
      const agroClimateZone = agroZoneData?.agro_climatic_zone
      const x0 = p?.floorArea
      const interpolatedSqft = getInterpolatedSqftByLoadClass(
        loadClass,
        p?.floorArea
      )
      const x1 = interpolatedSqft[0]
      const x2 = interpolatedSqft[1]
      const y1Code = `${loadClass}_${x1}`
      const y2Code = `${loadClass}_${x2}`
      const emData = p?.emData?.find(
        (em: any) => em?.climate_zone === agroClimateZone
      )
      const y1 = parseFloat(emData?.[y1Code])
      const y2 = parseFloat(emData?.[y2Code])
      const y = y1 === y2 ? y1 : y1 + (x0 - x1) * ((y2 - y1) / (x2 - x1))
      const acEnergyUsageKwh = y * p?.floorArea
      const acEnergyUsageCost = (centsKwh / 100) * acEnergyUsageKwh
      const isBafMethod = p?.energyDataSource === 'BAF Energy Models'
      const bafAcSavings = acEnergyUsageCost * reportedThermostatSavings

      // Get environmental impact results
      const fanEnergySavingsTotal = existingFansTotal - bafFansTotal
      const acSavingsTotalKwh = acEnergyUsageKwh * reportedThermostatSavings
      const energySum = isBafMethod
        ? acSavingsTotalKwh + fanEnergySavingsTotal
        : estimatedAnnualSavingsKwh + fanEnergySavingsTotal
      const co2 = energySum * CO2
      const eqBbtu = (energySum * EQ_BBTU_MULTI) / 1000000
      const particulates = eqBbtu * PARTICULATES
      const co2e = energySum * CO2E
      const cars = energySum * CARS
      const acres = energySum * ACRES
      const bulbs = energySum * BULBS

      // Build results
      const results = {
        savings: {
          acSavings: !isAirConditioned
            ? 0
            : isBafMethod
            ? bafAcSavings
            : estimatedAnnualSavingsCost,
          fanEnergySavingsCost: existingFanTotalCost - bafFanTotalCost,
        },
        environmentalImpact: [
          {
            title: 'Reduct. in Carbon Dioxide (CO2)',
            metric: 'Lbs',
            icon: 'Co2',
            value: co2,
          },
          {
            title: 'Reduct. in Particulates',
            metric: 'Lbs',
            icon: 'Grain',
            value: particulates,
          },
          {
            title: 'Greenhouse Gas Reduct. (CO2e)',
            metric: 'Lbs',
            icon: 'GasMeter',

            value: co2e,
          },
          {
            title: 'Equiv. Passenger Cars Reduction',
            metric: 'Cars',
            icon: 'DirectionsCar',
            value: cars,
          },
          {
            title: 'Equiv. Forest Carbon Sequestration',
            metric: 'Acres',
            icon: 'Park',
            value: acres,
          },
          {
            title: 'Equiv. Legacy Light Bulbs to LED',
            metric: 'Bulbs',
            icon: 'Lightbulb',
            value: bulbs,
          },
        ],
      }

      return { ...p, results }
    })
  },
})

export const accessAcSavingsState = () => wrapAcSavingsState(acSavingsState)

export const useAcSavingsState = () => {
  const state = useState(acSavingsState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-ac-savings-calc`))
  return wrapAcSavingsState(state)
}
