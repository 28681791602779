import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import { useNotes } from '../hooks/useNotes'
import Notes from './Notes'

interface NotesProps {
  id: number
  onClose: () => void
}

const modalStyle = {
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 600,
}

const NotesModal = ({ id, onClose = () => {} }: NotesProps) => {
  const { hasNotes, hasLinks } = useNotes({ id })

  if (!hasNotes && !hasLinks) return null

  return (
    <Modal open={id !== undefined} onClose={() => onClose && onClose()}>
      <Box component="form" sx={modalStyle}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '24px',
            p: 2,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '24px',
              height: '24px',
              cursor: 'pointer',
              fontSize: 18,
              fontWeight: 700,
              zIndex: 1,
            }}
            onClick={() => onClose && onClose()}
          >
            X
          </Box>
        </Box>
        <Notes id={id} />
      </Box>
    </Modal>
  )
}

export default NotesModal
