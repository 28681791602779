import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import FormCard from 'components/FormCard'

const Notes = () => {
  return (
    <FormCard>
      <Typography variant="h5" sx={{ mt: -1 }}>
        Notes
      </Typography>
      <Box sx={{ mt: 1 }}>
        (1) Calculations are based on population averages for the information
        selected and are only an estimate of actual savings. Actual Costs and
        savings will vary depending on weather conditions, usage, location and
        local utility rates. This information is intended as an example for
        comparison purposes only. BAF does not gurantee the accuracy of these
        calculations. No promise of performance is implied by us or should be
        inferred by you.
      </Box>
      <Box sx={{ mt: 1 }}>
        (2) The fan energy cost displayed is calculated using the methods
        developed by BAF expertise which best accounts for how customers utilize
        existing non-HVLS fans and Big Ass Fans in their spaces. Per CFR 10 Part
        429 and Part 430,the Department of Energy dictates that fan energy must
        also be reported using the methodologies therein. For the overhead fans
        as selected (not including directionals), annual DOE calculated electric
        consumption is equal to 0 kWh. Note that the BAF method accounts for the
        specific location and application selected above whereas the DOE method
        is a national average for all customers, locations, and applications.
      </Box>
      <Box sx={{ mt: 1 }}>
        (3) The differences in BAF and existing fan energy consumptions are
        found using the method referenced in note #2.
      </Box>
      <Box sx={{ mt: 1 }}>
        (4) Calculations are based on averages for the information selected and
        are only an estimate of actual savings. Actual loss and savings will
        vary depending on weather conditions, usage, location and business type.
        This information is intended as an example for comparison purposes only.
        BAF does not guarantee the accuracy of these calculations. No promise of
        performance is implied by us or should be inferred by you.
      </Box>
      <Box sx={{ mt: 1 }}>
        (5) Reduction in production of selected greenhouse gases and pollutants
        are found as a product of the reduction of natrual gas consumption and
        the reduction of coal-fired electricity production. As context, various
        benchmarks (cars/forrest/bulbs) are also provided.
      </Box>
    </FormCard>
  )
}

export default Notes
