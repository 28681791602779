import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import FormCard from 'components/FormCard'

const Notes = () => {
  return (
    <FormCard>
      <Typography variant="h5" sx={{ mt: -1 }}>
        Notes
      </Typography>
      <Box sx={{ mt: 1 }}>
        (1) Fan operating speed increases as airfoil height increases.
      </Box>
      <Box sx={{ mt: 1 }}>
        (2) The number of fans required is rounded up, unless within 5% (1.04
        fans = 1 fan, 1.10 fans = 2 fans).
      </Box>
      <Box sx={{ mt: 1 }}>
        (3) Fans with Plus winglets are not recommended at greater than 40 feet.
      </Box>
      <Box sx={{ mt: 1 }}>
        (4) To increase clearance from fan to ceiling, decrease airfoil height.
      </Box>
      <Box sx={{ mt: 1 }}>
        (5) All data based on an unobstructed space; significant obstructions
        may cause underestimation of fans needed.
      </Box>
    </FormCard>
  )
}

export default Notes
