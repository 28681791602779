import { useState, useEffect } from 'react'
import isEquaL from 'lodash/isEqual'
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'

import ProjectResultsRow from './components/ProjectResultsRow'
import ProjectResultsBanner from './components/ProjectResultsBanner'

import { axiosHelper } from 'utils'

const ProjectResults = () => {
  const { id } = useParams()
  const [alertOpen, setAlertOpen] = useState(false)
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [results, setResults] = useState<any[] | null>(null)

  // Data loading states
  const [loadingResults, setLoadingResults] = useState<boolean>(false)

  // Get project results for project
  useEffect(() => {
    setLoadingResults(true)
    axiosHelper({
      url: '/project-results/find',
      method: 'post',
      data: {
        project_id: id,
      },
      success: (values: any) => {
        if (!isEquaL(values?.data, results)) {
          setResults(values?.data)
          setLoadingResults(false)
          setNeedsUpdate(false)
        }
      },
    })
  }, [results, id, loadingResults, needsUpdate])

  const renderNoProjects = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        mt: 10,
      }}
    >
      <UploadFileIcon sx={{ color: 'LightGray', fontSize: '72px', mb: 1.5 }} />
      <Typography
        variant="h4"
        sx={{
          mt: 0,
          mb: 1,
          textDecoration: 'capitalize !important',
          fontFamily: "'proxima-nova', sans-serif",
          fontWeight: 700,
        }}
      >
        No results
      </Typography>
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          fontFamily: "'proxima-nova', sans-serif",
          color: 'gray',
        }}
      >
        Get started by saving results to this project.
      </Typography>
    </Box>
  )

  const renderProjects = () => (
    <Box
      sx={{
        flexDirection: 'column',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: '16px',
        mt: 6,
      }}
    >
      {results?.map((result, index) => (
        <ProjectResultsRow
          key={result?.name}
          results={result}
          isLast={!!results?.length && results?.length - 1 === index}
          onUpdate={(message: string) => {
            setNeedsUpdate(true)
            setAlertMessage(message)
            setAlertOpen(true)
          }}
        />
      ))}
    </Box>
  )

  return (
    <Box>
      <ProjectResultsBanner />
      {!results ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 10,
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : !results?.length ? (
        renderNoProjects()
      ) : (
        <Container maxWidth="lg">{renderProjects()}</Container>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Box>
  )
}

export default ProjectResults
