import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { InfoProps } from '../../../store/heatIndexState'

const Info = ({ data }: InfoProps) => (
  <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
    <Typography variant="h5" sx={{ mb: 2 }}>
      Information
    </Typography>
    {data.map((value, index) =>
      value.bodies.map((body, bodyNum) => (
        <div key={index}>
          {bodyNum === 0 && (
            <Typography
              variant="h6"
              sx={{ fontSize: 'small', fontWeight: 'bold' }}
              key={`h${index}`}
            >
              {value.header}
            </Typography>
          )}
          <Typography
            variant="body1"
            key={`h${index}b${bodyNum}`}
            sx={{ mb: 1 }}
          >
            {body}
          </Typography>
        </div>
      ))
    )}
  </Card>
)

export default Info
