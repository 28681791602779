import { useRef, useState } from 'react'
import { AppBarSpacer } from '../layout/helpers/styled'
// import { PDFDownloadLink } from '@react-pdf/renderer'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import HourlyHeatIndexDistribution from './components/HourlyHeatIndexDistribution'
import Info from './components/Info'
import RegionPickerTile from 'components/RegionPickerTile'
import ProductivitySavings from './components/ProductivitySavings'
import ProjectDetails from './components/ProjectDetails'
import Links from 'components/Links'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { useHeatIndexState } from 'store/heatIndexState'

// import HeatIndexCalcPdf from './HeatIndexCalcPdf'

const HeatIndexCalc = () => {
  const heatIndexState = useHeatIndexState()
  const info = heatIndexState.get().info.data
  const region = heatIndexState.get().projectDetails.value.climateZone
  const { valid, links, leadSaved } = heatIndexState.get()
  const [, setLoading] = useState<boolean>(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const componentRef = useRef<HTMLDivElement>(null)

  const TITLE = 'Heat Index Hours Calculator'

  // State data used to save project results (the whole state is too large)
  const toolSaveState = {
    projectDetails: {
      ...heatIndexState.get()?.projectDetails,
    },
    productivitySavings: {
      value: {
        hourlyWage:
          heatIndexState.get()?.productivitySavings?.value?.hourlyWage,
        numEmployees:
          heatIndexState.get()?.productivitySavings?.value?.numEmployees,
      },
    },
    hourlyHeatIndexDistribution: {
      value: {
        hours: heatIndexState.get()?.productivitySavings?.value?.hours,
      },
    },
    toolName: 'Heat Index Calc',
  }

  return (
    <Container maxWidth="xl">
      <AppBarSpacer />
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          heatIndexState.setValid(true)
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => heatIndexState.reset()}
          toolState={toolSaveState}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          onSave={() => heatIndexState.setValidated()}
          valid={valid}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => heatIndexState.setLeadSaved(true)}
          onLeadsModalClose={() => {
            if (!leadSaved) heatIndexState.setValid(false)
          }}
          printContent={() => componentRef.current}
          // saveActions={[
          //   {
          //     icon: () => <PictureAsPdfIcon fontSize="small" />,
          //     node: () => (
          //       <Box>
          //         <PDFDownloadLink
          //           document={<HeatIndexCalcPdf />}
          //           fileName="BAF-Heat-Index-Calc.pdf"
          //         >
          //           {({ blob, url, loading, error }) => 'Download PDF'}
          //         </PDFDownloadLink>
          //       </Box>
          //     ),
          //   },
          // ]}
        />
        <Box ref={componentRef}>
          <style type="text/css">
            {'@media print{@page {size: 8in 10.5in}}'}
          </style>
          <PrintHeader title={TITLE} />
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <ProjectDetails />
              <HourlyHeatIndexDistribution />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <RegionPickerTile
                state={heatIndexState}
                region={region}
                onZipChange={value =>
                  heatIndexState.setFormValue('projectDetails', 'zip', value)
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <ProductivitySavings />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <Info data={info} />
            </Grid>
          </Grid>
        </Box>
        <Links links={links} mt={2} />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default HeatIndexCalc
