import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store/salesToolsState'

interface APIAppearanceOption {
  id: number
  part?: {
    id: number
    part: string
  }
  color?: {
    id: number
    color: string
  }
  finish?: {
    id: number
    finish: string
  }
  hardware_finish?: {
    id: number
    hardware_finish: string
  }
  material?: {
    id: number
    material: string
  }
}

const AppearancePackage = () => {
  const { productData } = useSubmittalsState().getState()
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Appearance Package
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              {productData.appearances.map(
                (a: APIAppearanceOption, index: number) => (
                  <TableCell
                    sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, pr: 1 }}
                    key={`${index}Head`}
                  >
                    {a.part?.part}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {productData.appearances.map(
                (a: APIAppearanceOption, index: number) => (
                  <TableCell
                    sx={{ pt: 0, pb: 0, pl: 1, pr: 1 }}
                    id={index.toString()}
                    key={index}
                  >
                    {a.color?.color}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </Grid>
  )
}

export default AppearancePackage
