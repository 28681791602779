import { useAuth0 } from '@auth0/auth0-react'

interface Permission {
  permission_name: string
}

// TODO: Resolve local storage issue with auth0 so we don't need these
const ADMIN_DOMAINS = ['bigassfans.com', 'differential.com']

export function usePermissions() {
  const { user } = useAuth0()
  const DOMAIN = 'http://learn.bigassfans.com'
  const BAF_EMPLOYEE = 'BAF Employee'
  const BAF_ASSOCIATE = 'BAF Associate'
  const BAF_USER = 'General User'

  // Get roles and metadata from auth0 or local storage
  const roles = user?.[`${DOMAIN}/roles`] || []
  const hasMetadata = user?.user_metadata?.userType

  // Assign user roles
  const isEmployee = ADMIN_DOMAINS?.some(domain =>
    user?.email?.toLowerCase()?.includes(domain)
  )
  const isAdmin = isEmployee || roles.includes(BAF_EMPLOYEE)
  const isAssociate = roles?.includes(BAF_ASSOCIATE) && !isAdmin
  const isGeneralUser = roles?.includes(BAF_USER) && !isAdmin && !isAssociate
  const isGuest = !isGeneralUser && !isAdmin && !isAssociate

  // Get permissions from auth0
  const permissions = user?.[`${DOMAIN}/permissions`]?.map(
    (perm: Permission) => perm?.permission_name
  )

  return {
    user,
    hasMetadata,
    isAdmin,
    isAssociate,
    isGeneralUser,
    isGuest,
    permissions,
  }
}
