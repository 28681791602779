import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'
import cloneDeep from 'lodash/cloneDeep'

import { flatPaths } from '../features/design-guide/helpers/data'

import { LOCAL_STORAGE_KEY } from '../config/constants'

interface DesignGuidePath {
  path: string
  active?: boolean
  opened?: boolean
}

export interface GlobalState {
  designGuideSidebarOpened: boolean
  designGuidePaths: DesignGuidePath[]
}

const initialDesignGuideState = flatPaths.map(path => {
  let obj = { path } as DesignGuidePath
  if (path.split('-').length === 4) {
    obj['active'] = false
  }
  if (path.split('-').length < 4) {
    obj['opened'] = false
  }
  return obj
})

const initialState = {
  designGuideSidebarOpened: true,
  designGuidePaths: initialDesignGuideState,
}

const globalState = createState(initialState)

const wrapGlobalState = (s: State<GlobalState>) => ({
  get: () => s.value,
  resetState: () => s.set(cloneDeep(initialState)),
  getDesignGuideSidebarOpened: () => s.value.designGuideSidebarOpened,
  setDesignGuideSidebarOpened: (newOpenedStatus: boolean) =>
    s.set(p => ({ ...p, designGuideSidebarOpened: newOpenedStatus })),
  getDesignGuidePath: (pathToReturn: string) =>
    s.value.designGuidePaths.find(p => p.path === pathToReturn),
  setDesignGuidePaths: (newDesignGuidePaths: DesignGuidePath[]) =>
    s.set(p => ({ ...p, designGuidePaths: newDesignGuidePaths })),
})

export const accessGlobalState = () => wrapGlobalState(globalState)

export const useGlobalState = () => {
  const state = useState(globalState)
  state.attach(Persistence(LOCAL_STORAGE_KEY))
  return wrapGlobalState(state)
}
