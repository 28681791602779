import { useEffect, useState } from 'react'
import { axiosHelper } from 'utils'

type Tool = {
  tool: string
  icon: string
  link: string
  is_featured: number
  category: string
  description: string
  tags: string
  required_permission?: string
}

export function useToolConfigs() {
  const [tools, setTools] = useState<Tool[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  // Get tool configs
  useEffect(() => {
    if (!tools?.length && !loading) {
      setLoading(true)
      axiosHelper({
        url: '/tool-configs',
        method: 'get',
        success: (values: any) => {
          setLoading(false)
          setTools(values?.data)
        },
      })
    }
  }, [tools, loading])

  return { tools, loading }
}
