import cloneDeep from 'lodash/cloneDeep'
import sumBy from 'lodash/sumBy'
import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from 'config/constants'
import {
  getBafMethodData,
  getAireyeBafMethodData,
  getDoeMethodData,
  getExistingFansComparisonData,
  getEnvironmentalImpact,
} from 'utils/fanEnergyEstimatorUtils'

export interface Product {
  name?: string
  product_id?: number
  type?: string
  kwh_day?: string
  size?: string
}

interface ProductType {
  name?: string
  type?: string
}

interface LocationCost {
  location?: string
  residential?: number
  commercial?: number
  industrial?: number
}

interface Months {
  January: string
  February: string
  March: string
  April: string
  May: string
  June: string
  July: string
  August: string
  September: string
  October: string
  November: string
  December: string
}

export interface FanEnergyEstimatorState {
  aeosEquipped: boolean
  aireyeExistingFanModes: Months
  aireyeFanModes: Months
  bafFans: Product[]
  breaks15Min: number
  breaks60Min: number
  electricCost: number
  locationCosts: LocationCost[]
  existingFans: Product[]
  facilityType: string
  fanModes: Months
  fansLeftOn: boolean
  hoursPerWeek: number
  leadSaved: boolean
  productTypes: ProductType[]
  products: Product[]
  results: any
  selectedState: string
}

const DAYS_PER_YEAR = 365

const initialFanEnergyEstimatorState = {
  leadSaved: false,
  fansLeftOn: false,
  aeosEquipped: false,
  electricCost: 0,
  hoursPerWeek: 0,
  selectedState: '',
  facilityType: '',
  products: [] as Product[],
  productTypes: [] as ProductType[],
  locationCosts: [] as LocationCost[],
  bafFans: [] as Product[],
  existingFans: [] as Product[],
  results: null,
  breaks15Min: 0,
  breaks60Min: 0,
  fanModes: {
    January: 'Heat',
    February: 'Heat',
    March: 'Heat',
    April: 'Cool',
    May: 'Cool',
    June: 'Max Cool',
    July: 'Max Cool',
    August: 'Max Cool',
    September: 'Cool',
    October: 'Cool',
    November: 'Heat',
    December: 'Heat',
  },
  aireyeFanModes: {
    January: 'Off',
    February: 'Off',
    March: 'Off',
    April: 'Cool',
    May: 'Max Cool',
    June: 'Max Cool',
    July: 'Max Cool',
    August: 'Max Cool',
    September: 'Cool',
    October: 'Cool',
    November: 'Off',
    December: 'Off',
  },
  aireyeExistingFanModes: {
    January: 'Off',
    February: 'Off',
    March: 'Off',
    April: 'Cool',
    May: 'Max Cool',
    June: 'Max Cool',
    July: 'Max Cool',
    August: 'Max Cool',
    September: 'Cool',
    October: 'Cool',
    November: 'Off',
    December: 'Off',
  },
}

const fanEnergyEstimatorState = createState(initialFanEnergyEstimatorState)

const wrapFanEnergyEstimatorState = (s: State<FanEnergyEstimatorState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialFanEnergyEstimatorState,
      products: p?.products,
      productTypes: p?.productTypes,
      locationCost: p?.locationCost,
      bafFans: [p?.products?.[0]],
    })),
  setBafFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index] = value
      return { ...p, bafFans: fans, results: null }
    })
  },
  setBafFanQuantity: (quantity: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index].count = quantity
      return { ...p, bafFans: fans, results: null }
    })
  },
  removeBafFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.bafFans]
      delete fans[index]
      return { ...p, bafFans: fans.filter(Boolean), results: null }
    })
  },
  setExistingFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.existingFans)
      fans[index] = value
      return { ...p, existingFans: fans, results: null }
    })
  },
  resetExistingFans: () => {
    s.set((p: any) => {
      return { ...p, existingFans: [], results: null }
    })
  },
  setExistingFanQuantity: (quantity: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.existingFans)
      fans[index].count = quantity
      return { ...p, existingFans: fans, results: null }
    })
  },
  removeExistingFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.existingFans]
      delete fans[index]
      return { ...p, existingFans: fans.filter(Boolean), results: null }
    })
  },
  setProducts: (value: any) => {
    s.set((p: any) => ({ ...p, products: value }))
  },
  setLocationCosts: (value: any) => {
    s.set((p: any) => ({ ...p, locationCosts: value }))
  },
  setFanMode: (month: string, mode: string) => {
    s.set((p: any) => {
      const newFanModes = {
        ...p.fanModes,
        [month]: mode,
      }
      return { ...p, fanModes: newFanModes }
    })
  },
  setAireyeFanMode: (month: string, mode: string) => {
    s.set((p: any) => {
      const newFanModes = {
        ...p.aireyeFanModes,
        [month]: mode,
      }
      return { ...p, aireyeFanModes: newFanModes }
    })
  },
  setAireyeExistingFanMode: (month: string, mode: string) => {
    s.set((p: any) => {
      const newFanModes = {
        ...p.aireyeExistingFanModes,
        [month]: mode,
      }
      return { ...p, aireyeExistingFanModes: newFanModes }
    })
  },
  setProductTypes: (value: any) => {
    s.set((p: any) => ({ ...p, productTypes: value }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setFansLeftOn: (value: boolean) => {
    s.set((p: any) => ({ ...p, fansLeftOn: value, results: null }))
  },
  setAeosEquipped: (value: boolean) => {
    s.set((p: any) => ({ ...p, aeosEquipped: value, results: null }))
  },
  setHoursPerWeek: (value: number) => {
    s.set((p: any) => ({ ...p, hoursPerWeek: value, results: null }))
  },
  setSelectedState: (value: string) => {
    s.set((p: any) => ({ ...p, selectedState: value, results: null }))
  },
  setBreaks15Min: (value: number) => {
    s.set((p: any) => ({ ...p, breaks15Min: value, results: null }))
  },
  setBreaks60Min: (value: number) => {
    s.set((p: any) => ({ ...p, breaks60Min: value, results: null }))
  },
  setFacilityType: (value: string) => {
    s.set((p: any) => ({ ...p, facilityType: value, results: null }))
  },
  setElectricCost: (value: number) => {
    s.set((p: any) => ({ ...p, electricCost: value, results: null }))
  },
  setResults: () => {
    s.set((p: any) => {
      const isAireyeMode = p?.bafFans?.some((fan: Product) =>
        fan?.name?.includes('Aireye')
      )
      // Map types to BAF products
      const mappedBafProducts = [] as Product[]
      p?.bafFans?.forEach((product: any) => {
        const productTypes = cloneDeep(p?.productTypes)
        const type = productTypes?.find(
          (type: any) => type?.name === product?.name
        )?.type
        const count = product?.count || 1
        for (let i = 0; i < count; i++) {
          mappedBafProducts.push({
            ...product,
            type,
          })
        }
      })

      // Map types to existing products
      const mappedExistingProducts = [] as Product[]
      p?.existingFans?.forEach((product: any) => {
        const productTypes = cloneDeep(p?.productTypes)
        const type = productTypes?.find(
          (type: any) => type?.name === product?.name
        )?.type
        const count = product?.count || 1
        for (let i = 0; i < count; i++) {
          mappedExistingProducts.push({
            ...product,
            type,
          })
        }
      })

      // Group fans by type
      const largeFans = mappedBafProducts?.filter(
        (product: any) => product?.type === 'Large Dia'
      )
      const hssdFans = mappedBafProducts?.filter(
        (product: any) => product?.type === 'HSSD'
      )
      const lssdFans = mappedBafProducts?.filter(
        (product: any) => product?.type === 'LSSD'
      )

      // Get total energy values
      const totals = {
        maxCool: sumBy(mappedBafProducts, 'max_cool'),
        heat: sumBy(mappedBafProducts, 'heat'),
        cool: sumBy(mappedBafProducts, 'cool'),
      }

      // -------------- DOE METHOD -------------- //
      const doeMethodData = getDoeMethodData(largeFans, hssdFans, lssdFans)

      // -------------- BAF METHOD -------------- //
      const monthsData = isAireyeMode
        ? getAireyeBafMethodData(
            totals,
            p?.aireyeFanModes,
            p?.electricCost,
            p?.hoursPerWeek,
            p?.breaks15Min,
            p?.breaks60Min,
            p?.aeosEquipped,
            p?.fansLeftOn
          )
        : getBafMethodData(
            totals,
            p?.fanModes,
            p?.electricCost,
            p?.hoursPerWeek
          )

      // Get BAF energy cost totals
      const days = isAireyeMode ? sumBy(monthsData, 'days') : DAYS_PER_YEAR
      const usage = sumBy(monthsData, 'usage')
      const cost = sumBy(monthsData, 'cost')
      const monthsDataTotals = {
        name: 'Total',
        mode: '',
        days,
        hours: sumBy(monthsData, 'hours'),
        usage,
        cost,
        usagePerDay: isAireyeMode ? usage / days : usage / DAYS_PER_YEAR,
        costPerDay: isAireyeMode ? cost / days : cost / DAYS_PER_YEAR,
      }

      // -------------- Existing Fans Comparison -------------- //
      // Get existing fan total energy values
      const existingFanTotals = {
        maxCool: sumBy(mappedExistingProducts, 'max_cool'),
        heat: sumBy(mappedExistingProducts, 'heat'),
        cool: sumBy(mappedExistingProducts, 'cool'),
      }

      // Get total hours for each fan type
      const existingFansComparisonData = getExistingFansComparisonData(
        monthsData,
        existingFanTotals,
        p?.electricCost,
        p?.fansLeftOn
      )

      // -------------- Annual Savings Estimate -------------- //
      const savings =
        existingFansComparisonData?.energyTotalValue - monthsDataTotals?.usage
      const costSavings =
        existingFansComparisonData?.totalCost - monthsDataTotals?.cost

      // -------------- Environmental Impact -------------- //
      const environmentalImpact = getEnvironmentalImpact(savings)

      // -------------- Aireye Existing Fans -------------- //
      const aireyeExistingFansMonthsData = getAireyeBafMethodData(
        existingFanTotals,
        p?.aireyeExistingFanModes,
        p?.electricCost,
        p?.hoursPerWeek,
        p?.breaks15Min,
        p?.breaks60Min,
        false,
        p?.fansLeftOn
      )
      // Get BAF energy cost totals
      const aireyeExistingFansMonthsDataTotals = {
        name: 'Total',
        mode: '',
        days: sumBy(aireyeExistingFansMonthsData, 'days'),
        hours: sumBy(aireyeExistingFansMonthsData, 'hours'),
        usage: sumBy(aireyeExistingFansMonthsData, 'usage'),
        cost: sumBy(aireyeExistingFansMonthsData, 'cost'),
        usagePerDay: sumBy(aireyeExistingFansMonthsData, 'usage') / days,
        costPerDay:
          sumBy(aireyeExistingFansMonthsData, 'cost') /
          sumBy(aireyeExistingFansMonthsData, 'days'),
      }

      const results = {
        doeCost: {
          ...doeMethodData,
          largeFans,
          hssdFans,
          lssdFans,
        },
        bafCost: {
          monthsData,
          monthsDataTotals,
        },
        aireyeBafCost: {
          monthsData,
          monthsDataTotals,
        },
        aireyeExistingFansCost: {
          monthsData: aireyeExistingFansMonthsData,
          monthsDataTotals: aireyeExistingFansMonthsDataTotals,
        },
        existingFans: {
          energyTotal: existingFansComparisonData?.energyTotalValue,
          usagePerDay: existingFansComparisonData?.usagePerDay,
          totalCost: existingFansComparisonData?.totalCost,
          costPerDay: existingFansComparisonData?.costPerDay,
        },
        savings: {
          cost: costSavings,
          energy: savings,
        },
        environmentalImpact,
      }

      return { ...p, results }
    })
  },
})

export const accessFanEnergyEstimatorState = () =>
  wrapFanEnergyEstimatorState(fanEnergyEstimatorState)

export const useFanEnergyEstimatorState = () => {
  const state = useState(fanEnergyEstimatorState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-fan-energy-estimator`))
  return wrapFanEnergyEstimatorState(state)
}
