import React, { useState } from 'react'
import sortBy from 'lodash/sortBy'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import AppsIcon from '@mui/icons-material/Apps'

import { useToolConfigs } from 'hooks/useToolConfigs'

const AppMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState(null as any)
  const { tools } = useToolConfigs()

  return (
    <>
      <IconButton
        aria-controls="tool-menu"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e?.currentTarget)}
        aria-label="Open to show tools"
        title="Open to show tools"
      >
        <AppsIcon sx={{ width: '32px', height: '32px', color: 'black' }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{
          '.MuiPopover-paper': {
            borderRadius: '16px',
            backgroundColor: 'transparent !important',
          },
          '.MuiMenu-list': {
            borderRadius: '16px',
            backgroundColor: '#242424 !important',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '300px',
            display: 'flex',
            flexWrap: 'wrap',
            backgroundColor: '#242424',
            borderRadius: '16px',
          }}
        >
          {[...sortBy(tools, 'tool')].map(tool => (
            <MenuItem
              key={tool.link}
              value={tool.link}
              sx={{
                height: '110px',
                width: '100px',
                px: 1,
                zIndex: 100,
              }}
            >
              <Link
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  p: 1,
                }}
                href={tool.link}
              >
                <Box
                  component="img"
                  sx={{ width: '48px', height: '48px', mb: 0.5 }}
                  src={tool.icon}
                />
                <Box
                  sx={{
                    fontSize: '11px',
                    whiteSpace: 'normal',
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: 700,
                  }}
                >
                  {tool.tool}
                </Box>
              </Link>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  )
}

export default AppMenu
