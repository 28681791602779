import kebabCase from 'lodash/kebabCase'

import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'

import DataTable from './DataTable'

export interface TableProps {
  caption?: string
  headers?: string[]
  data: string[][]
  path?: string
  letter?: string
}

const Table = ({ path, letter, caption, headers, data }: TableProps) => {
  const label = kebabCase(caption)
  return (
    <Box>
      <DataTable label={label} headers={headers} data={data} path={path} />
      {caption && (
        <Typography fontStyle="italic" textAlign="center">
          <Box sx={{ m: 1, fontSize: 12 }}>
            Figure {path}
            {letter} - {caption}
          </Box>
        </Typography>
      )}
    </Box>
  )
}

export default Table
