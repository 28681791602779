import { useState, useRef, useLayoutEffect } from 'react'

import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TagIcon from '@mui/icons-material/Tag'

import { SubSectionProps } from '../helpers/interfaces'
import NestedSubSection from './NestedSubSection'
import BulletedList from './BulletedList'
import IndentedParagraph from './IndentedParagraph'
import Paragraph from './Paragraph'
import Pdf from './Pdf'
import Table from './table'
import Image from './Image'
import Hyperlink from './Hyperlink'
import OverlayedImage from './OverlayedImage'
import { useGlobalState } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'

const MainSubSection = ({
  subSectionObj,
  headerPrefix,
  path,
}: SubSectionProps) => {
  const headerRef = useRef<HTMLDivElement>(null)

  const globalState = useGlobalState()
  const globalPath = globalState.getDesignGuidePath(path)

  const navigate = useNavigate()

  const moveRefToTop = () => {
    if (headerRef.current) {
      const y =
        headerRef.current.getBoundingClientRect().top + window.pageYOffset - 95
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const handleRefClick = () => {
    const globalDesignGuidePaths = globalState.get().designGuidePaths
    const splitPath = path.split('-')
    const chapterPath = `${splitPath[0]}-${splitPath[1]}`
    const sectionPath = `${splitPath[0]}-${splitPath[1]}-${splitPath[2]}`

    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObject = { ...pathObject }
      newPathObject.active = false
      if (newPathObject.path === chapterPath) newPathObject.opened = true
      if (newPathObject.path === sectionPath) newPathObject.opened = true
      if (newPathObject.path === path) newPathObject.active = true
      return newPathObject
    })

    globalState.setDesignGuidePaths(newPaths)
    navigate(`#${path}`)
    moveRefToTop()
  }

  const { pathname } = useLocation()
  useLayoutEffect(() => {
    if (pathname.includes('#')) moveRefToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMounted = useRef(false)
  // used to only trigger on change, not on mount
  useLayoutEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      if (globalPath?.active) {
        moveRefToTop()
        navigate(`#${path}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalPath?.active])

  const header = `${headerPrefix} ${subSectionObj.mainContent}`

  const mappedData =
    subSectionObj.data &&
    subSectionObj.data.map((dataObj, index) => {
      let element
      switch (dataObj.type) {
        case 'image':
          element = (
            <Image key={index} {...dataObj.imageData!} path={headerPrefix} />
          )
          break
        case 'indent':
          element = <IndentedParagraph key={index} {...dataObj.indentData!} />
          break
        case 'link':
          element = <Hyperlink key={index} {...dataObj.urlData!} />
          break
        case 'list':
          element = <BulletedList key={index} {...dataObj.listData!} />
          break
        case 'overlay':
          element = (
            <OverlayedImage key={index} {...dataObj.overlayedImageData!} />
          )
          break
        case 'paragraph':
          element = <Paragraph key={index} content={dataObj.content!} />
          break
        case 'pdf':
          element = <Pdf key={index} {...dataObj.pdfData!} />
          break
        case 'table':
          element = (
            <Table key={index} {...dataObj.tableData!} path={headerPrefix} />
          )
          break
        default:
          element = <>error - type not found</>
          break
      }
      return element
    })

  const mappedNestedSubSections =
    subSectionObj.subSections &&
    subSectionObj.subSections.map((nestedSubSection, index) => (
      <NestedSubSection
        key={index}
        nestedSubSectionObj={nestedSubSection}
        headerPrefix={`${headerPrefix}.${index + 1}`}
        path={`${path}-${index + 1}`}
      />
    ))

  const [hover, setHover] = useState(false)

  return (
    <Box ref={headerRef}>
      <Typography
        variant="h5"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{ mt: 3 }}
      >
        {header}

        <IconButton
          color={hover ? 'secondary' : 'primary'}
          aria-label="copy direct hash link"
          onClick={handleRefClick}
        >
          <TagIcon />
        </IconButton>
      </Typography>
      {mappedData}
      <Box>{mappedNestedSubSections}</Box>
    </Box>
  )
}

export default MainSubSection
