import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { useProducts } from 'hooks/useProducts'

interface AddFanProps {
  clearOnBlur?: boolean
  clearIcon?: JSX.Element | null
  inputValue?: string
  onChange: (value: any) => void
  styles?: any
  value?: string
  overheadOnly?: boolean
}

const whiteUnderline = {
  borderBottom: 'white',
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
}

const nonOverheadProductCategories = [
  'Directional Fans',
  'Heaters',
  'Evaporative Coolers',
]

const ProductSelect = ({
  clearIcon = <Box sx={{ fontSize: '16px', fontWeight: 700, px: 0.5 }}>x</Box>,
  clearOnBlur = false,
  inputValue,
  onChange,
  styles,
  value = '',
  overheadOnly = false,
}: AddFanProps) => {
  const { products } = useProducts()
  return (
    <Box sx={styles}>
      <Autocomplete
        clearOnBlur={clearOnBlur}
        disablePortal
        clearIcon={clearIcon}
        size="small"
        value={value}
        inputValue={inputValue}
        options={products
          .filter(
            p =>
              p.name !== 'ShopFan' &&
              p.name !== 'Powerfoil 8 Plus' &&
              p.name !== 'Powerfoil Breeze'
                ? p
                : false // TODO: do this in the db instead https://github.com/bigassdata/sales-tools-api/issues/41
          )
          .filter(p => {
            if (!overheadOnly) return true
            return (
              !nonOverheadProductCategories.includes(p?.category.trim()) &&
              !p?.name?.includes('UV-C') &&
              !p?.name?.includes('CB6') &&
              !p?.name?.includes('Powerfoil X4 Plus') &&
              !p?.name?.includes('Isis')
            )
          })
          .sort((a, b) => {
            if (a.category === b.category) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            }
            return a.category > b.category ? 1 : -1
          })
          .filter(
            p =>
              p.category !== 'Heaters' &&
              p.category !== 'Controls' &&
              p.category !== 'HVLS Retail'
          )
          .map(p => p.name)}
        groupBy={option => products.find(p => p.name === option)!.category}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Add a fan..."
            variant="standard"
            sx={whiteUnderline}
            inputProps={{
              ...params.inputProps,
              style: { textTransform: 'uppercase' },
            }}
          />
        )}
        sx={{ minWidth: 190 }}
        onChange={async (event, newValue) => {
          event.preventDefault()
          if (newValue) onChange && onChange(newValue)
        }}
      />
    </Box>
  )
}

export default ProductSelect
