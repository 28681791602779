import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { SubSectionProps } from '../helpers/interfaces'
import { useGlobalState } from 'store'

const SidebarSubSection = ({
  subSectionObj,
  headerPrefix,
  path,
}: SubSectionProps) => {
  const globalState = useGlobalState()
  const globalPath = globalState.getDesignGuidePath(path)
  const globalDesignGuidePaths = globalState.get().designGuidePaths
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  const handleClick = () => {
    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObject = { ...pathObject }
      if (newPathObject.path === path) {
        newPathObject.active = true
      } else {
        newPathObject.active = false
      }
      return newPathObject
    })

    globalState.setDesignGuidePaths(newPaths)

    if (!mediumBreakpoint) {
      globalState.setDesignGuideSidebarOpened(false)
    }
  }

  const { hash } = useLocation()
  useEffect(() => {
    if (hash.length < 1) {
      const newPaths = globalDesignGuidePaths.map(pathObject => {
        const newPathObject = { ...pathObject }
        if (newPathObject?.active) newPathObject.active = false
        if (newPathObject?.opened) newPathObject.opened = false
        return newPathObject
      })

      globalState.setDesignGuidePaths(newPaths)
    } else {
      const newPath = hash.substring(1)
      const newPaths = globalDesignGuidePaths.map(pathObject => {
        const newPathObject = { ...pathObject }
        if (newPathObject?.active) newPathObject.active = false
        if (newPathObject.path === newPath) newPathObject.active = true
        return newPathObject
      })

      globalState.setDesignGuidePaths(newPaths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const header = `${headerPrefix} - ${subSectionObj.sidebarContent}`

  return (
    <ListItemButton
      selected={globalPath?.active}
      onClick={handleClick}
      sx={{
        '&:hover': { backgroundColor: 'primary.main' },
        '&.Mui-selected': { backgroundColor: 'primary.main' },
      }}
    >
      <ListItemText primary={header} />
    </ListItemButton>
  )
}

export default SidebarSubSection
