import { useEffect, useState } from 'react'
import { axiosHelper } from 'utils'

import { APIProduct } from 'store/scheduleBuilderState'

export function useProducts() {
  const [products, setProducts] = useState([] as APIProduct[])

  // Get product data
  useEffect(() => {
    const success = ({ data }: { data: APIProduct[] }) => {
      data.unshift({ id: -1, name: '', category: '', template: '' })
      setProducts(data)
    }
    if (!products?.length) axiosHelper({ url: '/products', success })
  }, [products])

  return { products }
}
