import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'
import cloneDeep from 'lodash/cloneDeep'

import { LOCAL_STORAGE_KEY } from '../config/constants'

export interface UserState {
  hasSetMetadata: boolean
}

const initialState = {
  hasSetMetadata: false,
}

const userState = createState(initialState)

const wrapUserState = (s: State<UserState>) => ({
  get: () => s.value,
  resetState: () => s.set(cloneDeep(initialState)),
  setHasSetMetadata: () => s.set(p => ({ ...p, hasSetMetadata: true })),
})

export const accessUserState = () => wrapUserState(userState)

export const useUserState = () => {
  const state = useState(userState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-user`))
  return wrapUserState(state)
}
