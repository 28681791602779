import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfNotesSection from 'components/pdf/PdfNotesSection'
import PdfInputSection from 'components/pdf/PdfInputSection'
import { useDestratToolState } from 'store'

const DestratToolPdf = () => {
  const destratToolState = useDestratToolState()
  const {
    results,
    isMetric,
    isBasedOnInputs,
    airfoilHeight,
    designAirTurnovers,
    floorToCeilingHeight,
    length,
    width,
    projectDate,
    bafContact,
    projectName,
    installLocation,
    product,
  } = destratToolState.get()

  const floorArea = length * width
  const airVolume = floorArea * floorToCeilingHeight

  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  })

  const notes = [
    `(1) Fan operating speed increases as airfoil height increases.`,
    `(2) The number of fans required is rounded up, unless within 5% (1.04 fans = 1 fan, 1.10 fans = 2 fans).`,
    `(3) Fans with Plus winglets are not recommend at greater than 40 feet.`,
    `(4) To increase clearance from fan to ceiling, decrease airfoil height.`,
    `(5) All data based on an unobstructed space; significant obstructions may cause underestimation of fans needed.`,
  ]

  const inputs = [
    { title: 'Project Name', value: projectName },
    { title: 'BAF Contact', value: bafContact },
    {
      title: 'Location',
      value: installLocation,
    },
    { title: 'Product', value: product?.name },
    { title: 'Date', value: projectDate },
    { title: 'Length', value: `${length} ${isMetric ? 'Meters' : ' Feet'}` },
    { title: 'Width', value: `${width} ${isMetric ? 'Meters' : ' Feet'}` },
    {
      title: 'Floor To Ceiling Height',
      value: `${floorToCeilingHeight} ${isMetric ? 'Meters' : ' Feet'}`,
    },
    {
      title: 'Airfoil Height',
      value: `${airfoilHeight} ${isMetric ? 'Meters' : ' Feet'}`,
    },
    {
      title: 'Design Air Turnovers',
      value: isBasedOnInputs ? 'N/A' : `${designAirTurnovers} Per Hour`,
    },
  ]

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '8px',
        marginHorizontal: '16px',
        marginVertical: '8px',
        borderRadius: '8px',
      }}
    >
      <Text style={{ ...styles.sectionTitle, borderBottom: '0' }}>Results</Text>
      <Text
        style={{
          ...styles.tableHeaderLabel,
          borderBottom: '0',
          marginBottom: '8px',
        }}
      >
        CALCULATED VALUES
      </Text>
      <View style={{ flexDirection: 'row', marginBottom: '16px' }}>
        <View style={{ flexDirection: 'column', marginRight: '16px' }}>
          <Text style={{ ...styles.tableHeaderLabel, borderBottom: '0' }}>
            Floor Area
          </Text>
          <Text style={{ ...styles.row, borderBottom: '0' }}>{floorArea}</Text>
          <Text
            style={{ ...styles.label, borderBottom: '0', marginTop: '-8px' }}
          >
            Square feet
          </Text>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <Text style={{ ...styles.tableHeaderLabel, borderBottom: '0' }}>
            Air Volume
          </Text>
          <Text style={{ ...styles.row, borderBottom: '0' }}>{airVolume}</Text>
          <Text
            style={{ ...styles.label, borderBottom: '0', marginTop: '-8px' }}
          >
            Cubic feet
          </Text>
        </View>
      </View>

      <Text
        style={{
          ...styles.tableHeaderLabel,
          borderBottom: '0',
          marginBottom: '8px',
        }}
      >
        EQUIPMENT REQUIREMENTS
      </Text>

      <View
        style={{ flexDirection: 'column', width: '75%', marginBottom: '8px' }}
      >
        <View style={{ ...styles.row }}>
          <Text style={{ ...styles.tableHeaderLabel, width: '200px' }}>
            Fan & Size
          </Text>
          <Text style={{ ...styles.tableHeaderLabel, width: '500px' }}>
            Quantity
          </Text>
        </View>

        {results?.productResults?.map((result: any) => (
          <View
            style={{
              ...styles.row,
              width: '75%',
              padding: '4px',
              marginBottom: '0',
              backgroundColor: result?.meetsSizeRequirements
                ? '#fff'
                : '#A9A9A9',
            }}
          >
            <Text style={{ ...styles.label, width: '300px' }}>
              {result?.name}
            </Text>
            <Text style={{ ...styles.label, width: '500px' }}>
              {result?.value}
            </Text>
          </View>
        ))}
      </View>

      {isBasedOnInputs && (
        <View style={{ flexDirection: 'column' }}>
          <Text style={{ ...styles.tableHeaderLabel }}>
            Delivered Air Turnovers per Hour (AT/HR)
          </Text>
          <Text style={{ ...styles.row }}>{results?.turnovers}</Text>
        </View>
      )}
    </View>
  )

  return (
    <Document>
      <Page size="A4">
        <PdfHeader title="Heat Savings Estimator" />
        <PdfInputSection inputs={inputs} />
        {renderResultsSection()}
        <PdfNotesSection notes={notes} />
      </Page>
    </Document>
  )
}

export default DestratToolPdf
