import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { useSubmittalsState } from 'store/salesToolsState'

const CriticalDimensions = () => {
  const {
    fan,
    sizeData,
    productData,
    productDetails,
    mountingHardware,
    mountData,
    dropTubeData,
  } = useSubmittalsState().getState()
  const { size } = productDetails.value
  const { mount, extensionTube } = mountingHardware.value
  let warranty = productData.warranty
  const full =
    (!warranty && fan.template === 'Large Fans') ||
    fan.template === 'E Series' ||
    fan.template === 'Radiant Heaters'
  return (
    <Grid item md={full ? 12 : 6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Critical Dimensions
        </Typography>
        {fan.template === 'Large Fans' && (
          <Table size="small">
            <TableHead>
              <TableRow>
                {extensionTube && sizeData.drop_tubes.length > 0 && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'ftmHead'}
                  >
                    {`Foil to Mount`}
                  </TableCell>
                )}
                {sizeData.fan_diameter && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'fanDiamHead'}
                  >
                    {`Fan Diameter`}
                  </TableCell>
                )}
                {sizeData.cage_diameter && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'cageDiamHead'}
                  >
                    {`Cage Diameter`}
                  </TableCell>
                )}
                {sizeData.cage_depth && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'cageDepthHead'}
                  >
                    {`Cage Depth`}
                  </TableCell>
                )}
                {(sizeData.height ||
                  (sizeData.mounts.length > 0 &&
                    mount &&
                    mountData.height)) && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'height'}
                  >
                    {`Height`}
                  </TableCell>
                )}
                {(sizeData.depth ||
                  (sizeData.mounts.length > 0 && mount && mountData.depth)) && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'depth'}
                  >
                    {`Depth`}
                  </TableCell>
                )}
                {(sizeData.width ||
                  (sizeData.mounts.length > 0 && mount && mountData.width)) && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'width'}
                  >
                    {`Width`}
                  </TableCell>
                )}
                {fan.name === 'AirEye' && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'bladeDiam'}
                  >
                    {`Blade Diameter`}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {extensionTube && sizeData.drop_tubes.length > 0 && (
                  <TableCell sx={{ pt: 0, pb: 0, pl: 1, pr: 1 }} key={'ftm'}>
                    {`${dropTubeData.foil_to_mount}"`}
                  </TableCell>
                )}
                {sizeData.fan_diameter && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'fanDiam'}
                  >
                    {`${sizeData.fan_diameter}"`}
                  </TableCell>
                )}
                {sizeData.cage_diameter && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'cageDiam'}
                  >
                    {`${sizeData.cage_diameter}"`}
                  </TableCell>
                )}
                {sizeData.cage_depth && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'cageDepth'}
                  >
                    {`${sizeData.cage_depth}"`}
                  </TableCell>
                )}
                {(sizeData.height ||
                  (sizeData.mounts.length > 0 &&
                    mount &&
                    mountData.height)) && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'height'}
                  >
                    {`${sizeData.height ?? mountData.height}"`}
                  </TableCell>
                )}
                {(sizeData.depth ||
                  (sizeData.mounts.length > 0 && mount && mountData.depth)) && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'depth'}
                  >
                    {`${sizeData.depth ?? mountData.depth}"`}
                  </TableCell>
                )}
                {(sizeData.width ||
                  (sizeData.mounts.length > 0 && mount && mountData.width)) && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'width'}
                  >
                    {`${sizeData.width ?? mountData.width}"`}
                  </TableCell>
                )}
                {fan.name === 'AirEye' && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'bladeDiam'}
                  >
                    {`${size.substring(0, 2)}.5"`}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        )}
        {fan.template === 'E Series' && (
          <Table size="small">
            <TableHead>
              <TableRow>
                {extensionTube && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'ftmHead'}
                  >
                    {`Foil to Mount`}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'width'}
                >
                  {`Width`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {extensionTube && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'ftm'}
                  >
                    {`${dropTubeData.foil_to_mount}"`}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'width'}
                >
                  {`${sizeData.width}"`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {fan.template === 'Haiku' && (
          <Table size="small">
            <TableHead>
              <TableRow>
                {extensionTube && (
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'mthHead'}
                  >
                    {`Mount to Hub`}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'fanDiamHead'}
                >
                  {`Fan Diameter`}
                </TableCell>
                {mount &&
                  sizeData.mounts.length > 0 &&
                  mountData.mount_diameter !== null && (
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        pt: 0,
                        pb: 0,
                        pl: 1,
                        pr: 1,
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={'mountDiamHead'}
                    >{`Mount Diameter`}</TableCell>
                  )}
                {extensionTube &&
                  (mountData?.mount_diameter === null ||
                    sizeData.drop_tubes.length > 0) && (
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        pt: 0,
                        pb: 0,
                        pl: 1,
                        pr: 1,
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={'downrodHead'}
                    >{`Downrod`}</TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {extensionTube && (
                  <TableCell
                    sx={{
                      pt: 0,
                      pb: 0,
                      pl: 1,
                      pr: 1,
                      '@media print': { fontSize: '0.8rem' },
                    }}
                    key={'mth'}
                  >
                    {`${
                      sizeData.mounts.length > 0
                        ? mountData.mount_to_hub
                        : dropTubeData.mount_to_hub
                    }"`}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'fanDiam'}
                >
                  {`${sizeData.fan_diameter}"`}
                </TableCell>
                {mount &&
                  sizeData.mounts.length > 0 &&
                  mountData.mount_diameter !== null && (
                    <TableCell
                      sx={{
                        pt: 0,
                        pb: 0,
                        pl: 1,
                        pr: 1,
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={'mountDiam'}
                    >{`${mountData.mount_diameter}"`}</TableCell>
                  )}
                {extensionTube &&
                  (mountData?.mount_diameter === null ||
                    sizeData.drop_tubes.length > 0) && (
                    <TableCell
                      sx={{
                        pt: 0,
                        pb: 0,
                        pl: 1,
                        pr: 1,
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={'downrod'}
                    >{`${extensionTube}"`}</TableCell>
                  )}
              </TableRow>
            </TableBody>
          </Table>
        )}
        {fan.template === 'Sweat Bee' && mount && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'height'}
                >
                  {`Height`}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'depth'}
                >
                  {`Depth`}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'width'}
                >
                  {`Width`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'height'}
                >
                  {`${sizeData.height ?? mountData.height}"`}
                </TableCell>
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'depth'}
                >
                  {`${sizeData.depth ?? mountData.depth}"`}
                </TableCell>
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'width'}
                >
                  {`${sizeData.width ?? mountData.width}"`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {fan.template === 'Radiant Heaters' && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'minTubeHead'}
                >
                  {`Minimum Length of Tube (A)`}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'minUTubeHead'}
                >
                  {`Minimum Length of U-Tube (B)`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'minTube'}
                >
                  {`${sizeData.radiant_heater.min_tube_length}`}
                </TableCell>
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={'minUTube'}
                >
                  {`${sizeData.radiant_heater.min_utube_length}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Card>
    </Grid>
  )
}

export default CriticalDimensions
