import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from '../config/constants'

export interface FormState {
  value: any
}
export interface APIProduct {
  id: number
  name: string
  category: string
  template: string
}
export interface APIProductData {
  id: number
  name: string
  category: string
  coverage_area: number
  water_capacity: number
  water_pressure: number
  num_airfoils: number
  schedule_builder_controller: string
  addons: any
  warranty: any
  links: any
  technicalNotes: any
  modules: any
  controllers: any
  features: any
  appearances: any
  sizes: any
  schedule_builder_notes: any
  schedule_builder_links: any
}
export interface APIProductSizeData {
  size: string
  weight?: string
  motor?: string
  rpm?: number
  max_sound?: string
  height?: string
  width?: string
  depth?: string
  cage_diameter?: string
  cage_depth?: string
  fan_diameter?: string
  airflow?: string
  schedule_builder_motor?: string
  fuels: any
  voltages: any
  drop_tubes: any
  mounts: any
  radiant_heater: any
  unit_heater: any
}

interface APIVoltageData {
  id: number
  voltage_id: number
  voltage: string
  supply_circuit_size?: number
  full_load_amperage?: string
  max_amps?: string
  max_watts?: string
  rpm?: number
}

interface APIMountData {
  id: number
  mount_id: number
  mount: string
  drop_tube?: string
  mount_diameter?: string
  mount_to_hub?: string
  weight?: string
  depth?: string
  height?: string
  width?: string
  rpm?: number
  airflow?: number
  max_watts?: string
}

export interface ScheduleBuilderState {
  valid: boolean
  validated: boolean
  schedules: any
  leadSaved: boolean
}

export interface ScheduleState {
  product: APIProduct
  productData: APIProductData
  sizeData: APIProductSizeData
  voltageData: APIVoltageData
  mountData: APIMountData
  productDetails: FormState
}
export interface WrappedScheduleBuilderState {
  getState: () => ScheduleBuilderState
  reset: () => void
  setValidated: () => void
  setValid: (value: boolean) => void
  setProduct: (product: APIProduct) => void
  setFormValue: (
    stateKey: keyof ScheduleBuilderState,
    valueKey: string,
    value: any
  ) => void
  setProductData: (data: APIProductData) => void
  setSizeData: (data: APIProductSizeData) => void
  setVoltageData: (data: APIVoltageData) => void
  setMountData: (data: APIMountData) => void
}

const deepClone = (value: any) => JSON.parse(JSON.stringify(value))

const initialData = {
  product: {
    id: -1,
    name: '',
    category: '',
    template: '',
  },
  // store data from backend queries
  productData: {
    id: -1,
    name: '',
    category: '',
    coverage_area: -1,
    water_capacity: -1,
    water_pressure: -1,
    num_airfoils: -1,
    schedule_builder_controller: '',
    addons: [],
    warranty: [],
    links: [],
    technicalNotes: [],
    modules: [],
    controllers: [],
    features: [],
    appearances: [],
    sizes: [],
    schedule_builder_notes: [],
    schedule_builder_links: [],
  },
  sizeData: {
    size: '',
    fuels: [],
    voltages: [],
    drop_tubes: [],
    mounts: [],
    radiant_heater: {},
    unit_heater: {},
  } as APIProductSizeData,
  voltageData: {
    id: -1,
    voltage_id: -1,
    voltage: '',
    max_amps: '',
  } as APIVoltageData,
  mountData: {
    id: -1,
    mount_id: -1,
    mount: '',
  } as APIMountData,
  // store inputted values from form
  productDetails: {
    value: {
      tag: '',
      size: null,
      sizeId: null,
      voltage: null,
      model: null,
      mount: null,
      material: null,
      notes_start: 1,
      currNotes: [],
      prevNotes: [],
    },
  },
}

const scheduleBuilderInitialState = {
  valid: false,
  validated: false,
  schedules: { 0: initialData },
  leadSaved: false,
}

const scheduleBuilderState = createState(scheduleBuilderInitialState)

const wrapScheduleBuilderState = (s: State<ScheduleBuilderState>) => ({
  getState: () => s.value,
  reset: () =>
    s.set((p: any) => {
      return deepClone(scheduleBuilderInitialState)
    }),
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setValid: (value: boolean) => s.set((p: any) => ({ ...p, valid: value })),
  setValidated: () => s.set((p: any) => ({ ...p, validated: true })),
  removeSchedule: (schedule_id: number) => {
    s.set((p: any) => {
      delete p.schedules[schedule_id]
      const newSchedules = {} as any
      Object.keys(p.schedules).forEach((key, index: number) => {
        newSchedules[index] = p.schedules[key]
      })
      return {
        ...p,
        schedules: {
          ...newSchedules,
        },
      }
    })
  },
  setProduct: (product: APIProduct, schedule_id: number) => {
    s.set((p: any) => ({
      ...p,
      schedules: {
        ...p.schedules,
        [schedule_id]: deepClone(initialData),
      },
    }))
    s.set((p: any) => ({
      ...p,
      schedules: {
        ...p.schedules,
        [schedule_id]: {
          ...p.schedules[schedule_id],
          product: product,
        },
      },
    }))
  },
  addSchedule: () => {
    let length = Object.keys(s.schedules).length
    s.schedules.set((p: any) => ({
      ...p,
      [length]: deepClone(initialData),
    }))
  },
  setFormValue: (
    schedule_id: number,
    stateKey: keyof ScheduleState,
    valueKey: string,
    value: any
  ) => {
    s.set((p: any) => ({ ...p, valid: false }))
    s.schedules.set((p: any) => ({
      ...p,
      [schedule_id]: {
        ...p[schedule_id],
        [stateKey]: {
          ...p[schedule_id][stateKey],
          value: {
            ...p[schedule_id][stateKey].value,
            [valueKey]: value,
          },
        },
      },
    }))
  },
  setProductData: (data: APIProductData, schedule_id: number) => {
    s.set((p: any) => ({
      ...p,
      schedules: {
        ...p.schedules,
        [schedule_id]: {
          ...p.schedules[schedule_id],
          productData: data,
        },
      },
    }))
  },
  setSizeData: (data: APIProductSizeData, schedule_id: number) => {
    s.set((p: any) => ({
      ...p,
      schedules: {
        ...p.schedules,
        [schedule_id]: {
          ...p.schedules[schedule_id],
          sizeData: data,
        },
      },
    }))
  },
  setVoltageData: (data: APIVoltageData, schedule_id: number) => {
    s.set((p: any) => ({
      ...p,
      schedules: {
        ...p.schedules,
        [schedule_id]: {
          ...p.schedules[schedule_id],
          voltageData: data,
        },
      },
    }))
  },
  setMountData: (data: APIMountData, schedule_id: number) => {
    s.set((p: any) => ({
      ...p,
      schedules: {
        ...p.schedules,
        [schedule_id]: {
          ...p.schedules[schedule_id],
          mountData: data,
        },
      },
    }))
  },
  removeNote: (schedule_id: number, start: number) => {
    let copy = s.get()
    let removed = JSON.parse(
      JSON.stringify(
        copy.schedules[schedule_id].productData.schedule_builder_notes
      )
    )
    removed.splice(start, 1)
    s.schedules.set((p: any) => ({
      ...p,
      [schedule_id]: {
        ...p[schedule_id],
        productData: {
          ...p[schedule_id].productData,
          schedule_builder_notes: removed,
        },
      },
    }))
  },
})

export const accessScheduleBuilderState = () =>
  wrapScheduleBuilderState(scheduleBuilderState)

export const useScheduleBuilderState = () => {
  const state = useState(scheduleBuilderState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-scheduleBuilder`))
  return wrapScheduleBuilderState(state)
}
