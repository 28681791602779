import TextField from '@mui/material/TextField'

export interface TextFieldProps {
  required?: boolean
  disabled?: boolean
  helperText?: string
  label: string
  name: string
  value: any
  inputProps?: any
  InputProps?: any
  error?: any
  onChange?: any
  onBlur?: any
  onFocus?: any
  type?: string
}

const StyledTextField = ({
  required,
  helperText,
  label,
  name,
  value,
  inputProps,
  InputProps,
  error,
  onChange,
  onFocus,
  disabled,
  onBlur,
  type = 'text',
}: TextFieldProps) => (
  <TextField
    required={required}
    helperText={helperText}
    size="small"
    margin="dense"
    disabled={disabled}
    type={type}
    variant="standard"
    inputProps={inputProps}
    InputProps={InputProps}
    label={label}
    name={name}
    value={value}
    error={error}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    sx={{ width: '100%', mt: 0 }}
  />
)

export default StyledTextField
