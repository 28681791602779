import cloneDeep from 'lodash/cloneDeep'
import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from 'config/constants'
import getInterpolatedSqftByLoadClass from 'utils/getInterpolatedSqftByLoadClass'

const LOAD_CLASSES = {
  Agriculture: 'C1',
  'Automotive Service': 'C1',
  Aviation: 'C7',
  Education: 'C3',
  Fitness: 'C6',
  Government: 'C3',
  Grocery: 'C5',
  Hospitality: 'C3',
  Manufacturing: 'C1',
  Office: 'C3',
  'Public Spaces': 'C4',
  'Restaurant/Bar': 'C5',
  Retail: 'C3',
  'Warehouse/Distribution': 'C1',
  'Worship Facilities': 'C3',
}

// Environmental impact multipliers
const EQ_BBTU_MULTI = 3.412
const CO2 = 0.9905
const CO2_CFF = 117000
const CO2E = 0.996
const CO2E_CCF = 149466
const PARTICULATES = 2744
const PARTICULATES_CCF = 7
const CARS = 0.002
const CARS_CCF = 11.5
const ACRES = 0.0009
const ACRES_CCF = 63.5
const BULBS = 0.025
const BULBS_CCF = 1804

export interface Product {
  name?: string
  product_id?: number
  type?: string
  kwh_day?: string
  cool: number
  max_cool: number
  heat: number
  count: number
  size?: string
}

interface ProductType {
  name?: string
  type?: string
}

interface LocationCost {
  location?: string
  cents_kwh?: string
}

interface LocationGasCost {
  location?: string
  usd_ccf?: string
}

interface CitiesAgroZone {
  city_state?: string
  city?: string
  state?: string
  agro_climatic_zone: string
}

interface ClimateRegion {
  max?: number
  heat?: number
  cool?: number
  climate_region?: number
  location?: string
}

interface PrimaryUse {
  name?: string
  type?: string
}

interface EmData {
  climate_zone?: string
  type?: string
}

export interface HeatSavingsState {
  bafFans: Product[]
  existingFans: Product[]
  leadSaved: boolean
  results: any

  // Data
  citiesAgroZonesData: CitiesAgroZone[]
  climateRegionsData: ClimateRegion[]
  locationCostsData: LocationCost[]
  locationGasCostsData: LocationGasCost[]
  emData: EmData[]
  primaryUsesData: PrimaryUse[]
  productTypes: ProductType[]
  products: Product[]

  // Input values
  companyName: string
  installLocation: any
  primaryUse: any
  ceilingHeight: any
  heated: any
  floorArea: any
  operatingHours: any
  energyDataSource: any
}

const initialHeatSavingsState = {
  bafFans: [] as Product[],
  existingFans: [] as Product[],
  leadSaved: false,
  results: null,

  // Data
  citiesAgroZonesData: [] as CitiesAgroZone[],
  climateRegionsData: [] as ClimateRegion[],
  locationCostsData: [] as LocationCost[],
  locationGasCostsData: [] as LocationGasCost[],
  emData: [] as EmData[],
  primaryUsesData: [] as PrimaryUse[],
  productTypes: [] as ProductType[],
  products: [] as Product[],

  // Input values
  companyName: '',
  installLocation: null,
  floorArea: null,
  ceilingHeight: null,
  primaryUse: null,
  heated: 'Yes - AHU - Electricity',
  operatingHours: null,
  energyDataSource: null,
}

const heatSavingsState = createState(initialHeatSavingsState)

const wrapHeatSavingsState = (s: State<HeatSavingsState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialHeatSavingsState,
      products: p?.products,
      primaryUsesData: p?.primaryUsesData,
      bafFans: [p?.products?.[0]],
    })),
  setBafFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index] = value
      return { ...p, bafFans: fans, results: null }
    })
  },
  setBafFanQuantity: (quantity: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index].count = quantity
      return { ...p, bafFans: fans, results: null }
    })
  },
  removeBafFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.bafFans]
      delete fans[index]
      return { ...p, bafFans: fans.filter(Boolean), results: null }
    })
  },
  setExistingFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.existingFans)
      fans[index] = value
      return { ...p, existingFans: fans, results: null }
    })
  },
  resetExistingFans: () => {
    s.set((p: any) => {
      return { ...p, existingFans: [], results: null }
    })
  },
  setExistingFanQuantity: (quantity: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.existingFans)
      fans[index].count = quantity
      return { ...p, existingFans: fans, results: null }
    })
  },
  removeExistingFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.existingFans]
      delete fans[index]
      return { ...p, existingFans: fans.filter(Boolean), results: null }
    })
  },
  setCompanyName: (value: string) => {
    s.set((p: any) => ({ ...p, companyName: value, results: null }))
  },
  setFloorArea: (value: number) => {
    s.set((p: any) => ({ ...p, floorArea: value, results: null }))
  },
  setOperatingHours: (value: number) => {
    s.set((p: any) => ({ ...p, operatingHours: value, results: null }))
  },
  setPrimaryUse: (value: string) => {
    s.set((p: any) => ({ ...p, primaryUse: value, results: null }))
  },
  setEnergyDataSource: (value: string) => {
    s.set((p: any) => ({ ...p, energyDataSource: value, results: null }))
  },
  setHeated: (value: string) => {
    s.set((p: any) => ({ ...p, heated: value, results: null }))
  },
  setCeilingHeight: (value: number) => {
    s.set((p: any) => ({ ...p, ceilingHeight: value, results: null }))
  },
  setInstallLocation: (value: string) => {
    s.set((p: any) => ({ ...p, installLocation: value, results: null }))
  },
  setProducts: (value: any) => {
    s.set((p: any) => ({ ...p, products: value }))
  },
  setLocationCostsData: (value: any) => {
    s.set((p: any) => ({ ...p, locationCostsData: value }))
  },
  setLocationGasCostsData: (value: any) => {
    s.set((p: any) => ({ ...p, locationGasCostsData: value }))
  },
  setPrimaryUsesData: (value: any) => {
    s.set((p: any) => ({ ...p, primaryUsesData: value }))
  },
  setClimateRegionsData: (value: any) => {
    s.set((p: any) => ({ ...p, climateRegionsData: value }))
  },
  setEmData: (value: any) => {
    s.set((p: any) => ({ ...p, emData: value }))
  },
  setCitiesAgroZonesData: (value: any) => {
    s.set((p: any) => ({ ...p, citiesAgroZonesData: value }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setResults: () => {
    s.set((p: any) => {
      const isHeated = p?.heated !== 'No'
      const isGas = p?.heated?.includes('Gas')
      const isNaturalGas = p?.heated === 'Yes - AHU - Natural Gas'
      const isRadiantHeat = p?.heated === 'Yes - Radiant Heaters - Natural Gas'
      const isBafMethod = p?.energyDataSource === 'BAF Energy Models'

      //--------------------ZONE DATA----------------------//
      const primaryUse = p?.primaryUsesData.find(
        (use: any) => use?.primary_use === p?.primaryUse
      )
      const agroZoneData = p?.citiesAgroZonesData?.find(
        (agroData: any) => agroData?.city_state === p?.installLocation
      )

      //------------------LOCATION USAGE COST--------------------//
      const locationGasCostData = p?.locationGasCostsData?.find(
        (location: any) => location?.location === agroZoneData?.state
      )
      const locationCostData = p?.locationCostsData?.find(
        (location: any) => location?.location === agroZoneData?.state
      )
      const centsKwh = locationCostData?.cents_kwh || 0
      const usdCcf = locationGasCostData?.usd_ccf || 0

      //--------------------EXISTING FAN TOTALS----------------------//
      const fanTotals = p?.existingFans?.map((fan: Product) => {
        const regionData = p?.climateRegionsData?.find(
          (region: any) => region?.location === agroZoneData?.state
        )
        const maxCoolTotal =
          (fan.max_cool * regionData?.max * p?.operatingHours * fan?.count) /
          1000
        const coolTotal =
          (fan.cool * regionData?.cool * p?.operatingHours * fan?.count) / 1000
        const heatTotal =
          (fan.heat * regionData?.heat * p?.operatingHours * fan?.count) / 1000
        return maxCoolTotal + coolTotal + heatTotal
      })
      const existingFansTotal = fanTotals.reduce(
        (runningTotal: number, x: number) => runningTotal + x,
        0
      )
      const existingFanTotalCost = (centsKwh / 100) * existingFansTotal || 0

      //--------------------BAF FAN TOTALS----------------------//
      const bafFanTotals = p?.bafFans?.map((fan: Product) => {
        const count = fan?.count || 1
        const regionData = p?.climateRegionsData?.find(
          (region: any) => region?.location === agroZoneData?.state
        )
        const maxCoolTotal =
          (fan.max_cool * regionData?.max * p?.operatingHours * count) / 1000
        const coolTotal =
          (fan.cool * regionData?.cool * p?.operatingHours * count) / 1000
        const heatTotal = (fan.heat * regionData?.heat * 168 * count) / 1000
        return maxCoolTotal + coolTotal + heatTotal
      })
      const bafFansTotal = bafFanTotals.reduce(
        (runningTotal: number, x: number) => runningTotal + x,
        0
      )
      const bafFanTotalCost = (centsKwh / 100) * bafFansTotal || 0

      //-----------------HEATING ENERGY DATA------------------//
      const regionData = p?.climateRegionsData?.find(
        (region: any) => region?.location === agroZoneData?.state
      )
      const climateRegionData =
        primaryUse?.[`region_${regionData?.climate_region}`]
      const primaryUseData = isGas
        ? (climateRegionData / 3412 / 298.932) * 10
        : climateRegionData / 3412
      const gasMultiplier = isRadiantHeat ? 0.15 : 0.3
      const savingsPercent = Math.min(gasMultiplier, p?.ceilingHeight / 100)
      const radiantHeatSavingsPercent = Math.min(0.15, p?.ceilingHeight / 100)
      const energyUsage = primaryUseData * p?.floorArea
      //Electric
      const electricityUsageCost = (energyUsage * centsKwh) / 100
      const electricityUsageSavings = energyUsage * savingsPercent
      const electricityCostSavings = electricityUsageCost * savingsPercent
      // AHU - Natural Gas
      const gasUsageCost = energyUsage * usdCcf
      const gasUsageSavings = energyUsage * savingsPercent
      const gasCostSavings = gasUsageCost * savingsPercent
      // Radiant Heater - Natural Gas
      const radiantHeaterUsageCost = energyUsage * usdCcf
      const radiantHeaterUsageSavings = energyUsage * radiantHeatSavingsPercent
      const radiantHeaterCostSavings =
        radiantHeaterUsageCost * radiantHeatSavingsPercent

      //--------------------BAF EM THERMAL DATA--------------------//
      const loadClass = LOAD_CLASSES[p?.primaryUse as keyof typeof LOAD_CLASSES]
      const agroClimateZone = agroZoneData?.agro_climatic_zone
      const x0 = p?.floorArea
      const interpolatedSqft = getInterpolatedSqftByLoadClass(
        loadClass,
        p?.floorArea
      )
      const x1 = interpolatedSqft[0]
      const x2 = interpolatedSqft[1]
      const y1Code = `${loadClass}_${x1}`
      const y2Code = `${loadClass}_${x2}`
      const emData = p?.emData?.find(
        (em: any) => em?.climate_zone === agroClimateZone
      )
      const y1 = parseFloat(emData?.[y1Code])
      const y2 = parseFloat(emData?.[y2Code])
      const y = y1 === y2 ? y1 : y1 + (x0 - x1) * ((y2 - y1) / (x2 - x1))
      const heatEnergyUsageCCF = y * p?.floorArea
      const heatEnergyUsageUSD = usdCcf * heatEnergyUsageCCF
      const heatSavingsUSD = savingsPercent * heatEnergyUsageUSD
      const heatSavingsCFF = heatEnergyUsageCCF * savingsPercent

      //-----------------ENVIRONMENTAL IMPACT RESULTS-----------------//
      const fanEnergySavingsTotal = existingFansTotal - bafFansTotal
      const heatSavingsTotalKwh = isNaturalGas
        ? gasUsageSavings
        : isRadiantHeat
        ? radiantHeaterUsageSavings
        : electricityUsageSavings
      const energySum =
        !isHeated || isGas
          ? fanEnergySavingsTotal
          : heatSavingsTotalKwh + fanEnergySavingsTotal
      const energySumCcf = !isHeated
        ? 0
        : isBafMethod
        ? heatSavingsCFF
        : isRadiantHeat
        ? radiantHeaterUsageSavings
        : gasUsageSavings
      const eqBbtu = (energySum * EQ_BBTU_MULTI) / 1000000
      const eqBbtuCcf = (energySumCcf * 102) / 1000000
      const co2Gas = eqBbtuCcf * CO2_CFF
      const co2Electric = energySum * CO2
      const co2 = isGas ? co2Electric + co2Gas : co2Electric
      const particulatesGas = eqBbtuCcf * PARTICULATES_CCF
      const particulatesElectric = eqBbtu * PARTICULATES
      const particulates = isGas
        ? particulatesGas + particulatesElectric
        : particulatesElectric
      const co2eGas = eqBbtuCcf * CO2E_CCF
      const co2eElectric = energySum * CO2E
      const co2e = isGas ? co2eGas + co2eElectric : co2eElectric
      const carsGas = eqBbtuCcf * CARS_CCF
      const carsElectric = energySum * CARS
      const cars = isGas ? carsGas + carsElectric : carsElectric
      const acresGas = eqBbtuCcf * ACRES_CCF
      const acresElectric = energySum * ACRES
      const acres = isGas ? acresGas + acresElectric : acresElectric
      const bulbsGas = eqBbtuCcf * BULBS_CCF
      const bulbsElectric = energySum * BULBS
      const bulbs = isGas ? bulbsGas + bulbsElectric : bulbsElectric

      const getHeatSavings = () => {
        if (p?.heated === 'No') return 0
        if (isGas && isBafMethod) return heatSavingsUSD
        if (isNaturalGas) return gasCostSavings
        if (isRadiantHeat) return radiantHeaterCostSavings
        return electricityCostSavings
      }

      // Build results
      const results = {
        savings: {
          heatSavings: getHeatSavings(),
          fanEnergySavingsCost: existingFanTotalCost - bafFanTotalCost,
        },
        environmentalImpact: [
          {
            title: 'Reduct. in Carbon Dioxide (CO2)',
            metric: 'Lbs',
            icon: 'Co2',
            value: co2 <= 0 ? 0 : co2,
          },
          {
            title: 'Reduct. in Particulates',
            metric: 'Lbs',
            icon: 'Grain',
            value: particulates <= 0 ? 0 : particulates,
          },
          {
            title: 'Greenhouse Gas Reduct. (CO2e)',
            metric: 'Lbs',
            icon: 'GasMeter',
            value: co2e <= 0 ? 0 : co2e,
          },
          {
            title: 'Equiv. Passenger Cars Reduction',
            metric: 'Cars',
            icon: 'DirectionsCar',
            value: cars <= 0 ? 0 : cars,
          },
          {
            title: 'Equiv. Forest Carbon Sequestration',
            metric: 'Acres',
            icon: 'Park',
            value: acres <= 0 ? 0 : acres,
          },
          {
            title: 'Equiv. Legacy Light Bulbs to LED',
            metric: 'Bulbs',
            icon: 'Lightbulb',
            value: bulbs <= 0 ? 0 : bulbs,
          },
        ],
      }

      return { ...p, results }
    })
  },
})

export const accessHeatSavingsState = () =>
  wrapHeatSavingsState(heatSavingsState)

export const useHeatSavingsState = () => {
  const state = useState(heatSavingsState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-ac-savings-calc`))
  return wrapHeatSavingsState(state)
}
