import { PropsWithChildren } from 'react'

import { usePermissions } from 'hooks/usePermissions'

interface Props {
  children: JSX.Element
  permission: string
}

const RequiredPermission = ({
  children,
  permission,
}: PropsWithChildren<Props>) => {
  const permissions = usePermissions().permissions

  if (!permissions?.length || !permissions?.includes(permission)) return null

  return children
}

export default RequiredPermission
