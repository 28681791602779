import { Tooltip } from 'react-tooltip'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import InfoIcon from '@mui/icons-material/Info'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useExtensionTubeState } from 'store/extensionTubeState'

const ResultsCard = () => {
  const extensionTubeState = useExtensionTubeState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  const { isMetric, results, product } = extensionTubeState.getState()

  // Highlight specific rows based on common tube size
  const isHighlightedRow = (value: string): boolean => {
    const highlightedRows = ["2'", "5'", "10'"]
    return highlightedRows.includes(value)
  }

  const requiresFieldModification = (value: string): boolean => {
    const ignoreList = ["2'", "5'", "10'", 'Direct Mount']
    return (
      !ignoreList.includes(value) && product?.category === 'Industrial HVLS'
    )
  }

  const renderStandardResults = () => (
    <Table aria-label="standard-results-table" size="small">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              fontWeight: 'bold',
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              width: '33%',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex' }}>
              Standard Extension Tube Lengths
              {mediumBreakpoint && (
                <InfoIcon
                  data-tooltip-id="standard-tooltip"
                  data-tooltip-content="Lengths of available standard extension tubes that meet BAF safety and performance criteria"
                  sx={{ ml: 1, '@media print': { display: 'none' } }}
                />
              )}
            </Box>
          </TableCell>
          <TableCell
            sx={{
              fontWeight: 'bold',
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              width: '33%',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex' }}>
              Airfoil Height AFF ({isMetric ? 'Meters' : 'Feet'})
              {mediumBreakpoint && (
                <InfoIcon
                  data-tooltip-id="standard-tooltip"
                  data-tooltip-content='Height of the airfoils above the finished floor. AKA "Spinning height".'
                  sx={{ ml: 1, '@media print': { display: 'none' } }}
                />
              )}
            </Box>
          </TableCell>
          <TableCell sx={{ width: '33%' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results?.standard?.map((result: any) => (
          <TableRow
            key={`${result?.tube}-row`}
            sx={{
              backgroundColor: isHighlightedRow(result?.tube)
                ? '#efefef'
                : 'white',
              padding: '0',
            }}
          >
            <TableCell
              key={`${result?.tube}-tube`}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {result?.tube}
              {requiresFieldModification(result?.tube) && (
                <InfoIcon
                  data-tooltip-id="standard-tooltip"
                  data-tooltip-content="Requires field modification"
                  sx={{
                    color: 'secondary.light',
                    ml: 1,
                    '@media print': { display: 'none' },
                  }}
                />
              )}
            </TableCell>
            <TableCell key={`${result?.tube}-height`}>
              {result?.airfoilHeight}
            </TableCell>
            <TableCell key={`${result?.tube}-valid`}></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  const renderCustomResults = () => (
    <Table aria-label="custom-results-table">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              fontWeight: 'bold',
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              width: '33%',
            }}
          >
            Calculation Type (If Applicable)
          </TableCell>
          <TableCell
            sx={{
              fontWeight: 'bold',
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              width: '33%',
            }}
          >
            ET Length (Feet-Inches)
          </TableCell>
          <TableCell
            sx={{
              fontWeight: 'bold',
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              width: '33%',
            }}
          >
            Airfoil Height AFF ({isMetric ? 'Meters' : 'Feet'})
            {mediumBreakpoint && (
              <InfoIcon
                data-tooltip-id="standard-tooltip"
                data-tooltip-content='Height of the airfoils above the finished floor. AKA "Spinning height".'
                sx={{ ml: 1, '@media print': { display: 'none' } }}
              />
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell key={0}>Absolute Minimum Tube Length:</TableCell>
          <TableCell key={1}>{results?.custom?.minTubeEtLength}</TableCell>
          <TableCell key={2}>{results?.custom?.minTubeHeightAff}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell key={3}>Absolute Maximum Tube Length:</TableCell>
          <TableCell key={4}>{results?.custom?.maxTubeEtLength}</TableCell>
          <TableCell key={5}>{results?.custom?.maxTubeHeightAff}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )

  const renderResults = () => {
    if (!results?.standard?.length || !results?.custom.maxTubeHeightAff) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '300px',
          }}
        >
          <Typography variant="h5">
            Sorry no results found. Please try again with different parameters
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          mt: 4,
        }}
      >
        <Box sx={{ width: '100%' }}>{renderStandardResults()}</Box>
        <Typography variant="h5" sx={{ mt: 4, mb: -0.5 }}>
          Custom Extension Tube Lengths
        </Typography>
        <Box sx={{ width: '100%' }}>{renderCustomResults()}</Box>
      </Box>
    )
  }

  return !results ? null : (
    <Card variant="outlined" sx={{ p: 4, mb: 8 }}>
      <Typography variant="h5">Results</Typography>
      {renderResults()}
      <Tooltip id="standard-tooltip" />
    </Card>
  )
}

export default ResultsCard
