import { useState, ReactInstance, ReactNode } from 'react'
import ReactToPrint from 'react-to-print'

import { usePermissions } from 'hooks/usePermissions'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import PrintIcon from '@mui/icons-material/Print'

import LeadsModal from './LeadsModal'
import SaveToProjectModal from './SaveToProjectModal'

type SaveAction = {
  icon?: () => ReactNode
  node: () => ReactNode
}

interface ToolHeaderProps {
  // REQUIRED
  title: string
  onReset: () => void
  printContent: () => ReactInstance | null
  onLeadsModalSuccess: () => void
  onLeadsModalClose: () => void
  leadSaved: boolean
  // OPTIONAL
  toolState?: any
  onProjectsModalSuccess?: () => void
  onProjectsModalClose?: () => void
  valid?: boolean
  canSave?: boolean
  onSave?: () => void
  renderBeforeActionButtons?: () => ReactNode
  saveActions?: SaveAction[]
  renderAfterActionButtons?: () => ReactNode
}

const ToolHeader = ({
  title,
  onReset,
  printContent,
  renderBeforeActionButtons,
  saveActions,
  renderAfterActionButtons,
  onLeadsModalSuccess,
  onLeadsModalClose,
  onProjectsModalSuccess,
  onProjectsModalClose,
  leadSaved,
  valid = true,
  canSave = true,
  onSave,
  toolState,
}: ToolHeaderProps) => {
  const { isAdmin } = usePermissions()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [leadsModalOpen, setLeadsModalOpen] = useState<boolean>(false)
  const [projectsModalOpen, setProjectsModalOpen] = useState<boolean>(false)
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const open = Boolean(anchorEl)
  // This is a temporary solution that allows the tools in this list to
  // bypass the leads modal for *all users*, including Guest Users.
  const TOOLS_THAT_CAN_BYPASS_LEADS = ['Fan Schedule Builder', 'Submittals']

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      !isAdmin &&
      !leadSaved &&
      // This is a temporary solution that allows the tools in this list to
      // bypass the leads modal for *all users*, including Guest Users.
      !TOOLS_THAT_CAN_BYPASS_LEADS.includes(title)
    ) {
      if (valid && canSave) setLeadsModalOpen(true)
    } else {
      if (onSave) onSave()
      if (valid && canSave) setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderSaveButtonText = () => {
    if (onSave && !valid) return 'Verify to Save'
    return 'Save'
  }

  function renderActionButtons() {
    return (
      <Stack direction={mediumBreakpoint ? 'row' : 'column'} spacing={3}>
        {renderBeforeActionButtons && renderBeforeActionButtons()}

        <ButtonGroup>
          <Button
            variant="outlined"
            disableElevation
            size="large"
            onClick={onReset}
            sx={{
              backgroundColor: 'white',
              py: 1.5,
              px: 1.5,
              fontFamily: 'industry-inc',
              letterSpacing: '0.04em',
              border: '1px solid #BCBEC0',
            }}
          >
            Reset
          </Button>

          <Button
            size="large"
            id="action-save-button"
            aria-controls={open ? 'action-buttons-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
            endIcon={<ArrowDropDownIcon />}
            sx={{
              backgroundColor: 'white',
              py: 1.5,
              px: 2,
              fontFamily: 'industry-inc',
              letterSpacing: '0.04em',
              border: '1px solid #BCBEC0',
            }}
            type={onSave ? 'submit' : 'button'}
          >
            {renderSaveButtonText()}
          </Button>

          <Menu
            id="action-buttons-menu"
            disableScrollLock={true}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'action-save-button',
            }}
          >
            <ReactToPrint
              trigger={() => (
                <MenuItem>
                  <ListItemIcon>
                    <PrintIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Print</ListItemText>
                </MenuItem>
              )}
              content={printContent}
            />

            {saveActions &&
              saveActions.map((action: SaveAction, index) => (
                <MenuItem key={index}>
                  {action.icon && <ListItemIcon>{action.icon()}</ListItemIcon>}
                  <ListItemText>{action.node()}</ListItemText>
                </MenuItem>
              ))}

            {!!toolState && (
              <MenuItem
                key="save-to-project"
                onClick={() => {
                  setAnchorEl(null)
                  setProjectsModalOpen(true)
                }}
              >
                <ListItemIcon>
                  <SaveAltIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Save to Project</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </ButtonGroup>

        {renderAfterActionButtons && renderAfterActionButtons()}
      </Stack>
    )
  }

  function renderLeadsModal() {
    return (
      <LeadsModal
        tool={title}
        open={leadsModalOpen}
        onSuccess={() => {
          onLeadsModalSuccess()
          setLeadsModalOpen(false)
        }}
        onClose={() => {
          onLeadsModalClose()
          setLeadsModalOpen(false)
        }}
      />
    )
  }

  function renderSaveToProjectsModal() {
    return (
      <SaveToProjectModal
        toolState={toolState}
        open={projectsModalOpen}
        onSuccess={() => {
          onProjectsModalSuccess && onProjectsModalSuccess()
          setProjectsModalOpen(false)
        }}
        onClose={() => {
          onProjectsModalClose && onProjectsModalClose()
          setProjectsModalOpen(false)
        }}
      />
    )
  }

  return (
    <Stack
      direction={mediumBreakpoint ? 'row' : 'column'}
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Typography
        variant={mediumBreakpoint ? 'h2' : 'h4'}
        sx={{ textAlign: mediumBreakpoint ? 'left' : 'center' }}
      >
        {title}
      </Typography>
      {renderActionButtons()}
      {renderLeadsModal()}
      {renderSaveToProjectsModal()}
    </Stack>
  )
}

export default ToolHeader
