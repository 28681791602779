import { Tooltip } from 'react-tooltip'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import InfoIcon from '@mui/icons-material/Info'
import Co2Icon from '@mui/icons-material/Co2'
import GasMeterIcon from '@mui/icons-material/GasMeter'
import ParkIcon from '@mui/icons-material/Park'
import GrainIcon from '@mui/icons-material/Grain'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LightbulbIcon from '@mui/icons-material/Lightbulb'

const EnvironmentalImpactTable = ({
  environmentalImpact = [],
  existingFans = [],
  width = '50%',
}: {
  environmentalImpact: any[]
  existingFans: any[]
  width?: string
}) => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const renderCard = (item: any) => {
    const { title, value, metric } = item
    const iconName = item?.icon as string

    const getIcon = (iconName: string) => {
      if (iconName === 'Co2') return <Co2Icon />
      if (iconName === 'GasMeter') return <GasMeterIcon />
      if (iconName === 'Grain') return <GrainIcon />
      if (iconName === 'DirectionsCar') return <DirectionsCarIcon />
      if (iconName === 'Lightbulb') return <LightbulbIcon />
      return <ParkIcon />
    }

    return (
      <Box key={title} sx={{ display: 'flex', width: '50%', mb: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '36px',
            width: '36px',
            borderRadius: '8px',
            border: '1px #FFC425 solid',
          }}
        >
          {getIcon(iconName)}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ ml: 1, fontWeight: 'bold' }}>{title}</Box>
          <Box sx={{ ml: 1 }}>{`${round(value, 1)} ${metric}`}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box width={width}>
      <Box sx={{ mt: 4, display: 'flex', width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 1.5 }}>
          Annual Environmental Impact
        </Typography>
        {mediumBreakpoint && (
          <InfoIcon
            data-tooltip-id="standard-tooltip"
            data-tooltip-content="Reduction in production of selected greenhouse gases and pollutants are found as a product of the reduction of coal-fired electricity production. As context, various benchmarks (cars/forests/bulbs) are also provided."
            sx={{ ml: 1, '@media print': { display: 'none' } }}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {environmentalImpact?.map((item: any) => renderCard(item))}
      </Box>
      <Tooltip id="standard-tooltip" />
    </Box>
  )
}

export default EnvironmentalImpactTable
