import { forwardRef } from 'react'
import Box from '@mui/material/Box'
import LargeFanTemplate from '../Templates/LargeFanTemplate'
import HaikuTemplate from '../Templates/HaikuTemplate'
import BAFConTemplate from '../Templates/BAFConTemplate'
import CoolSpaceTemplate from '../Templates/CoolSpaceTemplate'
import ESeriesTemplate from '../Templates/ESeriesTemplate'
import IRHTemplate from '../Templates/IRHTemplate'
import SweatBeeTemplate from '../Templates/SweatBeeTemplate'
import UnitHeaterTemplate from '../Templates/UnitHeaterTemplate'
import { useSubmittalsState } from 'store/salesToolsState'

const Form = forwardRef((props, ref) => {
  const { fan } = useSubmittalsState().getState()
  return (
    <Box ref={ref}>
      {fan.template === 'Large Fans' && <LargeFanTemplate />}
      {fan.template === 'Haiku' && <HaikuTemplate />}
      {fan.template === 'BAFCon' && <BAFConTemplate />}
      {fan.template === 'Cool-Space' && <CoolSpaceTemplate />}
      {fan.template === 'E Series' && <ESeriesTemplate />}
      {fan.template === 'Radiant Heaters' && <IRHTemplate />}
      {fan.template === 'Sweat Bee' && <SweatBeeTemplate />}
      {fan.template === 'Unit Heaters' && <UnitHeaterTemplate />}
    </Box>
  )
})

export default Form
