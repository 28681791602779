const PRODUCTS = {
  AirEye:
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/AirEye+-+Max+MIn+-+E+_+M.pdf',
  'Basic 6':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Basic+6+E_M.pdf',
  ES6: 'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/ES6+E_M+Tables.pdf',
  Essence:
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Essence+E_M.pdf',
  Haiku:
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Haiku+E_M.pdf',
  'Haiku L':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Haiku+L+E_M.pdf',
  'Haiku Coastal':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Haiku+L+and+Haiku+Coastal+E%26M.pdf',
  i6: 'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/i6+E_M+Table.pdf',
  'Pivot 2.0':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Pivot+2.0+E_M.pdf',
  'Powerfoil 8':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+8+E_M.pdf',
  'Powerfoil 8 Plus':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+8+Plus+E_M.pdf',
  'Powerfoil Breeze':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+Breeze+E_M.pdf',
  'Powerfoil D':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+D+E_M.pdf',
  'Powerfoil X 3.0':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+X+3.0+E_M.pdf',
  'Powerfoil X 3.0 Mount':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/PFX+3.0+-+Various+mount+dimensions+(Mt+Everest+one-pager).pdf',
  'Powerfoil X 3.0 Plus':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+X+3.0+Plus+E_M.pdf',
  'Powerfoil X4':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+X4+E_M.pdf',
  'Powerfoil X4 Plus':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Powerfoil+X4+Plus+E_M.pdf',
  'Speakeasy':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Speakeasy%20E%26M.pdf',
  'Yellow Jacket':
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/Yellow+Jacket+-+Max+Min.pdf',
}

export default PRODUCTS
