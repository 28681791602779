import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfNotesSection from 'components/pdf/PdfNotesSection'
import PdfFanSection from 'components/pdf/PdfFanSection'
import PdfInputSection from 'components/pdf/PdfInputSection'
import PdfEnvironmentalImpact from 'components/pdf/PdfEnvironmentalImpact'
import { useAcSavingsState } from 'store'

const AcSavingsCalcPdf = () => {
  const acSavingsState = useAcSavingsState()
  const {
    bafFans,
    existingFans,
    results,
    companyName,
    installLocation,
    primaryUse,
    floorArea,
    airConditioned,
    ceilingHeight,
    thermostatOffset,
    energyDataSource,
    operatingHours,
  } = acSavingsState.get()

  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  })

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const totalSavings = round(
    results?.savings?.acSavings + results?.savings?.fanEnergySavingsCost,
    1
  )
  const acSavings = round(results?.savings?.acSavings, 1) || 0
  const fanEnergySavingsCost =
    round(results?.savings?.fanEnergySavingsCost, 1) || 0

  const notes = [
    `(1) Calculations are based on population averages for the information selected and are only an estimate of actual savings. Actual Costs and savings will vary depending on weather conditions, usage, location and local utility rates. This information is intended as an example for comparison purposes only. BAF does not gurantee the accuracy of these calculations. No promise of performance is implied by us or should be inferred by you.`,
    `(2) The fan energy cost displayed is calculated using the methods developed by BAF expertise which best accounts for how customers utilize existing non-HVLS fans and Big Ass Fans in their spaces. Per CFR 10 Part 429 and Part 430,the Department of Energy dictates that fan energy must also be reported using the methodologies therein. For the overhead fans as selected (not including directionals), annual DOE calculated electric consumption is equal to 0 kWh. Note that the BAF method accounts for the specific location and application selected above whereas the DOE method is a national average for all customers, locations, and applications.`,
    `(3) The differences in BAF and existing fan energy consumptions are found using the method referenced in note #2.`,
    `(4) Calculations are based on averages for the information selected and are only an estimate of actual savings. Actual loss and savings will vary depending on weather conditions, usage, location and business type. This information is intended as an example for comparison purposes only. BAF does not guarantee the accuracy of these calculations. No promise of performance is implied by us or should be inferred by you.`,
    `(5) Reduction in production of selected greenhouse gases and pollutants are found as a product of the reduction of natural gas consumption and the reduction of coal-fired electricity production. As context, various benchmarks (cars/forrest/bulbs) are also provided.`,
  ]

  const inputs = [
    { title: 'Company Name', value: companyName },
    { title: 'Install Location', value: installLocation },
    { title: 'Primary Use', value: primaryUse },
    { title: 'Floor Area', value: `${floorArea} sqft` },
    { title: 'Average Ceiling Height', value: `${ceilingHeight} ft` },
    { title: 'Air Conditioned', value: airConditioned },
    { title: 'Thermostat Offset', value: `${thermostatOffset} °F` },
    { title: 'Energy Data Source', value: energyDataSource },
    { title: 'Operating Hours', value: `${operatingHours} hours/week` },
  ]

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '8px',
        marginHorizontal: '16px',
        marginVertical: '8px',
        borderRadius: '8px',
      }}
    >
      <Text style={{ ...styles.sectionTitle, borderBottom: '0' }}>Results</Text>

      {/* Annual Savings */}
      <Text style={{ ...styles.label, marginBottom: '4px' }}>
        Annual Savings
      </Text>
      <View
        style={{
          width: '47%',
          border: '1px solid black',
          borderRadius: '12px',
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            borderBottom: '1px solid black',
            paddingVertical: '8px',
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
            <Text style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {`$${totalSavings}`}
            </Text>
            <Text
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                marginTop: '5px',
                marginLeft: '4px',
              }}
            >
              per year
            </Text>
          </View>
          <Text style={{ ...styles.label, marginLeft: '-28px' }}>
            Total Energy Savings
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            padding: '8px',
            justifyContent: 'space-evenly',
          }}
        >
          <View style={{ flexDirection: 'column', width: '38%' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.value}>{`$${acSavings}`}</Text>
              <Text
                style={{
                  marginTop: '2px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                  marginLeft: '2px',
                }}
              >
                per year
              </Text>
            </View>
            <Text
              style={{
                fontSize: '8px',
                marginTop: '2px',
                color: '#525659',
              }}
            >
              Air Conditioner Savings
            </Text>
          </View>
          <Text style={{ fontWeight: 'bold' }}>+</Text>
          <View
            style={{
              flexDirection: 'column',
              width: '45%',
              paddingLeft: '8px',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.value}>{`$${fanEnergySavingsCost}`}</Text>
              <Text
                style={{
                  marginTop: '1px',
                  fontSize: '8px',
                  fontWeight: 'bold',
                  marginLeft: '2px',
                }}
              >
                per year
              </Text>
            </View>
            <Text
              style={{
                fontSize: '8px',
                marginTop: '2px',
                color: '#525659',
              }}
            >
              Fan Energy Savings / Cost
            </Text>
          </View>
        </View>
      </View>

      <PdfEnvironmentalImpact results={results?.environmentalImpact} />
    </View>
  )

  return (
    <Document>
      <Page size="A4">
        <PdfHeader title="AC Savings Calculator" />
        <PdfInputSection inputs={inputs} />
        <PdfFanSection bafFans={bafFans} existingFans={existingFans} />
        {renderResultsSection()}
        <PdfNotesSection notes={notes} />
      </Page>
    </Document>
  )
}

export default AcSavingsCalcPdf
