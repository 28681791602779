import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { useSubmittalsState } from 'store/salesToolsState'

interface APIWarranty {
  id: number
  type: string
  period: string
}

const Warranty = () => {
  const { productData } = useSubmittalsState().getState()
  const warranties = productData.warranty
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Warranty Information
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              {warranties.map((warranty: APIWarranty, index: number) => (
                <TableCell
                  sx={{
                    fontWeight: 700,
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={`${index}Head`}
                >
                  {warranty.type}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {warranties.map((warranty: APIWarranty, index: number) => (
                <TableCell
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: 1,
                    pr: 1,
                    '@media print': { fontSize: '0.8rem' },
                  }}
                  key={index}
                >
                  {warranty.period}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </Grid>
  )
}

export default Warranty
