import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Box from '@mui/system/Box'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { axiosHelper } from 'utils'

interface Props {
  onClose: () => void
  open: boolean
  project: any
  onUpdate: (message: string) => void
}

const modalStyle = {
  bgcolor: 'background.paper',
  borderRadius: '3%',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
}

const DeleteProjectModal = ({
  onClose = () => {},
  open,
  onUpdate,
  project,
}: Props) => (
  <Modal
    open={open}
    onClose={() => {
      onClose && onClose()
    }}
  >
    <Box
      component="form"
      sx={modalStyle}
      onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <ErrorOutlineIcon
          sx={{ fontSize: '32px', color: 'secondary.main', mr: 2 }}
        />
        <Typography variant="h6" sx={{ mt: -1, mb: 1 }}>
          {`Are you sure you want to delete ${project?.name}`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          variant="outlined"
          disableElevation
          type="submit"
          size="large"
          sx={{
            backgroundColor: 'white',
            py: 1.5,
            mr: 1,
            fontFamily: 'industry-inc',
            '&:hover': {
              backgroundColor: '#A9A9A9',
              color: '#fff',
            },
          }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          disableElevation
          type="submit"
          size="large"
          sx={{
            backgroundColor: 'secondary.main',
            py: 1.5,
            px: 4,
            fontFamily: 'industry-inc',
          }}
          onClick={() => {
            // Delete project
            axiosHelper({
              url: `/projects/${project?.id}`,
              method: 'delete',
              success: () => onUpdate && onUpdate('Deleted!'),
            })
            onClose()
          }}
        >
          Yes, delete
        </Button>
      </Box>
    </Box>
  </Modal>
)

export default DeleteProjectModal
