import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'

import StyledTextField from '../../../components/StyledTextField'

import { useHeatIndexState, DataValue } from 'store/heatIndexState'
import filterCoolingEffectData from 'utils/heatIndexUtils'

const ProductivitySavings = () => {
  const heatIndexState = useHeatIndexState()
  const {
    productivitySavings,
    hourlyHeatIndexDistribution,
    projectDetails,
    needsUpdate,
  } = heatIndexState.get()
  const region = projectDetails.value.climateZone
  const isCustomSchedule = projectDetails?.value?.schedule === 'Custom Schedule'
  const scenarios = hourlyHeatIndexDistribution?.value?.scenarios || []
  const fansOnly = scenarios?.['Fans Only']
  const fansEvap = scenarios?.['Fans + Evap Coolers']
  const value = productivitySavings?.value || {}
  const {
    hourlyWage,
    numEmployees,
    pmvNoFans,
    pmvFansOnly,
    pmvFansEvap,
    productivity,
  } = value

  // Filtered Data
  const [pmvNoFansFiltered, setPmvNoFansFiltered] = useState([])
  const [pmvFansOnlyFiltered, setPmvFansOnlyFiltered] = useState([])
  const [pmvFansEvapFiltered, setPmvFansEvapFiltered] = useState([])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    heatIndexState.setFormValue('productivitySavings', name, value)
  }
  const averageSummer = (array: DataValue[]) => {
    let sum = 0
    // only average values for dates May-September
    array = array?.filter(
      (value: DataValue) => value.id > 259200 && value.id < 589592
    )
    for (let i = 0; i < array?.length; i++) {
      sum += Number(array?.[i]?.value)
    }
    return Number(sum / array?.length).toFixed(2)
  }
  useEffect(() => {
    heatIndexState.hourlyProductivity(
      isCustomSchedule ? pmvNoFansFiltered : pmvNoFans,
      'No Fans'
    )
    if (fansOnly) {
      heatIndexState.hourlyProductivity(
        isCustomSchedule ? pmvFansOnlyFiltered : pmvFansOnly,
        'Fans Only'
      )
    }
    if (fansEvap) {
      heatIndexState.hourlyProductivity(
        isCustomSchedule ? pmvFansEvapFiltered : pmvFansEvap,
        'Fans + Evap Coolers'
      )
    }
    if (hourlyWage) {
      heatIndexState.calcLostWages(productivity?.['No Fans'], 'No Fans')
      if (fansOnly) {
        heatIndexState.calcLostWages(productivity['Fans Only'], 'Fans Only')
        heatIndexState.setAnnualSavings('Fans Only')
        heatIndexState.setReductionInLosses('Fans Only')
      }
      if (fansEvap) {
        heatIndexState.calcLostWages(
          productivity['Fans + Evap Coolers'],
          'Fans + Evap Coolers'
        )
        heatIndexState.setAnnualSavings('Fans + Evap Coolers')
        heatIndexState.setReductionInLosses('Fans + Evap Coolers')
      }
    }
    // eslint-disable-next-line
  }, [
    region,
    pmvFansOnlyFiltered,
    pmvNoFansFiltered,
    pmvFansEvapFiltered,
    pmvNoFans?.length,
    pmvFansOnly?.length,
    pmvFansEvap?.length,
    fansOnly,
    fansEvap,
    hourlyWage,
    needsUpdate,
  ])

  // Filter data based on schedule
  const schedule = projectDetails?.value?.customSchedule
  useEffect(() => {
    if (
      needsUpdate &&
      pmvNoFans?.length &&
      pmvFansOnly?.length &&
      pmvFansEvap?.length
    ) {
      setPmvNoFansFiltered(filterCoolingEffectData(pmvNoFans, schedule))
      setPmvFansOnlyFiltered(filterCoolingEffectData(pmvFansOnly, schedule))
      setPmvFansEvapFiltered(filterCoolingEffectData(pmvFansEvap, schedule))
      heatIndexState.setNeedsUpdate(false)
    }
  }, [
    pmvNoFans,
    pmvNoFansFiltered,
    pmvFansOnly,
    pmvFansOnlyFiltered,
    pmvFansEvap,
    pmvFansEvapFiltered,
    schedule,
    needsUpdate,
    heatIndexState,
  ])

  return (
    <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {`Productivity Savings ${region ? `- ${region}` : ''}`}
      </Typography>
      <Typography variant="h6" sx={{ fontSize: 'small', fontWeight: 'bold' }}>
        Inputs
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <StyledTextField
            label="Hourly Wage"
            name="hourlyWage"
            value={hourlyWage}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">per hour</InputAdornment>
              ),
            }}
            onChange={handleInput}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledTextField
            label="Number of Employees"
            name="numEmployees"
            value={numEmployees}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">employees</InputAdornment>
              ),
            }}
            onChange={handleInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontStyle: 'italic' }} fontSize={11}>
            *Represents average hourly wage of employees and the quantity of
            employees per shift in the areas served by fans/evap.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontSize: 'small', fontWeight: 'bold' }}>
        Average Summer PMV
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, width: '33%' }}
            >
              No Fans
            </TableCell>
            {fansOnly && (
              <TableCell
                sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, width: '33%' }}
              >
                Fans Only
              </TableCell>
            )}
            {fansEvap && (
              <TableCell
                sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, width: '33%' }}
              >
                Fans + Evap
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {!!region && pmvNoFans && (
              <TableCell sx={{ pt: 0, pb: 0, pl: 1, width: '33%' }}>
                {averageSummer(
                  isCustomSchedule ? pmvNoFansFiltered : pmvNoFans
                )}
              </TableCell>
            )}
            {!!region && fansOnly && (
              <TableCell sx={{ pt: 0, pb: 0, pl: 1, width: '33%' }}>
                {averageSummer(
                  isCustomSchedule ? pmvFansOnlyFiltered : pmvFansOnly
                )}
              </TableCell>
            )}
            {!!region && fansEvap && (
              <TableCell sx={{ pt: 0, pb: 0, pl: 1, width: '33%' }}>
                {averageSummer(
                  isCustomSchedule ? pmvFansEvapFiltered : pmvFansEvap
                )}
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
      <Typography variant="h6" sx={{ fontSize: 'small', fontWeight: 'bold' }}>
        Results
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, width: '33%' }}
            >
              Scenario
            </TableCell>
            <TableCell
              sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, width: '33%' }}
            >
              Annual Productivity Savings
            </TableCell>
            <TableCell
              sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1, width: '33%' }}
            >
              Reduction in Productivity Losses
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(scenarios)?.map(
            (o: string, index: number) =>
              scenarios?.[o] &&
              index > 0 && (
                <TableRow key={index}>
                  <TableCell sx={{ pt: 0, pb: 0, pl: 1 }}>{o}</TableCell>
                  <TableCell sx={{ pt: 0, pb: 0, pl: 1 }}>
                    {`${
                      Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(
                        Number(
                          (
                            productivitySavings.value.annualProductivitySavings[
                              o
                            ] * Number(numEmployees)
                          ).toFixed(2)
                        )
                      ) ?? '-'
                    }`}
                  </TableCell>
                  <TableCell sx={{ pt: 0, pb: 0, pl: 1 }}>
                    {`${
                      productivitySavings.value.reductionInProductivityLosses[
                        o
                      ] ?? '-'
                    }%`}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <Typography sx={{ fontStyle: 'italic' }} fontSize={11}>
        *Based on research and calculation methods as detailed in Srinavin, K.,
        & Mohamed, S. (2003.)
      </Typography>
    </Card>
  )
}

export default ProductivitySavings
