import { useState, useRef, useLayoutEffect } from 'react'

import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TagIcon from '@mui/icons-material/Tag'

import MainSection from './MainSection'
import { ChapterProps } from '../helpers/interfaces'
import { useGlobalState } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'

const MainChapter = ({ chapterObj, headerPrefix, path }: ChapterProps) => {
  const headerRef = useRef<HTMLDivElement>(null)

  const globalState = useGlobalState()
  const globalPath = globalState.getDesignGuidePath(path)

  const navigate = useNavigate()

  const moveRefToTop = () => {
    if (headerRef.current) {
      const y =
        headerRef.current.getBoundingClientRect().top + window.pageYOffset - 95
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const handleRefClick = () => {
    const globalDesignGuidePaths = globalState.get().designGuidePaths
    const splitPath = path.split('-')
    const chapterPath = `${splitPath[0]}-${splitPath[1]}`
    const sectionPath = `${splitPath[0]}-${splitPath[1]}-${splitPath[2]}`

    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObject = { ...pathObject }
      newPathObject.active = false
      if (newPathObject.path === chapterPath) newPathObject.opened = true
      if (newPathObject.path === sectionPath) newPathObject.opened = true
      if (newPathObject.path === path) newPathObject.active = true
      return newPathObject
    })

    globalState.setDesignGuidePaths(newPaths)
    navigate(`#${path}`)
    moveRefToTop()
  }

  const { pathname } = useLocation()
  useLayoutEffect(() => {
    if (pathname.includes('#')) moveRefToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMounted = useRef(false)
  // used to only trigger on change, not on mount
  useLayoutEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      if (globalPath?.active) {
        moveRefToTop()
        navigate(`#${path}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalPath?.active])

  const header = `${headerPrefix} ${chapterObj.mainContent}`
  const mappedMainSections = chapterObj.sections.map((section, index) => (
    <MainSection
      key={index}
      sectionObj={section}
      headerPrefix={`${headerPrefix}.${index + 1}`}
      path={`${path}-${index + 1}`}
    />
  ))

  const [hover, setHover] = useState(false)

  return (
    <Box ref={headerRef}>
      <Typography
        variant="h3"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {header}
        <IconButton
          color={hover ? 'secondary' : 'primary'}
          aria-label="copy direct hash link"
          onClick={handleRefClick}
        >
          <TagIcon />
        </IconButton>
      </Typography>
      {mappedMainSections}
    </Box>
  )
}

export default MainChapter
