import { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppBarSpacer } from '../layout/helpers/styled'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import Results from './components/Results'
import FansTile from './components/FansTile'
import AdditionalDetailsTile from './components/AdditionalDetailsTile'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { useProjectResults } from 'hooks/useProjectResults'
import { usePermissions } from 'hooks/usePermissions'
import { useFanEnergyEstimatorState } from 'store'
import { Product } from 'store/fanEnergyEstimatorState'
import { axiosHelper } from 'utils'

import FanEnergyEstimatorPdf from './FanEnergyEstimatorPdf'

const FanEnergyEstimator = ({ standalone }: { standalone?: boolean }) => {
  const { user } = usePermissions()
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const {
    aeosEquipped,
    bafFans,
    breaks15Min,
    breaks60Min,
    electricCost,
    existingFans,
    facilityType,
    fansLeftOn,
    hoursPerWeek,
    leadSaved,
    locationCosts,
    productTypes,
    products,
    results,
    selectedState,
  } = fanEnergyEstimatorState.get()
  const [loading, setLoading] = useState<boolean>(false)
  const componentRef = useRef<HTMLDivElement>(null)
  const isAireyeMode = bafFans?.some((fan: Product) =>
    fan?.name?.includes('Aireye')
  )

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      fanEnergyEstimatorState.loadState(newState)
    }
  }, [projectResults, fanEnergyEstimatorState, resultsLoaded])

  // Get product data
  useEffect(() => {
    if (!products?.length && !loading) {
      setLoading(true)
      axiosHelper({
        url: '/product-power-consumptions',
        method: 'get',
        success: (values: any) => {
          // Format data
          const productData = values?.data?.map((datum: any) => ({
            ...datum,
            value: datum?.name,
          }))

          // Add initial staged fan
          if (!bafFans?.length) {
            const product = { ...productData[0], count: 1 }
            fanEnergyEstimatorState.setBafFan(product, 0)
          }

          // Add product options
          setLoading(false)
          fanEnergyEstimatorState.setProducts(productData)
        },
      })
    }
  }, [fanEnergyEstimatorState, products, loading, bafFans])

  // Get product type data
  useEffect(() => {
    if (!productTypes?.length && !loading) {
      axiosHelper({
        url: '/product-types',
        method: 'get',
        success: (values: any) => {
          fanEnergyEstimatorState.setProductTypes(values?.data)
        },
      })
    }
  }, [fanEnergyEstimatorState, productTypes, loading])

  // Get location electric costs data
  useEffect(() => {
    if (!locationCosts?.length && !loading) {
      axiosHelper({
        url: '/location-electric-costs',
        method: 'get',
        success: (values: any) => {
          fanEnergyEstimatorState.setLocationCosts(values?.data)
        },
      })
    }
  }, [fanEnergyEstimatorState, locationCosts, loading])

  const TITLE = 'Fan Energy Estimator'
  const hasValidInputs = !!electricCost && !!hoursPerWeek

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          fanEnergyEstimatorState.setResults()
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => fanEnergyEstimatorState.resetState()}
          toolState={{
            ...fanEnergyEstimatorState.get(),
            products: [],
            productTypes: [],
            locationCosts: [],
            toolName: TITLE,
          }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          valid={hasValidInputs}
          canSave={!!results}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => fanEnergyEstimatorState.setLeadSaved(true)}
          onLeadsModalClose={() => {}}
          printContent={() => componentRef.current}
          renderAfterActionButtons={() => (
            <Button
              disableElevation
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => {
                if (hasValidInputs) fanEnergyEstimatorState.setResults()
              }}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-Fan-Energy-Estimator',
                        email,
                        bafFans,
                        existingFans,
                        results,
                        hoursPerWeek,
                        selectedState,
                        facilityType,
                        electricCost,
                        fansLeftOn,
                        breaks15Min,
                        breaks60Min,
                        aeosEquipped,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<FanEnergyEstimatorPdf />}
                    fileName="BAF-Fan-Energy-Estimator.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />
        <Box ref={componentRef}>
          <PrintHeader title={TITLE} />
          <style type="text/css">
            {'@media print{@page {size: 10.5in 8in}}'}
          </style>
          <Box
            display="flex"
            sx={{ flexDirection: mediumBreakpoint ? 'row' : 'column' }}
          >
            <Stack sx={{ width: mediumBreakpoint ? '50%' : '100%' }}>
              <FansTile isAireyeMode={isAireyeMode} loading={loading} />
            </Stack>
            <Stack sx={{ width: mediumBreakpoint ? '50%' : '100%' }}>
              <AdditionalDetailsTile isAireyeMode={isAireyeMode} />
            </Stack>
          </Box>
          {!!results ? <Results isAireyeMode={isAireyeMode} /> : null}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default FanEnergyEstimator
