import { Tooltip } from 'react-tooltip'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useCostOfOwnershipState } from 'store'

const Results = () => {
  const costOfOwnershipState = useCostOfOwnershipState()
  const { results } = costOfOwnershipState.get()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const fontSize = mediumBreakpoint ? 'medium' : 'small'
  const resultsCount = (results && Object.keys(results)?.length) || 0

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })

  if (!results) return null

  const renderResultsTable = () => (
    <Table aria-label="results-table" size="small">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              fontWeight: 700,
            }}
          >
            Model
          </TableCell>
          {results?.models?.data?.map((model: any) => (
            <TableCell
              key={model}
              sx={{
                fontSize: mediumBreakpoint ? 'medium' : 'small',
                whiteSpace: 'noWrap',
                fontWeight: 700,
              }}
            >
              {model}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(results)?.map(
          (key: string, index: number) =>
            index > 0 && (
              <TableRow key={key} sx={{ padding: '0' }}>
                <TableCell
                  sx={{
                    fontWeight: index + 1 === resultsCount ? 700 : 400,
                    fontSize,
                  }}
                >
                  <Box
                    sx={{ width: '200px' }}
                    data-tooltip-id="standard-tooltip"
                    data-tooltip-content={results[key]?.tooltip || ''}
                  >
                    {results[key].title}
                  </Box>
                </TableCell>
                {results?.[key]?.data?.map(
                  (result: string | number, dataIndex: number) => (
                    <TableCell
                      key={`${key}-${dataIndex}`}
                      sx={{
                        fontSize:
                          resultsCount === index + 1
                            ? mediumBreakpoint
                              ? 'medium'
                              : 'small'
                            : '14px',
                        fontWeight: resultsCount === index + 1 ? 700 : 400,
                      }}
                    >
                      {key.includes('Cost')
                        ? formatCurrency.format(result as number)
                        : result}
                    </TableCell>
                  )
                )}
              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  )

  return !results ? null : (
    <Card variant="outlined" sx={{ p: mediumBreakpoint ? 4 : 2, mb: 8 }}>
      <Typography variant="h5">Results</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          mt: 4,
        }}
      >
        <Box sx={{ width: '100%', overflowX: 'auto', pb: 2 }}>
          {renderResultsTable()}
        </Box>
      </Box>
      <Tooltip id="standard-tooltip" />
    </Card>
  )
}

export default Results
