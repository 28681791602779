import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useCostOfOwnershipState } from 'store'
import { Product } from 'store/costOfOwnershipState'

const InputsTile = () => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const costOfOwnershipState = useCostOfOwnershipState()
  const {
    bafFans,
    bafInstalled,
    bafProvidedLift,
    compareCompetitors,
    downTimeCost,
    evaluationYearPeriod,
    fanQuantity,
    products,
  } = costOfOwnershipState.get()

  const selectedFanNames = bafFans?.map(fan => fan.name)
  const fanOptions = products?.filter(fan => fan?.baf_product)
  const maxFansSelected = fanOptions?.length === bafFans?.length

  const renderBafProduct = (fan: any, index: number) => {
    return (
      <Box
        key={`${fan.name}-${index}`}
        display="inline-flex"
        sx={{ width: '100%', mt: 1 }}
      >
        <Select
          required
          value={fan?.name}
          onChange={(e: any) => {
            const value = e?.target?.value
            if (!value) return
            const product = products?.find(
              (product: Product) => product?.name === value
            )
            costOfOwnershipState.setBafFan(product, index)
          }}
          sx={{ width: '72%' }}
        >
          {fanOptions?.map((fan: Product) => (
            <MenuItem
              key={fan?.name}
              value={fan?.name}
              disabled={selectedFanNames.includes(fan?.name)}
            >
              {fan?.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          required
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: { min: 0 },
          }}
          label={index === 0 ? 'Cost' : ''}
          name="fan-cost"
          sx={{ ml: 1, width: '20%' }}
          value={fan?.fan_cost || 1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const cost = e?.target?.value
            costOfOwnershipState.setBafFanCost(parseInt(cost), index)
          }}
        />
        {index > 0 ? (
          <HighlightOffIcon
            sx={{
              fontSize: '32px',
              cursor: 'pointer',
              color: 'red',
              mt: 1,
              ml: 1,
            }}
            onClick={() => {
              costOfOwnershipState.removeBafFan(index)
            }}
          />
        ) : (
          <Box sx={{ width: '40px', height: '10px' }} />
        )}
      </Box>
    )
  }

  return (
    <Card variant="outlined" sx={{ p: 4 }}>
      <Box sx={{ height: 'auto' }}>
        <Box display="inline-flex">
          <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
            Inputs
          </Typography>
        </Box>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={1}
          direction={mediumBreakpoint ? 'row' : 'column'}
        >
          <Grid item xs={6} sx={{ pr: 1 }}>
            <Box
              display="inline-flex"
              sx={{
                position: 'relative',
                width: '100%',
                justifyContent: 'space-between',
                borderBottom: '1px solid rgb(188, 190, 192)',
                mb: 2,
              }}
            >
              <Typography variant="h6" sx={{ py: 0.5 }}>
                Fans
              </Typography>

              {/* Fan quantity input */}
              <TextField
                required
                variant="standard"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                label="Qty"
                name="cost"
                sx={{
                  position: 'absolute',
                  top: '-12px',
                  right: 0,
                  width: '45px',
                  mb: 0.5,
                }}
                value={fanQuantity || 1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = parseFloat(e?.target?.value)
                  costOfOwnershipState.setFanQuantity(value)
                }}
              />
            </Box>

            {/* Fan select inputs */}
            {bafFans?.map((fan, index) => renderBafProduct(fan, index))}
            {!maxFansSelected && (
              <Button
                variant="outlined"
                disableElevation
                size="large"
                sx={{
                  pt: 0.5,
                  pb: 0.5,
                  my: 2,
                  width: '93%',
                  border: '1px solid #FFC425',
                }}
                onClick={() => {
                  const remainingProducts = products?.filter(
                    fan => !selectedFanNames.includes(fan.name)
                  )
                  costOfOwnershipState.setBafFan(
                    remainingProducts?.[0],
                    bafFans?.length || 0
                  )
                }}
              >
                Add Fan
              </Button>
            )}

            {/* Compare competitors checkbox */}
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    costOfOwnershipState.setCompareCompetitors(
                      event.target.checked
                    )
                  }}
                  checked={compareCompetitors || false}
                />
              }
              label="Compare Against Top Competitors"
            />
          </Grid>

          {/* Additional Inputs */}
          <Grid item xs={6}>
            <Box
              display="inline-flex"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                borderBottom: '1px solid rgb(188, 190, 192)',
                mb: 2,
              }}
            >
              <Typography variant="h6" sx={{ py: 0.5 }}>
                Additional Inputs
              </Typography>
            </Box>

            {/* BAF Install checkbox */}
            <FormControlLabel
              sx={{ width: '100%', mb: 2 }}
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    costOfOwnershipState.setBafInstalled(event.target.checked)
                  }}
                  checked={bafInstalled || false}
                />
              }
              label="BAF Install"
            />

            {/* BAF provided install checkbox */}
            <FormControlLabel
              sx={{ width: '100%', mb: 2 }}
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    costOfOwnershipState.setBafProvidedLift(
                      event.target.checked
                    )
                  }}
                  checked={bafProvidedLift || false}
                />
              }
              label="BAF Provided Lift"
            />

            {/* Evaluation period select input */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Evaluation Year Period
              </InputLabel>
              <Select
                required
                labelId="evaluation-year-period"
                label="Evaluation Year Period"
                sx={{ width: '100%', mb: 2 }}
                value={evaluationYearPeriod}
                onChange={(e: any) => {
                  const value = e?.target?.value
                  costOfOwnershipState.setEvaluationYearPeriod(value)
                }}
              >
                <MenuItem key="10 Year" value="10 Year">
                  10 Year
                </MenuItem>
                <MenuItem key="15 Year" value="15 Year">
                  15 Year
                </MenuItem>
              </Select>
            </FormControl>

            {/* Down Time Cost input*/}
            <TextField
              required
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              label="Cost of Down Time / Hr"
              name="cost"
              sx={{ width: '100%' }}
              value={downTimeCost || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = parseFloat(e?.target?.value)
                costOfOwnershipState.setDownTimeCost(value)
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default InputsTile
