import { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppBarSpacer } from '../layout/helpers/styled'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import InputsTile from './components/InputsTile'
import Results from './components/Results'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { useProjectResults } from 'hooks/useProjectResults'
import { usePermissions } from 'hooks/usePermissions'
import { useEvapSizingToolState } from 'store'
import { axiosHelper } from 'utils'

import EvapSizingToolPdf from './EvapSizingToolPdf'

const EvapSizingTool = ({ standalone }: { standalone?: boolean }) => {
  const { user } = usePermissions()
  const [alertOpen, setAlertOpen] = useState(false)
  const evapSizingToolState = useEvapSizingToolState()
  const {
    dbAirTemp,
    deckHeight,
    environmentData,
    hasWindows,
    insulationLevel,
    isMetric,
    leadSaved,
    length,
    location,
    locationData,
    maxAirTempReduction,
    mcwbAirTemp,
    results,
    safetyFactor,
    solarIntensity,
    spaceAirTempReduction,
    spaceType,
    weatherDataType,
    width,
  } = evapSizingToolState.get()
  const [loading, setLoading] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState('')
  const componentRef = useRef<HTMLDivElement>(null)

  // Data loading states
  const [loadingEnvironmentData, setLoadingEnvironmentData] =
    useState<boolean>(false)
  const [loadingLocationData, setLoadingLocationData] = useState<boolean>(false)

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      evapSizingToolState.loadState(newState)
    }
  }, [projectResults, evapSizingToolState, resultsLoaded])

  // Get environment data
  useEffect(() => {
    if (!environmentData?.length && !loadingEnvironmentData) {
      setLoadingEnvironmentData(true)
      axiosHelper({
        url: '/environment-type-loads',
        method: 'get',
        success: (values: any) => {
          setLoadingEnvironmentData(false)
          evapSizingToolState.setEnvironmentData(values?.data)
        },
      })
    }
  }, [evapSizingToolState, environmentData, loadingEnvironmentData])

  // Get location data
  useEffect(() => {
    if (!locationData?.length && !loadingLocationData) {
      setLoadingLocationData(true)
      axiosHelper({
        url: '/location-wx-data-points',
        method: 'get',
        success: (values: any) => {
          setLoadingLocationData(false)
          evapSizingToolState.setLocationData(values?.data)
        },
      })
    }
  }, [evapSizingToolState, locationData, loadingLocationData])

  const TITLE = 'Evaporative Cooler Design Tool'

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          evapSizingToolState.setResults()
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => evapSizingToolState.resetState()}
          toolState={{
            ...evapSizingToolState.get(),
            environmentData: [],
            locationData: [],
            toolName: 'Evap Sizing Tool',
          }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          valid={true}
          canSave={results}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => evapSizingToolState.setLeadSaved(true)}
          onLeadsModalClose={() => {}}
          printContent={() => componentRef.current}
          renderAfterActionButtons={() => (
            <Button
              variant="text"
              disableElevation
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => evapSizingToolState.setResults()}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-Evap-Sizing-Tool',
                        email,
                        length,
                        isMetric,
                        width,
                        deckHeight,
                        spaceType,
                        insulationLevel,
                        location,
                        hasWindows,
                        weatherDataType,
                        dbAirTemp,
                        mcwbAirTemp,
                        solarIntensity,
                        maxAirTempReduction,
                        spaceAirTempReduction,
                        safetyFactor,
                        results,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<EvapSizingToolPdf />}
                    fileName="BAF-Evap-Sizing-Tool.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />
        <Box ref={componentRef}>
          <PrintHeader title={TITLE} />
          <Stack sx={{ width: '100%' }}>
            <InputsTile loading={loading} />
            {!!results && <Results />}
          </Stack>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default EvapSizingTool
