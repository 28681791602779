import { Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Search from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface Props {
  setQuery: (search: string) => void
}

const ArchivedProjectsBanner = ({ setQuery }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'primary.main',
        height: '120px',
        mt: 11,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'primary.main',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', py: 2 }}>
          <Link
            to="/projects"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Typography
              variant="body2"
              sx={{
                mb: 0,
                color: 'secondary.main',
                fontSize: '12px',
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon sx={{ fontSize: '14px' }} />
              All Projects
            </Typography>
          </Link>
          <Typography variant="h3" sx={{ color: 'white' }}>
            Archived Projects
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 0.5, color: 'grey', fontSize: '12px' }}
          ></Typography>
        </Box>
        <TextField
          placeholder="Search..."
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ ml: 1, mt: '3px', color: '#BCBEC0' }} />
              </InputAdornment>
            ),
          }}
          onChange={e => setQuery(e.target.value || '')}
          variant="standard"
          sx={{
            border: '1px solid #BCBEC0',
            background: 'white',
            maxWidth: '350px',
            width: '100%',
            borderRadius: '16px',
            paddingBottom: 0,
            marginTop: 0,
            fontSize: '14px',
            height: '36px',
            ml: 1,
            '.MuiInput-input': {
              paddingTop: '6px',
              fontSize: '14px',
            },
          }}
        />
      </Container>
    </Box>
  )
}

export default ArchivedProjectsBanner
