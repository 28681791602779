import { Tooltip } from 'react-tooltip'
import { useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'

import StyledTextField from 'components/StyledTextField'
import ProductSelect from 'components/ProductSelect'

import { useExtensionTubeState } from 'store/extensionTubeState'
import { useProducts } from 'hooks/useProducts'
import { axiosHelper, toMetric } from 'utils'

import { APIProduct, APIProductData } from 'store/scheduleBuilderState'

const InputsCard = ({
  onSelect = () => {},
}: {
  onSelect?: (value: string) => void
}) => {
  const [loading, setLoading] = useState(false)
  const extensionTubeState = useExtensionTubeState()
  const {
    buildingWidth,
    deckHeight,
    diameter,
    eaveHeight,
    mountingStructureHeight,
    peakHeight,
    product,
    roofPitch,
    isMetric,
  } = extensionTubeState.getState()
  const { products } = useProducts()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const type = isMetric ? '(Meters)' : '(Feet)'

  const inchesToFeetString = (size: string = ''): string => {
    const numberValue = Number(size.replace('"', '').replace("'", ''))
    const inchesToFeetValue = `${Math.round(numberValue * 0.0833 * 10) / 10}'`
    return inchesToFeetValue
  }

  // Size options for products. If product is 'small fan' convert inches to feet
  const isSmallFan = product?.category === 'Small Fans'
  const sizeOptions = product?.sizes?.map((size: any) => {
    return {
      ...size,
      value: isSmallFan ? inchesToFeetString(size?.size) : size?.size,
      standardValue: size?.size,
      metricValue: isSmallFan
        ? toMetric(inchesToFeetString(size?.size))
        : toMetric(size?.size),
    }
  })

  const selectedSizeOption =
    sizeOptions?.find((option: any) => {
      const convertedValueMatch =
        inchesToFeetString(option?.size) === inchesToFeetString(diameter)
      const valueMatch = option?.value === diameter
      const metricValueMatch = `${option?.metricValue}` === diameter
      return convertedValueMatch || valueMatch || metricValueMatch
    }) || ''

  const slopeOptions = [
    { id: 'Flat', value: 0 },
    { id: 'Unknown', value: 'none' },
    { id: '1/12', value: 0.083 },
    { id: '2/12', value: 0.167 },
    { id: '3/12', value: 0.25 },
    { id: '4/12', value: 0.333 },
    { id: '5/12', value: 0.417 },
    { id: '6/12', value: 0.5 },
    { id: '7/12', value: 0.583 },
    { id: '8/12', value: 0.667 },
    { id: '9/12', value: 0.75 },
    { id: '10/12', value: 0.833 },
    { id: '11/12', value: 0.917 },
    { id: '12/12', value: 1 },
  ]

  return (
    <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5">Inputs</Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          Standard
          <Switch
            checked={isMetric}
            onChange={() => extensionTubeState.setIsMetric(!isMetric)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          Metric
        </Stack>
      </Stack>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '416px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid
              container
              direction={mediumBreakpoint ? 'row' : 'column'}
              rowSpacing={2}
              columnSpacing={4}
              sx={{ mt: 2 }}
            >
              <Grid item xs={6}>
                Product
                <ProductSelect
                  overheadOnly={true}
                  clearOnBlur={false}
                  value={product?.name}
                  styles={{
                    width: 'auto',
                    borderRadius: 1,
                    border: '1px solid lightgray',
                    p: 1.5,
                  }}
                  onChange={value => {
                    extensionTubeState.reset()
                    const newProduct = products.find(
                      (p: APIProduct) => p.name === value
                    )!
                    const productSuccess = ({
                      data,
                    }: {
                      data: APIProductData
                    }) => {
                      setLoading(false)
                      extensionTubeState.setProduct(data)
                    }
                    setLoading(true)
                    axiosHelper({
                      url: `/products/relations/${newProduct?.id}`,
                      success: productSuccess,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {`Fan Diameter ${isMetric ? type : ''}`}
                <Select
                  value={selectedSizeOption}
                  onChange={(e: any) => {
                    extensionTubeState.resetResults()
                    const value = e.target.value
                    const diameterValue = isMetric
                      ? value?.metricValue
                      : value?.standardValue
                    extensionTubeState.setDiameter(`${diameterValue}`)
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  <MenuItem value="none" disabled>
                    Select an option
                  </MenuItem>
                  {sizeOptions?.map((size: any, index: number) => (
                    <MenuItem key={`${size?.size}-${index}`} value={size}>
                      {isMetric ? size?.metricValue : size?.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              direction={mediumBreakpoint ? 'row' : 'column'}
              rowSpacing={2}
              columnSpacing={4}
              sx={{ mt: 2 }}
            >
              <Grid item xs={6}>
                <Box
                  data-tooltip-id="standard-tooltip"
                  data-tooltip-content={
                    mediumBreakpoint
                      ? 'Deck height above the finished floor (AFF) at the fan mounting location.'
                      : ''
                  }
                >
                  <StyledTextField
                    required
                    label={`Ceiling / Deck Height AFF -  ${type}`}
                    name="deckHeight"
                    type="number"
                    inputProps={{ step: '0.1' }}
                    value={deckHeight > 0 ? deckHeight : ''}
                    onFocus={() => onSelect('a')}
                    onBlur={() => onSelect('')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      extensionTubeState.resetResults()
                      extensionTubeState.setDeckHeight(
                        parseFloat(e?.target?.value)
                      )
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  data-tooltip-id="standard-tooltip"
                  data-tooltip-content={
                    mediumBreakpoint
                      ? 'Mounting structure height above the finished floor (AFF) at the fan mounting location.'
                      : ''
                  }
                >
                  <StyledTextField
                    required
                    onFocus={() => onSelect('b')}
                    onBlur={() => onSelect('')}
                    label={`Mounting Structure Height AFF - ${type}`}
                    name="mountingStructureHeight"
                    type="number"
                    inputProps={{ step: '0.1' }}
                    value={
                      mountingStructureHeight > 0 ? mountingStructureHeight : ''
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      extensionTubeState.resetResults()
                      extensionTubeState.setMountingStructureHeight(
                        parseFloat(e?.target?.value)
                      )
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction={mediumBreakpoint ? 'row' : 'column'}
              rowSpacing={0}
              columnSpacing={4}
              sx={{ mt: 2 }}
            >
              <Grid item xs={6}>
                <Box
                  data-tooltip-id="standard-tooltip"
                  data-tooltip-content={
                    mediumBreakpoint
                      ? 'Select the X:12 slope of the roof deck.'
                      : ''
                  }
                >
                  Roof Pitch
                  <Select
                    label="Roof Pitch"
                    value={slopeOptions?.find(
                      (option: any) => option?.value === roofPitch
                    )}
                    onChange={(e: any) => {
                      extensionTubeState.resetResults()
                      extensionTubeState.setRoofPitch(e.target.value?.value)
                    }}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {slopeOptions?.map((slope: any) => (
                      <MenuItem key={slope?.id} value={slope}>
                        {slope?.id}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>

            {roofPitch === 'none' && (
              <>
                <Grid
                  container
                  direction={mediumBreakpoint ? 'row' : 'column'}
                  rowSpacing={0}
                  columnSpacing={4}
                  sx={{ mt: 3 }}
                >
                  <Grid item xs={6}>
                    <Box
                      data-tooltip-id="standard-tooltip"
                      data-tooltip-content={
                        mediumBreakpoint
                          ? 'Enter if roof pitch is unknown - leave blank otherwise.'
                          : ''
                      }
                    >
                      <StyledTextField
                        required
                        onFocus={() => onSelect('c')}
                        onBlur={() => onSelect('')}
                        label={`Building Width (Along Gabled Section) - ${type}`}
                        name="buildingWidth"
                        type="number"
                        value={buildingWidth > 0 ? buildingWidth : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          extensionTubeState.resetResults()
                          extensionTubeState.setBuildingWidth(
                            parseInt(e?.target?.value)
                          )
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sx={{ mt: mediumBreakpoint ? 0 : 2 }}>
                    <Box
                      data-tooltip-id="standard-tooltip"
                      data-tooltip-content={
                        mediumBreakpoint
                          ? 'Enter if roof pitch is unknown - leave blank otherwise.'
                          : ''
                      }
                    >
                      <StyledTextField
                        required
                        onFocus={() => onSelect('d')}
                        onBlur={() => onSelect('')}
                        label={`Eave Height (AFF) - ${type}`}
                        name="eaveHeight"
                        type="number"
                        value={eaveHeight > 0 ? eaveHeight : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          extensionTubeState.resetResults()
                          extensionTubeState.setEaveHeight(
                            parseInt(e?.target?.value)
                          )
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction={mediumBreakpoint ? 'row' : 'column'}
                  rowSpacing={0}
                  columnSpacing={4}
                  sx={{ mt: 2 }}
                >
                  <Grid item xs={6}>
                    <Box
                      data-tooltip-id="standard-tooltip"
                      data-tooltip-content={
                        mediumBreakpoint
                          ? 'Enter if roof pitch is unknown - leave blank otherwise.'
                          : ''
                      }
                    >
                      <StyledTextField
                        required
                        onFocus={() => onSelect('e')}
                        onBlur={() => onSelect('')}
                        label={`Peak Height (AFF) - ${type}`}
                        name="peakHeight"
                        type="number"
                        value={peakHeight > 0 ? peakHeight : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          extensionTubeState.resetResults()
                          extensionTubeState.setPeakHeight(
                            parseInt(e?.target?.value)
                          )
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
      <Tooltip id="standard-tooltip" />
    </Card>
  )
}

export default InputsCard
