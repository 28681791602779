import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useFanEnergyEstimatorState } from 'store'

const AnnualSavingsEstimate = () => {
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const { existingFans, results } = fanEnergyEstimatorState.get()

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const energy = round(results?.savings?.energy, 1)
  const cost = results?.savings?.cost?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  // Don't show results if no existing fans
  if (!existingFans?.length) return null

  return (
    <Box width={mediumBreakpoint ? '50%' : '100%'}>
      <Typography variant="h6" sx={{ mt: 4, mb: 1.5 }}>
        Annual Energy Savings Estimate
      </Typography>

      <Box sx={{ display: 'flex', fontSize: '32px', ml: 2 }}>
        {`${energy}`}
        <Box sx={{ fontSize: '16px', ml: 1, mt: 2 }}>kWh</Box>
      </Box>
      <Box sx={{ display: 'flex', fontSize: '32px', ml: 2 }}>
        {`${cost}`}
        <Box sx={{ fontSize: '16px', ml: 1, mt: 2 }}>per year</Box>
      </Box>
    </Box>
  )
}

export default AnnualSavingsEstimate
