const data = [
  {
    // this is a chapter
    mainContent: 'Introduction',
    sidebarContent: 'Introductions',
    sections: [
      {
        mainContent: 'Goals of the Guide',
        sidebarContent: 'Goals of the Guide',
        subSections: [
          {
            mainContent: 'Educate',
            sidebarContent: 'Educate',
            data: [
              {
                type: 'paragraph',
                content: `This guide is intended to educate owners, architects, and engineers about why and how to incorporate ceiling fans into building designs. After reading this guide, users should have a good understanding of the science behind thermal comfort, air distribution, and energy consumption. Readers will also understand how ceiling fans can contribute to thermal comfort, improve air distribution, and reduce energy consumption. Furthermore, they will understand how incorporating ceiling fans as a core component of an HVAC system provides more holistic benefits than inclusion as an optional "add-on."`,
              },
              {
                type: 'paragraph',
                content: `Users of the guide will learn about the various fan types available and which fan best suits a specific application. Readers will understand how to specify a fan to meet project requirements, and how to evaluate a fan submittal to determine if it truly meets those requirements.`,
              },
            ],
          },
          {
            mainContent: 'Provide Tools',
            sidebarContent: 'Provide Tools',
            data: [
              {
                type: 'paragraph',
                content: `The guide will provide readers with the tools necessary to effectively incorporate ceiling fans into building designs in a safe, code compliant, and cost-effective manner. These will include guidelines for safe installation, summaries of applicable codes and standards, and resources for assessing the financial implications of a design. Additionally, the guide highlights tools available from Big Ass Fans such as computational fluid dynamics (CFD) analysis and various online calculators.`,
              },
            ],
          },
          {
            mainContent: 'Give Examples',
            sidebarContent: 'Give Examples',
            data: [
              {
                type: 'paragraph',
                content: `Examples in the guide will show the reader how to apply their new knowledge and utilize the tools described in the guide in real life applications. Examples will include thermal comfort calculations, fan layouts, system designs, financial justifications, and more. This section will highlight the differences between designing a "standard" HVAC system and an HVAC system with ceiling fans, and compare factors such as layout complexity, energy consumption, and first costs between the two system types.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Guide References',
        sidebarContent: 'Guide References',
        subSections: [
          {
            mainContent: 'How to Use the Guide',
            sidebarContent: 'How to Use the Guide',
            data: [
              {
                type: 'paragraph',
                content: `This guide has been divided into five chapters: 1) Introduction, 2) Background & Rationale, 3) Applications and Examples, 4) Case Studies, and 5) FAQ. Chapter 1 introduces the benefits of ceiling fans and the intention of the guide. Chapter 2 will provide background information on the science behind thermal comfort and how to use fans to improve an HVAC design. Chapter 3 contains several examples illustrating the principles described in Chapter 2, as well as more detailed information on specific applications of fans. Chapter 4 features case studies that delve deeper into real-world applications of ceiling fans. Chapter 5 aims to discuss some common misconceptions and questions about Big Ass Fans. The guide has been written in such an order that a user could read it from start to finish and ideally gain a good understanding of ceiling fan systems and applications. The authors also recognize users may wish to jump between sections based on prior knowledge and/or specific project needs. As such, the guide attempts to group and label information in a logical and clear manner, such that users may easily identify areas of interest and read the relevant sections.`,
              },
              {
                type: 'paragraph',
                content: `As this guide is anticipated to be used by a variety of readers, so too is the information in the guide varied. Owners will find information on operating cost reduction strategies and which building types are well-suited to incorporate fans. Architects will see examples of fan layouts in various spaces and learn about specification options that allow fans to be selected that are well-integrated into a space. Engineers will understand the design decisions that must be made to incorporate ceiling fans into an HVAC design and the impacts fans have on thermal comfort. Overall, the guide aims to paint a full picture of the methods and benefits of ceiling fans to a building design.`,
              },
            ],
          },
          {
            mainContent: 'Units Table',
            sidebarContent: 'Units Table',
            data: [
              {
                type: 'table',
                tableData: {
                  headers: ['Description', 'IP Units', 'SI Units'],
                  data: [
                    [`Air Volume`, `Cubic feet - ft.³`, `Liters - L`],
                    [
                      `Airflow`,
                      `Cubic feet per minute - CFM`,
                      `Liters per second - L/s`,
                    ],
                    [
                      `Airspeed`,
                      `Feet per minute - fpm`,
                      `Meters per second - m/s`,
                    ],
                    [`Area`, `Square feet - ft.²`, `Square meters - m²`],
                    [
                      `Clothing insulation - clo`,
                      `1 clo = 0.88 ft.²*h*°F/Btu`,
                      `1 clo = 0.155 m²*°C/W`,
                    ],
                    [`Cost`, `$ (US Dollars)`, `-`],
                    [
                      `Diameter - ⌀`,
                      `Feet or inches - ft. or in.`,
                      `Meters or millimeters - m or mm`,
                    ],
                    [
                      `Distance`,
                      `Feet or inches - ft. or in.`,
                      `Meters or millimeters - m or mm`,
                    ],
                    [`Energy`, `Joules - J`, `Watts - W`],
                    [`Fan Power`, `Brake Horsepower - BHP`, `Kilowatt - kW`],
                    [
                      `Metabolic rate - met`,
                      `1 met = 18.4 Btu/(h*ft.²)`,
                      `1 met = 58.2 W/m²`,
                    ],
                    [
                      `Power`,
                      `British thermal units per hour - Btuh`,
                      `Watts - W`,
                    ],
                    [
                      `Pressure`,
                      `Inches water column - in.w.c.`,
                      `Pascals - Pa`,
                    ],
                    [`Temperature`, `°F`, `°C`],
                    [`Thermal Transmittance`, `Btu/(h*ft.²*°F)`, `W/(m²*K)`],
                    [`Thrust`, `Pounds force - lbf`, `Newtons - N`],
                    [
                      `Time`,
                      `Hours, Minutes, Seconds - h, m, s`,
                      `Hours, Minutes, Seconds - h, m, s`,
                    ],
                    [`Weight`, `Pounds - lbs`, `Kilograms - kg`],
                    [
                      `Efficiency - η`,
                      `Output Power / Input Power (W/W)`,
                      `Output Power / Input Power (W/W)`,
                    ],
                    [
                      `Ceiling Fan Energy Index - CFEI`,
                      `Fan Power / Reference Fan Power (W/W)`,
                      `Fan Power / Reference Fan Power (W/W)`,
                    ],
                    [`Fan Shaft Power`, `Brake Horsepower - bhp`, `Watts - W`],
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        // this is a section
        mainContent: 'Benefits of Ceiling Fans',
        sidebarContent: 'Benefits of Ceiling Fans',
        subSections: [
          {
            // this is a sub section
            mainContent: 'Thermal Comfort',
            sidebarContent: 'Thermal Comfort',
            data: [
              {
                type: 'paragraph',
                content: `Thermal comfort is an occupant's level of satisfaction with the perceived temperature of their surrounding environment. The level of satisfaction is directly related to how much heat is lost from an occupant's body. Too little and an occupant will feel hot. Too much and an occupant will feel cold. Six factors that affect occupant heat transfer and resulting thermal comfort are described in the American Society of Heating Refrigeration and Air-conditioning Engineers (ASHRAE) Standard 55: Thermal Environmental Conditions for Human Occupancy. These factors are: dry bulb temperature, relative humidity, radiant temperature, air speed, metabolic rate, and clothing level.`,
              },
              {
                type: 'paragraph',
                content: `Two of these factors are determined by occupants themselves metabolic rate and clothing level. A typical HVAC system directly controls dry bulb temperature in a building. The other three factors (relative humidity, radiant temperature, and air speed) are not directly controlled by the HVAC system but often result by maintaining the space's desired dry bulb temperature.`,
              },
              {
                type: 'paragraph',
                content: `When occupants feel hot or cold they typically respond by adjusting the thermostat, as it is often the only means of adjusting the indoor environment. But it is not uncommon for occupants to achieve thermal comfort by using fans and space heater devices that do not significantly change dry bulb temperature but affect thermal comfort. Fans increase air speed, which increases heat transfer from an occupant to the space and creates a cooling effect. Space heaters increase mean radiant temperature by emitting radiant energy that strikes the occupant and creates a heating effect.`,
              },
              {
                type: 'paragraph',
                content: `This guide will explore how adjusting air speed can impact building and HVAC system design while maintaining the same level of occupant thermal comfort as a typical HVAC system.`,
              },
              {
                type: 'table',
                tableData: {
                  headers: ['Factor', 'Description'],
                  data: [
                    [
                      `Dry bulb temperature`,
                      `The temperature measured by a dry thermometer in the open air. As the temperature is reduced, the cooling sensation is increased.`,
                    ],
                    [
                      `Relative humidity`,
                      `The amount of water vapor present in the air relative to saturated air. As the relative humidity is reduced, the cooling sensation is increased.`,
                    ],
                    [
                      `Radiant temperature`,
                      `The average temperature of surfaces such as walls, floors, ceilings, etc. in the space. As the mean radiant temperature is reduced, the cooling sensation is increased.`,
                    ],
                    [
                      `Air speed`,
                      `The speed of air passing over the occupant. As the air speed is increased, the cooling sensation is increased.`,
                    ],
                    [
                      `Metabolic rate`,
                      `An occupant's heat generation resulting from their activity or stress level. As the metabolic rate is increased, the body generates more heat and the cooling sensation is decreased.`,
                    ],
                    [
                      `Clothing level`,
                      `The insulating properties of an occupant's attire. As the clothing level is increased, less heat is removed from the body and the cooling sensation is decreased.`,
                    ],
                  ],
                },
              },
            ],
          },
          {
            mainContent: 'Improved Air Distribution',
            sidebarContent: 'Improved Air Distribution',
            data: [
              {
                type: 'paragraph',
                content: `Ceiling fans improve air distribution by increasing the circulation rate of air within a space relative to typical HVAC systems without ceiling fans. Increasing the air circulation within a space has positive effects on HVAC systems by reducing the extent of supply air ductwork, increasing ventilation effectiveness, reducing air temperature gradients, and improving cooling thermal comfort. This has the benefit of simplifying the HVAC system installation and improving its performance.`,
              },
            ],
          },
          {
            mainContent: 'First Cost Savings',
            sidebarContent: 'First Cost Savings',
            data: [
              {
                type: 'paragraph',
                content: `The benefits of improved air distribution offered by ceiling fans can reduce the first cost of HVAC systems in multiple ways. Reducing the extent of the supply air ductwork reduces not only sheet metal costs but also costs associated with reduced system heating and cooling capacity, fewer ductwork accessories, fewer terminals, and lower testing and balancing costs. This has the added benefit of lowering the first cost of the HVAC system where ceiling fans can be incorporated into the building design.`,
              },
            ],
          },
          {
            mainContent: 'Energy Savings',
            sidebarContent: 'Energy Saving',
            data: [
              {
                type: 'paragraph',
                content: `While it is true that ceiling fans consume energy, the energy saved by reducing the burden on the HVAC system is far greater. This results in a net energy savings for the building. There are several ways that ceiling fans reduce the heating and cooling burden on HVAC systems and reduce energy consumption. They do this primarily by reducing conductive heat losses through the building envelope, improving ventilation effectiveness, and reducing HVAC fan energy.`,
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   mainContent: 'Airflow',
  //   sidebarContent: 'Airflow',
  //   sections: [
  //     {
  //       mainContent: 'CFM',
  //       sidebarContent: 'CFM',
  //       subSections: [
  //         {
  //           mainContent: 'How Fan Airflow (CFM) Performance is Measured',
  //           sidebarContent: 'How Fan Airflow (CFM) Performance is Measured',
  //           data: [
  //             {
  //               type: 'paragraph',
  //               content: `In January of 2017, the United States Department of Energy (DOE) finalized regulations that included two test methods for the performance of ceiling fans.  The regulations specify that ceiling fans larger than 84 inches (213.4 cm) are to be tested using the 2015 version of Air Movement and Control Association's (AMCA) Standard 230- Laboratory Methods of Testing Air Circulating Fans for Rating and Certification, with some minor modifications.`,
  //             },
  //             {
  //               type: 'paragraph',
  //               content: `AMCA 230 was first published in 1999 and has changed significantly with each subsequent publication. The 2007, 2012, and 2015 versions of the standard revised test methods and airflow calculations, among other requirements.  In addition to the three revisions to the standard, AMCA also published an erratum to the 2015 version of the standard in 2021.`,
  //             },
  //             {
  //               type: 'paragraph',
  //               content: `All versions of AMCA 230 rely on the same basic procedure for ceiling fans.  The fan mounted to a load cell that is attached to the ceiling.  The fan is turned on, the power consumption and the load differential are measured for a period of time, and then average values are determined.  Electrical input power and airflow rate are then calculated from the measurements.`,
  //             },
  //             {
  //               type: 'image',
  //               imageData: {
  //                 src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/scissorLift.png',
  //                 alt: '', // TODO: add alt and caption - maybe update to better quality image
  //                 caption: ``,
  //                 letter: 'a',
  //               },
  //             },
  //             {
  //               type: 'paragraph',
  //               content: `When comparing the published performance data of different fans, it is critical that the same method of test be used.  Figure 2.1.1 below shows how the maximum-speed performance data for the same fan tested under each revision of AMCA 230 would vary.`,
  //             },
  //             {
  //               type: 'table',
  //               tableData: {
  //                 headers: [
  //                   'Revision',
  //                   'Power (W)',
  //                   'Thrust (lb-f)',
  //                   'Airflow (CFM)',
  //                 ],
  //                 data: [
  //                   ['AMCA 230-99', '750', '36.5', '113,664'],
  //                   ['AMCA 230-07', '750', '37.0', 'N/A'],
  //                   ['AMCA 230-12', '750', '37.0', '80,897'],
  //                   ['AMCA 230-15', '750', '37.0', '80,365'],
  //                   ['AMCA 230-15 with erratum', '-', '37.0', '-'],
  //                   ['US DOE Regulations', '-', '37.0', '-'],
  //                 ],
  //                 caption: `Impact of test method on ceiling fan's maximum-speed performance data`,
  //               },
  //             },
  //             {
  //               type: 'paragraph',
  //               content: `At the time of the writing of this guide, AMCA 230 is under review by an AMCA technical committee.  The goal of 2022/2023 revision of AMCA 230 is to revise the standard to align with DOE ceiling fan regulations, increase the clarity of the document, and increase the accuracy/repeatability of the results of testing performed in accordance with the standard.`,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    mainContent: 'Background & Rationale',
    sidebarContent: 'Background & Rationale',
    sections: [
      {
        mainContent: 'Thermal Comfort',
        sidebarContent: 'Thermal Comfort',
        subSections: [
          {
            mainContent: 'Human Body Thermoregulation',
            sidebarContent: 'Human Body Thermoregulation',
            data: [
              {
                type: 'paragraph',
                content: `As discussed in Chapter 1, thermal comfort is an occupant's level of satisfaction with the perceived temperature of their surrounding environment. How satisfied an occupant is depends on how much heat is lost from an occupant's body. There are four ways in which the body exchanges heat with its surrounding environment: convection, evaporation, radiation, and conduction.`,
              },
              {
                type: 'paragraph',
                content: `Convection occurs when heat is transferred between a solid source and a moving fluid. For example, convective heat transfer is at work when heat from the skin is transferred to the surrounding air. In still air, this method of heat transfer is responsible for about 15% of heat loss from the body. When air speed increases, so does the amount of heat loss through convection.`,
              },
              {
                type: 'paragraph',
                content: `Heat transfer from the body also occurs through the evaporation of water. As people sweat, moisture on the skin is converted to vapor. This phase change requires energy, which is provided by the skin. As energy is transferred from the skin to the water, a cooling effect is felt. The rate of evaporation is largely determined by the relative humidity in the space. Less humid air is able to hold more moisture, allowing more evaporation to occur. At rest, the human body experiences about 20% of its heat loss through evaporation. During exercise, this number increases significantly. Elevated air speeds increase evaporation by providing air movement over the skin to move humid air away from the body, which is then replaced by drier air that has the capacity to absorb more water.`,
              },
              {
                type: 'paragraph',
                content: `Radiation is the transfer of heat by electromagnetic waves. Radiant energy is transferred from an object at a higher temperature to one at a lower temperature, without the two objects touching. For example, radiant energy from the sun heats the skin making you feel warm. Heat from the body can also be lost to surfaces in the surrounding environment, resulting in a cooling effect. In an air conditioned space, approximately 60% of body heat loss is due to radiation.`,
              },
              {
                type: 'paragraph',
                content: `The last mechanism of heat transfer from the body is conduction. Conduction is defined as heat transfer between two objects in contact. When someone is holding a cold drink and the drink warms up while their hand feels cold, that is caused by conductive heat losses. Conduction is typically responsible for less than 5% of the body's heat loss.`,
              },
            ],
          },
          {
            mainContent: 'How Ceiling Fans Help Meet Thermal Comfort Goals',
            sidebarContent: 'How Ceiling Fans Help Meet Thermal Comfort Goals',
            data: [
              {
                type: 'paragraph',
                content: `Fans increase air speed, which increases heat loss by two of the four thermoregulation mechanisms: convection and evaporation. This helps the body cool itself and increases thermal comfort for occupants.`,
              },
              {
                type: 'paragraph',
                content: `Elevated air speed also results in more air movement felt by occupants. The Center for the Built Environment (CBE) at the University of California Berkeley conducted studies where air movement was varied by the use of ceiling fans and occupants were surveyed on their level of satisfaction with their environment. Studies found that perceptible air movement significantly improved the occupants' thermal comfort, perceived air quality, and satisfaction with environmental factors such as humidity.`,
              },
              {
                type: 'paragraph',
                content: `So air speed is known to increase thermal comfort and occupant satisfaction, but how is thermal comfort defined? And how much air movement is the right amount? To answer those questions and more, various standards have been established- including ASHRAE Standard 55.`,
              },
            ],
          },
          {
            mainContent:
              'ASHRAE Standard 55: Thermal Environmental Conditions for Human Occupancy',
            sidebarContent:
              'ASHRAE Standard 55: Thermal Environmental Conditions for Human Occupancy',
            data: [
              {
                type: 'paragraph',
                content: `ASHRAE Standard 55 is titled Thermal Environmental Conditions for Human Occupancy. The purpose, as stated in the standard, is "to specify the combinations of indoor thermal environmental factors and personal factors that will produce thermal environmental conditions acceptable to a majority of the occupants within the space." Simply stated, the standard identifies factors that affect thermal comfort and describes how adjusting each affects occupant satisfaction with an indoor environment. It also provides a method for determining optimal values for factors such as temperature and humidity to create a comfortable environment.`,
              },
              {
                type: 'paragraph',
                content: `Standard 55 identifies six factors that affect thermal comfort: metabolic rate, clothing insulation, air temperature, radiant temperature, air speed, and humidity. It then defines what combination of air temperature, radiant temperature, air speed, and humidity produces acceptable conditions for occupants with a certain metabolic rate and clothing level. The combination of these factors that predicts an acceptable thermal environment for occupants is defined as a thermal comfort zone.`,
              },
              {
                type: 'paragraph',
                content: `Metabolic rate is the rate of transformation of chemical energy into heat and mechanical work during a given activity. Therefore, the metabolic rate of occupants changes based on activity level. Table 5.2.1.2 in Standard 55 provides a list of metabolic rates for typical tasks. For example, the typical metabolic rate for typing is 1.1 met. The typical rate for cooking varies from 1.6 to 2.0 met. For individuals with activities that vary, time-weighted average metabolic rates can be used.`,
              },
              {
                type: 'paragraph',
                content: `Clothing levels of occupants can also vary based on season, space use type, and/or time of day. The more clothing an occupant wears, the higher the resistance to sensible heat transfer between the occupant and the surrounding environment. Tables 5.2.2.2A and 5.2.2.2B provide clothing insulation values for various clothing ensembles and individual garments. For example, trousers and a short sleeve shirt have a clothing insulation value of 0.57 clo. A long-sleeve, thick sweater has a value of 0.36 clo. Clothing insulation values are additive, so an individual wearing trousers, a short sleeve shirt, and a sweater, would have a clo of 0.93. Using these metabolic rate and clothing level tables, the heat transfer and thermal comfort characteristics of the occupants in a space can be defined.`,
              },
              {
                type: 'paragraph',
                content: `The next variables to define are the characteristics of the space: air temperature, radiant temperature, air speed, and humidity. These values are typically set by a combination of the outdoor environment and the HVAC systems present in the building. A typical HVAC system directly controls the dry bulb temperature in a building. The other three factors: relative humidity, radiant temperature, and air speed may not be directly controlled by the HVAC system, but will often result from maintaining the desired space dry bulb temperature. In a system employing ceiling fans, air speed can be directly controlled. The additional flexibility this provides in defining a thermal comfort zone will be discussed in more detail later in this section.`,
              },
              {
                type: 'paragraph',
                content: `Standard 55 uses two different methods to determine acceptable thermal comfort zones: graphical and analytical. The graphical comfort zone method, defined by Section 5.3.1 of the standard, provides a graph illustrating the comfort zone for a specific range of occupant metabolic rates and clothing insulation levels. Space conditions can be plotted on the graph to determine if they fall within the comfort zone. The graphical method may only be used when occupant characteristics fall within certain ranges defined by the Standard. This method does not account for the increased thermal comfort provided by elevated air speeds. If conditions are outside the given range, the analytical method must be used.`,
              },
              {
                type: 'paragraph',
                content: `The analytical comfort zone method is described in Section 5.3.2 and applies to a wider range of occupant characteristics. A variation on the analytical method allows users to determine compliance for systems using elevated air speeds. This is called the Elevated Air Speed Comfort Zone Method and is described in Section 5.3.3. This method is explained in further detail below. The analytical method calculates a predicted mean vote (PMV) value for a certain combination of thermal comfort factors. PMV is an index that predicts the self-reported perceptions of an occupant group about its environment. PMV values are on a scale from -3 to 3 where each number corresponds to a sensation: cold (-3), cool (-2), slightly cool (-1), neutral (0), slightly warm (+1), warm (+2), hot (+3). A compliant PMV (space conditions are within the thermal comfort zone) is between -0.5 and 0.5. The methodology for calculating PMV is described in two full pages of computer code in Normative Appendix B of the standard. For the busy engineer who doesn't have time to write a program to calculate PMV, there's the CBE Thermal Comfort Tool.`,
              },
            ],
          },
          {
            mainContent: 'CBE Thermal Comfort Tool',
            sidebarContent: 'CBE Thermal Comfort Tool',
            data: [
              {
                type: 'paragraph',
                content: `The Center for the Built Environment at the University of California at Berkeley developed a web-based tool for thermal comfort calculations using ASHRAE Standard 55 methodology. The user enters operative temperature, air speed, humidity, metabolic rate, and clothing level. Metabolic rates and clothing levels are provided for a number of typical activities and ensembles. The Thermal Comfort Tool will calculate the results, including PMV, and determine compliance with Standard 55. It also generates a chart illustrating the thermal comfort zone for those conditions and plots the input operating point.`,
              },
              {
                type: 'paragraph',
                content: `The CBE Thermal Comfort Tool allows a user to take credit for elevated air speeds by accounting for average air speed in the calculation. As airspeed increases, the range of acceptable operating temperatures increases as well. This lets the user pick a higher temperature setpoint, while still being compliant with Standard 55. The Thermal Comfort Tool also has an input for whether local air speed control is provided. Standard 55 limits the maximum average air speed permitted when occupants do not have control over the system. The Comfort Tool verifies compliance by checking that the air speed is below this maximum value if no local control is provided.`,
              },
              {
                type: 'paragraph',
                content: `For examples of how to use the CBE Thermal Comfort Tool and how thermal comfort zones change with elevated air speeds, refer to Chapter 2 Section 1.`,
              },
            ],
          },
          {
            mainContent:
              'Thermal Comfort Calculations with Elevated Air Speeds',
            sidebarContent:
              'Thermal Comfort Calculations with Elevated Air Speeds',
            data: [
              {
                type: 'paragraph',
                content: `In Section 5.3.3, Standard 55 provides a means of calculating thermal comfort in applications with elevated air speed. The Elevated Air Speed Comfort Zone Method uses the Analytical Comfort Zone Method combined with the Standard Effective Temperature (SET) method described in Normative Appendix D.`,
              },
              {
                type: 'paragraph',
                content: `SET is the hypothetical temperature of a standardized environment (50% RH, still air, mean radiant temperature equal to average air temperature) with a standardized occupant (1.0 met and 0.6 clo) where the total heat loss from the occupant is equal to the heat loss from an actual occupant in the actual environment. Appendix D uses SET to account for the cooling effect of air speeds that exceed the ranges covered by the Graphical and Analytical methods. In this method, SET is first calculated at the design environmental and occupant conditions. Then, the average air speed is replaced by that of still air, 20 fpm (0.1 m/s), and the air and radiant temperatures are adjusted until the resulting SET is equal to the SET calculated in the first step. The amount the air and radiant temperatures were adjusted is defined as the cooling effect (CE) of the elevated air speed. Those adjusted average air and mean radiant temperatures are then used to calculate the PMV value, along with an average air speed of 20 fpm (0.1 m/s), the original relative humidity, original clo value, and original metabolic rate.`,
              },
              {
                type: 'paragraph',
                content: `Cooling effect also plays an important role in the calculation of Cooling Fan Efficiency (CFE). This metric is defined by ASHRAE Standard 216 as the ratio of the cooling effect to the input power of the fan. CFE provides a standardized way of comparing ceiling fans and how much cooling they provide relative to energy consumption. Standard 216 outlines a framework for testing fans and providing standardized performance data to facilitate an even comparison.`,
              },
            ],
          },
          {
            mainContent: 'How To Calculate Air Speed',
            sidebarContent: 'How To Calculate Air Speed',
            data: [
              {
                type: 'paragraph',
                content: `Average air speed is defined by ASHRAE Standard 55 as "the average air speed surrounding a representative occupant… with respect to location and time." The average is calculated using three heights (ankle, waist, and head level) over a time interval between one and three minutes. Head and waist height values depend on whether occupants are seated or standing. For seated occupants, heights of 4 in. (0.1 m), 24 in. (0.6 m), and 43 in. (1.1 m) are used. For standing occupants, heights are 4 in. (0.1m), 43 in. (1.1 m), and 67 in. (1.7 m). Averaging air speed over these three heights provides an idea of how air movement is felt across the whole body.`,
              },
              {
                type: 'paragraph',
                content: `Standard 55 defines elevated air speed as an average air speed greater than 40 fpm (0.2 m/s). Still air is defined as an average air speed of 20 fpm (0.1 m/s). When designing for elevated air speed, a target air speed should be determined based on the space use type. Spaces with higher activity levels typically benefit from higher average air speeds, since occupants have higher cooling needs. Figure 2.1.6a below provides reference points for various velocities and some context for how different air speeds are perceived by humans.`,
              },
              {
                type: 'table',
                tableData: {
                  data: [
                    [`< 40 fpm (0.20 m/s)`, `Imperceptible`],
                    [
                      `85 fpm (0.43 m/s)`,
                      `Start of significant cooling effect`,
                    ],
                    [
                      `90 fpm (0.46 m/s)`,
                      `Average walking speed of a giant Galapagos tortoise`,
                    ],
                    [
                      `160 fpm (0.81 m/s)`,
                      `Upper limit for no local control per ASHRAE Standard 55`,
                    ],
                    [
                      `200 fpm (1.0 m/s)`,
                      `Suggested maximum targeted air speed - conditioned, sensitive environments`,
                    ],
                  ],
                  caption: `Reference points for various air speeds.`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `Average air speed is typically calculated using computational fluid dynamics (CFD). CFD modelers allow a user to input room and air characteristics and generate an airflow profile in the space. Big Ass Fans (BAF) SpecLab is an example of one such CFD modeler. In SpecLab, the BAF team is able to create a 3D representation of the space being modeled. The modeler inputs all floors, walls, and ceilings as well as space temperature and humidity set points. Floor level obstructions and occupant locations may be added to the space if desired. The modeler then calculates average air speed and other variables at various points throughout the room. These results allow a designer to see uniformity of airflow and temperature throughout the space and to determine necessary values to perform thermal comfort calculations.`,
              },
              {
                type: 'paragraph',
                content: `Examples of CFD models generated by SpecLab are included in Chapter 3 Section 2.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Heat Stress and Productivity ',
        sidebarContent: 'Heat Stress and Productivity',
        subSections: [
          {
            mainContent: 'What Is Heat Stress?',
            sidebarContent: 'What Is Heat Stress?',
            data: [
              {
                type: 'paragraph',
                content: `Heat stress is the buildup of heat in the body to unhealthy levels. In severe cases, it can lead to illnesses such as heat exhaustion and heat stroke. Both internal and external factors can contribute to heat stress. For example, strenuous activity that increases the metabolic rate will increase the amount of heat generated by the body. Additionally, if a person is in a hot environment, their body is less able to reject heat to the surroundings and overheating can occur. While individuals may be able to control the internal causes of heat stress by limiting activity, it is up to building operators and engineers to design a safe, comfortable indoor environment. Especially in situations where occupants are expected to have high levels of activity, such as gyms or warehouses, thermal comfort is key to keeping workers safe and healthy.`,
              },
              {
                type: 'paragraph',
                content: `The Occupational Safety and Health Administration (OSHA) has created a heat index to evaluate the risk of heat-related illness in a given environment. The index takes into account both the temperature and relative humidity in a space, as both contribute to the body's capacity to reject heat. The heat index ranges from temperatures less than 91°F (33°C) to temperatures above 115°F (46°C) and assigns risk levels of Lower, Moderate, High, and Very Extreme to High. OSHA provides recommendations of precautions to take at each risk level. The OSHA heat index is shown in Figure 2.2.1a below.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2_2_HeatIndex.jpg',
                  alt: 'Heat Index Chart',
                  caption: `Heat Index is a measure of how hot it "feels" to the body.`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `As discussed in Chapter 1 of this guide, elevated air speed across the human body can increase the rate of heat loss, which in turn lowers the risk of heat stress.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/HourlyHeatIndexDistribution.png',
                  alt: 'Hourly heat index distribution for a typical year',
                  caption: `Hourly Heat Index Distribution for a Typical Year`,
                  letter: 'b',
                },
              },
            ],
          },
          {
            mainContent: 'How Thermal Comfort Impacts Productivity',
            sidebarContent: 'How Thermal Comfort Impacts Productivity',
            data: [
              {
                type: 'paragraph',
                content: `In addition to the health risks presented by overheating, studies have also found productivity decreases when occupants are too warm. This productivity loss applies to both physical and intellectual work in typically conditioned spaces (offices, stores, etc) and to more physically focused work in unconditioned spaces such as manufacturing facilities and warehouses. The benchmark study for office-type productivity loss is a study conducted by Olli Seppanen and William Fisk at the Helinski University of Technology in Finland. The benchmark study for physical work productivity loss is a study by Sherif Mohamed and Korb Srinavin at Griffith University in Brisbane, Australia.`,
              },
              {
                type: 'paragraph',
                content: `This loss of productivity can occur at much lower temperatures than the range that causes heat stress, providing an incentive to strive for thermal comfort beyond basic safety. According to the study conducted by Seppanen and Fisk, worker performance starts to decrease at only 77°F (25°C). And an additional 2% decrease in performance is seen for every additional 1.8°F (1°C) increase up to 90°F (32°C). Above 90°F (32°C), heat-related illness risk increases and safety becomes a greater concern.`,
              },
              {
                type: 'paragraph',
                content: `In buildings with comfort cooling, maintaining an indoor temperature below 77°F (25°C) is typically as simple as setting a thermostat; however, not all buildings have a mechanical cooling system. In some space types, such as warehouses, it is not uncommon to forego an AC system. Christian Taber and Donald Colliver simulated the impact of air movement and elevated air speed on thermal comfort in these settings in a published ASHRAE Journal piece (Thermal Comfort in Heated-and-Ventilated Only Warehouses). The study simulates a typical warehouse (50,000 ft² (4645 m²)) built to increasingly stringent energy codes and predicts the occupant thermal comfort and productivity levels at each code revision. Additional simulations also introduce elevated air speed and evaluate the impact on productivity. The study found that without any cooling system in place, there were a significant number of hours outside of the ASHRAE Standard 55-defined thermal comfort range, especially in warmer climate zones. An equation developed by Srinavin and Mohamed was then used to calculate productivity loss based on thermal discomfort. The equation is based on PMV, the index of predicted occupant comfort defined by ASHRAE Standard 55. By calculating productivity loss, the financial impacts of reduced productivity may be ascertained based on worker occupancy levels and wages. The Taber/Colliver study calculates annual cost of productivity loss due to high PMV both with and without elevated air speed. Simulations showed that adding 160 fpm (0.81 m/s) resulted in a significant decrease in the number of uncomfortable hours and the financial impact of reduced productivity. Productivity losses varied by climate zone, with warmer zones seeing larger losses, but on average, annual productivity losses under the 90.1-2016 energy code were $6,272. This decreased to $5,227 with the addition of elevated air speed (at 160 fpm (0.81 m/s)), or a 17% reduction in losses.`,
              },
            ],
          },
          {
            mainContent: 'Overview of Productivity Calculator',
            sidebarContent: 'Overview of Productivity Calculator',
            data: [
              {
                type: 'paragraph',
                content: `Cost is a major factor in the selection of an HVAC system. Building owners and operators will look at first cost, and sometimes even operating cost, when deciding on a system, but often the value of increased occupant productivity is overlooked. This may be due in part to the perception that calculating the impact of productivity loss is difficult. Big Ass Fans provides a simple online tool to assist with this calculation.`,
              },
              {
                type: 'paragraph',
                content: `The BAF Productivity Calculator is available on the Big Ass Fans website under Resources. It utilizes the same methodology described in the Taber/Colliver study to calculate productivity loss due to thermal comfort. The only required inputs are the state where the building is located, number of employees, average employee hourly wages, and average hours per week per employee. The Calculator computes the annual losses due to reduced productivity, as well as the potential gains in productivity and profit achieved by adding elevated air speed. While these savings are approximate, they provide concrete justification for installing a fan system and show the magnitude of savings that stands to be gained. This information demonstrates how savings from increased productivity can offset the first cost of a fan system over the lifetime of a building.`,
              },
              {
                type: 'paragraph',
                content: `Examples of the financial justifications for elevated air speed due to productivity gains can be found in Chapter 3 Section 7.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Integrating with Evaporative Coolers',
        sidebarContent: 'Integrating with Evaporative Coolers',
        subSections: [
          {
            mainContent: 'Fans in Conjunction with Evaporative Coolers',
            sidebarContent: 'Fans in Conjunction with Evaporative Coolers',
            data: [
              {
                type: 'paragraph',
                content: `While elevated occupant level airspeed can provide a significant cooling effect and reduce heat stress in ventilated-only spaces across a wide range of conditions (Figure 2.3.1a), there are applications where auxiliary cooling devices may be necessary to accommodate indoor air conditions above the threshold of thermal comfort. This is especially true in very hot, dry climates where dry-bulb air temperatures can approach triple digits regularly. Cooling devices such as direct evaporative coolers may be introduced into the space and, when used in conjunction with HVLS fans and proper ventilation, can not only dramatically reduce heat stress in the immediate area but can also be paired with HVLS fans to provide a uniform distribution of cooler air throughout the space (Figures 2.3.1b below). The implementation of systems such as these is much less costly and much more energy-efficient than introducing commercial HVAC or adding capacity to under-conditioned or unconditioned spaces. `,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/HeatStrain.png',
                  alt: 'Heat strain levels in different conditions',
                  caption: `Heat Strain Levels in Different Thermal Conditions`,
                  letter: 'a',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/HourlyHeatIndexDistribution2.png',
                  alt: 'Hourly heat index distribution for a typical year',
                  caption: `Hourly Heat Index Distribution For a Typical Year`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `For spot cooling applications, evaporative units can be selected based on desired airflow patterns and other product characteristics - similar to how directional fans are selected. For whole space applications, evaporative coolers can be sized based on their cooling capacity in a given locale. Paired HVLS fans should be designed for air mixing at a minimum but cooling designs work well too. An example of thermal comfort and heat stress outcomes for a hybrid (HVLS+Evap) system is shown in Figure 2.3.1c below.
                `,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/OccupantPMV.png',
                  alt: 'Occupant PMV values for spaces with no fans, evap coolers only, and a hybrid of fans and evap coolers',
                  caption: `Occupant PMV Values for Spaces with No HVLS Fans, Evaporative Coolers Only, and a Hybrid of HVLS Fans and Evaporative Coolers`,
                  letter: 'c',
                },
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Ceiling Fan Use in Heating',
        sidebarContent: 'Ceiling Fan Use in Heating',
        subSections: [
          {
            mainContent: 'What is Stratification?',
            sidebarContent: 'What is Stratification?',
            data: [
              {
                type: 'paragraph',
                content: `Warm air is less dense than cool air causing it to be more buoyant and rise. When warm air in a space rises and cooler air sinks, this creates layers of air in the space where each layer is a different temperature. This effect is known as stratification.`,
              },
              {
                type: 'paragraph',
                content: `Air stratification causes a variety of issues in HVAC design. When warm air is delivered to a space during heating, and the air stratifies, the warm air stays at the top of the space instead of reaching the occupants in need of heating. That same supply air is delivering ventilation to the space. Again, if the air remains at the top of the space, outdoor air is not reaching the building's occupants. In these ways, stratification negatively impacts both thermal comfort and indoor air quality. Additionally, stratification can lead to stack effect, which occurs when warm air rises and escapes through penetrations in the top of the building envelope. This creates lower pressure in the bottom of the building, causing infiltration of cold air into the building. As a result, the heating system runs more and the building consumes more energy.`,
              },
            ],
          },
          {
            mainContent: 'Destratification Using Ceiling Fans',
            sidebarContent: 'Destratification Using Ceiling Fans',
            data: [
              {
                type: 'paragraph',
                content: `Destratification is the process of mixing air in a space to combat stratification. It is accomplished primarily through the use of fans, which recirculate air to break up the layers that form due to temperature differentials. Since hot air rises, ceiling fans are located near the stagnant hot air in a stratified room and are therefore able to push the warm air to the floor, mixing the air and destratifying the space.`,
              },
              {
                type: 'paragraph',
                content: `Conventional wisdom was that when attempting to destratify a space in winter, the ceiling fan's rotation should be reversed. In this method, hot air is moved across the ceiling and down the walls, recirculating it throughout the space. This strategy was favored due to concern that running a ceiling fan in the forward direction in winter would create a draft and cool occupants. However, if fans are run in the forward direction at a slower speed, they are better able to destratify a space and create a negligible amount of draft. Running fans in reverse, conversely, has been shown to be less effective at destratification and create a greater draft.`,
              },
              {
                type: 'paragraph',
                content: `Big Ass Fans conducted a study to test both methodologies, running a fan in the forward or reverse directions. The factors evaluated were destratification ability, energy use, and draft risk. The study compared three different fan scenarios in: a 60 in. (1.5 m) BAF Haiku fan running in the forward direction, a 60 in. (1.5 m) residential ceiling (paddle) fan from a common manufacturer running in reverse, and the same 60 in. (1.5 m) paddle fan running in the forward direction. Fans were tested in a 20 ft. by 20 ft. (6.1 m by 6.1 m) space with adjusted ceiling heights of 9 ft. (2.7 m), 10 ft. (3 m), and 12 ft. (3.7 m). In order to test for destratification ability, the room was heated and allowed to stratify for 40 minutes. After the stratification period, the fan was turned on for 40 minutes. Temperature was logged at various points in the room throughout the duration of the test. Temperature was measured at the floor (4 in. (102 mm) above finished floor (AFF)), at typical seated height (43 in. (1.1 m) AFF), and at typical standing height (67 in. (1.7 m) AFF) (the three heights recommended by ASHRAE Standard 2015). Temperature was also measured 12 in. (305 mm) below the ceiling. For the purposes of this study, destratification was defined as less than a 1.5°F temperature difference between the ceiling and seated occupant level (43 in. (1.1 m) AFF). Energy consumption was measured by recording power use of each fan. Draft was tested by running the fan for 10 minutes to allow airflow to reach steady state values, then measure air velocities at various distances radially from the center of the fan.`,
              },
              {
                type: 'paragraph',
                content: `The results of the study found the Haiku fan running in the forward direction was able to destratify the space using much slower speeds than the paddle fans running in either the reverse or forward direction. As such, the Haiku used 1/12th to 1/16th of the power of the paddle fan to achieve thermal mixing (depending on ceiling height).`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2_3_ComparisonFanPower.png',
                  alt: 'Comparison of Fan Power Consumption',
                  caption: `Comparison of Fan Power Consumption from Destratification white paper.`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `Additionally, at the air speeds required to destratify the space, the areas where a perceptible draft was recorded were much lower for the Haiku fan compared to the paddle fan. Specifically, the Haiku fan generated a drafty area directly below the fan equivalent to 0.7% of room area. Running the paddle fan in reverse resulted in 42% of the room area experiencing a perceptible draft. Therefore, the study concluded operating a fan slowly, in the forward direction is more effective for destratification, consumes less energy, and creates less draft.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2_3_AreaOfDraftRisk.png',
                  alt: '% Area pf 20-ft/ x 20 ft. Room in Draft Risk at Minimum Speed',
                  caption: `"% Area of 20 sq. ft.. (6.1 m²) Room in Draft Risk at Minimum Mixing Speed" from Lesser Paper`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `An additional negative impact of reversing a ceiling fan in heating is increased heat transfer through the roof. When a reversed fan blows high speed air across the bottom of the roof, the stagnant layer of air on the bottom of the roof is disturbed and convective heat transfer through the roof increases. This increased heat loss from the building requires the heating system to run more and results in greater energy consumption.`,
              },
              {
                type: 'paragraph',
                content: `As mentioned in the BAF study, different speeds were required for different ceiling fans to destratify the test room. The Haiku fan was able to destratify the room at a much lower speed than the paddle fan. This was due to both the effectiveness of the air path (forward vs. reverse direction) and to the volume of air the fan was moving. Fans are rated based on the airflow in CFM (L/s) they can provide. CFM (L/s) rating is important for destratification because it relates to the air turnover rate (ATH). ATH represents the number of times the entire room air volume is recirculated per hour by a ceiling fan. Air volume is measured in ft3 (L); therefore, in order to determine the number of times this air is recirculated per hour, the airflow rate of the fan in CFM (L/s) must be known. In order to select a ceiling fan for destratification, a designer must know the CFM (L/s) of air it can provide, at an operating speed that minimizes draft risk. It is also important the fan can reliably deliver this volume of air. Therefore, having a stringent fan rating and testing method is key.`,
              },
            ],
          },
          {
            mainContent: 'Incorporating Supplemental Heating with Fans',
            sidebarContent: 'Incorporating Supplemental Heating with Fans',
            data: [
              {
                type: 'paragraph',
                content: `Due to a variety of factors, occupant thermal comfort may be less than desirable during the extremes of winter. These factors may include a change in building use resulting in a space with no mechanical heating needing heating for occupant comfort, age of building, or outside air infiltration that cannot be accounted for with an existing HVAC system if one exists (ie. open dock doors). When occupant comfort cannot be met with existing systems, there are two primary modes of supplemental heating: unit heaters and infrared heaters.`,
              },
              {
                type: 'paragraph',
                content: `Unit Heaters utilize gas or electricity to heat and distribute forced air into a space. Heaters are typically mounted from a ceiling or column structure above the occupant level, and have onboard louvers that direct airflow downward to reach occupants. While unit heat does distribute passively outside of its direct line of airflow, the directional aspect of unit heaters cannot be ignored.`,
              },
              {
                type: 'paragraph',
                content: `Infrared heaters utilize gas or electricity to ignite a flame within a treated tube. They transfer the infrared energy in waves to occupant level with the assistance of specially designed reflectors. Since infrared heaters use radiant energy to directly heat occupants and objects rather than the air volume around them, these heaters are ideal for spaces with significant outdoor air infiltration.`,
              },
              {
                type: 'paragraph',
                content: `As with mechanical heating, unit heaters and infrared heaters still contribute to stratification within the building envelope. Stratification can significantly reduce the positive impact of unit heaters by preventing heated air from reaching (and staying at) occupant level. While it may seem like radiant heat would be less susceptible to stratification, heat is lost through passive convection and is difficult to recover. Implementing fans with unit heaters and infrared heaters allows for improved occupant comfort while maximizing the efficiency of these supplemental products.`,
              },
            ],
          },
          {
            mainContent: 'Improved Ventilation in Heating',
            sidebarContent: 'Improved Ventilation in Heating',
            data: [
              {
                type: 'paragraph',
                content: `In addition to destratification, another benefit ceiling fans provide while heating a room is improved ventilation efficiency. ASHRAE Standard 62.1 Ventilation for Acceptable Indoor Air Quality specifies minimum ventilation rates for commercial buildings. It specifies rates for various space types depending on floor area and occupancy and provides methods for calculating total outdoor airflow to a space. One value defined by the Standard that contributes to outdoor airflow requirements is zone air distribution effectiveness (Ez). Zone air distribution effectiveness is dependent upon air distribution configuration and air mixing within the room and it quantifies the fraction of air supplied to a room that reaches the occupant breathing zone, defined as 3 to 72 in. (75 to 1800 mm) above the floor and more than 2 ft. (600 mm) from walls or fixed air-conditioning equipment. The table in Figure 2.4.4a lists zone air distribution effectiveness for a number of air distribution configurations.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-3-4-Table.png',
                  alt: 'Zone Air Distribution Effectiveness',
                  caption: `Zone Air Distribution Effectiveness from ASHRAE Standard 55`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `Ceiling supply of warm air, 15°F (8°C) or more above space temperature, and ceiling return is one of the most common air distribution configurations in heating. As seen in Figure 2.4.4a, this configuration results in an Ez of 0.8 due to stratification causing warm air to stay at the ceiling rather than drop to the breathing zone. This means that ventilation airflow must be increased by 20% to deliver the required amount of outdoor air to the room's occupants The more outdoor air required in a system, the greater the energy consumption required to heat the outdoor air. However, in a room that has been destratified by a ceiling fan, the air is well-mixed and an Ez of 1 can be used in outdoor air calculations, as shown by CFD analysis and approved by the authority having jurisdiction (AHJ). When an Ez of 1.0 is used in lieu of 0.8, no increase in outdoor airflow rate is required.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Process Improvement',
        sidebarContent: 'Process Improvement',
        subSections: [
          {
            mainContent: 'Product Cooling',
            sidebarContent: 'Product Cooling',
            data: [
              {
                type: 'paragraph',
                content: `Manufacturing processes often have substantial cooling needs. Parts may be heated during one step of a process, but need to cool before beginning the next step. Traditionally components are allowed to cool by exposing them to room temperature air between steps of the process. Depending on initial temperature and desired final temperature this may be a lengthy process; resulting in time and efficiency losses. One method for decreasing cooling time would be to lower the ambient temperature, thus increasing the temperature differential between the process component and the surrounding air, thus expediting heat transfer. However, since manufacturing processes typically take place in large industrial spaces. this would necessitate cooling large volumes of air. A more targeted and lower cost approach would be to use overhead or directional fans.`,
              },
              {
                type: 'paragraph',
                content: `When the traditional cooling approach of allowing components to slowly come to room temperature is used, heat from the object dissipates and warms the air around it. The warm air creates a stagnant boundary layer around the object, insulating it and decreasing the temperature difference between the object and the air immediately surrounding it. This reduces the convective heat transfer from the object and results in a slower cooling process.`,
              },
              {
                type: 'paragraph',
                content: `By adding fans, elevated air speed will blow the stagnant boundary layer away from the object, allowing cooler air to reach the surface and additional heat transfer to occur. The optimal average air speed can be determined by BAF's applications engineers based on the object size and surface area, object material composition, typical ambient temperature, desired final temperature, and desired total cooling time. The fan air velocity profile (AVP) is also key in determining fan design, as it specifies air speed delivered at different points within the fan coverage area. Therefore, AVP is vital to determining fan layout, including aiming angle and mounting locations, for a cooling process. By considering process variables and designing an appropriate layout, fans can be incorporated into a process to provide efficient, cost effective cooling. The performance of the layout can then be validated with a CFD analysis using SpecLab.`,
              },
            ],
          },
          {
            mainContent: 'Condensation Abatement',
            sidebarContent: 'Condensation Abatement',
            data: [
              {
                type: 'paragraph',
                content: `Another common issue encountered in process and industrial applications is condensation. Condensation is the collection of water droplets from the air onto a surface and it occurs when surface temperature equals or drops below the dew point. Condensation can lead to a host of issues, including corrosion, mold, sweating slab, and structural collapse. Several factors affect condensation in an indoor environment including structural and architectural factors (insulation, infiltration, thermal mass, orientation, floor slab, fenestration), mechanical factors (exhaust vents, intake louvers, HVAC system, building automation), environmental factors (dry bulb, dew point, wind speed), and air movement factors (air speed, air-to-surface temperature differential). Since condensation occurs when surface temperature equals or drops below the dew point, the two main methods for condensation abatement are to increase surface temperature or decrease dew point. Increasing surface temperature requires providing heat strategically to the space. Decreasing dew point is accomplished through dehumidification. Both of these options work best when a building envelope is well-sealed, which may require additional time and/or materials, especially in retrofit applications.`,
              },
              {
                type: 'paragraph',
                content: `As stated previously, air movement can also have an impact on condensation. Elevated air speed enhances convection, dries condensation, and circulates warm air. Designed properly, a fan systems has the ability to reduce condensation at a lower initial cost and significantly lower operating cost than several other methods of condensation abatement. Fans may also be used in conjunction with other methods, such as dehumidification and radiant or forced air heaters, to enhance abatement. To optimize operation, all condensation abatement equipment should be integrated through the use of a control system, such as BAF's Dewtect. Dewtect is an autonomous control system for HVLS fans, exhaust fans, indirect-fired heaters, and dehumidifiers which uses sensors and advanced algorithms to balance temperature, humidity, and airflow to reduce the risk of condensation and resulting side effects. By monitoring environmental conditions and recording historical data trends, Dewtect proactively mitigates condensation before it occurs. It also optimizes runtimes of all equipment to reduce overall energy consumption.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Building Energy Efficiency Regulation',
        sidebarContent: 'Building Energy Efficiency Regulation',
        subSections: [
          {
            mainContent: 'Energy Efficiency Benefits',
            sidebarContent: 'Energy Efficiency Benefits',
            data: [
              {
                type: 'paragraph',
                content: `Reducing energy consumption is an increasingly important issue in today's world. Due to the depletion of natural resources and the negative impact of traditional fossil fuels on the environment, reducing energy consumption has become a focus for building owners, environmental organizations, and building codes. Buildings consume approximately 40% of total energy production in the US. Therefore, the building industry can have a significant impact on overall US energy savings. Reducing building energy consumption provides environmental benefits and also offers energy cost benefits. This means building owners can have a positive environmental impact while lowering utility bills.`,
              },
            ],
          },
          {
            mainContent: 'Utility Incentives',
            sidebarContent: 'Utility Incentives',
            data: [
              {
                type: 'paragraph',
                content: `Some energy efficiency measures can increase the first cost of constructing a building. To offset increased first costs, many energy efficient building measures are promoted by utility companies through rebates and financial incentives. These incentives lower the first cost of more efficient systems to bring them into parity with less efficient, lower cost systems. Although utility incentives vary by provider, some utility providers offer prescriptive incentives for HVLS fans. Where prescriptive incentives are absent, utility providers may offer performance based incentives that use the calculated energy savings of HVLS fans to determine the value of the incentive.`,
              },
            ],
          },
          {
            mainContent: 'Energy Conservation Codes',
            sidebarContent: 'Energy Conservation Codes',
            data: [
              {
                type: 'paragraph',
                content: `Building code enforcement agencies understand the benefits of energy conservation are not always enough to adequately promote responsible building design. As a result, building energy conservation codes are used to establish minimum energy efficiency standards that limit building energy consumption. As the need to save energy increases, energy conservation codes grow more stringent.`,
              },
              {
                type: 'paragraph',
                content: `One group that regulates the ceiling fan performance is the United States Federal Government. In an effort to standardize the efficiency measurement and rating method for HVLS fans, Large Diameter Ceiling Fans (HVLS Fans) are a federally regulated product. Published performance data for HVLS fans must be determined using the federally mandated test procedure. Per the Department Of Energy's (DOE) representation requirements at 10 CFR 429.32, all ceiling fan manufacturers are required to use the DOE testing methods as the basis of any published performance data. These requirements (listed in 10 CFR Appendix U to Subpart B of Part 430) specify how fan performance is to be measured and how efficiency is to be represented, Ceiling Fan Energy Index (CFEI). By regulating the method of test and reporting of fans, published data can be more easily compared with assurance performance has been established using consistent methodology. Furthermore, large diameter ceiling fans manufactured on or after January 21, 2020 must meet the following minimum efficiency, CFEI₁₀₀ of 1.00, CFEI₄₀ = 1.31. As will be discussed later, CFEI is only one means of quantifying ceiling fan efficiency and omits other ceiling fan performance metrics valuable in selecting ceiling fans.`,
              },
              {
                type: 'paragraph',
                content: `The International Energy Conservation Code (IECC), published by the International Code Council (ICC), is one of the most commonly adopted energy codes in the US. The IECC is published every three years, with each edition being slightly more stringent than the last. The IECC specifies minimum energy performance requirements for building envelopes, mechanical systems, service water heating, electrical power, artificial lighting, existing buildings, and more. The 2021 version of the IECC requires that large-diameter ceiling fans (greater than 7 feet in diameter) be tested and labeled in accordance with AMCA 230-15. As of writing this guide, changes that mirroor the requirements found in ASHRAE 90.1-2022 have been proposed but not voted on by cognizant IECC committee.`,
              },
              {
                type: 'paragraph',
                content: `An additional energy code for commercial buildings is ASHRAE Standard 90.1: Energy Standard for Buildings Except Low-Rise Residential Buildings. This Standard is also published every three years, with its publication occurring two years prior to each IECC publication (e.g., 2018 IECC is preceded by ASHRAE 90.1-2016). Like the IECC, ASHRAE Standard 90.1 also regulates building envelopes, mechanical systems, service water heating, and electric power and lighting. ASHRAE Standard 90.1-2016, as well as previous versions, does not regulate the efficiency of ceiling fans as part of the mandatory or prescriptive requirements. Addendum cq to 90.1-2016 adds requirements for ceiling fans greater than 84.5 in. (2.1 m) in diameter. The blade span (blade tip diameter) and the rated airflow and power consumption at maximum speed must be provided. The data must be determined using either 10 CFR 430 Appendix U (US projects) or AMCA 230-15 (non-US projects). The data is required to be either determined by an independent laboratory, included in a database published by US DOE, or from a certified rating program (such as AMCA CRP). ASHRAE 90.1-2022 removes the requirements from the 2019 version and replaces them with minimum efficiency requirements based on the CFEI metric.  A minimum CFEI of 1.00 at maximum speed and 1.31 at 40 percent speed or nearest speed that is not less than 40 percent speed is required.  These requirements are essentially identical the US DOE regulations for large diameter ceiling fans.`,
              },
              {
                type: 'paragraph',
                content: `Although there are no direct credits for the usage of ceiling fans in the IECC or Standard 90.1, ceiling fans can be used with the Performance Rating Method (PRM) option of Standard 90.1-2016 or Section C407 Total Building Performance of the 2018 IECC to demonstrate projected energy savings associated with the use of ceiling fans. These performance paths provide rulesets for calculating annual energy consumption of buildings using energy modeling software. The results establish the relative performance of a proposed building to that of a theoretical baseline building in terms of annual energy cost. When using the latest rules, the thermostat setpoints used in the baseline and proposed buildings must match. However, specific exceptions allow different setpoints when using elevated air speed to provide equal occupant thermal comfort. The use of higher cooling thermostat setpoints decreases the conductive heat transfer through the building envelope and the cooling required to treat the outdoor air.`,
              },
              {
                type: 'paragraph',
                content: `ASHRAE also publishes Standard 189.1: Standard for the Design of High-Performance Green Buildings. The purpose of Standard 189.1 is "to provide minimum requirements for the siting, design, construction, and plans for operation of high-performance green buildings." While Standard 189.1 references Standard 90.1, the breadth of 189.1 is much wider and the energy efficiency requirements more stringent. Standard 189.1 is also published as the International Green Conservation Code (IgCC) by the ICC. These standards are not widely adopted; however, a local jurisdiction may choose to adopt the IgCC/Standard 189.1, in which case compliance must be demonstrated. Similar to the IECC and Standard 90.1, these codes offer performance paths for demonstrating energy conservation compliance that can be used to take advantage of the energy savings offered by ceiling fans.`,
              },
              {
                type: 'paragraph',
                content: `In addition to codes and standards that dictate energy requirements, building owners may set project-specific requirements for energy efficiency. These can take the form of specific owner goals or participation in a green building rating system, such as LEED or Green Globes. Green building rating systems typically award points for improving the building's energy conservation. This creates an incentive to design energy efficient building systems. In addition, these rating systems also award points for buildings that provide adequate thermal comfort and indoor air quality to occupants. As discussed previously, ceiling fans enhance these aspects in buildings and earn points that contribute to achieving green building certifications.`,
              },
            ],
          },
          {
            mainContent: 'How Ceiling Fans Reduce Energy Consumption',
            sidebarContent: 'How Ceiling Fans Reduce Energy Consumption',
            data: [
              {
                type: 'paragraph',
                content: `Ceiling fans reduce energy consumption in buildings by reducing the heating and cooling burden on the HVAC systems. As noted in the introduction, although ceiling fans themselves consume energy, HVAC energy savings are significantly greater than the energy cost of the ceiling fans. HVAC energy savings is attributed to reduced conductive heat transfer through the building envelope, ventilation effectiveness improvement, and HVAC fan energy reduction.`,
              },
            ],
            subSections: [
              {
                mainContent: 'Higher Thermostat Cooling Point',
                data: [
                  {
                    type: 'paragraph',
                    content: `As discussed previously, when air speed is increased at the occupant level, the temperature experienced by the occupant feels cooler. As a result, when using a ceiling fan to circulate air in a room, the thermostat setpoint can be raised and provide the same cooling sensation as a room with a lower temperature setpoint and no air movement. Increasing the thermostat setpoint by 2°F to 3°F (1°C to 2°C) may not seem significant until several factors are considered. When the cooling temperature setpoint inside a room is increased, the conductive losses through the walls to the outdoors is reduced and the energy required to cool the outdoor ventilation air to the indoor air temperature is also reduced. A 2°F to 3°F (1°C to 2°C) increase in space temperature reduces each of these loads by approximately 10%.`,
                  },
                  {
                    type: 'paragraph',
                    content: `In addition, when the thermostat cooling setpoint is increased, the supply air temperature from the HVAC system can be increased. This not only reduces the energy required to cool the outdoor air, but also increases the number of hours the outdoor air can be used to help cool the building (air economizer). Air economizer is required by the energy code in most climate zones, but ceiling fans can increase the savings seen by air economizer as a result of the increased space thermostat setpoint increase. The additional separation between the cooling setpoint and the heating thermostat setpoint can lead to significant energy savings during colder months. Careful consideration must be given to the system's ability to dehumidify when increasing the supply air temperature. Using a higher supply air temperature during design conditions must consider the indoor space relative humidity. If a high internal latent load exists or the building is in a humid climate, increasing the supply air setpoint will decrease the amount of dehumidification provided by the cooling coil, leading to high humidity concerns.`,
                  },
                ],
              },
              {
                mainContent: 'Lower Mean Heating UADT',
                data: [
                  {
                    type: 'paragraph',
                    content: `In heating applications, destratification can reduce heating requirements by lowering the mean temperature in the room. When air stratifies in a room during heating, hot air rises to the ceiling and slowly falls as it cools. This generates a temperature gradient from the floor and the temperature rises with elevation. The temperature at the thermostat, typically about 4 ft. (1.2 m) above the floor, is the desired space temperature and all air temperatures above the thermostat will be greater, increasing the mean air temperature in the room. As the mean air temperature in the room increases, the conductive heat transfer through the building envelope increases proportionally. With a ceiling fan, the temperature gradient can nearly be eliminated and maintain the mean air temperature much closer to the thermostat setpoint than without a ceiling fan.`,
                  },
                ],
              },
              {
                mainContent: 'OA Distribution Effectiveness',
                data: [
                  {
                    type: 'paragraph',
                    content: `Outdoor ventilation air loads are further reduced when using a ceiling fan as the distribution effectiveness is frequently higher than without. Both ASHRAE Standard 62.1 and the International Mechanical Code use distribution effectiveness in calculating the total required volume of outdoor air an HVAC system must provide. The distribution effectiveness is associated with the method of distributing ventilation air to the space. Due to the elevated mixing associated with ceiling fans, the distribution effectiveness for a typical HVAC system using a ceiling fan is often 20% higher than a typical HVAC system without a ceiling fan that supplies and returns air at the ceiling. Increasing the distribution effectiveness by 20% reduces the required outdoor airflow rate by 20%, further reducing the energy consumed by conditioning outdoor air.`,
                  },
                ],
              },
              {
                mainContent: 'HVAC Supply Fan Energy Reduction',
                data: [
                  {
                    type: 'paragraph',
                    content: `Energy savings provided by ceiling fans is not only achieved through heating and cooling savings but also from reduced HVAC system fan energy. As discussed previously in the air distribution section, less HVAC distribution ductwork is required to effectively distribute air when using a ceiling fan. Reducing the ductwork system reduces HVAC system fan energy by lowering the External Static Pressure (ESP), the resistance to airflow experienced by the HVAC fan. This can have a significant effect on building energy consumption as HVAC fans run constantly during all occupied hours and are often one of the primary energy consumers in a building. Reducing the fan power proportionally reduces the fan energy consumption. For example, reducing the ESP of a 7,500 CFM (3540 L/s) HVAC system from 2.5 in. w.c. (622 Pa) by 20% reduces the Brake Horsepower (BHP) from 5.46 BHP (4.07 kW) to 4.37 BHP (3.26 kW). Running an average of 14 hours per day, 365 days per year, this reduction in fan power saves approximately $460 per year at $0.11/kWh.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-5-4-4-Table.png',
                      alt: 'Equation for Fan Power',
                    },
                  },
                ],
              },
              {
                mainContent: 'Replacement of Directional Fans',
                data: [
                  {
                    type: 'paragraph',
                    content: `Often, occupants or building owners add small directional fans to provide additional cooling in a space. While these do provide elevated air speed and cooling to a specifically targeted area, multiple fans will be needed to serve a single area. Multiple directional fans consume more energy than a single ceiling fan. Directional fans generally consume more energy than HVLS ceiling fans due to multiple factors:`,
                  },
                  {
                    type: 'list',
                    listData: {
                      data: [
                        `Directional fans typically use "low cost" induction motors which will have less material (copper and lamination steel). These motors are significantly less efficient than the high efficiency induction motors and electronically commutated motors (ECM) used in BAF products.`,
                        `Directional fans typically use small, imprecise, and inefficient plastic or sheet metal impellers to move air. BAF uses airfoil fan blades engineered for high mechanical efficiency.`,
                        `Directional fans are typically single speed or offer a limited number of speeds. If the directional fan model has a 2 or 3 speed option, the "lower" speeds will likely be 80% of maximum speed or higher due to limitations of the low cost induction motor design. HVLS fans by BAF are variable speed and can slow down to less than 20% of maximum speed.`,
                        `Size matters according to the laws of physics. The combination of tip speed, which leads to higher lift generation (aka airflow) and low RPM operation, which means efficient motor loading, results in HVLS fans being more efficient.`,
                        `Directional fans are typically standalone devices that require the user to turn them off when not needed. HVLS fans are more often connected to controls, which will vary speed and operation based on variables such as occupancy and/or air dry bulb temperature.`,
                        `It should be noted higher efficiency directional fans with ECM motors and highly engineered fan blades and occupancy sensors are available, but are not typical of the low cost directional fan product offerings.`,
                      ],
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Due to the factors above, directional fans often draw 3 to 5 times as much power per CFM (L/s) as a similar BAF fan. Compared to BAF ceiling fans, directional fans often draw 10 to 15 times as much power per CFM (L/s). When combined with the number of directional fans required to adequately cool occupants in a space, directional fans can significantly increase the electrical demand on the building. Properly designed ceiling fans provide uniform air speeds to the entire space and reduce the electric demand on the building's electrical system.`,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Mechanical Codes',
        sidebarContent: 'Mechanical Codes',
        subSections: [
          {
            mainContent: 'Fan Requirements in Mechanical Codes',
            sidebarContent: 'Fan Requirements in Mechanical Codes',
            data: [
              {
                type: 'paragraph',
                content: `The 2018 version of the International Mechanical Code (IMC) contains requirements for High-Volume Large Diameter Fans in Section 929.1. The requirements for ceiling fans larger than seven feet in diameter are to be tested and labeled in accordance with AMCA 230-15, listed and labeled in accordance with UL 507, and installed in accordance with the manufacturer's instructions.`,
              },
              {
                type: 'link',
                urlData: {
                  url: `https://www.regulations.doe.gov/certification-data/#q=Product_Group_s%3A*`,
                  content: `The first requirement can be met through the use of fans that comply with AMCA Publication 211 and are certified to bear the AMCA Certified Ratings Program seal through the AMCA Certified Ratings Program (CRP). The AMCA (CRP) is a third party program which allows the manufacturers of air movement products to obtain an AMCA Seal when their equipment has been tested and rated in accordance with recognized test standards. This provides assurance that the specified products will provide the performance published by the manufacturer and applies to projects located anywhere in the world. In the United States (and potentially/eventually Canada), an alternative method of complying with this requirement would be the U.S. Department of Energy's Compliance Certification Database. This database houses certification reports and compliance statements for covered products and equipment subject to Federal conservation standards.`,
                  clickable:
                    "U.S. Department of Energy's Compliance Certification Database",
                },
              },
              {
                type: 'paragraph',
                content: `The second requirement can be met through the use of a fan that has been tested by a nationally recognized testing lab for a minimum level of safety. Underwriters Laboratories 507, Standard for Electric Fans is a testing standard that is used to evaluate the safety of electrical components and connections and establish safe mounting heights for ceiling fans. Third party certification of safety is provided by a Nationally Recognized Testing Lab (NRTL) as defined by OSHA per 29 CFR 1910.7. Products that meet or exceed the requirements of UL 507 are listed and labeled by the NRTL that performed the testing.`,
              },
              {
                type: 'paragraph',
                content: `The last requirement provides increased safety as it tries to require the fan to be used in a manner consistent with the manufacturer's recommendations, to avoid untested methods of attaching the fans to structure, and to follow the minimum clearance requirements to nearby objects.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Fire Codes',
        sidebarContent: 'Fire Codes',
        subSections: [
          {
            mainContent: 'Fan Requirements in NFPA',
            sidebarContent: 'Fan Requirements in NFPA',
            data: [
              {
                type: 'paragraph',
                content: `In order to comply with the National Fire Protection Association (NFPA) guidelines for sprinkler systems (NFPA 13 - 11.1.7 and 12.1.4.1), all HVLS ceiling-mounted fans 6 to 24 ft. (1.8 to 7.3 m) in diameter and installed in the United States must incorporate a means of automatic shutdown upon detection of waterflow in the sprinkler system. In buildings in the United States equipped with sprinkler systems and in the scope of NFPA 13, including Early Suppression Fast Response (ESFR) sprinklers, 6 to 24 ft. (1.8 to 7.3 m) diameter fans must comply with the following guidelines to adhere to the NFPA 13 Standard:`,
              },
              {
                type: 'list',
                listData: {
                  data: [
                    `Each fan must be approximately centered between four (4) adjacent sprinklers.`,
                    `The vertical distance from the fan to the sprinkler deflector must be a minimum of 3 ft. (914 mm).`,
                    `All fans must be interlocked to shut down immediately upon receiving a waterflow signal from the alarm system in accordance with the requirements of NFPA 72.`,
                  ],
                },
              },
              {
                type: 'paragraph',
                content: `For fans less than 60 in. (1.5 m) in diameter and at least 50% open, sprinklers can be placed without regard to the location of the fan blades (NFPA 13 - 8.6.5.2.1.10, 8.7.5.2.1.6, 8.8.5.2.1.9, and 8.9.5.2.1.7).`,
              },
              {
                type: 'paragraph',
                content: `Refer to NFPA 13 and NFPA 72 for additional details on the requirements summarized in this guide.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Acoustics',
        sidebarContent: 'Acoustics',
        subSections: [
          {
            mainContent: 'Test Procedures and Acoustical Data',
            sidebarContent: 'Test Procedures and Acoustical Data',
            data: [
              {
                type: 'paragraph',
                content: `At this time, there is no standard method of test that is used across the ceiling fan industry. Sound pressure values are measured in a variety of different procedures and test environments. Standards such as ANSI/AMCA Standard 300-14, Reverberant Room Method for Sound Testing of Fans, require extremely large test chambers not readily available to allow for certified sound testing.`,
              },
              {
                type: 'paragraph',
                content: `In October of 2021, AMCA issued a call for volunteers to serve on the technical committee for a new AMCA Standard: AMCA Standard 340—Laboratory Method of Sound Testing of Large-Diameter Ceiling Fans.  The technical committee started meeting in early 2022 and is working to create the new method of test in 18 to 24 months.  The new method of test will allow for the third-party certification of sound data in a manner that is cost-effective and produces accurate, repeatable results.  The overall goal of the committee's work is to provide standardized design data for the application of HVLS ceiling fans in acoustically sensitive applications.`,
              },
              {
                type: 'paragraph',
                content: `Until that test method is created, it is recommended any published sound data should be considered to be proprietary and should only be used on a comparative basis between different fan models from the manufacturer.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Safety Design Considerations',
        sidebarContent: 'Safety Design Considerations',
        subSections: [
          {
            mainContent: 'Clearance Requirements',
            sidebarContent: 'Clearance Requirements',
            data: [
              {
                type: 'paragraph',
                content: `Fans must be installed such that required minimum clearances are maintained to ensure both safety and performance. General clearance guidelines are as follows:`,
              },
              {
                type: 'list',
                listData: {
                  data: [
                    `The fan installation area must be free of obstructions such as lights, cables, sprinklers, or other building structure components.`,
                    `The minimum clearance from obstructions should be no less than 2 ft. (610 mm) in all directions. Ceiling and wall clearance require specific consideration based on model and size of fan. See the following tables for minimum ceiling and wall clearance requirements.`,
                    `The fan must be installed so the airfoils are at least 10 ft. (3 m) above the finished floor (unless otherwise noted).`,
                    `The fan must be installed so that it is plumb to the ground (unless column-mounted).`,
                    `Space the fans at a center-to-center distance that is at least 2.5x the fan diameter.`,
                    `In order for a ceiling fan to operate properly, it must be able to draw air freely into the top of the fan. If the fan is placed too close to a large solid surface such as a wall or a roof, then the performance of the fan will be reduced. Dimensions A and B should match tabulated values in Figure 2.10.1b below.`,
                  ],
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-9-1-MinimumClearance.png',
                  alt: 'Minimum clearance requirements diagram',
                  caption: `Minimum clearance requirements diagram`,
                  letter: 'a',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-9-1-CeilingClearance.png',
                  alt: 'Tables indicating minimum clearance requirements between fan and ceiling and fan and wall',
                  caption: `Tables indicating minimum clearance requirements between fan and ceiling and fan and wall.`,
                  letter: 'b',
                },
              },
            ],
          },
          {
            mainContent: 'Minimum Blade Height',
            sidebarContent: 'Minimum Blade Height',
            data: [
              {
                type: 'paragraph',
                content: `Many ceiling fans are safety tested using UL 507 - Standard for Electric Fans. The testing is typically performed by a Nationally Recognized Testing Laboratory, (NRTL), which is regulated by the Occupational Safety and Health Administration in the United States Department of Labor (OSHA). If the ceiling fan meets the requirements of UL 507, as determined by the NRTL, the fan can carry the NRTLs mark and be listed as compliant with UL 507 by that NRTL. Included in the requirements for UL 507 compliance are minimum blade heights for ceiling fans. The rules for minimum blade height are based on how fast the blade is moving, the direction of operation, and the thickness of the blade. For slower moving fans with thicker blades, the blade height can be as low as 7 feet (2.1 meters) above the floor. For thinner blades or faster moving blades, the minimum blade height is 10 feet (3.05 meters) above the floor. Table 90.1 in UL 507 lists the maximum tip speed and associated minimum blade thickness for fans to qualify for the 7 feet (2.1 meter) minimum mounting height.`,
              },
              {
                type: 'table',
                tableData: {
                  headers: [
                    'Airflow',
                    'Maximum Tip Speed',
                    'Minimum Thickness',
                  ],
                  data: [
                    ['Downward', '3,200 fpm (16.3 m/s)', '0.125 in. (3.2mm)'],
                    ['Downward', '4,000 fpm (20.1 m/s)', '0.1875 in. (4.8mm)'],
                    ['Upward', '3,200 fpm (16.3 m/s)', '0.1875 in. (4.8mm)'],
                    ['Upward', '2,400 fpm (1.2 m/s)', '0.125 in. (3.2mm)'],
                  ],
                  caption: `UL 507 - Table 90.1 - Criteria for minimum blade height 7 feet (2.1m) above the floor`,
                },
              },
              {
                type: 'paragraph',
                content: `Example - A 60 inch (1.52mm) diameter ceiling fan has a maximum speed of 200 rpm in both directions of airflow and a blade thickness of 0.2 inches (5.1mm).  The maximum tip speed (π x diameter x RPM) of the fan is 3,142 fpm (3.1416 x 5ft x 200 rpm) in both directions of operation.  The fan would not exceed the 4,000 fpm limit in the forward direction (air downward) and it would not exceed the 3,200 fpm limit in the reverse direction (air upward), so the fan would have a minimum blade height of 7 feet above the floor.`,
              },
            ],
          },
          {
            mainContent: 'Wind Exposure',
            sidebarContent: 'Wind Exposure',
            data: [
              {
                type: 'paragraph',
                content: `Guy wires are recommended to restrain the fan's lateral movement in any installation that is exposed to wind. The guy wires should be of at least ¼ in. (6.35 mm) diameter, utilize turnbuckles, and not include quick connection devices such as gripple kits.`,
              },
              {
                type: 'paragraph',
                content: `For most applications, it is recommended the fan be sheltered from direct wind by placing the fan higher in the structure. For example, in hangar applications, the fan blade height should be no lower than the top of the hangar door and fans should not be placed directly inside the hangar door.`,
              },
              {
                type: 'paragraph',
                content: `Long term exposure to wind can cause flexing of the airfoil and hub system, which may eventually lead to fatigue. The inclusion of a secondary airfoil restraint system (safety strap) is recommended in all applications where the fan may be exposed to wind. The secondary airfoil restraint system should provide a redundant connection from the outer tip of the airfoil to the hub.`,
              },
            ],
          },
          {
            mainContent: 'Seismic Considerations',
            sidebarContent: 'Seismic Considerations',
            data: [
              {
                type: 'paragraph',
                content: `A seismic restraint system is recommended to restrain against lateral forces experienced in a seismic event. The guy wires should be at least ¼ in. (6.35 mm) diameter, utilize turnbuckles, and not include quick connection devices such as gripple kits.`,
              },
              {
                type: 'paragraph',
                content: `Any mechanical component identified as non-exempt from Seismic Requirements by the International Building Code must have further seismic support. The seismic support system must be approved to resist the calculated required Seismic Force.`,
              },
            ],
          },
          {
            mainContent: 'Safety Considerations in Special Applications',
            sidebarContent: 'Safety Considerations in Special Applications',
            data: [
              {
                type: 'link',
                urlData: {
                  url: `https://guide.bigassfans.com/vertical-marketing`,
                  content: `Special considerations should be made when installing fans in a space with unique environmental building conditions. Guidelines for some common special applications of fans are detailed below. More information can be found on the Vertical Market Design Considerations resource page.`,
                  clickable: `Vertical Market Design Considerations`,
                },
              },
            ],
            subSections: [
              {
                mainContent: 'Overhead Cranes',
                data: [
                  {
                    type: 'list',
                    listData: {
                      data: [
                        `Overhead cranes cause vibrations that can radiate to the building structure on which a fan is mounted. Over time, this can stress the fan's mounting system and airfoils.`,
                        `HVLS fans can be installed near an overhead crane, but safe installation and maintenance practices must be followed:`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Install the fan at least 3 ft (914mm) away from the crane's path of movement.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Limit fan diameter to 18 ft (5.5m) if the fan is mounted above the crane's path of movement, especially when the crane is attached to the same portion of building structure as the fan.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Annual inspection of all critical hardware, including mounting, airfoil, and safety system hardware is recommended (proof of annual inspection may be required for warranty claims). Fans should include airfoil restraint system if applicable.`,
                      ],
                    },
                  },
                ],
              },
              {
                mainContent: 'Outdoors or Crosswinds',
                data: [
                  {
                    type: 'list',
                    listData: {
                      data: [
                        `Outdoor environments or environments susceptible to crosswinds include:`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Open-air pavilions, sports stadiums, and concert venues.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Spaces with at least one open wall or large operational door, including barns and aircraft hangars.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [`etc.`],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      data: [
                        `HVLS fans can be installed in environments susceptible to crosswinds, but safe installation and maintenance practices must be followed:`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Limit fan diameter to 20 ft (6m) in locations susceptible to crosswinds.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Guy wires, regardless of extension tube length, must be installed. Fans should include airfoil restraint system if applicable.`,
                      ],
                    },
                  },
                ],
              },
              {
                mainContent: 'Indoor Pools / Natatoriums',
                data: [
                  {
                    type: 'list',
                    listData: {
                      data: [
                        `Indoor spaces that are not adequately ventilated can allow for buildup of corrosive gases emitted by a pool. Over time, this could cause the fan's mounting system and airfoils to deteriorate.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      data: [
                        `HVLS fans can be installed in an indoor pool environment, but safe installation and maintenance practices must be followed:`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `The space must meet ASHRAE standard 62.1 (Ventilation for Acceptable Indoor Air Quality) and all local electrical and safety codes.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `The space must have an adequate and functioning ventilation system. Check that the other appliances and equipment located in the space are well-maintained and do not show signs of corrosion (such as lighting fixtures).`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Install the fan(s) at least 10 ft (3m) from the surface of the water.`,
                      ],
                    },
                  },
                  {
                    type: 'list',
                    listData: {
                      indentLevel: 1,
                      data: [
                        `Annual inspection of all critical hardware, including mounting, airfoil, and safety system hardware is recommended (proof of annual inspection may be required for warranty claims). Fans should include airfoil restraint system if applicable.`,
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Controls',
        sidebarContent: 'Controls',
        subSections: [
          {
            mainContent: 'General',
            sidebarContent: 'General',
            data: [
              {
                type: 'paragraph',
                content: `HVLS fans have a wide array of control options. Fan control can be as simple as an on/off switch to full automation through integration with an advanced building automation system. The level of control necessary for a given project is dependent upon the space conditions required and the budget allowed for controls.`,
              },
            ],
          },
          {
            mainContent: 'Standalone',
            sidebarContent: 'Standalone',
            data: [
              {
                type: 'paragraph',
                content: `Standalone controls are the most common solution for fan control. They are generally simple and economical but can be as sophisticated as necessary for the application. Standalone controls are those that control the ceiling fan only with no interaction or communication with any other systems. The following is a list of common control options for HVLS fans.`,
              },
            ],
            subSections: [
              {
                mainContent: 'Manual Control',
                data: [
                  {
                    type: 'paragraph',
                    content: `The most common and simple form of fan control is an on/off wall switch that provides manual control of the fan with no user interface for speed control. This type of control may be applicable in spaces where the fan is required to operate continuously at a fixed speed. An example might be a distribution center in a predominantly cooling climate that operates 24/7 and requires constant elevated air speed to maintain occupant comfort. In this scenario, there is no need to turn the fan off on a regular basis and there's no need to reduce the speed of the fan since there is no heating.`,
                  },
                  {
                    type: 'paragraph',
                    content: `If there is a desire for the users to have control over the fan speed as a means to adjust the thermal comfort in the space, or as required for a process, it may be necessary to add adjustment for speed control. This could be a rotary dial, a digital display, or buttons with designated preset speeds. A rotary dial is robust and simple but does not provide precise control. A digital display with incremental speed adjustment provides precise control but may not be as robust or easy to use. Buttons with assigned fan speeds could be used to simplify operation. For example, a fan could be provided with "heating" and "cooling" buttons where the cooling button increases the fan speed to provide perceptible air movement while the heating button would decrease the fan speed to destratify warm air at low air speeds imperceptible to the occupants. Alternatively, if set fan speeds are required for a process application, buttons could be assigned to each process that requires a specific air speed.`,
                  },
                ],
              },
              {
                mainContent: 'Thermostat Control',
                data: [
                  {
                    type: 'paragraph',
                    content: `If air speed is required to vary based on temperature, and little to no human interaction is desired, it may be advantageous to provide the fan with a thermostatic control. This type of control could be used in a comfort cooling application or a process cooling application. In a comfort cooling application, the thermostat can be used to increase fan speed in response to elevated air temperature. For example:`,
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `When the space temperature is 70°F (21°C) or below the fan maintains minimum speed to provide destratification. When the space temperature is 90°F (32°C) or above, the fan maintains maximum speed to provide maximum air movement. Between 70°F (21°C) and 90°F (32°C) the fan speed is adjusted linearly between minimum and maximum speed directly proportional to the space temperature.`,
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Conversely, if there were a process application where it is important to attempt to cool a product when the space temperature is low and reduce heat transfer as the space warms, the inverse of the logic above could be applied:`,
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `When the space temperature is 60°F (16°C) or below the fan maintains maximum speed to maximize heat transfer. When the space temperature is 80°F (27°C) or above, the fan is stopped to minimize heat transfer. Between 60°F (16°C) and 80°F (27°C) the fan speed is adjusted linearly between maximum and minimum speed directly proportional to the space temperature.`,
                    },
                  },
                ],
              },
              {
                mainContent: 'Optional Control Accessories',
                data: [
                  {
                    type: 'paragraph',
                    content: `With either of the above control methods, it may be necessary to control when the fan is permitted to operate. If the hours of operation repeat according to a schedule, a time clock schedule can be added to the controls. Like a residential programmable thermostat, a time clock schedule will set the time of day and days the fan is operational. Within those operational hours, the fan will run according to the standard sequence of operation. All other times, the fan would remain off. Alternatively, there could be more than one sequence of operation that implements each sequence of operation at the time it applies.`,
                  },
                  {
                    type: 'paragraph',
                    content: `If the ceiling fan is used for occupant thermal comfort and the fan is not required during unoccupied times, occupancy sensors can be used to change the sequence of operation. For example, comfort cooling is required only during occupied periods to reduce energy consumption. However, heating destratification saves energy regardless of occupancy. So it would be advantageous to stop the fan when the space is unoccupied and warm, but run the fan when the space is cool, regardless of occupancy.`,
                  },
                ],
              },
            ],
          },
          {
            mainContent: 'Integration with BAS',
            sidebarContent: 'Integration with BAS',
            data: [
              {
                type: 'paragraph',
                content: `Where more sophisticated control sequences or interaction with other building systems is required, HVLS fans can be integrated with a Building Automation System (BAS) where one exists. Building Automation Systems are common in medium to large commercial buildings that use central heating and cooling equipment in lieu of unitary equipment. A BAS provides two-way communication between building systems. Primarily, it is used for HVAC systems but can be integrated with other systems such as lighting systems, computer equipment, or industrial equipment. This integration enables the HVAC system to "listen" to these other systems and respond with a programmed reaction. Conversely, the other systems have the ability to "listen" to the HVAC system and respond.`,
              },
              {
                type: 'paragraph',
                content: `Because a BAS can incorporate custom programming, the logic achieved by the integration is unlimited. However, common reasons to integrate an HVLS fan with the HVAC system are as follows:`,
              },
              {
                type: 'list',
                listData: {
                  data: [
                    `The operating/occupancy schedule in the BAS can be used to control the HVLS fan and run it only when appropriate.`,
                    `The BAS can be programmed with logic to adjust the HVLS fan speed in response to other HVAC system parameters.`,
                    `The BAS can adjust the HVAC system operation to maintain occupant thermal comfort according to ASHRAE Standard 55 by adjusting the space temperature dry bulb setpoint in response to the elevated air speed generated by the HVLS fan.`,
                    `HVLS fan alarms can be transferred to the BAS to notify maintenance personnel.`,
                  ],
                },
              },
              {
                type: 'paragraph',
                content: `To enable integration with the Building Automation System, BAF fans that make use of a Variable Frequency Drive (VFD) and an induction motor can be equipped with a BAS interface that allows the VFD to communicate with the BAS using BACnet, an industry standard protocol. BACnet is a manufacturer agnostic, open source, "plug and play" protocol that enables two-way communication between building systems. Because building automation systems can use a myriad of inputs and can be programmed with completely custom logic, the possibilities for interaction between an HVLS fan and other systems is extremely flexible.`,
              },
              {
                type: 'pdf',
                pdfData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/HVLS+Fan+Control+Sequence+-+Design+Guide+-+Google+Docs.pdf',
                  title: `Example Integrated HVLS Control Sequence
                  Cooling, Heating, Occupied, Unoccupied`,
                },
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Financial Justification',
        sidebarContent: 'Financial Justification',
        subSections: [
          {
            mainContent: 'Reduced Ductwork Costs',
            sidebarContent: 'Reduced Ductwork Costs',
            data: [
              {
                type: 'paragraph',
                content: `To effectively distribute conditioned air throughout a space, a standard HVAC system uses various forms of air distribution terminals to control air movement within a conditioned space. Due to the large differential in temperature between the supply air and the room air, it is often undesirable to direct supply air directly toward occupants. Instead, supply air is "diffused" at the ceiling or "thrown" directly toward windows, walls, and other objects where thermal loads are generated. This method requires many supply air terminals to evenly distribute supply air and eliminate air temperature gradients. The large number of supply grilles used with this method requires an extensive supply air ductwork system to direct supply air from a central air handler to supply air grilles in the space.`,
              },
              {
                type: 'paragraph',
                content: `With a standard HVAC system, typical air change rates range from 4-6 Air Changes per Hour (ACH). Ceiling fans circulate a much larger volume of air in the same period of time, increasing the circulation of air within the room to 20-40 Air Turnovers per Hour (ATH). ACH represents the number of times the entire room air volume is exchanged per hour by a typical HVAC system. The air circulated by an HVAC system is filtered, mixed with outdoor air, conditioned, and returned to the room. ATH represents the number of times the entire room air volume is recirculated within a room per hour by a ceiling fan. Although 1 ATH is equal to 1 ACH in volume, the condition of the air in an ATH has not been affected.`,
              },
              {
                type: 'paragraph',
                content: `The high ATH provided by ceiling fans is highly effective at mixing and distributing the air within a room. When using ceiling fans however, far fewer supply air grilles are required to uniformly distribute the air within the room. To achieve this, the distribution system uses fewer supply air terminals to inject conditioned air into the room at much wider injection points than a typical HVAC system and the ceiling fan distributes the air through the room. The level of mixing and air temperature uniformity achieved by this approach is nearly impossible to achieve with standard HVAC supply air distribution systems primarily due to relatively poor mixing and low ACH.`,
              },
              {
                type: 'paragraph',
                content: `By reducing the number of supply air terminals, the need for supply air ductwork is reduced. The reduction of ductwork saves costs associated with the construction and installation of ductwork. Ductwork construction includes not only the sheet metal used to construct the ductwork but also internal duct liner or external ductwork insulation, ductwork sealing, ductwork hangers and supports, supply air grille installation, and balancing dampers. The quantity of all of these items decreases proportionally with the reduction of the ductwork sheet metal.`,
              },
              {
                type: 'paragraph',
                content: `In a typical supply air distribution system each supply air terminal not only includes a branch duct but also a balancing damper. Balancing dampers are required to direct supply air to all areas of the room. To balance a ductwork system, the balancing contractor must iteratively adjust each balancing damper until all branches are within the allowable range of the flow requested. Where less is air is required from a branch, the balancing damper is closed and where more air is required the damper is opened. Therefore by reducing the number of supply air terminals, the test and balance effort is significantly reduced. Thus, the addition of a ceiling fan can significantly lower labor and material cost of a typical HVAC system. Refer to Section 3.5 for an example duct scope reduction.`,
              },
            ],
          },
          {
            mainContent: 'Reduced System Capacity',
            sidebarContent: 'Reduced System Capacity',
            data: [
              {
                type: 'paragraph',
                content: `As discussed in the energy savings section, the addition of a ceiling fan can reduce heating and cooling energy consumption as well as HVAC fan energy consumption. This reduced energy consumption is associated with lower heating and cooling loads. Reducing the peak loads has the potential to reduce the size of the HVAC equipment required to condition the building. This includes the heating coil, cooling coil, and the supply fan.This savings also extends to the ductwork. Reducing the heating and cooling loads reduces the supply airflow required which allows downsizing of the ductwork.`,
              },
              {
                type: 'paragraph',
                content: `Ductwork first cost savings is not only associated with smaller ductwork, but is also associated with elimination of ductwork that would otherwise be required in a typical HVAC system. As noted previously, the use of ceiling fans improves air distribution without the need for an extensive ductwork distribution system.`,
              },
            ],
          },
          {
            mainContent: 'Resiliency',
            sidebarContent: 'Resiliency',
            data: [
              {
                type: 'paragraph',
                content: `The goal of a resilient design is to improve the safety of occupants during periods of crisis. In hot climates, elevated air speed provides a very low power option to reduce heat stress. When combined with renewable energy, thermal mass, and passive cooling, a building with elevated air speed can maintain manageable thermal condition during an extended power outage.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Specifications - Getting the Fan You Want',
        sidebarContent: 'Specifications - Getting the Fan You Want',
        data: [
          {
            type: 'paragraph',
            content: `Previous sections of this guide have discussed important criteria for designing a ceiling fan system, such as airflow, controls, and code compliance. In addition to these requirements, numerous other specification options exist to ensure the selected fan is high quality and well suited to the application. Below is an overview of some key components of a fan selection.`,
          },
        ],
        subSections: [
          {
            mainContent: 'Key Specification Criteria',
            sidebarContent: 'Key Specification Criteria',
            data: [],
            subSections: [
              {
                mainContent: 'Type of Fan',
                data: [
                  {
                    type: 'paragraph',
                    content: `Overhead fans come in a wide variety of sizes and styles, and the correct model of fan must be selected based on the specific requirements of each application. Fans are typically broken into two main categories: residential and commercial/industrial. Residential fans are usually between 52 in. and 84 in. (1.3 m and 2.1 m) in diameter. Commercial/industrial fans usually range from 8 ft. to 24 ft. (2.4 m to 7.3 m) in diameter. Different features are typically required in commercial and industrial fans, as opposed to residential fans, due to differences in size and in project priorities. Several of these features are described below, in addition to important criteria to specify for any fan selection.`,
                  },
                ],
              },
              {
                mainContent: 'Layout, Sizing, and Performance',
                data: [
                  {
                    type: 'paragraph',
                    content: `Fan layout in a space should be determined based on a number of variables including: space type, desired thermal conditions, room size and geometry, and any obstructions, if present. Circulator fan sizing, placement, and performance are also essential to achieving thermal comfort.`,
                  },
                  {
                    type: 'paragraph',
                    content: `In general, circulator fans should be placed in areas where the greatest cooling effect is desired- such as where occupants will be located or, in a process scenario, where components need to be cooled. Typically, fan diameter should be as large as can be coordinated within the space. Larger fans provide more uniform airflow and operate at a lower percentage of maximum speed. (For more information on diameter, see 2.4.2.) Blade height should ideally be one to two fan diameters above the finished floor in order to maximize cooling effect and fan coverage area. (For more information on blade height, see Section 2.4.3.)`,
                  },
                  {
                    type: 'paragraph',
                    content: `In order to verify fan layout and selection, a computational fluid dynamics (CFD) analysis should be run of the proposed design. Through analysis, programs such as BAF's SpecLab can evaluate whether the design meets performance requirements of the space. At a minimum, the input data for the CFD analysis should include the ceiling fan(s), significant obstructions to airflow at the floor level, and the actual space dimensions. As verification of performance, the submittal shall include results of the CFD analysis including the following performance metrics determined in accordance with ASHRAE Standard 55: average air speed, minimum, maximum and average cooling effect from elevated airspeed, Predicted Mean Vote, and Predicted Percentage Dissatisfied for seated and standing occupants in each occupied zone.`,
                  },
                ],
              },
              {
                mainContent: 'Test Standard Compliance',
                data: [
                  {
                    type: 'paragraph',
                    content: `As discussed previously, a number of testing standards apply to ceiling fans. It is important to verify the selected fan complies with these standards to ensure code compliance and desired performance. The 2018 IMC requires ceiling fans larger than 7 ft. (2.1 m) in diameter to be tested and labeled in accordance with AMCA 230-15, listed and labeled in accordance with UL 507, and installed in accordance with the manufacturer's instructions. AMCA 230 establishes uniform methods for laboratory testing of air circulating fans to determine fan performance. Selecting an AMCA 230 rated fan ensures any published performance data has been determined by standardized, rigorous third party testing. UL 507 is a minimum safety testing standard for fans that is used to evaluate the safety of electrical components and connections and establish safe mounting heights for ceiling fans. As ceiling fans are installed directly above occupants, it is imperative that methods of attaching fans to structure have been tested and verified and minimum clearance requirements to nearby objects have been met.`,
                  },
                ],
              },
              {
                mainContent: 'Fan Construction',
                data: [
                  {
                    type: 'paragraph',
                    content: `Fan construction specifications are also key to selecting safe, robust fans. The basic components of an HVLS fan are shown in Figure 2.13.1.4a below.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-4a-CeilingFanIllustration.png',
                      alt: 'Ceiling fan illustration labeling major components of HVLS fan',
                      caption: `Ceiling fan illustration labeling major components of HVLS fan`,
                      letter: 'a',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Fan hubs should be large diameter (19 in. or 480 mm) and constructed of precision cut aluminum for high strength and light weight. The hub should consist of two laser cut aluminum plates, several extruded aluminum spars (quantity dependent on number of airfoils), and one aluminum spacer fastened with a pin and collar rivet system. This design provides a flexible assembly that distributes force loads experienced by the hub assembly over a large area, thus reducing the fatigue experienced at the fan blade attachment point and increasing the life of the hub and airfoils. This is especially critical in applications with larger diameter fans (greater than 16 ft. or 4.9 m), applications where fans may be exposed to wind, and industrial environments.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Airfoils should be precision extruded aluminum alloy, connected to the hub by two high-strength (Grade 8) locking bolts per airfoil. The airfoils are connected to the hub and interlocked with zinc-plated steel retainers (see Figure 2.13.1.4b). The combination of the two bolts and the airfoil retainers creates redundancy and provides an extra level of safety.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-4b-ZincPlatedRetainers.png',
                      alt: 'Zinc-plated steel airfoil retainers on Big Ass fan',
                      caption: `Zinc-plated steel airfoil retainers on Big Ass fan`,
                      letter: 'b',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Mounting bolts to attach the fan to structure should be SAE Grade 8. The torque applied by off-the-shelf impact drivers can overtorque a Grade 5 bolt, which may lead to eventual failure of that bolt. Grade 8 hardware is more robust and can withstand installations where torque specifications are not followed during the installation process.`,
                  },
                  {
                    type: 'paragraph',
                    content: `In the case of harsh or critical environments, fan construction materials should be selected to meet the requirements of the specific application. In environments with corrosion risks, such as moisture or cleaning agents, fans should be built of all aluminum and stainless steel construction. Fans should use nonporous, nonabsorbent materials and utilize a non-pooling design to prevent water from collecting and supporting bacteria and mold growth. If required, stainless steel motors are able to handle more frequent, intense cleaning. In sensitive applications, food-grade oil should be used to mitigate concerns in the unlikely case of a leak. Food-duty epoxy coating is available for gearboxes to provide additional protection. BAF provides an option on Powerfoil fans called 'Washdown,' which includes all aforementioned features and is designed primarily for the food manufacturing industry.`,
                  },
                ],
              },
              {
                mainContent: 'Lighting and Other System Integration',
                data: [
                  {
                    type: 'paragraph',
                    content: `Most direct drive, and some gear driven, HVLS fans are capable of supporting onboard LED lighting. If this option is desired, LED light kits of various outputs, dimming capabilities, and lens and light distribution options may be specified. Alternately, fans may be installed in a building with an independent lighting system. In this case, special attention should be paid to preventing obstruction of lights by fan blades, as this causes light flashing each time a blade passes a light fixture. Attaching a light fixture to the fan and selecting a proper lens angle mitigates this issue.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Additionally, larger fan diameters may be used, as fan blades will not be at risk of conflicting with lights.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Spatial coordination of multiple systems in a building can prove challenging depending on quantity of components and space allowances. To assist in coordinating fans with lighting and other systems, HVLS fans may be specified with a hollow shaft threaded to accept a ¾ in. NPT fitting in which wiring, piping, etc. can be routed below the fan. A standard junction box can also be affixed to this hollow shaft to allow for installing optional features such as lights, cameras, or other accessories.`,
                  },
                ],
              },
              {
                mainContent: 'Fan Motors',
                data: [
                  {
                    type: 'paragraph',
                    content: `In sound-critical environments, fans with direct drive motors generate less noise than gear driven fans. Direct drive fans also typically offer a more refined appearance and reduced operating cost; although, they deliver less airflow and motor replacement can be more complicated. Direct drive fans are well suited to commercial or residential environments where sound and aesthetics are greater concerns and the spaces typically have air conditioning and lower ceiling heights, thus requiring less airflow.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Gear driven fans are generally used in applications where maximum airflow is the first priority, and acoustics and refined appearance are secondary considerations. Higher motor power allows the fans to provide increased airflow, making them well suited to industrial environments with high ceilings and often no air conditioning. The C-Face motors on gear driven fans allow for simpler field replacement. Additionally, gear drives typically carry higher IP Ratings, which support operation in harsh environments. There are direct drive fans designed for operation in harsh environments, if direct drive is preferred for a given application. IP should be verified for all fan components (motor, drive, and controller) when selecting fans for industrial or harsh environments.`,
                  },
                  {
                    type: 'paragraph',
                    content: `IP Ratings are defined by IEC Standard 60529 and describe the capacity of an electrical enclosure to prevent the ingress of solids and water. Each IP rating is written as IPXY, where X and Y are numerical values. The first number, X, describes the resistance to solids, such as objects and dust, and ranges from 0 to 6. A value of 1 indicates the lowest level of resistance - protects against objects greater than 2 in. (50 mm) (e.g., a hand)- and 6 indicates the highest level- no ingress of dust for eight (8) hours. The second number, Y, represents water resistance and ranges from 0 to 9. Again, 1 is the lowest level of resistance - protects against vertically falling water drops with limited ingress for 10 minutes - and 9 is the highest level - no ingress from powerful, high-temperature water jets. Additional digits may be added to provide further information on protection. In critical and harsh environments, an acceptable level of dust and water ingress should be determined, and a fan motor with a sufficient IP rating selected.`,
                  },
                ],
              },
              {
                mainContent: 'Redundant Safety Features',
                data: [
                  {
                    type: 'paragraph',
                    content: `HVLS fans are typically in excess of 200 lbs (90 kg) and mounted directly above occupants. To ensure secure fan operation and safety for personnel and equipment, overhead HVLS fans should be provided with various redundant safety features. In addition to high quality fan construction and attachments, as mentioned previously, the following failsafes should be provided in conjunction to achieve an acceptable level of redundancy and safety.`,
                  },
                  {
                    type: 'paragraph',
                    content: `All 20 ft. to 24 ft. (6 m to 7.3 m) diameter overhead HVLS fans should be equipped with an Airfoil Restraint System (ARS) that prevents separation of the airfoil from the fan assembly in the event of critical impact damage. The ARS uses durable nylon straps that extend through the entire airfoil's interior and attach at the fan hub and winglets. Should a failure occur at any point along the airfoil, this redundant security measure will ensure the airfoil remains connected to the fan assembly. This safety feature should be available upon request for smaller diameter HVLS models at no additional charge. This is especially critical in applications with larger diameter fans (greater than 16 feet (4.9 m)) where fans may be exposed to wind.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-7a-7b.png',
                      alt: 'Airfoil restraint system',
                      caption: `Airfoil Restraint System`,
                      letter: 'a, b',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `HVLS fans should be equipped with a safety cable system to ensure the fan will not fall in an unlikely disaster scenario. This system is comprised of an upper safety cable that secures the mounting system to the building structure, in addition to two lower safety cables that secure the hub assembly to the mounting system. Each cable should be made from ⌀⅜ in. (⌀9.5 mm) aircraft-grade galvanized steel cable and factory attached to the motor frame and secured to extension tube with bolts. The upper safety cable, pictured in Figure 2.13.1.7c, encompasses the entire upper mount and provides complete redundancy from extension tube to structure. The lower safety cable, shown in Figure 2.13.1.7d, encompasses the entire motor/gearbox assembly, connects to the extension tube, and provides complete redundancy from extension tube to hub.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-7c.png',
                      alt: 'Upper safety cable',
                      caption: `Upper safety cable`,
                      letter: 'c',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-7d-LowerSafetyCable.png',
                      alt: 'Lower safety cable',
                      caption: `Lower safety cable`,
                      letter: 'd',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Guy wires are recommended to provide additional stability for the fan. Wires should meet the specifications described in the Safety section and include required diameter and connection method.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Variable frequency drives (VFD) are provided on fans for additional control and safety. VFDs may be provided with IP66 enclosures for use in harsh environments and up to operating temperatures of 131°F (55°C). As a safety feature, VFDs should be provided with a built-in accelerometer and sensor to shut down the fan when an impact or obstruction is detected.`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    mainContent: 'Applications and Examples',
    sidebarContent: 'Applications and Examples',
    sections: [
      {
        mainContent: 'Occupant Thermal Comfort',
        sidebarContent: 'Occupant Thermal Comfort',
        subSections: [
          {
            mainContent: 'Comfort Calculations',
            sidebarContent: 'Comfort Calculations',
            data: [
              {
                type: 'paragraph',
                content: `Applications pertaining specifically to maintaining or improving occupant comfort will be discussed in this section.`,
              },
              {
                type: 'paragraph',
                content: `The CBE Thermal Comfort Tool is an online calculator that performs thermal comfort calculations according to the ASHRAE Standard 55 methodology. The user enters operative temperature, air speed, humidity, metabolic rate, and clothing level. The Thermal Comfort Tool calculates the PMV, PPD, Sensation, and SET. Using these results, it determines compliance with Standard 55. Additionally, it simultaneously plots the input condition and alternate space conditions that satisfy Standard 55 on a psychrometric chart. This plot provides a visual representation of where the design condition falls relative to any acceptable conditions.`,
              },
            ],
            subSections: [
              {
                mainContent: 'Example: Office Building',
                data: [
                  {
                    type: 'paragraph',
                    content: `For example, if an office building is designed to maintain an operative temperature of 77°F (25°C) and relative humidity of 50% in the summer with no elevated air speed, the inputs to the Thermal Comfort Tool might be shown in Figure 3.1.1.1a. In this example, a typical metabolic rate for typing, 1.1 met, was selected, as well as a typical summer indoor clothing insulation level, 0.5 clo.`,
                  },
                  {
                    type: 'paragraph',
                    content: `The results show a PMV of -0.13, which is within the necessary range to comply with ASHRAE Standard 55 (-0.5 to 0.5). The Thermal Comfort Tool generated a chart illustrating the acceptable range of operative temperatures and humidity ratios at these occupant conditions. The design point was plotted on this chart and is within the comfort zone, demonstrating compliance with Standard 55.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1a.png',
                      alt: 'Design conditions plotted on the psychrometric chart fall within the thermal comfort zone.',
                      caption: `Design conditions have been plotted on the psychrometric chart and fall within the thermal comfort zone.`,
                      letter: 'a',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Now consider how thermal comfort would change if the occupants of this office building have an important meeting and are wearing suits. The clothing level increases to 0.96 clo and the thermal comfort zone has shifted. PMV now exceeds 0.5 and conditions no longer comply with Standard 55. As shown in Figure 3.1.1.1b, the design point now falls outside the acceptable range.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1b.png',
                      alt: 'An increased clo value has shifted the acceptable thermal comfort zone to the left, leaving the space condition point outside the new zone.',
                      caption: `An increased clo value has shifted the acceptable thermal comfort zone to the left (cooler temperatures), leaving the space condition point outside the new zone.`,
                      letter: 'b',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `The CBE Thermal Comfort Tool is able to use ASHRAE Standard 55 methodology to account for elevated air speed in thermal comfort calculations. Using the example of the same office with workers wearing suits (clothing level of 0.96 clo), the air speed can be increased to simulate the cooling effect provided by fans. If instead of still air at 20 fpm (0.1 m/s), the air speed is increased to 100 fpm (0.5 m/s) the thermal comfort zone shifts as shown in Figure 3.1.1.1c. The new PMV is 0.01, which complies with Standard 55. The Thermal Comfort Tool also calculates the equivalent dry bulb temperature that would be required to achieve the same level of comfort without the elevated air speed, the "Dry bulb temperature at still air." The difference between the actual dry bulb temperature and the dry bulb temperature at still air is the "cooling effect". These values are calculated by the software using the methodology of Standard 55 Appendix D.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1c.png',
                      alt: 'Using the same high clo value as in the previous Figure, the acceptable thermal comfort zone can be shifted to the right by increasing the air speed.',
                      caption: `Using the same high clo value (suits) as in the previous Figure, the acceptable thermal comfort zone can be shifted to the right (higher temperatures) by increasing the air speed.`,
                      letter: 'c',
                    },
                  },
                ],
              },
              {
                mainContent: 'Determining Target Air Speed',
                data: [
                  {
                    type: 'paragraph',
                    content: `When determining target air speed the primary consideration is providing equivalent comfort in air conditioned spaces or improving thermal comfort in unconditioned spaces. In general terms, as the space temperature increases to "warm" or "hot", the air speed must be increased to improve thermal comfort.`,
                  },
                  {
                    type: 'paragraph',
                    content: `When increasing the cooling thermostat setpoint in air conditioned spaces to save energy, the air speed must be increased to maintain equal thermal comfort to the air conditioned space at the lower cooling thermostat setpoint. To achieve equal thermal comfort the PMV and PPD values for the new condition must match the baseline condition. If the cooling thermostat setpoint were increased without increasing the air speed, the PMV and PPD will increase beyond the acceptable threshold established by Standard 55.`,
                  },
                  {
                    type: 'paragraph',
                    content: `When adding ceiling fans to an unconditioned space, the primary concern is not to maintain the same level of thermal comfort, but to improve the thermal comfort. Although it may not be feasible to satisfy the requirements of Standard 55, the results will demonstrate a higher percentage of satisfied occupants than an unconditioned space without a ceiling fan and equate to increased worker productivity as well.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Other considerations should be given to air speed, however. There are limits to how much air speed can be increased. For example in an office or school environment, high air speed may blow papers off desks and become a nuisance. Similarly, in industrial applications where critical processes are being performed, it may be necessary to limit air speed to mitigate interference with the process; adding partitions or barriers to protect sensitive processes while still providing worker comfort is also an option.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1-2a.png',
                      alt: 'Where to find the equivalent Dry bulb temperature at still air and the corresponding cooling effect in the CBE Thermal Comfort Tool',
                      caption: `The above figure indicated where to find the equivalent Dry bulb temperature at still air and the corresponding cooling effect in the CBE Thermal Comfort Tool.`,
                      letter: 'a',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `In the example table in Figure 3.1.1.2b below, the Office and School space types achieve approximately the same PMV with different cooling setpoints. The air speed is increased and allows the thermostat setpoints to each be increased by 5°F (2.8°C), saving cooling energy. The Still Air examples for Fitness, Manufacturing, and Warehouse all have high PMV values, indicating uncomfortable spaces primarily due to the high metabolic rate of the occupants. So for these examples, the objective is to demonstrate that the addition of fans improves thermal comfort by significantly lowering the PMV value for each space. In the Fitness example though, the baseline used a lower temperature setpoint frequently used in Fitness Centers. Using elevated air speed allows the setpoint to be increased while still providing improved thermal comfort.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3.2.1.2b-Table.png',
                      alt: 'The acceptable rise in dry bulb temperature when elevated airspeed is implemented',
                      caption: `The table above illustrates the acceptable rise in dry bulb temperature when elevated airspeed is implemented.`,
                      letter: 'b',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        mainContent: 'CFD & Layouts',
        sidebarContent: 'CFD & Layouts',
        subSections: [
          {
            mainContent: 'Example Layouts and CFD Analyses',
            sidebarContent: 'Example Layouts and CFD Analyses',
            data: [
              {
                type: 'paragraph',
                content: `As target air speeds and space characteristics change from project to project, it is key to build a design around a specific space. Engineers should verify that fan selections and layouts provide proper air speeds at the target velocity for desired areas in a room by requesting an analysis be performed using a CFD tool, such as SpecLab. Below are example layouts and CFD analysis results from SpecLab for a variety of space types. These examples demonstrate some successful techniques for creating a space-specific fan system design.`,
              },
            ],
            subSections: [
              {
                mainContent: 'Example: School Gym',
                data: [
                  {
                    type: 'paragraph',
                    content: `An engineer has been asked to provide a circulator fan design for a school gymnasium. The gym is 116 ft. x 94 ft. (35 m x 29 m) with a ceiling height of 29 ft. (9 m). Summer indoor conditions are 80°F (27°C) and 50% RH. Taking into account metabolic rate and clo, what would be an appropriate fan selection and layout for this space?`,
                  },
                  {
                    type: 'paragraph',
                    content: `One proposed solution is shown in Figure 3.2.1.1b below. The gym is a large room with very few obstructions, as such, a single, large diameter fan has been selected and placed in the center of the gym. For this particular application, an 18 ft. (5.5 m) diameter fan has been selected and mounted with a blade height of 22 ft. (6.7 m). This design will then be evaluated using CFD analysis in SpecLab to determine air speeds and airflow profile. Higher air speeds are desired in this space due to occupants generally having high activity levels. According to the CBE Comfort Tool, typical metabolic rates for individuals doing calisthenics is 3.5 met. Playing a sport, such as basketball, typically results in a rate of 6.3 met. Clothing level corresponding to shorts and a t-shirt is 0.36 clo.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1a.png',
                      alt: 'School Gym Example - Gym Floor Plan',
                      caption: `School Gym Example - Gym Floor Plan`,
                      letter: 'a',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `The following images are outputs from the CFD analysis conducted in SpecLab. Figures 3.2.1.1c through 3.2.1.1i, show air speeds at different points and heights throughout the room. CFD analysis results are summarized in Figure 3.2.1.1j.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1b.png',
                      alt: 'School Gym Example - Fan Layout and Schedule',
                      caption: `School Gym Example - Fan Layout and Schedule`,
                      letter: 'b',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1c-1d.png',
                      alt: 'School Gym CFD Air Speed Example - Room section cut through the center of the ceiling fan at the the x-z and y-z planes.',
                      caption: `School Gym CFD Air Speed Example - Room section cut through the center of the ceiling fan at the the x-z and y-z planes.`,
                      letter: 'c, d',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1d-1e.png',
                      alt: 'School Gym CFD Air Speed Example - Plan view of room cut at 24 in. (0.6 m) on the left and 04 in. (0.1 m) on the right.',
                      caption: `School Gym CFD Air Speed Example - Plan view of room cut at 24 in. (0.6 m) on the left and 04 in. (0.1 m) on the right.`,
                      letter: 'e, f',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1f-1g.png',
                      alt: 'School Gym CFD Air Speed Example - Plan view of room cut at 43 in. (1.1 m) on the left and 67 in. (1.7 m) on the right.',
                      caption: `School Gym CFD Air Speed Example - Plan view of room cut at 43 in. (1.1 m) on the left and 67 in. (1.7 m) on the right.`,
                      letter: 'g, h',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1h.png',
                      alt: 'School Gym Air Speed Example - The above image traces the path the air follows as it travels through the room. The color of each path indicates the air speed at that point along the path.',
                      caption: `School Gym Air Speed Example - The above image traces the path the air follows as it travels through the room. The color of each path indicates the air speed at that point along the path.`,
                      letter: 'i',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1i.png',
                      alt: 'School Gym Example - Performance Summary',
                      caption: `School Gym Example - Performance Summary`,
                      letter: 'j',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Figures 3.2.1.1e through 3.2.1.1h show the air speed profile at four different heights: 4 in. (0.1 m) AFF, 24 in. (0.6 m) AFF, 43 in. (1.1 m) AFF, and 67 in. (1.7 m) AFF. These are the typical test heights for seated and standing occupants from ASHRAE Standard 55 thermal comfort calculations. As illustrated in the images, the highest air speeds are felt closest to the floor. Figure 3.2.1.1j lists the minimum, maximum, and average air speeds, as well as the corresponding cooling effects for a standing occupant. It also calculates cooling coverage, which is defined by ASHRAE Standard 216 as the portion of the test area where Average Air Speed exceeds 85 fpm (0.43 m/s). Other outputs include PMV, PPD, and SET, as defined by ASHRAE Standard 55, which allow the user to evaluate thermal comfort in the space.`,
                  },
                  {
                    type: 'paragraph',
                    content: `As indicated in Figure 3.2.1.1j, the fan provides an average air speed of 224 fpm (1.1 m/s), resulting in an average cooling effect of 27°F (15.0°C). The cooling coverage percent is very high, indicating that the vast majority of the space is experiencing a cooling effect. While the PMV and PPD are still relatively high, these values are also based on the previously stated metabolic rate, which will be very high for active occupants in a gym, and a lower clo value suitable for playing basketball or participating in gym class. As such, these occupants may never achieve "thermal comfort" while they are exercising, but the large cooling effect will result in much greater comfort than occupants would experience otherwise due to the effect of air speed on perceived temperature and thermoregulation.`,
                  },
                ],
              },
              {
                mainContent: 'Example: Warehouse',
                data: [
                  {
                    type: 'paragraph',
                    content: `An unconditioned warehouse has typical indoor summer conditions of 89°F (32°C) and 55% RH. In order to make the warehouse more comfortable for workers, the owner has requested HVLS fans be added to the loading dock area where the most strenuous activities take place. The warehouse is approximately 699 ft. x 378 ft. (213 m x 115 m) with a ceiling height of 40 ft. (12 m). The loading dock area is identified in Figure 3.2.1.2a below. What would be an effective design for cooling this zone?`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2a.png',
                      alt: 'Warehouse Example - Loading Dock Cooling Zone',
                      caption: `Warehouse Example - Loading Dock Cooling Zone`,
                      letter: 'a',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `In this example, as is typical for warehouses, the added variable exists of having several large obstructions. Obstructions, such as storage racks or pallets in this case, must be taken into account when laying out fans such that they do not block airflow to large portions of the space. A proposed layout is shown in Figure 3.2.1.2b below. The layout for the whole facility has been included for reference.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2b.png',
                      alt: 'Warehouse Example - Fan Layout and Schedule',
                      caption: `Warehouse Example - Fan Layout and Schedule`,
                      letter: 'b',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `To avoid the large obstructions in the space, fans are placed in "aisles" and open spaces, where occupants are more likely to be located. Different fans with a range of diameters have been selected based on the size of the area they are serving. Whenever there are multiple fans and large obstructions, fan clearance requirements are especially important to consider.`,
                  },
                  {
                    type: 'paragraph',
                    content: `For the loading dock area, four (4) 24 ft. (7.3 m) diameter fans were selected to cool the zone. They are mounted at a height of 36 ft. (11 m) AFF, with a spinning height of 32 ft. 4 in.`,
                  },
                  {
                    type: 'paragraph',
                    content: `Figures 4.2.1.2c through 4.3.1.2g below show the air speed profiles and other results from the CFD analysis performed in SpecLab.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2c.png',
                      alt: 'Warehouse Example - CFD Analysis Section - Fans along southern wall',
                      caption: `Warehouse Example - CFD Analysis Section - Fans along southern wall`,
                      letter: 'c',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2d.png',
                      alt: 'Warehouse Example - CFD Analysis Section - Fans along western wall',
                      caption: `Warehouse Example - CFD Analysis Section - Fans along western wall`,
                      letter: 'd',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2e.png',
                      alt: 'Warehouse Example - CFD Analysis at 04 in. (0.1 m)',
                      caption: `Warehouse Example - CFD Analysis at 04 in. (0.1 m)`,
                      letter: 'e',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2f.png',
                      alt: 'Warehouse Example - CFD Analysis Air Speeds',
                      caption: `Warehouse Example - CFD Analysis Air Speeds`,
                      letter: 'f',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2g.png',
                      alt: 'Warehouse Example - Performance Summary',
                      caption: `Warehouse Example - Performance Summary`,
                      letter: 'g',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `As shown in the CFD images, the highest and most uniform air speeds are found in the open areas where the fans have been placed and where occupants are expected to be. The fans are spaced such that the cooling coverage of these areas is fairly comprehensive.`,
                  },
                  {
                    type: 'paragraph',
                    content: `The Performance Metrics in Figure 3.2.1.2g summarize analysis results for the loading dock area. Average air speed is approximately 217 fpm (1.1 m/s), resulting in an average cooling effect of more than 10°F (5.6°C). Cooling coverage is in excess of 99% in the comfort zone highlighted in Figure 3.2.1.2a. While the average PPD is 43%, this is a significant improvement from still air conditions, where a PPD in excess of 90% would be expected. In spaces where mechanical cooling is not typical, ceiling fans can significantly decrease discomfort due to heat and as a result, increase occupant productivity. For more information on the financial benefits of increased occupant productivity, refer to Section 3.7, Financial.`,
                  },
                ],
              },
              {
                mainContent: 'Example: Office Space',
                data: [
                  {
                    type: 'paragraph',
                    content: `An engineer designing an office space wants to decrease air handling equipment size by using a higher temperature setpoint in the space- approximately 80°F (27°C) and 50% RH. Ceiling fans will be used to provide a cooling effect that results in a comfortable perceived temperature for occupants. At its widest point, the office space is approximately 76 ft. long and 51 ft. wide (23 m long and 16 m wide). Ceiling height is 9 ft. 4 in. (2.8 m). What fan selection and layout would produce the desired cooling effect?`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3a.png',
                      alt: 'Office Example - Floor plan',
                      caption: `Office Example - Floor plan`,
                      letter: 'a',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Offices have several unique design considerations, including several small obstructions (e.g., desks, copiers, etc.) and the need to provide lower air speeds to minimize paper movement. This example features an open office plans with several long desks and chairs. Figure 3.2.1.3b illustrates a proposed fan design for this example.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3b.png',
                      alt: 'Office Example - Fan Layout and Schedule',
                      caption: `Office Example - Fan Layout and Schedule`,
                      letter: 'b',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Multiple fans have been placed above desks where occupants are located. Smaller fans (60 in. diameter (1.5 m)) are used here, as compared to in previous examples, as this space and the targeted regions to be cooled are also smaller. Fans are mounted at the ceiling (9 ft. 4 in. (3 m)), with a spinning height of 8 ft. 1 in. (2.5 m).`,
                  },
                  {
                    type: 'paragraph',
                    content: `CFD analysis outputs are shown in Figures 4.2.1.3c through 4.2.1.3m below.`,
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3c-3d.png',
                      alt: 'Office Example - CFD Analysis Sections',
                      caption: `Office Example - CFD Analysis Sections`,
                      letter: 'c, d',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3e-3f.png',
                      alt: 'Office Example - CFD Analysis Sections',
                      caption: `Office Example - CFD Analysis Sections`,
                      letter: 'e, f',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3f-3g.png',
                      alt: 'Office Example - CFD Analysis Section and CFD Analysis at 04 in. (0.1 m)',
                      caption: `Office Example - CFD Analysis Section and CFD Analysis at 04 in. (0.1 m)`,
                      letter: 'g, h',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3h-3i.png',
                      alt: 'Office Example - CFD Analysis at 24 in. (0.6 m) and 43 in. (1.1 m)',
                      caption: `Office Example - CFD Analysis at 24 in. (0.6 m) and 43 in. (1.1 m)`,
                      letter: 'i, j',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3j-3k.png',
                      alt: 'Office Example - CFD Analysis at 67 in. (1.7 m) and Airflow Diagram.',
                      caption: `Office Example - CFD Analysis at 67 in. (1.7 m) and Airflow Diagram.`,
                      letter: 'k, l',
                    },
                  },
                  {
                    type: 'image',
                    imageData: {
                      src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3-PerformanceMetrics.png',
                      alt: 'Office Example - Performance Summary',
                      caption: `Office Example - Performance Summary`,
                      letter: 'm',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `As shown in the CFD analysis images, highest air speeds are concentrated over desks and chairs, such that occupants will benefit from the cooling effect. Desks largely block the airflow, preventing much of it from reaching the floor. The highest air speeds are seen at seated and standing levels, which is ideal for an office scenario as these are the levels where occupants spend the majority of their time. Average air speed is 87 fpm (0.44 m/s), which provides a 4°F (2.2°C) cooling effect and is in the target range for an office. Maximum air speed does not exceed 200 fpm (1 m/s) at desk level, as this could lead to paper movement. The resulting PMV is -0.02, which indicates a high level of thermal comfort according to ASHRAE Standard 55 metrics.`,
                  },
                ],
              },
            ],
          },
          {
            mainContent: 'Fan Diameter Design',
            sidebarContent: 'Fan Diameter Design',
            data: [
              {
                type: 'paragraph',
                content: `Another key component of designing with ceiling fans is fan diameter. Typically, there are a range of fan diameters that will be able to provide the target average air speed desired in a space. However, within a product family, larger diameter fans will be able to provide the target air speed at a fraction of the maximum speed. This will result in more uniform airflow in the space, which can be a critical design factor in conditioned applications. The example below compares a 20 ft. (6 m) diameter fan operating at 70% of maximum speed and a 12 ft. (3.7 m) diameter fan operating at 100% of maximum speed, both in a 100 ft. x 100 ft. x 25 ft. (30.5 m x 30.5 m x 7.6 m) space. The average air speed in both scenarios is nearly identical, but the 12 ft. (3.7 m) fan shows much greater variance between the minimum and maximum values.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-2a.png',
                  alt: 'CFD results from a 20 ft. (6 m) diameter fan at 70% maximum RPM',
                  caption: `CFD results from a 20 ft. (6 m) diameter fan at 70% maximum RPM`,
                  letter: 'a',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-2b.png',
                  alt: 'CFD results from a 12 ft. (3.7 m) diameter fan at 100% maximum RPM',
                  caption: `CFD results from a 12 ft. (3.7 m) diameter fan at 100% maximum RPM`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `In addition to increased uniformity in the horizontal plane, larger fans also provide greater vertical uniformity. The "boundary layer," or wall of air, moving along the floor is taller with a larger fan, resulting in a less severe drop off in air speed closer to the floor. Therefore, air speeds felt by seated and standing occupants is more constant.`,
              },
            ],
          },
          {
            mainContent: 'Blade Height Design',
            sidebarContent: 'Blade Height Design',
            data: [
              {
                type: 'paragraph',
                content: `One key component of ideal fan layout is mounting height. Often, designers are unsure what mounting height to specify and how blade height impacts performance. Blade height impacts both air speed and air profile uniformity, therefore it is important to specify a blade height that meets the requirements of a space.`,
              },
              {
                type: 'paragraph',
                content: `A good rule of thumb for large diameter fans is to keep the fan approximately 1 to 2 diameters above the floor, when possible. For example, mounting a 24 ft. (7.3 m) fan such that the blades are approximately 24 ft. (7.3 m) to 48 ft. (14.6 m) AFF. If the fan blades are too close to the floor, less than approximately 0.75 diameter above the floor, the airflow from the fan can become impeded which will result in reduced average air speed in the space.`,
              },
              {
                type: 'paragraph',
                content: `To illustrate the impact of blade height, a comparison for a 24 ft. (7.3 m) fan in a 150 ft. x 150 ft. (46 m x 46 m) space was performed. Simulations were run for blade heights of 10 ft. (3.0 m), 15 ft. (4.6 m), 20 ft. (6.1 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9.1 m) AFF, and 40 ft. (12.2 m) AFF. The ceiling deck was raised to 52 ft. (16 m) to allow for more variation in blade height. As can be seen in Figure 3.2.3a below, the jet of air exits the fan and moves down to the floor level. As the jet interacts with the floor, the airflow transitions from vertical to horizontal and the air spreads out across the space.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-3a.png',
                  alt: 'CFD sections of fan mounted at 10 ft. (3 m) AFF, 15 ft. (4.6 m) AFF, 20 ft. (6 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9 m) AFF, and 40 ft. (12.2 m) AFF',
                  caption: `CFD sections of fan mounted at 10 ft. (3 m) AFF, 15 ft. (4.6 m) AFF, 20 ft. (6 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9 m) AFF, and 40 ft. (12.2 m) AFF`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `A summary of the resulting air speeds, cooling coverage fractions, cooling effect, and standard effective temperature are shown in Figure 3.2.3b.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-3b.png',
                  alt: 'CFD performance summary for fan mounted at 10 ft. (3 m) AFF, 15 ft. (4.6 m) AFF, 20 ft. (6 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9 m) AFF, and 40 ft. (12.2 m) AFF',
                  caption: `CFD performance summary for fan mounted at 10 ft. (3 m) AFF, 15 ft. (4.6 m) AFF, 20 ft. (6 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9 m) AFF, and 40 ft. (12.2 m) AFF`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `The results of the study show blade height impacts air speeds at occupant levels, cooling coverage, and cooling effect. Fans mounted higher in the space produce lower average air speeds, but tend to increase uniformity of the air speed, as evidenced by the difference between average standing and seated height air speeds and the difference between maximum and average standing air speeds. It should be noted these results are for an appropriately sized fan in an empty space; an undersized fan would see larger differences in average air speed and cooling effect as blade height increases.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Lighting Integration',
        sidebarContent: 'Lighting Integration',
        subSections: [
          {
            mainContent: 'Fan Location in Lighting Layout',
            sidebarContent: 'Fan Location in Lighting Layout',
            data: [
              {
                type: 'paragraph',
                content: `When luminaires are located at an elevation higher than the ceiling fan blade height, a risk of stroboscopic effect or shadowing exists. stroboscopic effect is a result of the fan blades creating a shadow under the luminaire each time the blade passes between the luminaire and an occupant below. This is illustrated in Figure 3.3.1b. stroboscopic effect can be disruptive and distracting to occupants. In extreme situations, such as industrial applications, stroboscopic effect has the potential to create a potentially dangerous environment.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1a.png',
                  alt: 'A typical candela plot indicating beam angle that would be provided by a luminaire manufacturer as part of the product design data.',
                  caption: `The above graphic is a typical candela plot indicating beam angle that would be provided by a luminaire manufacturer as part of the product design data.`,
                  letter: 'a',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1b.png',
                  alt: 'Luminaires are placed in a location that will create a stroboscopic effect. The fan blades interfere with the luminaire light pattern.',
                  caption: `Luminaires are placed in a location that will create a stroboscopic effect. The fan blades interfere with the luminaire light pattern.`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `To reduce the potential for stroboscopic effect, coordination between the luminaire and ceiling fan placement is required. In general, mitigation of stroboscopic effect is achieved by shifting the luminaires vertically or horizontally such that the lighting throw pattern of the luminaire is not interrupted by the fan blades. Figure 3.3.1c provides an example of shifting the luminaires horizontally to reduce stroboscopic effect and Figure 3.3.1d provides an example of shifting the luminaires vertically. Where luminaires are shifted horizontally, it may be necessary to improve the lighting beneath the fan to maintain uniform illumination at the work surface. To achieve this, ceiling fans can be specified with optional LED luminaires that mount to the hub of the fan and illuminate the area directly below the fan without generating a stroboscopic effect or shadowing. An example of a hub mounted luminaire is pictured in Figure 3.3.1e. The hub mounted luminaire can be selected to match the color temperature of the other luminaires in the space and the lighting output can be adjusted to maintain uniform illumination at the work surface.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1c.png',
                  alt: 'Luminaires are mounted at the same height as in the previous figure but have been shifted horizontally to reduce the stroboscopic effect by reducing the fan blade interference with the light pattern.',
                  caption: `Luminaires are mounted at the same height as in the previous figure but have been shifted horizontally to reduce the stroboscopic effect by reducing the fan blade interference with the light pattern.`,
                  letter: 'c',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1d.png',
                  alt: 'Luminaire horizontal spacing is maintained but the mounting height is lowered to reduce the fan blade interference with the light pattern.',
                  caption: `Luminaire horizontal spacing is maintained but the mounting height is lowered to reduce the fan blade interference with the light pattern.`,
                  letter: 'd',
                },
              },
              {
                type: 'paragraph',
                content: `Luminaires are generally provided with data that documents the lighting throw patterns of the luminaire. This data is available in two dimensional graphical representations as illustrated in the Figure 3.3.1a. These two dimensional throw patterns are helpful for approximating appropriate spacing but do not offer the precision and accuracy of computer modeling. Throw patterns for luminaires are also typically available as electronic files for use in lighting modeling software. This software can be used to optimize luminaire locations within a space. Ideally, the luminaires and the ceiling fan can both be input into the lighting modeling software to best understand the effects of the fan on the light distribution. To effectively model the fan in the software, the fan can be modeled as a simple shape such as a flat disc representing the rotating blades or a cylinder of the fan diameter that extends down from the ceiling to the blade height. If the fan representation in the model generates an area of significantly lower lighting level as illustrated in Figure 3.3.1f, a stroboscopic effect will result within that area.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1e.png',
                  alt: 'Where ceiling fan installations create a gap in lighting, an LED luminaire matching the color temperature and lumen output can be added to the fan to compensate and maintain lighting uniformity.',
                  caption: `Where ceiling fan installations create a gap in lighting, an LED luminaire matching the color temperature and lumen output can be added to the fan to compensate and maintain lighting uniformity.`,
                  letter: 'e',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1f.png',
                  alt: 'The inclusion of a ceiling fan in lighting modeling software. This result demonstrates the approximate result of the layout illustrated in Figure 3.3.1b.',
                  caption: `The above illustrates the inclusion of a ceiling fan in lighting modeling software. This result demonstrates the approximate result of the layout illustrated in Figure 3.3.1b.`,
                  letter: 'f',
                },
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Duct Design',
        sidebarContent: 'Duct Design',
        subSections: [
          {
            mainContent: 'Standard Supply Duct Design Without Ceiling Fans',
            sidebarContent: 'Standard Supply Duct Design Without Ceiling Fans',
            data: [
              {
                type: 'paragraph',
                content: `To uniformly distribute air and maintain a consistent space temperature without gradients or stratification, the standard practice for supply ductwork design in gyms and other large spaces is to provide multiple injection points at multiple locations throughout the space. An example of this type of ductwork configuration for a gym is illustrated in Figure 3.4.3a.`,
              },
            ],
          },
          {
            mainContent:
              'Sample Supply Duct Layout With Large Diameter Ceiling Fans',
            sidebarContent:
              'Sample Supply Duct Layout With Large Diameter Ceiling Fans',
            data: [
              {
                type: 'paragraph',
                content: `To reduce HVAC construction cost associated with the standard air distribution method, ceiling fans can be added. The fans help distribute supply air evenly throughout the space without the need for the additional ductwork. This saves construction cost by reducing materials and labor for the following equipment and services:`,
              },
              {
                type: 'list',
                listData: {
                  data: [
                    `Ductwork and fittings`,
                    `Ductwork insulation or liner`,
                    `Ductwork sealing`,
                    `Ductwork painting (if applicable)`,
                    `Manual balancing dampers`,
                    `Supply grilles or diffusers`,
                    `Air balancing`,
                  ],
                },
              },
            ],
          },
          {
            mainContent: 'Sample First Cost Estimate',
            sidebarContent: 'Sample First Cost Estimate',
            data: [
              {
                type: 'paragraph',
                content: `Using the gym example below, the ductwork can be reduced as shown in Figure 3.4.3b with the addition of two 14 ft. (4.3 m) diameter ceiling fans. By adding two ceiling fans, twelve (12) supply grilles, 68 ft. of 14 in.ø (21 m of 355 mmø) supply duct, and 84 ft. of 18 in.ø (26 m of 457 mmø) supply duct can be eliminated. The first cost of the system with the ceiling fans was estimated, as was the first cost of the fully ducted system without the ceiling fans. The cost of the system with the ceiling fans was found to be less than the fully ducted system. The HVAC scope removed from this example project costs approximately 1.5 times that of the two large diameter ceiling fans added to the project, thus more than offsetting the cost of the fans.`,
              },
              {
                type: 'paragraph',
                content: `In this example, the savings is associated with the reduction of air distribution hardware only and does not include first-cost savings associated with reducing the size of the central air handling equipment. In situations where the central air handling unit serving the space can be reduced in size, the HVAC scope removed could be up to 4.5 times that of the two large diameter ceiling fans added to the project.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-5-1a.png',
                  alt: 'A plan view of a conventional duct layout in a typical gym where no circulator fans are used',
                  caption: `Above is a plan view of a conventional duct layout in a typical gym where no circulator fans are used.`,
                  letter: 'a',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-5-1b.png',
                  alt: 'An example of the same gym as above but with reduced ductwork and the addition of large diameter ceiling fans.',
                  caption: `An example of the same gym as above but with reduced ductwork and the addition of large diameter ceiling fans.`,
                  letter: 'b',
                },
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Heating And Cooling Load Calculations',
        sidebarContent: 'Heating And Cooling Load Calculations',
        data: [
          {
            type: 'paragraph',
            content: `As described in Chapter 2, incorporating ceiling fans can have a significant impact on overall building design. Some ways in which ceiling fans can impact heating and cooling loads in a building include: raising the temperature setpoint in cooling, affecting conductive heat transfer through the envelope, and reducing the required outdoor airflow rate. The following example illustrates how load calculations can vary between a traditional HVAC system without ceiling fans and one with fans in a real world application.`,
          },
          {
            type: 'paragraph',
            content: `An example of this concept is featured in the diagram below.`,
          },
          {
            type: 'image',
            imageData: {
              src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6.png',
              alt: 'Diagram illustrating impact of fans on room temperature and stratification.',
              caption: `Diagram illustrating impact of fans on room temperature and stratification.`,
              letter: 'a',
            },
          },
        ],
        subSections: [
          {
            mainContent: 'Atrium Example',
            sidebarContent: 'Atrium Example',
            data: [
              {
                type: 'paragraph',
                content: `A new university building features a three-story atrium in the lobby. It is 1,130 ft² (105 m²), 36 ft. (11 m) tall, and has one exterior wall, which is entirely glass. See Figure 3.5.1a below for floor plans. The atrium is to be served by a packaged air handler with direct expansion cooling and gas-fired heating.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1a.png',
                  alt: 'Atrium Floor Plan - Level 1',
                  caption: `Atrium Floor Plan - Level 1`,
                  letter: 'a',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1b.png',
                  alt: 'Atrium Floor Plan - Level 2',
                  caption: `Atrium Floor Plan - Level 2`,
                  letter: 'b',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1c.png',
                  alt: 'Atrium Floor Plan - Level 3',
                  caption: `Atrium Floor Plan - Level 3`,
                  letter: 'c',
                },
              },
              {
                type: 'paragraph',
                content: `Space characteristics were entered into a load calculation software, including all envelope and internal heat gain values. These values were entered identically into both the model with the fan and the one without.`,
              },
              {
                type: 'paragraph',
                content: `For the system with no fan, the space temperature was set to 75°F (24°C) in cooling. In heating, the temperature was also input as 75°F (24°C) to account for stratification. In a heated space that is not sufficiently mixed, warm air will rise to the top of the room. The thermostat is at a lower height and does not sense the warmer air toward the ceiling. As a result, the thermostat will continue to call for heat, even when the mean temperature in the room is above the set point. To simulate this effect, a 75°F (24°C) setpoint was entered into the model, assuming that the actual room setpoint would be closer to 70°F (21°C) and with stratification the mean temperature would be approximately 75°F (24°C). Outdoor airflow rate was calculated using ASHRAE Standard 62.1 requirements.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1d.png',
                  alt: `SpecLab CFD analysis. Airspeed at a horizontal plane 43" above the floor.`,
                  caption: `SpecLab CFD analysis. Airspeed at a horizontal plane 43" above the floor.`,
                  letter: 'd',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1e.png',
                  alt: `SpecLab CFD analysis. Airspeed at a horizontal plane 67" above the floor.`,
                  caption: `SpecLab CFD analysis. Airspeed at a horizontal plane 67" above the floor.`,
                  letter: 'e',
                },
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1f.png',
                  alt: `SpecLab CFD analysis. Airspeed using a vertical cut plane through the center of the room.`,
                  caption: `SpecLab CFD analysis. Airspeed using a vertical cut plane through the center of the room.`,
                  letter: 'f',
                },
              },
              {
                type: 'paragraph',
                content: `From the area and people based rates, a ventilation rate of 323 CFM (152 L/s) was calculated. In a traditional HVAC system, air is typically supplied from and returned via the ceiling, resulting in a zone air distribution effectiveness of 0.8 in heating and 1.0 in cooling. The lower effectiveness value is selected to account for the worst case distribution, and the required outdoor air is divided by 0.8, resulting in an increased rate of 404 CFM (191 L/s).`,
              },
              {
                type: 'paragraph',
                content: `For the system with a fan, the space temperature in cooling was increased to 80°F (27°C). This set point was determined by selecting a fan and running a CFD analysis for the proposed layout in SpecLab as illustrated in Figure 3.5.1d through Figure 3.5.1f. The minimum air speed observed in the SpecLab model at 43" above the floor was 100 fpm (0.51 m/s). By plugging this value into the CBE Thermal Comfort Tool, the cooling effect allowed for a thermostat offset of 5°F (2.8°C) and the baseline setpoint of 75°F (24°C) was increased to 80°F (26.7°C). In heating, the room setpoint was 70°F (21°C) since the fan will destratify the space. The addition of the fan also provides better air-mixing in the space. As a result, a zone air distribution effectiveness of 1.0 was used to calculate the minimum outdoor airflow. In this scenario, a lower outdoor airflow rate of 323 CFM (152 L/s) was used.`,
              },
              {
                type: 'paragraph',
                content: `A summary of all input parameters may be found in Figure 3.5.1g below.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1g.png',
                  alt: 'Summary of Load Calculation Inputs',
                  caption: `Summary of Load Calculation Inputs`,
                  letter: 'g',
                },
              },
              {
                type: 'paragraph',
                content: `Cooling and heating loads were calculated using the Radiant Time Series (RTS)/Heat Balance and U-factor*Area*Temperature Difference (UATD) methodologies, respectively. Results from both load calculations are summarized in Figure 3.5.1h below.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1h.png',
                  alt: 'Summary of Load Calculation Results',
                  caption: `Summary of Load Calculation Results`,
                  letter: 'h',
                },
              },
              {
                type: 'paragraph',
                content: `As can be seen in the table, both the heating and cooling loads were lower for the design with a fan. Reductions in required capacity are due largely to the changes in temperature setpoint allowed by providing elevated air speed in cooling and destratification in heating. Additionally, by providing a well-mixed space and selecting temperature setpoints slightly closer to outdoor temperature, conductive heat transfer through the building envelope is reduced.`,
              },
              {
                type: 'paragraph',
                content: `The total required cooling capacity was 133.3 MBh (39 kW) for the design without a fan and 123.0 MBh (36 kW) for the design that utilized a fan. This project is to be served by a packaged rooftop unit, which is often selected by required cooling tonnage. Without a fan, the system requires 11.1 tons (39 kW) of cooling. With a fan, 10.3 tons (36.2 kW) are required. While this may not be a large difference, packaged RTUs are typically provided in integral increments and actual cooling capacity delivered is lower than nominal tonnage. For example, an 11 ton (38.6 kW) RTU may only provide closer to 10.5 tons (36.9 kW) of cooling. This would be sufficient for the case with a circulator fan, but for the design without a fan, a larger nominal unit would need to be selected. Actual RTU sizes depend on unit manufacturer and model; however, whenever a smaller unit may be selected, it typically provides multiple benefits including lower first cost, smaller footprint, and lighter weight.`,
              },
              {
                type: 'paragraph',
                content: `Additionally, reducing heating and cooling loads also reduced required supply airflow of the system. This decreases first costs by allowing ductwork sizes to be reduced. These savings are in addition to savings associated with reducing the extent of supply ductwork, as described in Section 3.4.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Building Energy Consumption',
        sidebarContent: 'Building Energy Consumption',
        data: [
          {
            type: 'paragraph',
            content: `Section 2.6.1 described the effects of adding ceiling fans on heating and cooling load calculations for a given space. In addition to the first cost and design benefits of reducing required equipment capacity, the incorporation of ceiling fans also results in lower energy consumption and operating costs. More detail on how fans save energy is provided in Section 2.6.4.`,
          },
        ],
        subSections: [
          {
            mainContent: 'Atrium Example',
            sidebarContent: 'Atrium Example',
            data: [
              {
                type: 'paragraph',
                content: `To illustrate these energy savings, an energy model was completed for the atrium example in the previous section. Modeling inputs are summarized in Figure 3.6.1a. All inputs for the load calculation portion of the energy model are the same as in Section 2.5.1 and can be found in Figure 3.5.1d.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-7-1a.png',
                  alt: 'Summary of Energy Model Inputs',
                  caption: `Summary of Energy Model Inputs`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `Energy modeling inputs, in this case, were identical between both scenarios; however, it should be noted that in other cases there could be differences. For example, in this atrium space, supply ductwork was already fairly minimal due to limited wall and ceiling space. Often, a reduction in supply ductwork is seen when ceiling fans are incorporated into a design and facilitate air distribution. When supply ductwork is decreased, supply fan static pressure is also decreased, resulting in lower energy consumption. Additionally, it was discussed in Section 2.5.1 how including ceiling fans in a design can decrease heating and cooling loads and, as a result, HVAC equipment size. In this example, the decrease in loads was small enough that it was assumed the RTUs in both scenarios would have the same efficiency. It is possible, in a different example, the difference in required equipment capacity could result in a more efficient unit being selected; as size of RTUs increases, efficiency also typically increases.`,
              },
              {
                type: 'paragraph',
                content: `A full year (8760 hour) energy model simulation was run for the atrium example. Modeling results are summarized in Figure 3.6.1b.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-7-1b.png',
                  alt: 'Summary of Energy Model Results',
                  caption: `Summary of Energy Model Results`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `In the design with a fan, energy consumption is reduced by about 42 million Btu/yr (12,000 kWh/yr) and annual energy savings totals approximately $600. Savings are largely due to the reduction in heating and cooling requirements in the space as a result of temperature setpoints and reduced envelope losses. When heating and cooling requirements are decreased, the RTU may be run less, resulting in energy savings. Additionally, even when heating and cooling are required, when the circulator fan may be used in lieu of the RTU, less energy is consumed by the system. It is more efficient for the ceiling fan to provide a 5°F (2.8°C) cooling effect with elevated air speeds than for the RTU to run the DX coil and supply fan to provide the same cooling effect.`,
              },
              {
                type: 'paragraph',
                content: `Savings are achieved in the case with the fan due to increased air economizer hours. Since the space temperature setpoint is higher in the case with a fan, the economizer high limit temperature may also be greater, resulting in more hours when economizer may be used in lieu of DX cooling. For this weather location, increasing the high-limit shutoff setting by 5°F (2.8°C) equates to an additional 410 hours of economizer operation.`,
              },
            ],
          },
          {
            mainContent: 'Energy Models for Code Compliance',
            sidebarContent: 'Energy Models for Code Compliance',
            data: [
              {
                type: 'paragraph',
                content: `Often energy models are performed not only to compare two design options, as in Section 2.7.1, but to demonstrate compliance with a code. In this case, the proposed design would be compared to a baseline, code-defined building to evaluate performance improvements of the proposed design over minimum requirements. One common methodology used to demonstrate compliance is ASHRAE Standard 90.1 Appendix G.`,
              },
              {
                type: 'paragraph',
                content: `Appendix G outlines all necessary requirements for modeling both the baseline and proposed buildings and specifies how credit may be taken for efficient designs. Table G3.1 Section 4 lists requirements for thermostat and equipment schedules. In general, baseline and proposed models must use identical schedules, unless one of the listed exceptions applies. Exception 1 under Baseline Building Performance states:`,
              },
              {
                type: 'indent',
                indentData: {
                  content: `Set points and schedules for HVAC systems that automatically provide occupant thermal comfort via means other than directly controlling the air dry-bulb and wet-bulb temperature may be allowed to differ, provided that equivalent levels of occupant thermal comfort are demonstrated via the methodology in ASHRAE Standard 55, Section 5.3.3, "Elevated Air Speed," or Standard 55, Appendix B, "Computer Program for Calculation of PMV-PPD."`,
                },
              },
              {
                type: 'paragraph',
                content: `Under this exception, set points and schedules may vary between the baseline and proposed models when the proposed model uses a fan to provide occupant comfort through elevated air speed. This allows the modeler to take credit for the savings discussion that results from incorporating a ceiling fan into an HVAC design, as discussed in this section.`,
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Financial',
        sidebarContent: 'Financial',
        subSections: [
          {
            mainContent: 'Cost Savings',
            sidebarContent: 'Cost Savings',
            data: [
              {
                type: 'paragraph',
                content: `From a financial standpoint, large diameter ceiling fans have the potential to reduce both first cost and utility cost of a building. However, first cost savings is variable and will not be the same for every project. For instance, the gym example in Section 3.4 resulted in a net first cost reduction. Therefore, excluding a net increase in utility costs, the inclusion of ceiling fans is financially justified on day one. When factoring the utility savings that will be achieved with the use of a ceiling fan, the savings will continue to be realized after construction is complete. Justification of a ceiling fan in situations such as this is obvious.`,
              },
              {
                type: 'paragraph',
                content: `In projects where the first cost of the project with ceiling fans is higher than the alternative without fans, there is a potential for the utility cost savings to quickly justify any additional cost associated with the addition of the ceiling fans. Using the Atrium Example from above, there is an annual utility savings of $600. Because there is little HVAC scope reduction for this example, the net first cost is an increase on the base project cost as the cost of adding a fan will exceed the cost saved by reducing air distribution scope. Assuming the first cost cost net increase is approximately $4,500, the simple payback for the ceiling fan is 7.5 years. In practice, simple payback periods less than 7 years are generally viewed as a good investment.`,
              },
              {
                type: 'paragraph',
                content: `First cost and energy conservation are not the only ways a ceiling fan reduces operating expenses. When employees are hot, productivity suffers. Overheated employees require more frequent trips to directional fans and water coolers. Stress levels are increased and motivation falls. The addition of ceiling fans improves working conditions by cooling employees. The Big Ass Fans website includes a tool for estimating the cost of lost productivity due to uncomfortable employees (detailed in Section 2.2.3). The tool uses weather data by state, the number of employees, the hourly salary per employee, and the number of hours worked per employee per week. It calculates "Money Lost Due to Lowered Productivity", the amount of money a Big Ass Fan could save per year, "The Annual Loss Due to Heat", and the percent a Big Ass Fan could reduce lost productivity. See Figure 3.7a for a sample calculation.`,
              },
              {
                type: 'paragraph',
                content: `More detailed calculations on the impact of thermal comfort on productivity can be found in the ASHRAE Journal article "Simulated Impact of Energy Codes: Thermal Comfort in Heated-and-Ventilated-Only Warehouses," by Christian Taber and Donald Colliver, Ph.D., P.E.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-8a.png',
                  alt: 'Sample results from the BAF Productivity Calculator for the state of Missouri',
                  caption: `Above are sample results from the BAF Productivity Calculator for the state of Missouri.`,
                  letter: 'a',
                },
              },
              {
                type: 'paragraph',
                content: `A combined financial calculation that includes numerous factors such as first cost, fan operating costs, replacement of inefficient fans, destratification energy savings, employee productivity, and environmental impacts provide a more holistic view of the potential impacts of ceiling fans on a project. See Figure 3.7b for a sample calculation of a heated manufacturing facility that is replacing fifteen column fans with five twenty foot diameter ceiling fans.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-8b.png',
                  alt: 'Sample results for a manufacturing retrofit project',
                  caption: `Above are sample results for a manufacturing retrofit project.`,
                  letter: 'b',
                },
              },
              {
                type: 'paragraph',
                content: `Detailed financial calculations that incorporate the time value of money reflect the total cost of ownership may be required to justify a fan project as illustrated by the length of the simple payback in the atrium example above. See Figure 3.7c for the detailed financial calculations for the manufacturing project over a five year and ten year time period. Internal rate of return (IRR) is essentially the interest rate at which the net present value of all the cash flows from the project equals zero. Internal rate of return is a commonly used metric to determine the merit of a financial decision. In this example, the 10-year IRR is 33% which would be compared to other potential projects and typically, the highest IRR project(s) would be selected. Net present value (NPV) is used to determine the current value of all future cash flows generated by the project. NPV is used to analyze the profitability of a projected investment in today's dollars. In this example, the 5-year NPV is a positive $12,713 which indicates that the project has paid for itself in less than 5 years and generated an additional $12,713 in savings in today's dollars.`,
              },
              {
                type: 'image',
                imageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/3-8c.png',
                  alt: 'Sample results from the BAF Productivity Calculator for the state of Missouri',
                  caption: `Above are sample results from the BAF Productivity Calculator for the state of Missouri.`,
                  letter: 'c',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    mainContent: 'Case Studies',
    sidebarContent: 'Case Studies',
    sections: [
      {
        mainContent: 'The Bullitt Center',
        sidebarContent: 'The Bullitt Center',
        subSections: [
          {
            mainContent: 'A Comfortable Indoor Environment',
            sidebarContent: 'A Comfortable Indoor Environment',
            data: [
              {
                type: 'overlay',
                overlayedImageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/Bullitt-Center.jpg',
                  alt: 'Bullitt Center',
                  content: `Ceiling fans are a key part of the strategy in achieving world-class commercial building performance and delivering a comfortable indoor environment for office workers.`,
                  url: 'http://dev-guide.bigassfans.com.s3-website.us-east-2.amazonaws.com/US_CBE_CaseStudy_BullittCenter.pdf',
                },
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Franco Center',
        sidebarContent: 'Franco Center',
        subSections: [
          {
            mainContent: 'Energy Savings for Senior Living',
            sidebarContent: 'Energy Savings for Senior Living',
            data: [
              {
                type: 'overlay',
                overlayedImageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/franco-center.jpg',
                  alt: 'Franco Center',
                  content: `Energy retrofits of a senior living facility shows how ceiling fans integrated with air conditioning can deliver thermal comfort improvements and energy savings for community housing.`,
                  url: 'http://dev-guide.bigassfans.com.s3-website.us-east-2.amazonaws.com/US_CBE_CaseStudy_FrancoCenter.pdf',
                },
              },
            ],
          },
        ],
      },
      {
        mainContent: 'Coastal Biology Building',
        sidebarContent: 'Coastal Biology Building',
        subSections: [
          {
            mainContent: 'Thermal Comfort in an Educational Setting',
            sidebarContent: 'Thermal Comfort in an Educational Setting',
            data: [
              {
                type: 'overlay',
                overlayedImageData: {
                  src: 'https://design-guide-images.s3.us-east-2.amazonaws.com/coastal_biology_building.jpg',
                  alt: 'Coastal Biology Building',
                  content: `Thoughtful design of a world-class research facility highlights the benefits of ceiling fans in naturally ventilated spaces in ensuring occupant thermal comfort in educational settings.`,
                  url: 'http://dev-guide.bigassfans.com.s3-website.us-east-2.amazonaws.com/US_CBE_CaseStudy_CoastalBiologyBuilding.pdf',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    mainContent: 'FAQ',
    sidebarContent: 'FAQ',
    sections: [
      {
        mainContent: 'Common Questions and Misconceptions',
        sidebarContent: 'Common Questions and Misconceptions',
        subSections: [
          {
            mainContent:
              'Big Ass Fans Cannot be Run in Reverse (a.k.a. Airflow Blowing Up)',
            sidebarContent: 'Can Big Ass Fans be Run in Reverse?',
            data: [
              {
                type: 'paragraph',
                content: `Almost all ceiling fans are reversible, including all Big Ass Fans' ceiling fans. The United States Code of Federal Regulations (CFR) requires that ceiling fans sold in the U.S. must have “the capability of reversible fan action.” Below is the exact text from 10 CFR, 430.32 - Energy and Water Conservation Standards and Their Compliance Dates:`,
              },
              {
                type: 'indent',
                indentData: {
                  content: `(ff) CEILING FANS AND CEILING FAN LIGHT KITS.—`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  content: `(1)(A) All ceiling fans manufactured on or after January 1, 2007, shall have the following features:`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 2,
                  content: `(i) Fan speed controls separate from any lighting controls.`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 2,
                  content: `(ii) Adjustable speed controls (either more than 1 speed or variable speed).`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 2,
                  content: `(iii) Adjustable speed controls (either more than 1 speed or variable speed).`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 2,
                  content: `(iv) The capability of reversible fan action, except for—`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 3,
                  content: `(I) fans sold for industrial applications;`,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 3,
                  content: `(II) outdoor applications; and `,
                },
              },
              {
                type: 'indent',
                indentData: {
                  indentLevel: 3,
                  content: `(III) cases in which safety standards would be violated by the use of the reversible mode.`,
                },
              },
              {
                type: 'link',
                urlData: {
                  url: '',
                  content: 'Sources:',
                  clickable: ' ',
                },
              },
              {
                type: 'link',
                urlData: {
                  url: `https://www.law.cornell.edu/cfr/text/10/430.32`,
                  content: 'Cornell',
                },
              },
              {
                type: 'link',
                urlData: {
                  url: `https://www.govinfo.gov/content/pkg/PLAW-109publ58/pdf/PLAW-109publ58.pdf`,
                  content: 'GovInfo',
                },
              },
            ],
          },
          {
            mainContent:
              'Does the amp draw of a fan need to be known to estimate the operating cost of the fan? Similarly, when comparing two fans, is comparing the amp draw an accurate way of comparing relative energy use, efficiency, and/or operating cost?',
            sidebarContent:
              'Does the Amp Draw of a Fan Need to be Known to Estimate the Operating Cost?',
            data: [
              {
                type: 'paragraph',
                content: `A typical electric bill is based on the amount of power that is consumed by the facility over the billing period.  Power is measured in watts and the billing period is measured in hours.  Therefore, the most accurate data for determining the energy usage or operating cost of any product is the rated power consumption (aka the wattage) paired with the number of hours that the product will operate (aka the operating hours).`,
              },
            ],
            subSections: [
              {
                mainContent:
                  'Estimating Energy Use and Operating Cost - Basic Example',
                data: [
                  {
                    type: 'paragraph',
                    content: `If a device uses 1,000 Watts (1.0 kW) of power and it is operated at full speed for 3 hours per day, then the daily energy consumption of the product is 3,000 Watt-hours (3.0 kWh).  If the utility charges the customer $0.15/kWh for their electricity, then the product would cost the owner $0.45 per day to operate.  The calculations for energy consumption and operating cost are shown below.`,
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Power = 1,000 W = 1.0 kW`,
                    },
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Operating hours = 3 hours per day at full speed`,
                    },
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Energy Consumption = 1.0 kW x 3 hours / day = 3.0 kWh / day`,
                    },
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Energy Cost = $0.15/kWh`,
                    },
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Operating cost = 3.0 kWh / day x $0.15/kWh = $0.45 / day`,
                    },
                  },
                ],
              },
              {
                mainContent: 'What About the Amps?',
                data: [
                  {
                    type: 'paragraph',
                    content: `It is a common misconception that simply comparing the amps consumed by a product is an accurate way of determining the energy use, efficiency, and/or operating cost of a product.`,
                  },
                  {
                    type: 'paragraph',
                    content: `The table below shows the relevant electrical data for three different input voltage/phases for the same model of fan.`,
                  },
                  {
                    type: 'table',
                    tableData: {
                      headers: ['', 'Amps', 'Volts', 'Phase', 'Watts'],
                      data: [
                        ['Fan 1', '11.1', '240', '1ɸ', '1,598'],
                        ['Fan 2', '6.3', '240', '3ɸ', '1,596'],
                        ['Fan 3', '3.2', '480', '3ɸ', '1,596'],
                      ],
                      caption: 'Energy Use of Three Similar Fans',
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Comparing Fan 2 to Fan 3, we see that the amp draw of the 480V/3ɸ fan (Fan 3) is half the amp draw of the 240V/3ɸ fan (Fan 2).  However, when looking at the power consumed by the two fans, we see that the watts are nearly identical. The calculations for`,
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Fan 1: Power = 11.1 amps x 240 volts x 0.6 power factor = 1,598 watts`,
                    },
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Fan 2: Power = 6.4 amps x 240V x √3 x 0.6 power factor = 1,596 watts`,
                    },
                  },
                  {
                    type: 'indent',
                    indentData: {
                      content: `Fan 3: Power = 3.2 amps x 480V x √3 x 0.6 power factor = 1,596 watts`,
                    },
                  },
                  {
                    type: 'paragraph',
                    content: `Since the wattage of all three fans are nearly identical, the cost to operate each of the three fans would also be identical.  If amps alone are used in an analysis of a product's efficiency, power consumption, or operating cost, it is highly unlikely that an accurate result will be achieved.  Stated simply, the power company sends you a power bill, therefore power (watts) will provide the best estimate for the cost to operate a product.  Until the amp company starts sending you amp bills, ask for watts not amps.`,
                  },
                ],
              },
            ],
          },
          {
            mainContent:
              'Is there a standard method of rating the performance of ceiling fans?',
            sidebarContent:
              'Is There a Standard Method of Rating the Performance of Ceiling Fans?',
            data: [
              {
                type: 'paragraph',
                content: `Ceiling fans are a federally regulated product in the United States.  The federal method of test can be found in the code of federal regulations (10 CFR Appendix U to Subpart B of Part 430 - Uniform Test Method for Measuring the Energy Consumption of Ceiling Fans). The uniform test method includes definitions, product classifications, test procedures, and efficiency metrics.`,
              },
              {
                type: 'paragraph',
                content: `Ceiling fans sold in the United States must also meet a minimum efficiency level which can also be found in the code of federal regulations (10 CFR 430.32(s)(2)) Efficiency levels are set for each product class based on the efficiency metric and test data determined using the Ceiling Fan Test Procedure.`,
              },
              {
                type: 'link',
                urlData: {
                  url: 'https://www.regulations.doe.gov/certification-data/#q=Product_Group_s%3A*',
                  content: `The DOE's  Compliance Certification Database houses certification reports and compliance statements submitted by manufacturers for covered products and equipment subject to Federal conservation standards. The database offers an easy-to-use search function for verification of compliance with Federal testing and efficiency regulations. Manufacturers must submit their certification reports and compliance statements before offering a covered product for sale in the United States.`,
                  clickable: 'Compliance Certification Database',
                },
              },
            ],
          },
          {
            mainContent:
              'How efficient are ceiling fans at moving air compared to other types of fans?',
            sidebarContent:
              'How Efficient are Ceiling Fans at Moving Air Compared to Other Types of Fans?',
            data: [
              {
                type: 'paragraph',
                content: `The table below compares the efficiency of different types of fans operating at their highest speeds. All values are approximate, but are representative of the product class and were determined from third party performanced data (when available).`,
              },
              {
                type: 'table',
                tableData: {
                  headers: [
                    'Fan Type',
                    'Airflow (CFM)',
                    'Power (W)',
                    'Approx. Efficiency (CFM/W)',
                  ],
                  data: [
                    [`Big Ass Fans HVLS - 20'`, '185,700', '1300', '143'],
                    [`Ceiling fan with AC motor - 52"`, '4,660', '62', '75'],
                    [`Axial panel - 36"`, '9,200', '360', '26'],
                    [
                      `Upblast roof exhaust fan - 42" (0 s.p.)`,
                      '18,000',
                      '815',
                      '22',
                    ],
                    [
                      `Cylindrical air circulating fan - small thermal destratification - 12"`,
                      '550',
                      '35',
                      '16',
                    ],
                    [`Box fan - 31"`, '7,050', '470', '15'],
                    [
                      `Unhoused air circulating fan head - 24"`,
                      '3,470',
                      '264',
                      '13',
                    ],
                    [
                      `Cylindrical air circulating fan - barrel/tube - 24"`,
                      '5,100',
                      '450',
                      '12',
                    ],
                    [`Rooftop HVAC supply (5 ton)`, '2,000', '650', '3'],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
]

const flatPaths = data
  .map((chapter, i) => {
    const sections = chapter.sections.map((section, j) => {
      const subSections = section.subSections.map((subSection, k) => {
        return `chapter-${i + 1}-${j + 1}-${k + 1}`
      })
      return [`chapter-${i + 1}-${j + 1}`, subSections]
    })
    return [`chapter-${i + 1}`, sections]
  })
  .flat(4)

export { data, flatPaths }
