import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { IndentDataObj } from '../helpers/interfaces'

const IndentedParagraph = ({ content, indentLevel }: IndentDataObj) => (
  <Box display="flex" alignItems="flex-start">
    <Typography paragraph sx={{ ml: 6 * (indentLevel ?? 1) }}>
      {content}
    </Typography>
  </Box>
)

export default IndentedParagraph
