import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import SiteSearch from './site-search'
import AdditionalFields from './additional-fields'
import Directory from './directory'
import { usePermissions } from 'hooks/usePermissions'
import { useUserState } from 'store'

interface Metadata {
  reasonType: string
  userType: string
}

const Home = () => {
  const [metadata, setMetadata] = useState<Metadata>()
  const { user, isAdmin } = usePermissions()
  const userState = useUserState()
  const { hasSetMetadata } = userState.get()
  const userHasMetadata =
    user?.user_metadata?.userType || metadata?.userType || hasSetMetadata

  // Load metadata
  useEffect(() => {
    if (userHasMetadata && !metadata) {
      setMetadata(user?.user_metadata)
    }
  }, [metadata, user, userHasMetadata])

  return (
    <Box sx={{ backgroundColor: '#F7F7F7', minHeight: '100vh' }}>
      <SiteSearch />
      <Directory />
      <AdditionalFields
        open={!!(user && !userHasMetadata && !isAdmin)}
        userId={user?.sub || null}
        onSuccess={value => setMetadata(value)}
      />
    </Box>
  )
}

export default Home
