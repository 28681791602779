import { Tooltip } from 'react-tooltip'

import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import useMediaQuery from '@mui/material/useMediaQuery'

import FormCard from 'components/FormCard'
import StyledTextField from 'components/StyledTextField'

import { useEvapSizingToolState } from 'store'

const InputsTile = ({ loading = false }: { loading: boolean }) => {
  const evapSizingToolState = useEvapSizingToolState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const {
    length,
    width,
    deckHeight,
    isMetric,
    isCustom,
    spaceType,
    hasWindows,
    dbAirTemp,
    maxAirTempReduction,
    spaceAirTempReduction,
    mcwbAirTemp,
    safetyFactor,
    solarIntensity,
    weatherDataType,
    insulationLevel,
    environmentData,
    locationData,
    location,
  } = evapSizingToolState.get()

  // Build input options
  const spaceTypeOptions = environmentData?.map((datum: any) => ({
    ...datum,
    value: datum?.type,
  }))
  const locationOptions = locationData?.map((datum: any) => ({
    ...datum,
    value: datum?.location,
  }))

  return (
    <FormCard>
      <Box sx={{ pb: 2 }}>
        <Box
          display="inline-flex"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignContent: 'center',
            borderBottom: '1px solid',
            borderColor: 'grey.400',
            py: 1,
          }}
        >
          <Typography variant="h5" sx={{ mt: 0, mb: 0 }}>
            Inputs
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              mt: -1,
              '@media print': {
                display: 'none',
              },
            }}
          >
            Standard
            <Switch
              checked={isMetric}
              onChange={() => evapSizingToolState.setIsMetric(!isMetric)}
            />
            Metric
          </Stack>
        </Box>

        <Box
          display="inline-flex"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            borderBottom: '1px solid',
            borderColor: 'grey.400',
          }}
        >
          <Typography variant="h6" sx={{ py: 0.5 }}>
            Area
          </Typography>
        </Box>

        <Box display="flex" flexDirection={'column'}>
          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={4}>
              <StyledTextField
                required
                type="number"
                helperText={isMetric ? 'Meters' : 'Feet'}
                label="Length"
                name="length"
                value={length || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setLength(parseFloat(value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: 0 }}>
              <StyledTextField
                required
                type="number"
                helperText={isMetric ? 'Meters' : 'Feet'}
                label="Width"
                name="width"
                value={width || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setWidth(parseFloat(value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: 0 }}>
              <StyledTextField
                required
                type="number"
                helperText={isMetric ? 'Meters' : 'Feet'}
                label="Deck Height"
                name="deck-height"
                value={deckHeight || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setDeckHeight(parseFloat(value))
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={6}>
              <FormControl
                variant="standard"
                sx={{ width: '100%', mt: mediumBreakpoint ? -0.5 : 0 }}
              >
                <InputLabel>Space Type</InputLabel>
                <Select
                  variant="standard"
                  label="Space Type"
                  required
                  value={spaceType || ''}
                  onChange={(e: any) => {
                    evapSizingToolState.setSpaceType(e?.target?.value)
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  {spaceTypeOptions?.map((type: any) => (
                    <MenuItem key={type?.value} value={type?.value}>
                      {type?.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: mediumBreakpoint ? 0 : 1 }}>
              <FormControl variant="standard" sx={{ width: '100%', mt: -0.5 }}>
                <InputLabel>Insulation Level</InputLabel>
                <Select
                  variant="standard"
                  label="Insulation Level"
                  required
                  value={insulationLevel || ''}
                  onChange={(e: any) => {
                    evapSizingToolState.setInsulationLevel(e?.target?.value)
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Average">Average</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: mediumBreakpoint ? 2 : 1 }}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%', mt: -0.5 }}>
                <InputLabel>Location</InputLabel>
                <Select
                  variant="standard"
                  label="Location"
                  required
                  value={location || ''}
                  onChange={(e: any) => {
                    evapSizingToolState.setLocation(e?.target?.value)
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  {locationOptions?.map((type: any) => (
                    <MenuItem key={type?.value} value={type?.value}>
                      {type?.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={event => {
                      evapSizingToolState.setHasWindows(event.target.checked)
                    }}
                    checked={hasWindows || false}
                  />
                }
                label="Significant Windows"
              />
            </Grid>
          </Grid>

          <Box
            display="inline-flex"
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              borderBottom: '1px solid rgb(188, 190, 192)',
              borderTop: '1px solid rgb(188, 190, 192)',
              mt: 3,
            }}
          >
            <Typography variant="h6" sx={{ py: 0.5 }}>
              Weather / Unit Selection
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              Location
              <Switch
                checked={isCustom}
                onChange={() => evapSizingToolState.setIsCustom(!isCustom)}
              />
              Custom
            </Stack>
          </Box>

          <Grid container spacing={2} sx={{ mt: 0 }}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%', mt: -0.5 }}>
                <InputLabel>Weather Data</InputLabel>
                <Select
                  variant="standard"
                  label="Weather Data"
                  required
                  value={weatherDataType || ''}
                  onChange={(e: any) => {
                    evapSizingToolState.setWeatherDataType(e?.target?.value)
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  <MenuItem value="Annual 1%">Annual 1%</MenuItem>
                  <MenuItem value="Annual 2%">Annual 2%</MenuItem>
                  <MenuItem value="Monthly 2%">Monthly 2%</MenuItem>
                  <MenuItem value="Monthly 5%">Monthly 5%</MenuItem>
                  <MenuItem value="Monthly 10%">Monthly 10%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 0 }}>
              <StyledTextField
                required
                type="number"
                disabled={!isCustom}
                label="DB Air Temp"
                name="db-air-temp"
                value={dbAirTemp || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setDbAirTemp(parseFloat(value))
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: mediumBreakpoint ? 1 : 0 }}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                required
                type="number"
                disabled={!isCustom}
                label="MCWB Air Temp"
                name="mcwb-air-temp"
                value={mcwbAirTemp || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setMcwbAirTemp(parseFloat(value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 0 }}>
              <StyledTextField
                required
                type="number"
                disabled={!isCustom}
                label="Solar Intensity"
                name="solar-intensity"
                value={solarIntensity || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setSolarIntensity(parseFloat(value))
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: mediumBreakpoint ? 1 : 0 }}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                required
                disabled={true}
                type="number"
                label="Max Air Temp Reduction"
                name="max-air-temp-reduction"
                value={maxAirTempReduction || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e?.target?.value
                  evapSizingToolState.setMaxAirTempReduction(parseFloat(value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: 0 }}>
              <Box
                data-tooltip-id="standard-tooltip"
                data-tooltip-html={
                  mediumBreakpoint
                    ? 'Calculations are based on population averages for the information selected and are only an estimate of actual savings. <br/>Actual Costs and savings will vary depending on weather conditions, usage, location and local utility rates. <br />This information is intended as an example for comparison purposes only. BAF does not gurantee the accuracy of these <br />calculations. No promise of performance is implied by us or should be inferred by you.'
                    : ''
                }
              >
                <StyledTextField
                  required
                  type="number"
                  label="Space Air Temp Reduction"
                  name="space-air-temp-reduction"
                  InputProps={{
                    inputProps: { min: 1, max: maxAirTempReduction || 1 },
                  }}
                  value={spaceAirTempReduction || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const airTemp = parseInt(e?.target?.value)
                    const value =
                      airTemp > maxAirTempReduction
                        ? maxAirTempReduction
                        : airTemp
                    evapSizingToolState.setSpaceAirTempReduction(value)
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: mediumBreakpoint ? 1 : 0 }}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: '100%', mt: -0.5 }}>
                <InputLabel>Safety Factor</InputLabel>
                <Select
                  variant="standard"
                  label="Safety Factor"
                  required
                  value={safetyFactor || ''}
                  onChange={(e: any) => {
                    evapSizingToolState.setSafetyFactor(
                      parseFloat(e?.target?.value)
                    )
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  <MenuItem value={2.0}>Standard - 2.0</MenuItem>
                  <MenuItem value={2.5}>Conservative - 2.5</MenuItem>
                  <MenuItem value={3.0}>Very Conservative - 3.0</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Tooltip id="standard-tooltip" />
    </FormCard>
  )
}

export default InputsTile
