import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useAuth0 } from '@auth0/auth0-react'
import Skeleton from '@mui/material/Skeleton'
import useMediaQuery from '@mui/material/useMediaQuery'

interface DirectoryCardProps {
  description?: string
  icon?: string
  id?: string
  isFeatured?: boolean
  label?: string
  to?: string
  type?: string
}

const DirectoryCard = ({
  description = '',
  icon,
  id = '',
  type = 'Design Tools',
  isFeatured = false,
  label = '',
  to = '/',
}: DirectoryCardProps) => {
  const smallBreakpoint = useMediaQuery('(min-width:600px)')
  const { isLoading } = useAuth0()
  const HEIGHT = 200

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height={HEIGHT} />
  }

  return (
    <Link
      href={to}
      underline="none"
      sx={{
        display: 'block',
        p: 0,
        mx: 0,
        mb: 0.5,
        width: smallBreakpoint ? '368px' : '100%',
      }}
    >
      <Card
        id={id}
        sx={{
          height: HEIGHT,
          width: '100%',
          borderRadius: 4,
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: '100%',
            height: '48px',
            borderBottom: '1px solid #E2E3E4',
          }}
        ></Box>
        <CardContent>
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              sx={{ position: 'absolute', top: '-48px' }}
              src={icon}
            />
            {isFeatured && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '-59px',
                  right: 0,
                  border: '1px solid #FFC429',
                  borderRadius: '16px',
                  padding: '4px 14px',
                  fontSize: '10px',
                  fontWeight: 700,
                  letterSpacing: '0.1em',
                }}
              >
                FEATURED
              </Box>
            )}
            <Typography variant="body1" sx={{ ml: 1, mt: 1 }}>
              {type}
            </Typography>
            <Typography variant="h5" sx={{ ml: 1 }}>
              {label}
            </Typography>
            <Typography variant="body1" sx={{ ml: 1 }}>
              {description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  )
}

export default DirectoryCard
