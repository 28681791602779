import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import ImageCard from './components/ImageCard'
import InputsCard from './components/InputsCard'
import ResultsCard from './components/ResultsCard'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'
import { AppBarSpacer } from '../layout/helpers/styled'

import { usePermissions } from 'hooks/usePermissions'
import { useProjectResults } from 'hooks/useProjectResults'
import { useExtensionTubeState } from 'store'

import { axiosHelper } from 'utils'
import ExtensionTubeCalcPdf from './ExtensionTubeCalcPdf'

const ExtensionTubeCalc = ({
  standalone,
}: {
  showVersion?: boolean
  standalone?: boolean
}) => {
  const extensionTubeState = useExtensionTubeState()
  const [alertMessage, setAlertMessage] = useState('')
  const { user } = usePermissions()
  const [alertOpen, setAlertOpen] = useState(false)
  const { leadSaved, results } = extensionTubeState.getState()
  const {
    buildingWidth,
    deckHeight,
    diameter,
    eaveHeight,
    focusedInput,
    isMetric,
    mountingStructureHeight,
    peakHeight,
    product,
    roofPitch,
    customDropDimensionsData,
    mountToBottomDimensionsData,
    mountToWingletDimensionsData,
    productsData,
    dropTubeData,
    dropTubeOptionsData,
  } = extensionTubeState.getState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const printAreaRef = useRef<HTMLDivElement>(null)

  const [loadingProductsData, setLoadingProductsData] = useState(false)
  const [loadingDropTubeOptionsData, setLoadingDropTubeOptionsData] =
    useState(false)
  const [loadingDropTubeData, setLoadingDropTubeData] = useState(false)
  const [loadingCustomDropData, setLoadingCustomDropData] = useState(false)
  const [loadingMountToBottomData, setLoadingMountToBottomData] =
    useState(false)
  const [loadingMountToWingletData, setLoadingMountToWingletData] =
    useState(false)

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      extensionTubeState.loadState(newState)
    }
  }, [projectResults, extensionTubeState, resultsLoaded])

  // Get product custom drop dimensions data
  useEffect(() => {
    if (!customDropDimensionsData?.length && !loadingCustomDropData) {
      setLoadingCustomDropData(true)
      axiosHelper({
        url: '/product-custom-drop-dimensions',
        method: 'get',
        success: (values: any) => {
          setLoadingCustomDropData(false)
          extensionTubeState.setCustomDropDimensionsData(values?.data)
        },
      })
    }
  }, [extensionTubeState, customDropDimensionsData, loadingCustomDropData])

  // Get product mount to bottom dimensions data
  useEffect(() => {
    if (!mountToBottomDimensionsData?.length && !loadingMountToBottomData) {
      setLoadingMountToBottomData(true)
      axiosHelper({
        url: '/product-mount-to-bottom-dimensions',
        method: 'get',
        success: (values: any) => {
          setLoadingMountToBottomData(false)
          extensionTubeState.setMountToBottomDimensionsData(values?.data)
        },
      })
    }
  }, [
    extensionTubeState,
    mountToBottomDimensionsData,
    loadingMountToBottomData,
  ])

  // Get product mount to winglet dimensions data
  useEffect(() => {
    if (!mountToWingletDimensionsData?.length && !loadingMountToWingletData) {
      setLoadingMountToWingletData(true)
      axiosHelper({
        url: '/product-mount-to-winglet-dimensions',
        method: 'get',
        success: (values: any) => {
          setLoadingMountToWingletData(false)
          extensionTubeState.setMountToWingletDimensionsData(values?.data)
        },
      })
    }
  }, [
    extensionTubeState,
    mountToWingletDimensionsData,
    loadingMountToWingletData,
  ])

  // Get product data points
  useEffect(() => {
    if (!productsData?.length && !loadingProductsData) {
      setLoadingProductsData(true)
      axiosHelper({
        url: '/product-data-points',
        method: 'get',
        success: (values: any) => {
          setLoadingProductsData(false)
          extensionTubeState.setProductsData(values?.data)
        },
      })
    }
  }, [extensionTubeState, productsData, loadingProductsData])

  // Get drop tube data
  useEffect(() => {
    if (!dropTubeData?.length && !loadingDropTubeData) {
      setLoadingDropTubeData(true)
      axiosHelper({
        url: '/drop-tubes',
        method: 'get',
        success: (values: any) => {
          setLoadingDropTubeData(false)
          extensionTubeState.setDropTubeData(values?.data)
        },
      })
    }
  }, [extensionTubeState, dropTubeData, loadingDropTubeData])

  // Get product drop tube options data
  useEffect(() => {
    if (!dropTubeOptionsData?.length && !loadingDropTubeOptionsData) {
      setLoadingDropTubeOptionsData(true)
      axiosHelper({
        url: '/product-drop-tube-options',
        method: 'get',
        success: (values: any) => {
          setLoadingDropTubeOptionsData(false)
          extensionTubeState.setDropTubeOptionsData(values?.data)
        },
      })
    }
  }, [extensionTubeState, dropTubeOptionsData, loadingDropTubeOptionsData])

  // Disable button until needed data is available
  const roofPitchUnknownIsValid = !!(
    roofPitch === 'none' &&
    buildingWidth &&
    eaveHeight &&
    peakHeight
  )
  const validRoofPitchInputs =
    roofPitch === 'none'
      ? roofPitchUnknownIsValid
      : roofPitch || roofPitch === 0

  const resultsDisabled =
    !product ||
    !deckHeight ||
    !diameter ||
    !mountingStructureHeight ||
    !validRoofPitchInputs

  const TITLE = 'Extension Tube Calculator'

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box component="form">
        <ToolHeader
          title={TITLE}
          onReset={() => extensionTubeState.reset()}
          toolState={{ ...extensionTubeState.getState(), toolName: TITLE }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          valid={!resultsDisabled}
          canSave={results}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => extensionTubeState.setLeadSaved(true)}
          onLeadsModalClose={() => true}
          printContent={() => printAreaRef.current}
          renderAfterActionButtons={() => (
            <Button
              variant="text"
              disableElevation
              disabled={resultsDisabled}
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => {
                extensionTubeState.setResults()
              }}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-Extension-Tube-Calc',
                        email,
                        product,
                        buildingWidth,
                        eaveHeight,
                        peakHeight,
                        diameter,
                        results,
                        isMetric,
                        deckHeight,
                        mountingStructureHeight,
                        roofPitch,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<ExtensionTubeCalcPdf />}
                    fileName="BAF-Extension-Tube-Calc.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />

        <Box ref={printAreaRef}>
          <PrintHeader title={TITLE} />
          <Grid container spacing={2}>
            <Grid
              item
              md={8}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <InputsCard
                onSelect={(value: string) =>
                  extensionTubeState.setFocusedInput(value)
                }
              />
            </Grid>
            {mediumBreakpoint && (
              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  '@media print': {
                    display: 'none',
                  },
                }}
              >
                <ImageCard focusedInput={focusedInput} />
              </Grid>
            )}
            <Grid item xs={12}>
              <ResultsCard />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default ExtensionTubeCalc
