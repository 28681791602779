import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Outlet, useLocation } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuIcon from '@mui/icons-material/Menu'

import { useGlobalState, useHeatIndexState, useTimestampState } from 'store'
import ScrollToTop from './ScrollToTop'
import AppMenu from 'components/AppMenu'
import SupportButton from 'components/SupportButton'
import { AppBar } from './helpers/styled'
import { useScheduleBuilderState } from 'store/scheduleBuilderState'
import {
  accessSalesToolsState,
  useSubmittalsState,
} from 'store/salesToolsState'
import Logo from 'components/Logo'
import RequiredPermission from 'components/RequiredPermission'

const Layout = () => {
  const globalState = useGlobalState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const timestampState = useTimestampState()
  const heatIndexState = useHeatIndexState()
  const scheduleBuilderState = useScheduleBuilderState()
  const submittalsState = useSubmittalsState()
  const salesToolsState = accessSalesToolsState()
  const smallBreakpoint = useMediaQuery('(min-width:600px)')

  const lastUpdated = timestampState.get().lastUpdated
  const { isLoading, user, loginWithRedirect, logout } = useAuth0()

  useEffect(() => {
    const dayDuration = 24 * 60 * 60 * 1000
    const now = new Date().getTime()
    const twoDays = now - 2 * dayDuration

    // if the user revisits after 2 days, reset globalState
    if (twoDays > lastUpdated) globalState.resetState()
    // if the user revisits after 1 day, reset all sales toolbox states
    if (now - dayDuration > lastUpdated) {
      salesToolsState.reset()
      heatIndexState.resetState()
      submittalsState.resetState()
      scheduleBuilderState.reset()
    }

    timestampState.setLastUpdated()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const open = globalState.getDesignGuideSidebarOpened()
  const handleDrawerToggle = () =>
    globalState.setDesignGuideSidebarOpened(!open)

  const { pathname } = useLocation()
  const allowPush = pathname === '/design-guide'
  const isDirectory = pathname === '/' || pathname?.includes('projects')

  const {
    // designGuidePaths,
    designGuideSidebarOpened,
  } = globalState.get()
  useEffect(() => {
    // if path or global state values change, set timestamp
    timestampState.setLastUpdated()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    // TODO: we cant use effect on global state, until this gets merged
    // FIXME: https://github.com/avkonst/hookstate/issues/140#issuecomment-1031400164
    // designGuidePaths,
    designGuideSidebarOpened,
  ])

  useEffect(() => {
    if (pathname.toLowerCase().indexOf('design-guide') === -1) {
      // clean up design guide paths for reuse on page visit

      const globalDesignGuidePaths = globalState.get().designGuidePaths

      const newPaths = globalDesignGuidePaths.map(pathObject => {
        const newPathObject = { ...pathObject }
        if (newPathObject?.active) newPathObject.active = false
        if (newPathObject?.opened) newPathObject.opened = false
        return newPathObject
      })

      globalState.setDesignGuidePaths(newPaths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={menuOpen}
      disableScrollLock={true}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <RequiredPermission permission="view:projects">
        <MenuItem onClick={handleClose}>
          <Link sx={{ textDecoration: 'none' }} href="/projects">
            Projects
          </Link>
        </MenuItem>
      </RequiredPermission>

      <MenuItem
        onClick={() => {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
          handleClose()
        }}
      >
        Sign Out
      </MenuItem>
    </Menu>
  )

  return (
    <ScrollToTop>
      <Box sx={{ display: allowPush ? 'flex' : 'block' }}>
        <CssBaseline />
        <AppBar position="fixed" color="inherit" open={open} push={allowPush}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Toolbar
              sx={{
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                // '1536px' is the max width of <Container maxWidth="xl" /> from MUI.
                maxWidth: isDirectory ? '1200px' : '1536px',
                pl: '0 !important',
                height: smallBreakpoint ? 'auto' : '85px',
              }}
            >
              <Stack direction="row" alignItems="center">
                {allowPush && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{ height: 40, ml: 1 }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                <Logo />
              </Stack>
              {/* TODO: https://mui.com/components/app-bar/#app-bar-with-search-field */}

              {!isLoading && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {user && (
                    <Box sx={{ mr: smallBreakpoint ? 0 : '-10px' }}>
                      <AppMenu />
                    </Box>
                  )}

                  {/* User avatar  */}
                  {user && smallBreakpoint && (
                    <Box
                      onClick={e => {
                        menuOpen ? handleClose() : handleClick(e)
                      }}
                      sx={{ display: 'flex', margin: '0 3px 0 4px !important' }}
                    >
                      <Box
                        sx={{
                          borderRadius: '50%',
                          overflow: 'hidden',
                          height: '40px',
                          width: '40px',
                          cursor: 'pointer',
                        }}
                      >
                        <img
                          src={user?.picture}
                          alt="User avatar"
                          height="40px"
                          width="40px"
                        />
                      </Box>
                      <Box sx={{ cursor: 'pointer', pt: 1, ml: 0 }}>
                        <ExpandMoreIcon sx={{ color: 'gray' }} />
                      </Box>
                      {renderMenu()}
                    </Box>
                  )}

                  {/* Sign in button */}
                  {!user && (
                    <Button
                      variant="text"
                      sx={{
                        backgroundColor: 'white',
                        width: user ? '75px' : '100px',
                        py: 1.5,
                        px: user ? 0 : 1.5,
                        fontFamily: 'industry-inc',
                        letterSpacing: '0.04em',
                        border: user ? 'none' : '1px solid #BCBEC0',
                      }}
                      onClick={() => loginWithRedirect()}
                    >
                      Sign In
                    </Button>
                  )}
                </Stack>
              )}
            </Toolbar>
          </Box>
        </AppBar>

        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />

        <SupportButton />
      </Box>
    </ScrollToTop>
  )
}

export default Layout
