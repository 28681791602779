import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store'

interface APIController {
  id: number
  label: string
  features: APIControllerFeature[]
}

interface APIControllerFeature {
  id: number
  feature: string
}

const ControllerSetup = () => {
  const submittalsState = useSubmittalsState()
  const { productData, numCols, validated, controllerFeatures } =
    submittalsState.getState()
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Controller Setup
        </Typography>
        <Grid container rowSpacing={0} columnSpacing={1} id="controllerSetup">
          <Grid item xs={24 / numCols}>
            <Autocomplete
              disablePortal
              size="small"
              options={productData.controllers.map(
                (c: APIController) => c.label
              )}
              getOptionLabel={option => option.toString()}
              sx={{ pt: 0 }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="Controller Type"
                  error={
                    validated && controllerFeatures.value.controller === null
                  }
                />
              )}
              value={controllerFeatures.value.controller}
              onChange={(event, newController) => {
                event.preventDefault()
                submittalsState.setFormValue(
                  'controllerFeatures',
                  'controller',
                  newController
                )
              }}
              inputValue={controllerFeatures.value.controller ?? ''}
            />
          </Grid>
          {controllerFeatures.value.controller === 'BAFCon (Advanced)' && (
            <Grid item xs={24 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={['Single', 'Multi']}
                sx={{ pt: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Single/Multi Fan Control"
                    error={
                      validated && controllerFeatures.value.singleMulti === null
                    }
                  />
                )}
                value={controllerFeatures.value.singleMulti}
                onChange={(event, newVal) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'controllerFeatures',
                    'singleMulti',
                    newVal
                  )
                }}
                inputValue={controllerFeatures.value.singleMulti ?? ''}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  )
}

export default ControllerSetup
