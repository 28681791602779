import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import StyledTextField from '../../../components/StyledTextField'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useSubmittalsState } from 'store/salesToolsState'

const ProjectDetails = () => {
  const submittalsState = useSubmittalsState()
  const { valid, projectDetails, numCols, validated } =
    submittalsState.getState()
  const hideEmpty = (field: string) =>
    valid && projectDetails.value[field] === ''
  const handleChange = (newDate: Date | null) => {
    submittalsState.setFormValue(
      'projectDetails',
      'date',
      newDate === null ? '' : newDate.toLocaleDateString()
    )
  }
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    submittalsState.setFormValue('projectDetails', name, value)
  }
  return (
    <Grid item xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Box display="inline-flex">
          <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
            Project Details
          </Typography>
          {(hideEmpty('architect') ||
            hideEmpty('engineer') ||
            hideEmpty('contractor') ||
            hideEmpty('tags')) && (
            <Button
              variant="outlined"
              size="large"
              onClick={() => submittalsState.setValid(false)}
              sx={{
                pt: 0,
                pb: 0,
                ml: 2,
                mt: -1,
                '@media print': { display: 'none' },
              }}
            >
              Show Hidden Fields
            </Button>
          )}
        </Box>
        <Grid container rowSpacing={0} columnSpacing={1} id="projectDetails">
          <Grid item xs={12 / numCols}>
            <StyledTextField
              required
              label="Project"
              name="project"
              value={projectDetails.value.project}
              onChange={handleInput}
              error={validated && projectDetails.value.project === ''}
            />
          </Grid>
          <Grid item xs={12 / numCols}>
            <StyledTextField
              required
              label="Location"
              name="location"
              value={projectDetails.value.location}
              onChange={handleInput}
              error={validated && projectDetails.value.location === ''}
            />
          </Grid>
          <Grid item xs={12 / numCols}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date"
                format="MM/dd/yyyy"
                value={new Date(projectDetails.value.date)}
                onChange={handleChange}
                disablePast
                minDate={new Date()}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    required: true,
                    size: 'small',
                    margin: 'dense',
                    type: 'text',
                    variant: 'standard',
                    sx: { width: '100%', mt: 0 },
                    error: validated && projectDetails.value.date === '',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12 / numCols}>
            <StyledTextField
              required
              label="Submitted by"
              name="submitter"
              value={projectDetails.value.submitter}
              onChange={handleInput}
              error={validated && projectDetails.value.submitter === ''}
            />
          </Grid>
          {!hideEmpty('architect') && (
            <Grid item xs={12 / numCols}>
              <StyledTextField
                label="Architect"
                name="architect"
                value={projectDetails.value.architect}
                onChange={handleInput}
              />
            </Grid>
          )}
          {!hideEmpty('engineer') && (
            <Grid item xs={12 / numCols}>
              <StyledTextField
                label="Engineer"
                name="engineer"
                value={projectDetails.value.engineer}
                onChange={handleInput}
              />
            </Grid>
          )}
          {!hideEmpty('contractor') && (
            <Grid item xs={12 / numCols}>
              <StyledTextField
                label="Contractor"
                name="contractor"
                value={projectDetails.value.contractor}
                onChange={handleInput}
              />
            </Grid>
          )}
          {!hideEmpty('tags') && (
            <Grid item xs={12 / numCols}>
              <StyledTextField
                label="Tags"
                name="tags"
                value={projectDetails.value.tags}
                onChange={handleInput}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  )
}

export default ProjectDetails
