import { SCHEDULE_BUILDER_VERSION } from 'config/constants'
import { printedNotes, printedNotesRange } from '../hooks/usePrintedNotes'

const buildCsv = (schedules: any, categories: string[]) => {
  const notes = printedNotes(schedules)
  const csv = []

  // Add title
  csv.push([`BAF Fan Schedule Builder v${SCHEDULE_BUILDER_VERSION}`])

  // Add empty row
  csv.push([])

  categories?.forEach(category => {
    // Add category title
    csv.push([category?.toUpperCase()], [])

    // Add headings
    csv.push([
      'TAG',
      'MANUFACTURER',
      'MODEL',
      'SIZE',
      'VOLT/PH',
      '# OF AIRFOILS',
      'WEIGHT (LBS)',
      'SOUND LEVEL (DBA)',
      'MOTOR',
      'MAX RPM',
      'CONTROL',
      'FULL LOAD CURRENT (AMPS)',
      'CIRCUIT SIZE (AMPS)',
      'NOTES',
    ])

    // Add data rows
    Object.keys(schedules).forEach((key: any) => {
      const data = schedules?.[key]
      if (data?.product?.category === category) {
        const notesRange = printedNotesRange({ notes, id: data?.product?.id })
        const productDetails = data?.productDetails || {}
        const productData = data?.productData || {}
        const sizeData = data?.sizeData || {}
        const mountData = data?.mountData || {}
        const voltageData = data?.voltageData || {}
        const { voltage } = productDetails?.value

        // tag
        const tag = productDetails?.value?.tag

        // name
        const name = data?.product?.name

        // size
        const size = data?.sizeData?.size

        // voltage
        const _voltage = data?.voltageData?.voltage

        // num_airfoils
        const numAirfoils =
          productData?.num_airfoils === -1
            ? ''
            : productData?.num_airfoils ?? 'N/A'

        // weight
        const weight = `${
          sizeData?.weight !== '' && sizeData?.weight ? sizeData?.weight : ''
        } ${sizeData?.size !== '' && mountData.weight ? mountData.weight : ''}`

        // max_sound
        const maxSound =
          sizeData?.size !== '' && sizeData?.max_sound?.includes('dBA')
            ? sizeData?.max_sound?.substring(
                0,
                sizeData?.max_sound?.indexOf('dBA')
              )
            : '---'

        // motor
        const motor =
          (sizeData?.size !== '' &&
            (sizeData?.motor || sizeData?.schedule_builder_motor) &&
            sizeData?.schedule_builder_motor) ??
          sizeData?.motor

        // max_rpm
        const maxRpm =
          (sizeData?.size !== '' && voltage && sizeData?.rpm) ??
          voltageData?.rpm ??
          mountData?.rpm ??
          'N/A'

        // control
        const control = data?.productData?.schedule_builder_controller ?? 'N/A'

        // full_load_current
        const fullLoadCurrent = `${
          (sizeData?.size !== '' &&
            voltage &&
            (voltageData?.max_amps || voltageData?.full_load_amperage) &&
            voltageData?.max_amps) ??
          voltageData?.full_load_amperage
        } ${
          (sizeData?.size !== '' &&
            voltage &&
            !voltageData?.max_amps &&
            !voltageData?.full_load_amperage &&
            mountData?.max_watts) ??
          voltageData?.max_watts
        }`

        // circuit_size
        const circuitSize =
          (sizeData?.size !== '' &&
            voltage &&
            voltageData.supply_circuit_size) ??
          'N/A'

        csv.push([
          tag,
          'Big Ass Fans',
          name, // MANUFACTURER
          // Double quotes cause problems in CSVs, so we're removing them for
          // both the `size` and `_voltage`.
          size.replace(/"/g, '""'), // SIZE
          _voltage.replace(/"/g, '""'), // VOLT/PH
          numAirfoils, // # OF AIRFOILS
          weight, // WEIGHT (LBS)
          maxSound, // SOUND LEVEL (DBA)
          motor, // MOTOR
          maxRpm, // MAX RPM
          control, // CONTROL
          // The `false` string comes out of `fullLoadCurrent`, so we're removing
          // the string in case it pops up.
          fullLoadCurrent.replace('false', ''), // FULL LOAD CURRENT (AMPS)
          circuitSize, // CIRCUIT SIZE (AMPS)
          // Excel likes to convert the range (i.e. 1-13) to a date, so this
          // just adds a space in the front to avoid that.
          ` ${notesRange}`,
        ])
      }
    })

    // Add Notes section
    csv.push([], ['NOTES:'])

    const _notes = notes.filter(note => note.category === category)
    _notes.map((n: any) => csv.push([n.note]))

    // Add ending empty rows
    csv.push([], [], [])
  })

  return csv
}

export default buildCsv
