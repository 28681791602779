import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import MainChapter from './MainChapter'
import { StyledMain } from './helpers/styled'

import { data } from '../helpers/data'

import { useGlobalState } from 'store'
import { AppBarSpacer } from 'features/layout/helpers/styled'

const Main = () => {
  const globalState = useGlobalState()
  const open = globalState.getDesignGuideSidebarOpened()

  const mappedMainChapters = data.map((chapter, index) => (
    <MainChapter
      key={index}
      chapterObj={chapter}
      headerPrefix={`${index + 1}`}
      path={`chapter-${index + 1}`}
    />
  ))

  return (
    <StyledMain open={open}>
      <AppBarSpacer />
      <Container>
        <Typography variant="h1">BAF Ceiling Fan Design Guide</Typography>
        {mappedMainChapters}
      </Container>
    </StyledMain>
  )
}

export default Main
