import uniqBy from 'lodash/uniqBy'
import {
  APIProductData,
  FormState,
  useScheduleBuilderState,
} from 'store/scheduleBuilderState'

export interface APIScheduleBuilderNote {
  id: number
  note: string
}
export interface APIScheduleBuilderLink {
  id: number
  link: string
  name: string
}
interface UseNotesArgs {
  id: number
  combineNotesBy?: 'none' | 'id' | 'category'
}
interface UseNotesReturn {
  hasNotes: boolean
  hasLinks: boolean
  notes: APIScheduleBuilderNote[]
  builderLinks: APIScheduleBuilderLink[]
  productData: APIProductData
  productDetails: FormState
}

export function useNotes({
  id,
  combineNotesBy = 'none',
}: UseNotesArgs): UseNotesReturn {
  const scheduleBuilderState = useScheduleBuilderState()
  const { schedules } = scheduleBuilderState.getState()
  let _schedules = schedules

  if (combineNotesBy !== 'none') {
    _schedules = uniqBy(
      Object.values(schedules),
      (s: any) => s.product[combineNotesBy]
    )
  }

  const { productData, productDetails } = _schedules?.[id] || {}
  const notes = productData?.schedule_builder_notes || []
  const builderLinks = productData?.schedule_builder_links || []

  const hasNotes = !!notes?.[0]
  const hasLinks = !!builderLinks?.[0]

  return {
    hasNotes,
    hasLinks,
    notes,
    builderLinks,
    productData,
    productDetails,
  }
}
