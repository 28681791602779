import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import StyledTextField from 'components/StyledTextField'

import { useEmployeeSavingsState } from 'store'

const InputsTile = ({ region }: { region: string }) => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const employeeSavingsState = useEmployeeSavingsState()
  const {
    airSpeedCoolingEffect,
    averageHours,
    averageWage,
    heatingSetPoint,
    coolingSetPoint,
    isCooled,
    isHeated,
    numOfEmployees,
    radiantHeatEffect,
    solution,
  } = employeeSavingsState.get()

  return (
    <Card variant="outlined" sx={{ p: 4 }}>
      <Box sx={{ minHeight: '520px' }}>
        <Box display="inline-flex">
          <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
            Inputs
          </Typography>
        </Box>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={1}
          direction={mediumBreakpoint ? 'row' : 'column'}
        >
          <Grid item xs={6}>
            <StyledTextField
              required
              helperText="Select from the map or search your ZIP code on the right"
              label="Climate Zone"
              name="region"
              value={region}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" sx={{ width: '100%', mt: -0.5 }}>
              <InputLabel>Solution</InputLabel>
              <Select
                variant="standard"
                label="Solution"
                required
                value={solution || ''}
                onChange={(e: any) => {
                  employeeSavingsState.setSolution(e?.target?.value)
                }}
                sx={{
                  width: '100%',
                }}
              >
                <MenuItem value="Fans">Fans</MenuItem>
                <MenuItem value="Radiant Heaters">Radiant Heaters</MenuItem>
                <MenuItem value="Fans + Radiant Heaters">
                  Fans + Radiant Heaters
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {solution && solution !== 'Radiant Heaters' && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTop: '1px solid gray',
                borderBottom: '1px solid gray',
                mt: 2,
                mb: 3,
                py: 0.5,
              }}
            >
              <Typography variant="h6">Cooling Temperature Inputs</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCooled}
                    onChange={() => employeeSavingsState.setIsCooled(!isCooled)}
                  />
                }
                label="Air Conditioned"
              />
            </Box>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={1}
              direction={mediumBreakpoint ? 'row' : 'column'}
            >
              <Grid item xs={6}>
                <StyledTextField
                  required
                  type="number"
                  label="Cooling Effect Due To Elevated Air Speed (F)"
                  name="coolingEffect"
                  value={airSpeedCoolingEffect || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    employeeSavingsState.setAirSpeedCoolingEffect(
                      parseInt(e?.target?.value)
                    )
                  }}
                />
              </Grid>
              {isCooled && (
                <Grid item xs={6}>
                  <StyledTextField
                    required
                    type="number"
                    label="Cooling Set Point (F)"
                    name="cooling-set-point"
                    value={coolingSetPoint || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      employeeSavingsState.setCoolingSetPoint(
                        parseInt(e?.target?.value)
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}

        {solution && solution !== 'Fans' && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTop: '1px solid gray',
                borderBottom: '1px solid gray',
                mt: 2,
                mb: 3,
                py: 0.5,
              }}
            >
              <Typography variant="h6">Heating Temperature Inputs</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isHeated}
                    onChange={() => employeeSavingsState.setIsHeated(!isHeated)}
                  />
                }
                label="Heated"
              />
            </Box>

            <Grid
              container
              rowSpacing={0}
              columnSpacing={1}
              direction={mediumBreakpoint ? 'row' : 'column'}
            >
              <Grid item xs={6}>
                <StyledTextField
                  required
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 15 } }}
                  label="Heating Effect Due to Radiant Heaters (F)"
                  name="heatingEffect"
                  value={radiantHeatEffect || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    employeeSavingsState.setRadiantHeatEffect(
                      parseInt(e?.target?.value)
                    )
                  }}
                />
              </Grid>
              {isHeated && (
                <Grid item xs={6}>
                  <StyledTextField
                    required
                    type="number"
                    label="Heating Set Point (F)"
                    name="heating-set-point"
                    value={heatingSetPoint || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      employeeSavingsState.setHeatingSetPoint(
                        parseInt(e?.target?.value)
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid gray',
            borderBottom: '1px solid gray',
            mt: 2,
            mb: 3,
            py: 1.5,
          }}
        >
          <Typography variant="h6">Personnel Inputs</Typography>
        </Box>

        <Grid
          container
          rowSpacing={0}
          columnSpacing={1}
          direction={mediumBreakpoint ? 'row' : 'column'}
        >
          <Grid item xs={6}>
            <StyledTextField
              required
              label="Number of Employees"
              name="number-of-employees"
              value={numOfEmployees || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                employeeSavingsState.setNumOfEmployees(
                  parseInt(e?.target?.value)
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              required
              label="Average Hourly Wage ($)"
              name="average-hourly-wage"
              value={averageWage || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                employeeSavingsState.setAverageWage(parseInt(e?.target?.value))
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={1}
          direction={mediumBreakpoint ? 'row' : 'column'}
        >
          <Grid item xs={6} sx={{ mt: 1 }}>
            <StyledTextField
              required
              label="Average Operating Hours"
              name="average-operating-hours"
              value={averageHours || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                employeeSavingsState.setAverageHours(parseInt(e?.target?.value))
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default InputsTile
