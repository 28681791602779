import { View } from '@react-pdf/renderer'

const PdfDivider = ({ styles = {} }: { styles?: any }) => (
  <View
    style={{
      width: '100%',
      borderBottom: '1px solid gray',
      ...styles,
    }}
  ></View>
)

export default PdfDivider
