import { useState } from 'react'

import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Box from '@mui/system/Box'

import { useUserState } from 'store'
import { axiosHelper } from 'utils'

interface Metadata {
  reasonType: string
  userType: string
}

interface Props {
  userId: string | null
  open: boolean
  onSuccess: (metadata: Metadata) => void
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '3%',
}

const AdditionalFields = ({ userId, onSuccess, open }: Props) => {
  const userState = useUserState()
  const [userType, setUserType] = useState<string>('none')
  const [reasonType, setReasonType] = useState<string>('none')
  const formIsComplete = reasonType !== 'none' && userType !== 'none'

  const updateUserMetadata = () => {
    if (!userId || !formIsComplete) return
    axiosHelper({
      url: `/users/${userId}`,
      method: 'patch',
      data: {
        userType,
        reasonType,
      },
      success: (data: any) => {
        userState.setHasSetMetadata()
        onSuccess(data?.data?.user_metadata)
      },
    })
  }

  return (
    <Modal open={open}>
      <Box sx={modalStyle}>
        <Typography variant="h4" sx={{ mt: 0, mb: 2 }}>
          Finalize your account
        </Typography>

        <Typography variant="h6" sx={{ mt: 1, mb: -1 }}>
          I am a(n)…
        </Typography>
        <Select
          value={userType}
          onChange={e => setUserType(e.target.value)}
          sx={{
            mt: 1,
            width: '100%',
          }}
        >
          <MenuItem value="none" disabled>
            Select an option
          </MenuItem>
          <MenuItem value={'Architect'}>Architect</MenuItem>
          <MenuItem value={'Engineer'}>Engineer</MenuItem>
          <MenuItem value={'Building Owner/Tenant'}>
            Building Owner/Tenant
          </MenuItem>
          <MenuItem value={'BAF Partner'}>BAF Partner</MenuItem>
        </Select>

        <Typography variant="h6" sx={{ mt: 2, mb: -1 }}>
          My reason for using this site is…
        </Typography>
        <Select
          value={reasonType}
          onChange={e => setReasonType(e.target.value)}
          sx={{
            mt: 1,
            width: '100%',
          }}
        >
          <MenuItem value="none" disabled>
            Select an option
          </MenuItem>
          <MenuItem value={'Business'}>Business</MenuItem>
          <MenuItem value={'Personal'}>Personal</MenuItem>
        </Select>

        <Button
          disableElevation
          disabled={!formIsComplete}
          size="large"
          sx={{
            mt: 4,
            pt: 0.5,
            pb: 0.5,
            width: '100%',
            backgroundColor: 'secondary.main',
          }}
          onClick={() => updateUserMetadata()}
        >
          Create Account
        </Button>
      </Box>
    </Modal>
  )
}

export default AdditionalFields
