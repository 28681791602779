import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns-tz'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import RenameProjectsModal from './RenameProjectsModal'
import DeleteProjectModal from './DeleteProjectModal'

import { useProjects } from 'hooks/useProjects'
import { axiosHelper } from 'utils'

const ProjectResultsBanner = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const { projects } = useProjects({
    needsUpdate,
    onUpdate: setNeedsUpdate,
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const menuOpen = Boolean(anchorEl)

  // Get project associated with project results
  const project = projects?.find(
    (project: any) => id && project?.id === parseInt(id)
  )

  // Menu click handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={menuOpen}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        onClick={() => {
          setModalOpen(true)
          handleClose()
        }}
      >
        Rename
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (id) {
            axiosHelper({
              url: `/projects/${id}`,
              method: 'patch',
              data: {
                id,
                archived: 1,
              },
              success: () => {
                setAlertMessage('Archived!')
                setAlertOpen(true)
              },
            })
          }
          handleClose()
        }}
      >
        Archive
      </MenuItem>
      <MenuItem
        onClick={() => {
          setDeleteModalOpen(true)
          handleClose()
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'primary.main',
        height: '120px',
        mt: 11,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'primary.main',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', py: 2 }}>
          <Link
            to="/projects"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Typography
              variant="body2"
              sx={{
                mb: 0,
                color: 'secondary.main',
                fontSize: '12px',
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowBackIcon sx={{ fontSize: '14px' }} />
              All Projects
            </Typography>
          </Link>
          <Typography variant="h3" sx={{ color: 'white' }}>
            {project?.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 0.5, color: 'grey', fontSize: '12px' }}
          >
            {format(new Date(project?.created_at || Date.now()), 'Pp')?.replace(
              ',',
              ''
            )}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="outlined"
            disableElevation
            onClick={e => handleClick(e)}
            size="large"
            sx={{
              backgroundColor: 'primary.main',
              pr: 1,
              pl: 1.5,
              ml: 1,
              color: 'white',
              height: '36px',
              fontSize: '12px',
              border: '1px solid white',
              fontFamily: 'industry-inc',
              letterSpacing: '0.04em',
              '&:hover': {
                backgroundColor: '#A9A9A9',
                color: '#fff',
              },
            }}
          >
            Options
            <ExpandMoreIcon />
          </Button>
          {renderMenu()}
        </Box>
      </Container>

      <RenameProjectsModal
        project={project}
        onUpdate={() => {
          setNeedsUpdate(true)
          setAlertMessage('Renamed!')
          setAlertOpen(true)
        }}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
      />

      <DeleteProjectModal
        project={project}
        onUpdate={() => {
          setAlertMessage('Deleted!')
          setAlertOpen(true)
        }}
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false)
          setTimeout(() => {
            navigate('/projects')
          }, 2000)
        }}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Box>
  )
}

export default ProjectResultsBanner
