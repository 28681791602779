import { useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import Checkbox from '@mui/material/Checkbox'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { useHeatIndexState } from 'store/heatIndexState'

interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle sx={{ offset: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const ScheduleModal = () => {
  const heatIndexState = useHeatIndexState()
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    heatIndexState.setNeedsUpdate(true)
  }

  const customSchedule =
    heatIndexState.get().projectDetails.value.customSchedule
  const hasTimeSelected =
    customSchedule.end.value !== -1 && customSchedule.start.value !== -1
  const hours = [
    '',
    'Midnight',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    'Noon',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM',
  ]
  return (
    <div>
      <IconButton onClick={handleClickOpen} sx={{ p: 0 }}>
        <CalendarTodayIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <BootstrapDialogTitle id="scheduleDialog" onClose={handleClose}>
          <div>
            <Typography variant="h4">Custom Occupancy Schedule</Typography>
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            Occupied Days (Select all days your facility is occupied)
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule('Monday', event.target.checked)
                  }}
                  checked={customSchedule.Monday}
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule('Tuesday', event.target.checked)
                  }}
                  checked={customSchedule.Tuesday}
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule(
                      'Wednesday',
                      event.target.checked
                    )
                  }}
                  checked={customSchedule.Wednesday}
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule('Thursday', event.target.checked)
                  }}
                  checked={customSchedule.Thursday}
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule('Friday', event.target.checked)
                  }}
                  checked={customSchedule.Friday}
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule('Saturday', event.target.checked)
                  }}
                  checked={customSchedule.Saturday}
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={event => {
                    heatIndexState.setSchedule('Sunday', event.target.checked)
                  }}
                  checked={customSchedule.Sunday}
                />
              }
              label="Sunday"
            />
          </FormGroup>
          <Typography variant="h6" sx={{ mt: 1, mb: -1 }}>
            Hours of Occupancy
          </Typography>
          <Stack direction="row" spacing={3}>
            <Autocomplete
              disablePortal
              disableClearable
              value={customSchedule.start.label}
              onChange={(e, newVal) =>
                heatIndexState.setSchedule('start', {
                  label: newVal,
                  value: hours.indexOf(newVal) - 1,
                })
              }
              inputValue={customSchedule.start.label}
              options={hours}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  margin="dense"
                  size="small"
                  label="Start of Occupancy"
                />
              )}
              sx={{ width: '100%' }}
            />
            <Autocomplete
              disablePortal
              disableClearable
              value={customSchedule.end.label}
              onChange={(e, newVal) =>
                heatIndexState.setSchedule('end', {
                  label: newVal,
                  value: hours.indexOf(newVal) - 1,
                })
              }
              inputValue={customSchedule.end.label}
              options={hours}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  margin="dense"
                  size="small"
                  label="End of Occupancy"
                />
              )}
              sx={{ width: '100%' }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={!hasTimeSelected} autoFocus onClick={handleClose}>
            Save Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ScheduleModal
