import { Text, View, StyleSheet } from '@react-pdf/renderer'

const PdfInputSection = ({
  inputs = [],
  title = 'Inputs',
}: {
  inputs: any
  title?: string
}) => {
  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '16px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '4px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    cell: {
      flexDirection: 'column',
      width: '25%',
      marginTop: '8px',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
  })

  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>{title}</Text>

      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {inputs?.map((input: any) => (
          <View style={styles.cell}>
            <Text style={styles.label}>{input.title}</Text>
            <Text style={styles.value}>{input.value}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default PdfInputSection
