import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

interface LogoProps {
  name: string
}

const Logo = ({ name }: LogoProps) => (
  <Stack
    direction={{ xs: 'column', md: 'row' }}
    alignItems="center"
    justifyContent="space-between"
  >
    <Box
      component="img"
      height={60}
      sx={{ mr: 2 }}
      alt="BAF logo"
      src="https://design-guide-images.s3.us-east-2.amazonaws.com/BAF-Logo.svg"
    />
    <Divider orientation="vertical" variant="middle" flexItem />
    <Typography variant="h3" sx={{ ml: 2, mb: -1 }}>
      {name}
    </Typography>
  </Stack>
)

export default Logo
