import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import Typography from '@mui/material/Typography'

import FormCard from 'components/FormCard'
import StyledTextField from 'components/StyledTextField'

import { useDestratToolState } from 'store'
import { useProducts } from 'hooks/useProducts'

const InputsTile = ({ loading = false }: { loading: boolean }) => {
  const { products } = useProducts()
  const destratToolState = useDestratToolState()
  const {
    bafContact,
    airfoilHeight,
    isBasedOnInputs,
    projectName,
    designAirTurnovers,
    isMetric,
    installLocation,
    projectDate,
    width,
    length,
    floorToCeilingHeight,
    product,
  } = destratToolState.get()

  // // Build product select options
  const productOptions = products?.filter((product: any) => {
    const includedProducts = [
      'Powerfoil 8',
      'Powerfoil 8 Plus',
      'Powerfoil D',
      'Powerfoil X4',
      'Powerfoil X4 Plus',
      'Basic 6',
      'Hornet',
      'Essence',
      'Haiku',
      'Haiku L',
      'i6',
      'es6',
      'Pivot 2.0',
      'Speakeasy'
    ]
    return includedProducts?.includes(product?.name)
  })

  return (
    <FormCard>
      <Box sx={{ pb: 2 }}>
        <Box
          display="inline-flex"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignContent: 'center',
            borderBottom: '1px solid black',
            py: 1,
          }}
        >
          <Typography variant="h5" sx={{ mt: 0, mb: 0 }}>
            Inputs
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              mt: -1,
              '@media print': {
                display: 'none',
              },
            }}
          >
            Standard
            <Switch
              checked={isMetric}
              onChange={() => destratToolState.setIsMetric(!isMetric)}
            />
            Metric
          </Stack>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6" sx={{ mt: 2 }}>
              PROJECT INFORMATION
            </Typography>
            <Box display="flex" flexDirection={'column'}>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    required
                    helperText=""
                    label="Project Name"
                    name="project-name"
                    value={projectName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setProjectName(value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    required
                    helperText=""
                    label="Location"
                    name="location"
                    value={installLocation}
                    onChange={(e: any) => {
                      destratToolState.setInstallLocation(e?.target?.value)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date"
                      format="MM/dd/yyyy"
                      value={new Date(projectDate)}
                      onChange={(newDate: any) => {
                        const date = newDate.toLocaleDateString()
                        destratToolState.setProjectDate(date)
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          size: 'small',
                          margin: 'dense',
                          type: 'text',
                          variant: 'standard',
                          sx: { width: '100%', mt: 0 },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    required
                    helperText=""
                    label="BAF Contact"
                    name="baf-contact"
                    value={bafContact}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setBafContact(value)
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h6" sx={{ mt: 4 }}>
              CALCULATIONS
            </Typography>
            <Box display="flex" flexDirection={'column'}>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText={isMetric ? 'Meters' : 'Feet'}
                    label="Length"
                    name="length"
                    value={length || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setLength(parseFloat(value))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText={isMetric ? 'Meters' : 'Feet'}
                    label="Width"
                    name="width"
                    value={width || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setWidth(parseFloat(value))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText={isMetric ? 'Meters' : 'Feet'}
                    label="Floor to Ceiling Height"
                    name="floor-to-ceiling-height"
                    value={floorToCeilingHeight || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setFloorToCeilingHeight(
                        parseFloat(value)
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText={isMetric ? 'Meters' : 'Feet'}
                    label="Airfoil Height"
                    name="airfoil-height"
                    value={airfoilHeight || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setAirfoilHeight(parseFloat(value))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    variant="standard"
                    sx={{ width: '100%', mt: -0.5 }}
                  >
                    <InputLabel>Fan Model</InputLabel>
                    <Select
                      variant="standard"
                      label="Product"
                      required
                      value={product?.name || ''}
                      onChange={(e: any) => {
                        const fan = products?.find(
                          (fan: any) => fan?.name === e?.target?.value
                        )
                        console.log('e: ', fan)
                        destratToolState.setProduct(fan)
                      }}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {productOptions?.map((option: any) => (
                        <MenuItem key={option?.name} value={option?.name}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText="Per hour"
                    disabled={isBasedOnInputs}
                    label="Design Air Turnovers"
                    name="design-air-turnovers"
                    value={designAirTurnovers || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      destratToolState.setDesignAirTurnovers(parseFloat(value))
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    sx={{ mt: 1 }}
                    control={
                      <Checkbox
                        checked={isBasedOnInputs}
                        onChange={() =>
                          destratToolState.setIsBasedOnInputs(!isBasedOnInputs)
                        }
                      />
                    }
                    label="Calculate based on fan input quantities"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </FormCard>
  )
}

export default InputsTile
