import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import FormCard from 'components/FormCard'

import { useFanEnergyEstimatorState } from 'store'
import { Product } from 'store/fanEnergyEstimatorState'

const FansTile = ({
  isAireyeMode = false,
  loading = false,
}: {
  isAireyeMode: boolean
  loading: boolean
}) => {
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const { products, bafFans, existingFans } = fanEnergyEstimatorState.get()

  // Build product select options
  const bafFanOptions = products?.filter((product: Product) => {
    const isBafFan = product?.product_id !== -1 && product?.product_id !== -2
    const isAireye = product?.name?.includes('Aireye')
    const onlyOneProduct = bafFans?.length === 1

    // Always show all options if only first input
    if (onlyOneProduct) return isBafFan

    // Only return Aireye products inAireyeMode
    if (isAireyeMode) return isAireye && isBafFan

    // Return all products that aren't Aireye
    return !isAireye && isBafFan
  })

  const filteredExistingFans = products?.filter(
    (product: Product) => product?.product_id === -1
  )
  const filteredExistingAireyeFans = products?.filter(
    (product: Product) => product?.product_id === -2
  )
  const existingFanOptions = isAireyeMode
    ? filteredExistingAireyeFans
    : filteredExistingFans

  const renderBafProduct = (fan: any, index: number) => {
    return (
      <Box
        key={`${fan?.name}-${index}`}
        display="inline-flex"
        sx={{ width: '100%', mt: 1 }}
      >
        <Select
          required
          value={fan?.name}
          onChange={(e: any) => {
            const value = e?.target?.value
            if (!value) return
            const product = products?.find(
              (product: Product) => product?.name === value
            )
            fanEnergyEstimatorState.setBafFan(product, index)

            // Remove all existing fans if selecting first product
            if (bafFans?.length === 1) {
              fanEnergyEstimatorState.resetExistingFans()
            }
          }}
          sx={{ width: '82%' }}
        >
          {bafFanOptions?.map((option: Product) => (
            <MenuItem key={option?.name} value={option?.name}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          required
          type="number"
          InputProps={{ inputProps: { min: 0, max: 9001 } }}
          label={index === 0 ? 'Quantity' : ''}
          name="quantity"
          sx={{ ml: 1, width: '10%' }}
          value={fan?.count || 1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const count = e?.target?.value
            fanEnergyEstimatorState.setBafFanQuantity(parseInt(count), index)
          }}
        />
        {index > 0 && (
          <HighlightOffIcon
            sx={{
              fontSize: '32px',
              cursor: 'pointer',
              color: 'red',
              mt: 1,
              ml: 1,
            }}
            onClick={() => {
              fanEnergyEstimatorState.removeBafFan(index)
            }}
          />
        )}
      </Box>
    )
  }

  const renderExistingProduct = (fan: any, index: number) => {
    return (
      <Box
        key={`${fan?.name}-${index}`}
        display="inline-flex"
        sx={{ width: '100%', mt: 1 }}
      >
        <Select
          required
          value={fan?.name}
          onChange={(e: any) => {
            const value = e?.target?.value
            if (!value) return
            const product = products?.find(
              (product: Product) => product?.name === value
            )
            fanEnergyEstimatorState.setExistingFan(product, index)
          }}
          sx={{ width: '82%' }}
        >
          {existingFanOptions?.map((option: Product, index: number) => (
            <MenuItem key={`${option?.name}-${index}`} value={option?.name}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          required
          type="number"
          InputProps={{ inputProps: { min: 0, max: 9001 } }}
          label={index === 0 ? 'Quantity' : ''}
          name="quantity"
          sx={{ ml: 1, width: '10%' }}
          value={fan?.count || 1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const count = e?.target?.value
            fanEnergyEstimatorState.setExistingFanQuantity(
              parseInt(count),
              index
            )
          }}
        />
        <HighlightOffIcon
          sx={{
            fontSize: '32px',
            cursor: 'pointer',
            color: 'red',
            mt: 1,
            ml: 1,
          }}
          onClick={() => {
            fanEnergyEstimatorState.removeExistingFan(index)
          }}
        />
      </Box>
    )
  }

  return (
    <FormCard>
      <Box sx={{ pb: 2 }}>
        <Box display="inline-flex">
          <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
            Fans
          </Typography>
        </Box>

        {!products?.length && loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection={'column'}>
              <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                Big Ass Fans
              </Typography>

              {/* Render BAF product rows */}
              {bafFans?.map((fan: any, index: number) =>
                renderBafProduct(fan, index)
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  width: '93%',
                }}
              >
                <Button
                  variant="outlined"
                  disableElevation
                  size="large"
                  sx={{
                    pt: 0.5,
                    pb: 0.5,
                    width: existingFans?.length ? '100%' : '49%',
                    border: '1px solid #FFC425',
                  }}
                  onClick={() =>
                    fanEnergyEstimatorState.setBafFan(
                      bafFanOptions?.[0],
                      bafFans?.length || 0
                    )
                  }
                >
                  Add Big Ass Fan
                </Button>
                {!existingFans?.length && (
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    sx={{
                      pt: 0.5,
                      pb: 0.5,
                      width: '49%',
                      border: '1px solid #FFC425',
                    }}
                    onClick={() =>
                      fanEnergyEstimatorState.setExistingFan(
                        existingFanOptions?.[0],
                        existingFans?.length
                      )
                    }
                  >
                    Add Existing Fan
                  </Button>
                )}
              </Box>
            </Box>

            {/* Render existing  product rows */}
            {!!existingFans?.length && (
              <Box display="flex" flexDirection={'column'}>
                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                  Existing Fans
                </Typography>
                {existingFans?.map((fan: any, index: number) =>
                  renderExistingProduct(fan, index)
                )}
                <Box
                  sx={{
                    mt: 2,
                    width: '93%',
                  }}
                >
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    sx={{
                      pt: 0.5,
                      pb: 0.5,
                      width: '100%',
                      border: '1px solid #FFC425',
                    }}
                    onClick={() =>
                      fanEnergyEstimatorState.setExistingFan(
                        existingFanOptions?.[0],
                        existingFans?.length
                      )
                    }
                  >
                    Add Existing Fan
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </FormCard>
  )
}

export default FansTile
