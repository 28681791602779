import React, { useState } from 'react'
import * as d3 from 'd3'

import Alert, { AlertColor } from '@mui/material/Alert'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'

interface State {
  open: boolean
  severity: AlertColor
  message: string
}

const ZipCodeInput = ({
  state,
  onChange,
}: {
  state: any
  onChange: (zip: string) => any
}) => {
  const [alertState, setAlertState] = useState<State>({
    open: false,
    severity: 'info',
    message: '',
  } as State)
  const { severity, message, open } = alertState
  const { zip } = state.get()
  const zipcodes = require('zipcodes-nrviens')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    state?.setZip(event.target.value)
  }
  const findCounty = () => {
    let fips = zipcodes.lookup(zip).fips
    if (zip.length !== 5) {
      setAlertState({
        open: true,
        severity: 'error',
        message: `This ZIP code does not belong to the continental United States.
      Please enter a different ZIP code or select a region from the map.`,
      })
      state?.setZip('')
    }
    d3.csv('./map-files/counties.csv')
      .then(data => {
        for (let i = 0; i < data.length; i++) {
          if (!!fips) {
            if (data[i].code === fips.toString()) {
              state.setRegion(data[i].region ?? '')
              state?.setZip(zip)
              setAlertState({
                open: true,
                severity: 'info',
                message: `The ZIP code you entered is in ${data[i].name} County, ${data[i].state}. That is in climate zone ${data[i].region}.`,
              })
            }
          } else {
            setAlertState({
              open: true,
              severity: 'error',
              message: `This ZIP code does not belong to the continental United States.
            Please enter a different ZIP code or select a region from the map.`,
            })
            state?.setZip('')
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setAlertState({ ...alertState, open: false })
        }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      <TextField
        label="Find Your Region"
        variant="standard"
        size="small"
        placeholder="Enter ZIP code..."
        onChange={handleChange}
        onKeyDown={e => {
          if (e.key === 'Enter') findCounty()
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={findCounty} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={zip || ''}
      />
    </Box>
  )
}

export default ZipCodeInput
