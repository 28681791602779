import { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppBarSpacer } from '../layout/helpers/styled'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import Results from './components/Results'
import Notes from './components/Notes'
import InputsTile from './components/InputsTile'

import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { usePermissions } from 'hooks/usePermissions'
import { useProjectResults } from 'hooks/useProjectResults'
import { useAcSavingsState } from 'store'
import { axiosHelper } from 'utils'

import AcSavingsCalcPdf from './AcSavingsCalcPdf'

const AcSavingsCalc = ({ standalone }: { standalone?: boolean }) => {
  const { user } = usePermissions()
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const acSavingsState = useAcSavingsState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const componentRef = useRef<HTMLDivElement>(null)

  const {
    bafFans,
    existingFans,
    leadSaved,
    products,
    results,
    citiesAgroZonesData,
    emData,
    climateRegionsData,
    locationData,
    locationCostsData,
    primaryUsesData,

    // inputs
    companyName,
    ceilingHeight,
    thermostatOffset,
    installLocation,
    primaryUse,
    floorArea,
    airConditioned,
    energyDataSource,
    operatingHours,
  } = acSavingsState.get()

  // Data loading states
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false)
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false)
  const [loadingPrimaryUses, setLoadingPrimaryUses] = useState<boolean>(false)
  const [loadingEmData, setLoadingEmData] = useState<boolean>(false)
  const [loadingClimateRegions, setLoadingClimateRegions] =
    useState<boolean>(false)
  const [loadingLocationCosts, setLoadingLocationCosts] =
    useState<boolean>(false)
  const [loadingCitiesAgroZones, setLoadingCitiesAgroZones] =
    useState<boolean>(false)

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      acSavingsState.loadState(newState)
    }
  }, [projectResults, acSavingsState, resultsLoaded])

  // Get product data
  useEffect(() => {
    if (!products?.length && !loadingProducts) {
      setLoadingProducts(true)
      axiosHelper({
        url: '/product-power-consumptions',
        method: 'get',
        success: (values: any) => {
          // Format data
          const productData = values?.data?.map((datum: any) => ({
            ...datum,
            value: datum?.name,
          }))

          // Add initial staged fan
          if (!bafFans?.length) {
            const product = { ...productData[0], count: 1 }
            acSavingsState.setBafFan(product, 0)
          }

          // Add product options
          setLoadingProducts(false)
          acSavingsState.setProducts(productData)
        },
      })
    }
  }, [acSavingsState, products, loadingProducts, bafFans])

  // Get location ac savings data
  useEffect(() => {
    if (!locationData?.length && !loadingLocations) {
      setLoadingLocations(true)
      axiosHelper({
        url: '/location-ac-savings',
        method: 'get',
        success: (values: any) => {
          setLoadingLocations(false)
          acSavingsState.setLocationData(values?.data)
        },
      })
    }
  }, [acSavingsState, locationData, loadingLocations])

  // Get cooling primary use data
  useEffect(() => {
    if (!primaryUsesData?.length && !loadingPrimaryUses) {
      setLoadingPrimaryUses(true)
      axiosHelper({
        url: '/cooling-primary-uses',
        method: 'get',
        success: (values: any) => {
          setLoadingPrimaryUses(false)
          acSavingsState.setPrimaryUsesData(values?.data)
        },
      })
    }
  }, [acSavingsState, primaryUsesData, loadingPrimaryUses])

  // Get location climate region data
  useEffect(() => {
    if (!climateRegionsData?.length && !loadingClimateRegions) {
      setLoadingClimateRegions(true)
      axiosHelper({
        url: '/location-climate-regions',
        method: 'get',
        success: (values: any) => {
          setLoadingClimateRegions(false)
          acSavingsState.setClimateRegionsData(values?.data)
        },
      })
    }
  }, [acSavingsState, climateRegionsData, loadingClimateRegions])

  // Get location electric commercial costs data
  useEffect(() => {
    if (!locationCostsData?.length && !loadingLocationCosts) {
      setLoadingLocationCosts(true)
      axiosHelper({
        url: '/location-electric-commercial-costs',
        method: 'get',
        success: (values: any) => {
          setLoadingLocationCosts(false)
          acSavingsState.setLocationCostsData(values?.data)
        },
      })
    }
  }, [acSavingsState, locationCostsData, loadingLocationCosts])

  // Get cities agro climatic zones data
  useEffect(() => {
    if (!citiesAgroZonesData?.length && !loadingCitiesAgroZones) {
      setLoadingCitiesAgroZones(true)
      axiosHelper({
        url: '/location-cities-agro-climatic-zones',
        method: 'get',
        success: (values: any) => {
          setLoadingCitiesAgroZones(false)
          acSavingsState.setCitiesAgroZonesData(values?.data)
        },
      })
    }
  }, [acSavingsState, citiesAgroZonesData, loadingCitiesAgroZones])

  // Get em data
  useEffect(() => {
    if (!emData?.length && !loadingEmData) {
      setLoadingEmData(true)
      axiosHelper({
        url: '/climate-zones-em-data-points',
        method: 'get',
        success: (values: any) => {
          setLoadingEmData(false)
          acSavingsState.setEmData(values?.data)
        },
      })
    }
  }, [acSavingsState, emData, loadingEmData])

  const loading =
    loadingLocationCosts ||
    loadingClimateRegions ||
    loadingPrimaryUses ||
    loadingLocations ||
    loadingProducts

  const TITLE = 'AC Savings Calculator'

  const hasValidInputs =
    !!companyName &&
    !!installLocation &&
    !!primaryUse &&
    !!floorArea &&
    !!airConditioned &&
    !!energyDataSource &&
    !!operatingHours

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          acSavingsState.setResults()
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => acSavingsState.resetState()}
          toolState={{ ...acSavingsState.get(), toolName: TITLE }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          valid={hasValidInputs}
          canSave={!!results}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => acSavingsState.setLeadSaved(true)}
          onLeadsModalClose={() => {}}
          printContent={() => componentRef.current}
          renderAfterActionButtons={() => (
            <Button
              variant="text"
              disableElevation
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => {
                if (hasValidInputs) acSavingsState.setResults()
              }}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-AC-Savings-Calc',
                        email,
                        bafFans,
                        existingFans,
                        results,
                        companyName,
                        installLocation,
                        primaryUse,
                        floorArea,
                        airConditioned,
                        ceilingHeight,
                        thermostatOffset,
                        energyDataSource,
                        operatingHours,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<AcSavingsCalcPdf />}
                    fileName="BAF-AC-Savings-Calc.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />
        <Box ref={componentRef}>
          <PrintHeader title={TITLE} />
          <Box
            display="flex"
            sx={{ flexDirection: mediumBreakpoint ? 'row' : 'column' }}
          >
            <Stack sx={{ width: '100%' }}>
              <InputsTile loading={loading} />
            </Stack>
          </Box>

          {!!results && (
            <Box
              display="flex"
              sx={{ flexDirection: mediumBreakpoint ? 'row' : 'column' }}
            >
              <Stack sx={{ width: mediumBreakpoint ? '50%' : '100%' }}>
                <Results />
              </Stack>
              <Stack
                sx={{
                  ml: mediumBreakpoint ? 1 : 0,
                  width: mediumBreakpoint ? '50%' : '100%',
                }}
              >
                <Notes />
              </Stack>
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default AcSavingsCalc
