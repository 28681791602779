import { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'

import ZipCodeInput from 'components/ZipCodeInput'

import { Topology } from 'topojson-specification'
import { Typography } from '@mui/material'

interface Props {
  region: string
  state: any
  onZipChange: (zip: string) => any
  styles?: any
}

const RegionPickerTile = ({ state, region, onZipChange, styles }: Props) => {
  const svgRef = useRef(null)
  const defaultWidth = 900
  const defaultHeight = 450
  const defaultRatio = defaultWidth / defaultHeight

  // Legend zones and colors
  const zones = ['7A/B', '6A/B', '5A/B', '4A/B', '3A/B', '2A/B', '1A/B']
  const colors = [
    '#9932cc',
    '#1e90ff',
    '#32cd32',
    '#ffd700',
    '#f8ad51',
    '#f52525',
    '#7c0606',
  ]

  const createCityId = (d: d3.DSVRowString<string>) => {
    let id = `${d.city}${d.state}`
    id = id.split(' ').join('')
    id = id.split('.').join('')
    return id
  }
  useEffect(() => {
    let width = defaultWidth,
      height = defaultHeight
    let currentWidth = window.innerWidth * 0.45,
      currentHeight = window.innerHeight * 0.45
    let currentRatio = currentWidth / currentHeight
    if (currentRatio > defaultRatio) {
      height = currentHeight
      width = currentHeight * defaultRatio
    } else {
      width = currentWidth
      height = currentWidth / defaultRatio
    }
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${height * 2} ${width / 2}`)
      .attr('preserveAspectRatio', 'xMinYMax meet')
    // create tooltip
    const tooltip = d3
      .select('#map')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border-radius', '5px')
      .style('padding', '2px')
      .style('visibility', 'hidden')
    const projection = d3
      .geoAlbersUsa()
      .scale(width)
      .translate([width / 2 + 10, height / 2])
    const path = d3.geoPath().projection(projection)
    const color = d3
      .scaleQuantize<string, number>()
      .range([
        'firebrick',
        'red',
        'darkorange',
        'gold',
        'limegreen',
        'dodgerblue',
        'darkorchid',
      ])
      .domain([1, 7])
    d3.json('./map-files/counties-10m.json')
      .then(json => {
        let counties = (
          topojson.feature(
            json as Topology,
            (json as Topology).objects.counties
          ) as GeoJSON.FeatureCollection
        ).features
        let states = (
          topojson.feature(
            json as Topology,
            (json as Topology).objects.states
          ) as GeoJSON.FeatureCollection
        ).features
        let regions = (
          topojson.feature(
            json as Topology,
            (json as Topology).objects.regions
          ) as GeoJSON.FeatureCollection
        ).features
        // remove pre-existing elements
        d3.selectAll('.region').remove()
        d3.selectAll('.state').remove()
        d3.selectAll('.county').remove()
        d3.selectAll('.city-circle').remove()
        d3.selectAll('.city-label').remove()
        d3.selectAll('.city-label-back').remove()
        // bind the data to the svg
        svg
          .selectAll('region')
          .data(regions)
          .join('path')
          .attr('d', path)
          .attr('class', 'region')
          .attr('id', d => d.properties!.name)
          .style('fill', d =>
            color(parseInt(d.properties!.name.substring(0, 1)))
          )
          .attr('opacity', d => (d.properties!.name === region ? 0.5 : 1))
          .on('mouseover', function () {
            d3.select(this).attr('opacity', '0.5')
            tooltip.style('visibility', 'visible')
          })
          .on('mousemove', function (e) {
            tooltip
              .style('top', e.pageY + 10 + 'px')
              .style('left', e.pageX + 25 + 'px')
              .text(d3.select(this).attr('id'))
          })
          .on('mouseout', function () {
            d3.select(this).attr(
              'opacity',
              d3.select(this).attr('id') === region ? 0.7 : 1
            )
            tooltip.style('visibility', 'hidden')
          })
          .on('click', d => {
            state.setRegion(d.target.id)
            // Hide tooltip after selection is made
            tooltip.style('visibility', 'hidden')
          })
        svg
          .selectAll('.county')
          .data(counties)
          .join('path')
          .attr('d', path)
          .attr('class', 'county')
          .style('stroke', '#eee')
          .style('stroke-width', 0.5)
          .style('fill', 'none')
        svg
          .selectAll('state')
          .data(states)
          .join('path')
          .attr('d', path)
          .attr('class', 'state')
          .attr('id', d => d.properties!.name)
          .style('stroke', '#000')
          .style('stroke-width', '0.7')
          .style('fill', 'none')
        d3.csv('./map-files/cities.csv').then(cities => {
          svg
            .selectAll('.city-circle')
            .data(cities)
            .join('circle')
            .attr('class', 'city-circle')
            .attr('id', d => createCityId(d))
            .attr('r', 3)
            .attr('cx', d => projection([Number(d.long!), Number(d.lat!)])![0])
            .attr('cy', d => projection([Number(d.long!), Number(d.lat!)])![1])
            .attr('fill', 'red')
            .attr('stroke', 'white')
            .attr('stroke-width', '1.5')

            .on('mouseover', function (d) {
              d3.select(this).attr('stroke-width', '3').attr('r', '5')
              d3.select(`#${d.target.id}.city-label`).style(
                'visibility',
                'visible'
              )
              d3.select(`#${d.target.id}.city-label-back`).style(
                'visibility',
                'visible'
              )
            })
            .on('mouseout', function (d) {
              d3.select(this).attr('stroke-width', '1.5').attr('r', '3')
              d3.select(`#${d.target.id}.city-label`).style(
                'visibility',
                'hidden'
              )
              d3.select(`#${d.target.id}.city-label-back`).style(
                'visibility',
                'hidden'
              )
            })
          let cityLabelAlts = [
            'San Francisco',
            'Syracuse',
            'Brattleboro',
            'Boston',
            'Minneapolis',
            'Tracy',
            'Cheyenne',
          ]
          svg
            .selectAll('.city-label-back')
            .data(cities)
            .join('rect')
            .attr('class', 'city-label-back')
            .attr('id', d => createCityId(d))
            .attr('x', d => {
              let coords = projection([Number(d.long!), Number(d.lat!)])
              return Number(d.offset!) < 0
                ? coords![0] + Number(d.offset!) - 5
                : coords![0] + 1
            })
            .attr('y', d => {
              let coords = projection([Number(d.long!), Number(d.lat!)])
              return cityLabelAlts.find(city => city === d.city)
                ? coords![1] - 6
                : coords![1] - 3
            })
            .attr('width', d => d.width!)
            .attr('height', '15')
            .attr('fill', 'white')
            .attr('opacity', '0.6')
            .style('visibility', 'hidden')
            .attr('rx', '7')
            .attr('ry', '10')
          svg
            .selectAll('.city-label')
            .data(cities)
            .join('text')
            .attr('class', 'city-label')
            .attr('id', d => createCityId(d))
            .attr('x', d => projection([Number(d.long!), Number(d.lat!)])![0])
            .attr('y', d => projection([Number(d.long!), Number(d.lat!)])![1])
            .text(d => `${d.city}, ${d.state}`)
            .attr('font-size', 11)
            .style('visibility', 'hidden')
            .attr('dx', d => d.offset!)
            .attr('dy', d =>
              cityLabelAlts.find(city => city === d.city) ? 6 : 8
            )
        })
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line
  }, [region, window.innerHeight, window.innerWidth])

  const renderLegend = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '340px',
        height: '44px',
        fontSize: '1rem',
        textAlign: 'left',
        color: 'white',
        textShadow: '-1px 1px 0 #000',
      }}
    >
      <Typography sx={{ color: 'black', textShadow: 'none' }}>
        ASHRAE Climate Zone:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '32px',
          textAlign: 'center',
          fontSize: '0.75rem',
          backgroundColor: '#ff0000',
        }}
      >
        {zones?.map((zone: string, index: number) => (
          <Box
            sx={{
              flex: 1,
              height: '100%',
              pt: 0.5,
              backgroundColor: colors[index],
            }}
            key={`${zone}-${index}`}
          >
            {zone}
          </Box>
        ))}
      </Box>
    </Box>
  )

  return (
    <Card id="map" variant="outlined" sx={{ p: 4, height: '100%' }}>
      <ZipCodeInput
        state={state}
        onChange={value => {
          onZipChange && onZipChange(value)
        }}
      />
      <Box
        sx={{
          ...styles,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <svg ref={svgRef} />
        {renderLegend()}
      </Box>
    </Card>
  )
}

export default RegionPickerTile
