import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { PdfDataObj } from '../helpers/interfaces'

const Pdf = ({ src, title }: PdfDataObj) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      bgcolor: 'background.paper',
      overflow: 'hidden',
      padding: 1,
      fontWeight: 'bold',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {title && (
        <Typography variant="h5" textAlign="center">
          {title}
        </Typography>
      )}
      <Card
        sx={{
          width: '100%',
          maxWidth: 545,
          minHeight: 700,
          boxShadow: 0,
        }}
      >
        <CardMedia
          component="iframe"
          image={src + '#view=fit&toolbar=0'}
          sx={{
            width: '100%',
            maxWidth: 545,
            minHeight: 700,
            border: 0,
          }}
        />
      </Card>
    </Box>
  </Box>
)

export default Pdf
