import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/system/Box'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'

import { axiosHelper } from 'utils'
import { usePermissions } from 'hooks/usePermissions'
import { useProjects } from 'hooks/useProjects'

interface Props {
  onClose: () => void
  onSuccess: () => void
  open: boolean
  toolState: any
}

const modalStyle = {
  bgcolor: 'background.paper',
  borderRadius: '3%',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
}

const SaveToProjectModal = ({
  onClose = () => {},
  onSuccess = () => {},
  open,
  toolState,
}: Props) => {
  const { user } = usePermissions()
  const { projects } = useProjects({})
  const [project, setProject] = useState<any>(null)
  const [newProjectName, setNewProjectName] = useState<string>('')

  const projectOptions = projects?.map(project => ({
    label: project?.name,
    value: project?.id,
  }))

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose()
      }}
    >
      <Box
        component="form"
        sx={modalStyle}
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CreateNewFolderIcon
            sx={{ fontSize: '32px', color: 'secondary.main', mr: 2 }}
          />
          <Typography variant="h6" sx={{ fontWeight: 700, mt: 0.5 }}>
            Save to Project
          </Typography>
        </Box>

        <Autocomplete
          disablePortal
          freeSolo
          id="save-project-combo-box"
          options={projectOptions || []}
          sx={{ width: '100%' }}
          renderInput={params => <TextField {...params} label="Projects" />}
          onInputChange={(_, value) => {
            setProject(null)
            setNewProjectName(value)
          }}
          onChange={(_, value) => {
            setNewProjectName('')
            setProject(value)
          }}
        />

        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            disableElevation
            variant="outlined"
            onClick={() => onClose()}
            size="large"
            sx={{
              fontFamily: 'industry-inc',
              backgroundColor: 'white',
              py: 1,
              mr: 1,
            }}
          >
            Close
          </Button>
          <Button
            variant="text"
            disableElevation
            size="large"
            sx={{
              fontFamily: 'industry-inc',
              backgroundColor: 'secondary.main',
              py: 1.25,
              px: 2.5,
              '&:hover': {
                backgroundColor: '#A9A9A9',
                color: '#fff',
              },
            }}
            onClick={() => {
              const projectId = project?.value
              if (projectId) {
                // Create new results for existing project
                axiosHelper({
                  url: '/project-results',
                  method: 'post',
                  data: {
                    project_id: projectId,
                    name: `${project?.label || newProjectName} results`,
                    tool_name: toolState?.toolName,
                    data: JSON.stringify(toolState),
                  },
                  success: () => {
                    onSuccess()
                    onClose()
                  },
                })
              } else {
                // Create new project then create new results for it
                axiosHelper({
                  url: '/projects',
                  method: 'post',
                  data: {
                    name: newProjectName || 'New Project',
                    user_id: user?.email,
                    archived: 0,
                  },
                  success: (data: any) => {
                    axiosHelper({
                      url: '/project-results',
                      method: 'post',
                      data: {
                        project_id: data?.data?.id,
                        name: `${newProjectName} results`,
                        tool_name: toolState?.toolName,
                        data: JSON.stringify(toolState),
                      },
                      success: () => {
                        onSuccess()
                        onClose()
                      },
                    })
                  },
                })
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SaveToProjectModal
