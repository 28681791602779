import { useState } from 'react'
import { Link } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import ProjectsModal from './components/ProjectsModal'
import ProjectRow from './components/ProjectRow'
import ProjectSearchBanner from './components/ProjectSearchBanner'

import { useProjects } from 'hooks/useProjects'

const Projects = () => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const { projects } = useProjects({
    needsUpdate,
    onUpdate: setNeedsUpdate,
  })
  const [searchQuery, setSearchQuery] = useState('')

  // Filter shown projects based on search query
  const validProjects = projects?.filter((project: any) => !project.archived)
  const filteredProjects = validProjects
    ?.filter(
      (project: any) =>
        !searchQuery || project?.name.toLowerCase().includes(searchQuery)
    )
    .sort((a, b) => a?.created_at - b?.created_at)

  const renderNoProjects = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        mt: 18,
      }}
    >
      <Box sx={{ width: '200px', position: 'relative' }}>
        <FolderOpenIcon sx={{ color: 'LightGray', fontSize: '72px' }} />
        <AddIcon
          sx={{
            position: 'absolute',
            color: 'LightGray',
            fontSize: '32px',
            top: '24px',
            right: '72px',
            fontWeight: 700,
          }}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{
          mt: 0,
          mb: 1,
          textDecoration: 'capitalize !important',
          fontFamily: "'proxima-nova', sans-serif",
          fontWeight: 700,
        }}
      >
        No projects
      </Typography>
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          fontFamily: "'proxima-nova', sans-serif",
          color: 'gray',
        }}
      >
        Get started by creating a new project.
      </Typography>
      <Button
        variant="text"
        disableElevation
        onClick={() => setModalOpen(true)}
        size="large"
        sx={{
          backgroundColor: 'secondary.main',
          py: 1.75,
          px: 2.5,
          mt: 2,
          fontFamily: 'industry-inc',
          letterSpacing: '0.04em',
        }}
      >
        New Project
      </Button>
      <Link
        to={'/projects/archived'}
        style={{
          textDecoration: 'none',
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '24px',
          marginBottom: '16px',
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          View Archived Projects
        </Typography>
        <ArrowForwardIcon sx={{ fontSize: '16px', ml: 0.5 }} />
      </Link>
    </Box>
  )

  const renderProjects = () => (
    <Box
      sx={{
        flexDirection: 'column',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: '16px',
        mt: 6,
      }}
    >
      {!!filteredProjects?.length ? (
        filteredProjects?.map((project, index) => (
          <ProjectRow
            key={project?.name}
            project={project}
            onUpdate={(message: string) => {
              setNeedsUpdate(true)
              setAlertMessage(message)
              setAlertOpen(true)
            }}
            isLast={
              !!filteredProjects?.length &&
              filteredProjects?.length - 1 === index
            }
          />
        ))
      ) : (
        <Typography
          variant="h5"
          sx={{
            p: 4,
            fontFamily: "'proxima-nova', sans-serif",
            color: 'gray',
          }}
        >
          No projects match your search.
        </Typography>
      )}
    </Box>
  )

  return (
    <Box>
      {!projects ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 28,
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : !validProjects?.length ? (
        renderNoProjects()
      ) : (
        <>
          <ProjectSearchBanner
            setQuery={setSearchQuery}
            onClick={() => setModalOpen(true)}
          />
          <Container maxWidth="lg">
            {renderProjects()}

            <Link
              to={'/projects/archived'}
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                View Archived Projects
              </Typography>
              <ArrowForwardIcon sx={{ fontSize: '16px', ml: 0.5 }} />
            </Link>
          </Container>
        </>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>

      <ProjectsModal
        open={modalOpen}
        onClose={() => {
          setNeedsUpdate(true)
          setModalOpen(false)
        }}
        onSuccess={() => {
          setAlertOpen(true)
          setAlertMessage('Created!')
          setNeedsUpdate(true)
          setModalOpen(false)
        }}
      />
    </Box>
  )
}

export default Projects
