import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useEmployeeSavingsState } from 'store'

const ProductivitySavings = () => {
  const employeeSavingsState = useEmployeeSavingsState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const { averageHours, averageWage, results, numOfEmployees, solution } =
    employeeSavingsState.get()

  const resultsDisabled =
    !numOfEmployees || !averageHours || !averageWage || !solution

  const renderResultsCard = (title: string, amount: number) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: mediumBreakpoint ? '1rem' : '0.75rem',
      }}
    >
      <Box sx={{ fontWeight: 'bold' }}>
        {amount}
        <Box
          sx={{
            display: 'inline',
            fontSize: 'small',
            pl: 1,
            color: 'gray',
            fontWeight: 400,
          }}
        >
          per year
        </Box>
      </Box>
      <Box>{title}</Box>
    </Box>
  )

  return (
    <Card variant="outlined" sx={{ p: 4 }}>
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        Employee Impacts
      </Typography>
      {results ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              {renderResultsCard(
                'Employee Turnover Savings',
                results?.employeeTurnoverSavings
              )}
            </Box>
            <Box>
              {renderResultsCard(
                'Cooling Productivity Savings',
                results?.coolingSavings
              )}
            </Box>
            <Box>
              {renderResultsCard(
                'Heating Productivity Savings',
                results?.heatingSavings
              )}
            </Box>
          </Box>
          <Box
            sx={{
              ml: 8,
              border: '1px solid rgb(188, 190, 192)',
              borderRadius: '4px',
              height: '100px',
              p: 3,
            }}
          >
            {renderResultsCard('Total Employee Savings', results?.total)}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '176px',
            width: '100%',
          }}
        >
          <Button
            variant="text"
            disableElevation
            disabled={resultsDisabled}
            type="submit"
            size="large"
            sx={{
              backgroundColor: 'secondary.main',
              py: 1.5,
              mt: 1.5,
              fontFamily: 'industry-inc',
              '&:hover': {
                backgroundColor: '#A9A9A9',
                color: '#fff',
              },
            }}
            onClick={() => employeeSavingsState.setResults()}
          >
            View Results
          </Button>
        </Box>
      )}
    </Card>
  )
}

export default ProductivitySavings
