import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store'
import { useState } from 'react'

interface ColorOptsProps {
  setFinish: (finish: string | null) => void
}

interface APIAppearanceOption {
  id: number
  part?: {
    id: number
    part: string
  }
  color?: {
    id: number
    color: string
  }
  finish?: {
    id: number
    finish: string
  }
  hardware_finish?: {
    id: number
    hardware_finish: string
  }
  material?: {
    id: number
    material: string
  }
}

const ColorOptions = ({ setFinish }: ColorOptsProps) => {
  const submittalsState = useSubmittalsState()
  const { productData, sizeData, numCols, validated, colorDetails } =
    submittalsState.getState()
  const finishes = productData.appearances
    .map((a: APIAppearanceOption) => a.finish?.finish)
    .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
  const [hardwareFinishes, setHardwareFinishes] = useState([] as string[])
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Color Options
        </Typography>
        <Grid container rowSpacing={0} columnSpacing={1} id="colorOptions">
          <Grid item xs={(sizeData.mounts ? 24 : 12) / numCols}>
            <Autocomplete
              disablePortal
              size="small"
              options={finishes}
              sx={{ pt: 0 }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  label="Finish"
                  error={validated && colorDetails.value.finish === ''}
                />
              )}
              value={colorDetails.value.finish}
              onChange={(event, newFinish) => {
                event.preventDefault()
                submittalsState.setFormValue(
                  'colorDetails',
                  'finish',
                  newFinish
                )
                submittalsState.setFormValue(
                  'colorDetails',
                  'hardwareFinish',
                  null
                )
                setFinish(newFinish)
                setHardwareFinishes(
                  productData.appearances
                    .map((a: APIAppearanceOption) => {
                      if (a.finish?.finish === colorDetails.value.finish) {
                        return a.hardware_finish?.hardware_finish
                      } else return null
                    })
                    .filter((n: any) => n)
                )
                submittalsState.setFormValue(
                  'colorDetails',
                  'material',
                  productData.appearances.find(
                    (a: any) => a.finish.finish === newFinish
                  ).material.material ?? ''
                )
              }}
              inputValue={colorDetails.value.finish ?? ''}
            />
          </Grid>
          {colorDetails.value.finish && hardwareFinishes.length !== 0 && (
            <Grid item xs={(sizeData.mounts ? 24 : 12) / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={hardwareFinishes}
                sx={{ pt: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Hardware Finish"
                    error={
                      validated && colorDetails.value.hardwareFinish === ''
                    }
                  />
                )}
                value={colorDetails.value.hardwareFinish}
                onChange={(event, newFinish) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'colorDetails',
                    'hardwareFinish',
                    newFinish
                  )
                }}
                inputValue={colorDetails.value.hardwareFinish ?? ''}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  )
}

export default ColorOptions
