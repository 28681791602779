import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import DoeCostTable from './DoeCostTable'
import BafCostTable from './BafCostTable'
import AireyeBafCostTable from './AireyeBafCostTable'
import ExistingFansCostTable from './ExistingFansCostTable'
import AnnualSavingsEstimate from './AnnualSavingsEstimate'
import EnvironmentalImpactTable from 'components/EnvironmentalImpactTable'
import FormCard from 'components/FormCard'

import { useFanEnergyEstimatorState } from 'store'

const Results = ({ isAireyeMode = false }: { isAireyeMode: boolean }) => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const { results, existingFans } = fanEnergyEstimatorState.get()

  return (
    <FormCard width="100%">
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        Results
      </Typography>

      <DoeCostTable isAireyeMode={isAireyeMode} />

      {/* BAF method results */}
      {isAireyeMode ? (
        <AireyeBafCostTable
          results={results?.aireyeBafCost}
          onModeChange={(name: string, mode: string) => {
            fanEnergyEstimatorState.setAireyeFanMode(name, mode)
          }}
          title="Annual Energy Usage & Energy Cost - BAF Method"
        />
      ) : (
        <BafCostTable />
      )}

      {/* Existing Fan results */}
      {!isAireyeMode && <ExistingFansCostTable />}
      {isAireyeMode && !!existingFans?.length && (
        <AireyeBafCostTable
          results={results?.aireyeExistingFansCost}
          onModeChange={(name: string, mode: string) => {
            fanEnergyEstimatorState.setAireyeExistingFanMode(name, mode)
          }}
          title="Annual Energy Usage & Energy Cost - Alternative Fans - BAF Method"
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: mediumBreakpoint ? 'row' : 'column',
        }}
      >
        <AnnualSavingsEstimate />
        <EnvironmentalImpactTable
          environmentalImpact={results?.environmentalImpact}
          existingFans={existingFans}
          width={mediumBreakpoint ? '50%' : '100%'}
        />
      </Box>
    </FormCard>
  )
}

export default Results
