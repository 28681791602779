import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useMediaQuery from '@mui/material/useMediaQuery'

import FormCard from 'components/FormCard'
import StyledTextField from 'components/StyledTextField'

import { PRIMARY_USES } from 'config/constants'
import { useHeatSavingsState } from 'store'
import { Product } from 'store/fanEnergyEstimatorState'

const InputsTile = ({ loading = false }: { loading: boolean }) => {
  const heatSavingsState = useHeatSavingsState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const {
    products,
    bafFans,
    existingFans,
    companyName,
    primaryUse,
    floorArea,
    installLocation,
    heated,
    ceilingHeight,
    energyDataSource,
    operatingHours,
    citiesAgroZonesData,
  } = heatSavingsState.get()

  // Build product select options
  const bafFanOptions = products?.filter((product: Product) => {
    const isBafFan = product?.product_id !== -1 && product?.product_id !== -2
    return isBafFan
  })

  const existingFanOptions = products?.filter(
    (product: Product) =>
      product?.product_id === -1 && !product?.name?.includes('Generic')
  )

  const installLocationOptions = citiesAgroZonesData?.map((zone: any) => ({
    ...zone,
    value: zone?.city_state,
  }))
  const primaryUsesOptions = PRIMARY_USES?.map((use: string) => ({
    value: use,
    label: use,
  }))

  const renderBafProduct = (fan: any, index: number) => {
    return (
      <Box
        key={`${fan?.name}-${index}`}
        display="inline-flex"
        sx={{ width: '100%', mt: 1 }}
      >
        <Select
          required
          value={fan?.name}
          onChange={(e: any) => {
            const value = e?.target?.value
            if (!value) return
            const product = products?.find(
              (product: Product) => product?.name === value
            )
            heatSavingsState.setBafFan(product, index)

            // Remove all existing fans if selecting first product
            if (bafFans?.length === 1) {
              heatSavingsState.resetExistingFans()
            }
          }}
          sx={{ width: mediumBreakpoint ? '82%' : '75%' }}
        >
          {bafFanOptions?.map((option: Product) => (
            <MenuItem key={option?.name} value={option?.name}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          required
          type="number"
          InputProps={{ inputProps: { min: 0, max: 9001 } }}
          label={index === 0 ? 'Quantity' : ''}
          name="quantity"
          sx={{ ml: 1, width: '10%' }}
          value={fan?.count || 1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const count = e?.target?.value
            heatSavingsState.setBafFanQuantity(parseInt(count), index)
          }}
        />
        {index > 0 && (
          <HighlightOffIcon
            sx={{
              fontSize: '32px',
              cursor: 'pointer',
              color: 'red',
              mt: 1,
              ml: 1,
            }}
            onClick={() => {
              heatSavingsState.removeBafFan(index)
            }}
          />
        )}
      </Box>
    )
  }

  const renderExistingProduct = (fan: any, index: number) => {
    return (
      <Box
        key={`${fan?.name}-${index}`}
        display="inline-flex"
        sx={{ width: '100%', mt: 1 }}
      >
        <Select
          required
          value={fan?.name}
          onChange={(e: any) => {
            const value = e?.target?.value
            if (!value) return
            const product = products?.find(
              (product: Product) => product?.name === value
            )
            heatSavingsState.setExistingFan(product, index)
          }}
          sx={{ width: mediumBreakpoint ? '82%' : '75%' }}
        >
          {existingFanOptions?.map((option: Product, index: number) => (
            <MenuItem key={`${option?.name}-${index}`} value={option?.name}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          required
          type="number"
          InputProps={{ inputProps: { min: 0, max: 9001 } }}
          label={index === 0 ? 'Quantity' : ''}
          name="quantity"
          sx={{ ml: 1, width: '10%' }}
          value={fan?.count || 1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const count = e?.target?.value
            heatSavingsState.setExistingFanQuantity(parseInt(count), index)
          }}
        />
        <HighlightOffIcon
          sx={{
            fontSize: '32px',
            cursor: 'pointer',
            color: 'red',
            mt: 1,
            ml: 1,
          }}
          onClick={() => {
            heatSavingsState.removeExistingFan(index)
          }}
        />
      </Box>
    )
  }

  return (
    <FormCard>
      <Box sx={{ pb: 2 }}>
        <Box display="inline-flex">
          <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
            Inputs
          </Typography>
        </Box>

        {!products?.length && loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection={'column'}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    helperText=""
                    label="Company Name"
                    name="company-name"
                    value={companyName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      heatSavingsState.setCompanyName(value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    variant="standard"
                    sx={{ width: '100%', mt: -0.5 }}
                  >
                    <InputLabel>Install Location</InputLabel>
                    <Select
                      variant="standard"
                      label="Install Location"
                      required
                      value={installLocation || ''}
                      onChange={(e: any) => {
                        heatSavingsState.setInstallLocation(e?.target?.value)
                      }}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {installLocationOptions?.map((option: any) => (
                        <MenuItem key={option?.value} value={option?.value}>
                          {option?.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    variant="standard"
                    sx={{ width: '100%', mt: -0.5 }}
                  >
                    <InputLabel>Primary Use</InputLabel>
                    <Select
                      variant="standard"
                      label="Primary Use"
                      required
                      value={primaryUse || ''}
                      onChange={(e: any) => {
                        heatSavingsState.setPrimaryUse(e?.target?.value)
                      }}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {primaryUsesOptions?.map((option: any) => (
                        <MenuItem key={option?.value} value={option?.value}>
                          {option?.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: mediumBreakpoint ? 3 : 0 }}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText="Square Ft"
                    label="Floor Area"
                    name="floor-area"
                    value={floorArea || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      heatSavingsState.setFloorArea(parseInt(value))
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ mt: mediumBreakpoint ? 0 : -2.5 }}
                >
                  <StyledTextField
                    required
                    type="number"
                    helperText="Feet"
                    label="Average Ceiling Height"
                    name="average-ceiling-height"
                    value={ceilingHeight || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      heatSavingsState.setCeilingHeight(parseInt(value))
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ mt: mediumBreakpoint ? 0 : -2.5 }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ width: '100%', mt: -0.5 }}
                  >
                    <InputLabel>Heated</InputLabel>
                    <Select
                      variant="standard"
                      label="Heated"
                      required
                      value={heated || ''}
                      onChange={(e: any) => {
                        heatSavingsState.setHeated(e?.target?.value)
                      }}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <MenuItem value="Yes - AHU - Natural Gas">
                        Yes - AHU - Natural Gas
                      </MenuItem>
                      <MenuItem value="Yes - AHU - Electricity">
                        Yes - AHU - Electricity
                      </MenuItem>
                      <MenuItem value="Yes - Radiant Heaters - Natural Gas">
                        Yes - Radiant Heaters - Natural Gas
                      </MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 1, mb: 3 }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ mt: mediumBreakpoint ? 0 : -2.5 }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ width: '100%', mt: -0.5 }}
                  >
                    <InputLabel>Energy Data Source</InputLabel>
                    <Select
                      variant="standard"
                      label="Energy Data Source"
                      required
                      value={energyDataSource || ''}
                      onChange={(e: any) => {
                        heatSavingsState.setEnergyDataSource(e?.target?.value)
                      }}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <MenuItem value="BAF Energy Models">
                        BAF Energy Models
                      </MenuItem>
                      <MenuItem value="EIA Building Data">
                        EIA Building Data
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    required
                    type="number"
                    helperText="Per Week"
                    label="Operating Hours"
                    name="operating-hours"
                    value={operatingHours || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e?.target?.value
                      heatSavingsState.setOperatingHours(parseInt(value))
                    }}
                  />
                </Grid>
              </Grid>

              {/* Render BAF product rows */}
              <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                Fans
              </Typography>
              {bafFans?.map((fan: any, index: number) =>
                renderBafProduct(fan, index)
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  width: mediumBreakpoint ? '92.5%' : '86%',
                }}
              >
                <Button
                  variant="outlined"
                  disableElevation
                  size="large"
                  sx={{
                    pt: 0.5,
                    pb: 0.5,
                    width: existingFans?.length ? '100%' : '49%',
                    border: '1px solid #FFC425',
                  }}
                  onClick={() =>
                    heatSavingsState.setBafFan(
                      bafFanOptions?.[0],
                      bafFans?.length || 0
                    )
                  }
                >
                  Add Big Ass Fan
                </Button>
                {!existingFans?.length && (
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    sx={{
                      pt: 0.5,
                      pb: 0.5,
                      width: '49%',
                      border: '1px solid #FFC425',
                    }}
                    onClick={() =>
                      heatSavingsState.setExistingFan(
                        existingFanOptions?.[0],
                        existingFans?.length
                      )
                    }
                  >
                    Add Existing Fan
                  </Button>
                )}
              </Box>
            </Box>

            {/* Render existing  product rows */}
            {!!existingFans?.length && (
              <Box display="flex" flexDirection={'column'}>
                <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
                  Existing Fans
                </Typography>
                {existingFans?.map((fan: any, index: number) =>
                  renderExistingProduct(fan, index)
                )}
                <Box
                  sx={{
                    mt: 2,
                    width: mediumBreakpoint ? '92.5%' : '86%',
                  }}
                >
                  <Button
                    variant="outlined"
                    disableElevation
                    size="large"
                    sx={{
                      pt: 0.5,
                      pb: 0.5,
                      width: '100%',
                      border: '1px solid #FFC425',
                    }}
                    onClick={() =>
                      heatSavingsState.setExistingFan(
                        existingFanOptions?.[0],
                        existingFans?.length
                      )
                    }
                  >
                    Add Existing Fan
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </FormCard>
  )
}

export default InputsTile
