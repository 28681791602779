import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

interface CardProps {
  children: any
  width?: string
  mt?: number
  pb?: number
  mr?: number
  id?: string
  display?: string
  justifyContent?: string
}

const FormCard = ({
  children,
  width,
  mt,
  pb,
  mr,
  id,
  display,
  justifyContent,
}: CardProps) => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  return (
    <Box
      sx={{
        mt: mt ?? 0.7,
        mr: mr ? mr : mediumBreakpoint ? 0.7 : 0,
        p: 5,
        border: 1,
        borderColor: 'grey.400',
        borderRadius: 2,
        breakInside: 'avoid',
        width: width,
        flex: 1,
      }}
      id={id}
      display={display}
      justifyContent={justifyContent}
    >
      {children}
    </Box>
  )
}

export default FormCard
