import { Text, View, Image, StyleSheet } from '@react-pdf/renderer'

const PdfHeader = ({ title = '' }: { title: string }) => {
  const styles = StyleSheet.create({
    header: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '16px',
      marginBottom: '16px',
    },
    logo: {
      width: '144px',
      height: '34px',
      marginTop: '-3px',
    },
    toolName: {
      paddingLeft: '8px',
      marginLeft: '8px',
      fontWeight: 'ultrabold',
      borderLeft: '2px solid black',
    },
  })

  return (
    <View style={styles.header}>
      <Image
        style={styles.logo}
        src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/BAF-Logo.png"
      ></Image>
      <Text style={styles.toolName}>{title}</Text>
    </View>
  )
}

export default PdfHeader
