import { useEffect, useState } from 'react'
import { axiosHelper } from 'utils'
import isEquaL from 'lodash/isEqual'

interface Results {
  archived?: number
  name?: string
  created_at?: number
  id?: string
  data?: any
}

export function useProjectResults({ id }: { id: string | null }) {
  const [projectResults, setResults] = useState<Results | null>(null)

  // Get project results for id
  useEffect(() => {
    if (id) {
      axiosHelper({
        url: `/project-results/${id}`,
        method: 'get',
        success: (values: any) => {
          if (!isEquaL(values?.data, projectResults)) {
            setResults(values?.data)
          }
        },
      })
    }
  }, [projectResults, id])

  return {
    projectResults,
  }
}
