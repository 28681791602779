import sumBy from 'lodash/sumBy'

import { MONTHS } from 'config/constants'
import { Product } from 'store/fanEnergyEstimatorState'

const HOURS_PER_YEAR = 8760
const HOURS_PER_DAY = 24

// Environmental impact multipliers
const EQ_BBTU_MULTI = 3.412
const CO2 = 0.9905
const CO2E = 0.996
const PARTICULATES = 2744
const CARS = 0.002
const ACRES = 0.0009
const BULBS = 0.025

const getHours = (days: number, mode: string, hoursPerWeek: number): number => {
  if (mode === 'Cool' || mode === 'Max Cool') {
    return (days * hoursPerWeek) / 7
  }
  return days * HOURS_PER_DAY
}

const getAireyeHours = (
  days: number,
  mode: string,
  hoursPerWeek: number
): number => {
  if (mode === 'Cool' || mode === 'Max Cool') {
    return (days * hoursPerWeek) / 7
  }
  return 0
}

const getUsage = (mode: string, hours: number, totals: any): number => {
  const total =
    mode === 'Cool'
      ? totals.cool
      : mode === 'Max Cool'
      ? totals.maxCool
      : totals.heat
  return (total * hours) / 1000
}

const getBafMethodData = (
  totals: any,
  fanModes: any,
  electricCost: number,
  hoursPerWeek: number
) => {
  // Get BAF energy cost data
  const monthsData = MONTHS?.map((month: any) => {
    const name = month?.name
    const days = month?.days
    const mode = fanModes[name as keyof typeof fanModes]
    const hours = getHours(days, mode, hoursPerWeek)
    const usage = getUsage(mode, hours, totals)
    const cost = usage * electricCost
    const usagePerDay = usage / days
    const costPerDay = cost / days

    return {
      name,
      days,
      hours,
      mode,
      usage,
      cost,
      usagePerDay,
      costPerDay,
    }
  })

  return monthsData
}

const getAireyeBafMethodData = (
  totals: any,
  fanModes: any,
  electricCost: number,
  hoursPerWeek: number,
  breaks15Min: number,
  breaks60Min: number,
  aeosEquipped: boolean,
  fansAlwaysOn: boolean
) => {
  // Get break hours
  const weekly15MinBreakHours = (0.25 - 1 / 12) * breaks15Min
  const weekly60MinBreakHours = (1 - 1 / 12) * breaks60Min
  const weeklyBreakHoursTotal = weekly15MinBreakHours + weekly60MinBreakHours
  const dailyBreakHoursTotal = weeklyBreakHoursTotal / 7

  // Get BAF energy cost data
  const monthsData = MONTHS?.map((month: any) => {
    const name = month?.name
    const mode = fanModes[name as keyof typeof fanModes]
    const days = mode === 'Off' ? 0 : month?.days
    const aireyeHours = getAireyeHours(days, mode, hoursPerWeek)
    const aeosHours = aireyeHours - dailyBreakHoursTotal * days
    const hours = aeosEquipped
      ? aeosHours
      : fansAlwaysOn
      ? days * HOURS_PER_DAY
      : aireyeHours
    const usage = getUsage(mode, hours, totals)
    const cost = usage * electricCost
    const usagePerDay = usage / days
    const costPerDay = cost / days

    return {
      name,
      days,
      hours,
      mode,
      usage,
      cost,
      usagePerDay,
      costPerDay,
    }
  })

  return monthsData
}

const getDoeMethodData = (
  largeFans: Product[],
  hssdFans: Product[],
  lssdFans: Product[]
) => {
  // Get total active hours for each type
  const activeLargeFanHours = ((HOURS_PER_YEAR * 12) / 24) * largeFans?.length
  const activeHssdFanHours = ((HOURS_PER_YEAR * 12) / 24) * hssdFans?.length
  const activeLssdFanHoursLow = ((HOURS_PER_YEAR * 3) / 24) * lssdFans?.length
  const activeLssdFanHoursHigh =
    ((HOURS_PER_YEAR * 3.4) / 24) * lssdFans?.length
  const standbyLssdFanHours = ((HOURS_PER_YEAR * 17.6) / 24) * lssdFans?.length

  // Get total power consumption for each type
  const largeFansUsage = sumBy(largeFans, (fan: Product) =>
    parseFloat(fan.kwh_day as string)
  )
  const hssdFansUsage = sumBy(hssdFans, (fan: Product) =>
    parseFloat(fan.kwh_day as string)
  )
  const lssdFansUsage = sumBy(lssdFans, (fan: Product) =>
    parseFloat(fan.kwh_day as string)
  )

  return {
    activeLargeFanHours,
    activeHssdFanHours,
    activeLssdFanHoursLow,
    activeLssdFanHoursHigh,
    standbyLssdFanHours,
    largeFansUsage,
    hssdFansUsage,
    lssdFansUsage,
  }
}

const getExistingFansComparisonData = (
  monthsData: any,
  fanTotals: any,
  electricCost: number,
  fansLeftOn: boolean
) => {
  const hoursByType = {
    maxCool: sumBy(monthsData, (month: any) =>
      month?.mode === 'Max Cool' ? month?.hours : 0
    ),
    heat: sumBy(monthsData, (month: any) =>
      month?.mode === 'Heat' ? month?.hours : 0
    ),
    cool: sumBy(monthsData, (month: any) =>
      month?.mode === 'Cool' ? month?.hours : 0
    ),
  }

  // Get total possible hours for each fan type
  const possibleHoursByType = {
    maxCool:
      sumBy(monthsData, (month: any) =>
        month?.mode === 'Max Cool' ? month?.days : 0
      ) * 24,
    heat:
      sumBy(monthsData, (month: any) =>
        month?.mode === 'Heat' ? month?.days : 0
      ) * 24,
    cool:
      sumBy(monthsData, (month: any) =>
        month?.mode === 'Cool' ? month?.days : 0
      ) * 24,
  }
  const hoursTotal =
    hoursByType?.maxCool + hoursByType?.cool + hoursByType?.heat

  // Get existing fans total energy
  const energyTotals = {
    maxCool: (hoursByType?.maxCool * fanTotals?.maxCool) / 1000,
    cool: (hoursByType?.cool * fanTotals?.cool) / 1000,
    heat: (hoursByType?.heat * fanTotals?.heat) / 1000,
  }
  const energyTotal =
    energyTotals?.maxCool + energyTotals?.cool + energyTotals?.heat

  // Get existing fans total possible energy
  const possibleEnergyTotals = {
    maxCool: (possibleHoursByType?.maxCool * fanTotals?.maxCool) / 1000,
    cool: (possibleHoursByType?.cool * fanTotals?.cool) / 1000,
    heat: (possibleHoursByType?.heat * fanTotals?.heat) / 1000,
  }
  const possibleEnergyTotal =
    possibleEnergyTotals?.maxCool +
    possibleEnergyTotals?.cool +
    possibleEnergyTotals?.heat

  // Get existing fan usage per day
  const energyTotalValue = fansLeftOn ? possibleEnergyTotal : energyTotal
  const divisor = fansLeftOn ? HOURS_PER_YEAR : hoursTotal
  const usagePerDay = energyTotalValue / (divisor / HOURS_PER_DAY)

  // Get existing fan total cost
  const totalCost = energyTotalValue * electricCost
  const costPerDay = totalCost / (divisor / HOURS_PER_DAY)

  return {
    energyTotalValue,
    totalCost,
    usagePerDay,
    costPerDay,
  }
}

const getEnvironmentalImpact = (savings: number) => {
  const co2 = savings * CO2
  const eqBbtu = (savings * EQ_BBTU_MULTI) / 1000000
  const particulates = eqBbtu * PARTICULATES
  const co2e = savings * CO2E
  const cars = savings * CARS
  const acres = savings * ACRES
  const bulbs = savings * BULBS

  return [
    {
      title: 'Reduct. in Carbon Dioxide (CO2)',
      metric: 'Lbs',
      icon: 'Co2',
      value: co2,
    },
    {
      title: 'Reduct. in Particulates',
      metric: 'Lbs',
      icon: 'Grain',
      value: particulates,
    },
    {
      title: 'Greenhouse Gas Reduct. (CO2e)',
      metric: 'Lbs',
      icon: 'GasMeter',

      value: co2e,
    },
    {
      title: 'Equiv. Passenger Cars Reduction',
      metric: 'Cars',
      icon: 'DirectionsCar',
      value: cars,
    },
    {
      title: 'Equiv. Forest Carbon Sequestration',
      metric: 'Acres',
      icon: 'Park',
      value: acres,
    },
    {
      title: 'Equiv. Legacy Light Bulbs to LED',
      metric: 'Bulbs',
      icon: 'Lightbulb',
      value: bulbs,
    },
  ]
}

export {
  getBafMethodData,
  getAireyeBafMethodData,
  getDoeMethodData,
  getExistingFansComparisonData,
  getEnvironmentalImpact,
}
