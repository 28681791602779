import { useEffect } from 'react'

import Box from '@mui/material/Box'

import Main from './main'
import Sidebar from './sidebar'

import { useGlobalState } from 'store'

const DesignGuide = () => {
  const globalState = useGlobalState()
  useEffect(() => {
    return () => {
      // clean up design guide paths for reuse on unmount
      const globalDesignGuidePaths = globalState.get().designGuidePaths

      const newPaths = globalDesignGuidePaths.map(pathObject => {
        const newPathObject = { ...pathObject }
        if (newPathObject?.active) newPathObject.active = false
        if (newPathObject?.opened) newPathObject.opened = false
        return newPathObject
      })

      globalState.setDesignGuidePaths(newPaths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Sidebar />
      <Main />
    </Box>
  )
}

export default DesignGuide
