import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ImageDataObj } from '../helpers/interfaces'

const Image = ({ path, letter, caption, src, alt }: ImageDataObj) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        padding: 1,
        fontWeight: 'bold',
      }}
    >
      <Box component="img" alt={alt} src={src} sx={{ maxWidth: '100%' }} />

      {caption && (
        <Typography fontStyle="italic" textAlign="center">
          <Box component="span" sx={{ m: 1, fontSize: 12 }}>
            Figure {path}
            {letter} - {caption}
          </Box>
        </Typography>
      )}
    </Box>
  )
}

export default Image
