import React, { useEffect } from 'react'

import Box from '@mui/system/Box'

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
          formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <Box sx={{ mb: 2 }}>
      <Box id="hubspotForm"></Box>
    </Box>
  )
}

export default HubSpotForm
