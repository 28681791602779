import uniq from 'lodash/uniq'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { usePrintedNotes } from '../hooks/usePrintedNotes'
import { APIScheduleBuilderLink } from '../hooks/useNotes'
import Link from '@mui/material/Link'

interface PrintedNotesProps {
  category: string
}

const PrintedNotes = ({ category }: PrintedNotesProps) => {
  const { notes } = usePrintedNotes()
  const _notes = notes.filter(note => note.category === category)
  const links = uniq(_notes.map(note => note.link).flat())

  return (
    <Box sx={{ mb: 2 }}>
      <>
        <Typography variant="h4" sx={{ mb: 0 }}>
          Notes
        </Typography>
        <List>
          {_notes.map((n: any, index: number) => (
            <ListItem
              key={index}
              sx={{
                p: 0,
                mt: -0.5,
                textTransform: 'uppercase',
                breakInside: 'avoid',
              }}
            >
              <ListItemText>{n.note}</ListItemText>
            </ListItem>
          ))}
        </List>
        <List>
          {links.map((l: APIScheduleBuilderLink, index: number) => (
            <ListItem key={index} sx={{ p: 0, pb: 0.5, breakInside: 'avoid' }}>
              <Link href={l.link}>{l.name}</Link>
            </ListItem>
          ))}
        </List>
      </>
    </Box>
  )
}

export default PrintedNotes
