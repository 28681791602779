import Link from '@mui/material/Link'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { UrlDataObj } from '../helpers/interfaces'
import { Typography } from '@mui/material'

const Hyperlink = ({ url, content, clickable, styled }: UrlDataObj) => {
  const linkProps = styled
    ? ({ underline: 'hover', variant: 'h5', color: 'secondary' } as const)
    : ({ underline: 'always' } as const)

  let front, back
  if (clickable) {
    front = content.split(clickable)[0]
    back = content.split(clickable)[1] ?? ''
  }
  return (
    <Typography>
      {front ?? ''}
      <Link href={url} target="_blank" rel="noopener" {...linkProps}>
        {clickable ?? content}{' '}
        {styled && <ArrowForward sx={{ fontSize: 18 }} />}
      </Link>
      {back ?? ''}
    </Typography>
  )
}

export default Hyperlink
