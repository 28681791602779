import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import {
  APIScheduleBuilderLink,
  APIScheduleBuilderNote,
  useNotes,
} from '../hooks/useNotes'
import List from '@mui/material/List'

interface NotesProps {
  id: number
  combineNotesBy?: 'none' | 'id' | 'category'
}

const Notes = ({ id, combineNotesBy = 'none' }: NotesProps) => {
  const { hasNotes, hasLinks, productData, productDetails } = useNotes({
    id,
    combineNotesBy,
  })

  return (
    <Box sx={{ mb: 2 }}>
      {hasNotes && (
        <>
          <Typography variant="h4" sx={{ mb: 0 }}>
            Notes
          </Typography>
          <Box
            component="p"
            className="printable-notes-description"
            sx={{ fontSize: 18, mt: 0, opacity: 0.5 }}
          >
            {`${productData.name} | ${productDetails.value.size} | ${productDetails.value.voltage}`}
          </Box>
          <List>
            {productData.schedule_builder_notes.map(
              (n: APIScheduleBuilderNote, index: number) => (
                <ListItem
                  key={n.id}
                  sx={{
                    p: 0,
                    mt: -0.5,
                    textTransform: 'uppercase',
                    breakInside: 'avoid',
                  }}
                >
                  <ListItemText>{`${
                    index + productDetails.value.notes_start
                  }) ${n.note}`}</ListItemText>
                </ListItem>
              )
            )}
          </List>
        </>
      )}
      {hasLinks && (
        <>
          <Typography variant="h5" sx={{ mt: 1 }}>
            Links
          </Typography>
          <List>
            {productData.schedule_builder_links.map(
              (l: APIScheduleBuilderLink) => (
                <ListItem
                  key={l.id}
                  sx={{ p: 0, pb: 0.5, breakInside: 'avoid' }}
                >
                  <Link href={l.link}>{l.name}</Link>
                </ListItem>
              )
            )}
          </List>
        </>
      )}
    </Box>
  )
}

export default Notes
