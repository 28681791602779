import React from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store/salesToolsState'
import { Typography } from '@mui/material'

const Notes = () => {
  const submittalsState = useSubmittalsState()
  const { fan, productData } = submittalsState.getState()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    submittalsState.setNotes(e.target.value)
  }
  return (
    <Grid
      item
      md={
        (productData.technicalNotes.length > 1 ||
          productData.technicalNotes.length === 0) &&
        fan.name !== 'IRH'
          ? 12
          : 6
      }
      xs={12}
    >
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -2 }}>
          Notes
        </Typography>
        <TextField
          label=""
          size="small"
          margin="dense"
          variant="standard"
          multiline
          onChange={handleChange}
          sx={{ width: '100%' }}
        />
      </Card>
    </Grid>
  )
}

export default Notes
