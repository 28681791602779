import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useSubmittalsState } from 'store/salesToolsState'

interface APIFeature {
  id: number
  feature: string
}

const Features = () => {
  const { fan, productData, sizeData, productDetails, controllerFeatures } =
    useSubmittalsState().getState()
  const model = productDetails.value.model
  const singleMulti = controllerFeatures.value.singleMulti
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
          Features
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              {productData.features.map(
                (feature: APIFeature, index: number) =>
                  index < 4 && (
                    <TableCell
                      sx={{
                        p: 1,
                        width: 100,
                        lineHeight: '1rem',
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={index}
                    >
                      {feature.feature}
                    </TableCell>
                  )
              )}
            </TableRow>
            <TableRow>
              {productData.features.map(
                (feature: APIFeature, index: number) =>
                  index > 3 &&
                  index < 8 && (
                    <TableCell
                      sx={{
                        p: 1,
                        width: 100,
                        lineHeight: '1rem',
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={index}
                    >
                      <>
                        {fan.name === 'UH' &&
                          index === 6 &&
                          model &&
                          `${feature.feature} (${sizeData.max_sound})`}
                        {fan.name === 'UH' && index !== 6 && feature.feature}
                        {(fan.name !== 'UH' || !model) && feature.feature}
                      </>
                    </TableCell>
                  )
              )}
            </TableRow>
            <TableRow>
              {productData.features.map(
                (feature: APIFeature, index: number) =>
                  index > 7 && (
                    <TableCell
                      sx={{
                        p: 0,
                        pr: 0.5,
                        width: 100,
                        lineHeight: '1rem',
                        '@media print': { fontSize: '0.8rem' },
                      }}
                      key={index}
                    >
                      {feature.feature}
                    </TableCell>
                  )
              )}
              {productData.name === 'BAFCon' && singleMulti === 'Multiple' && (
                <TableCell
                  sx={{
                    p: 0,
                    pr: 0.5,
                    width: 100,
                    lineHeight: '1rem',
                    '@media print': { fontSize: '0.8rem' },
                  }}
                >
                  Controls up to 24 HVLS fans
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </Grid>
  )
}

export default Features
