import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useFanEnergyEstimatorState } from 'store'

const ExistingFansCostTable = () => {
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const { existingFans, results } = fanEnergyEstimatorState.get()

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  // Don't show cost table if no existing fans
  if (!existingFans?.length) return null

  return (
    <Box width="100%">
      <Typography variant="h6" sx={{ mt: 4, mb: 1.5 }}>
        Annual Energy Usage & Cost - Existing Fans
      </Typography>

      <Box sx={{ overflowX: 'auto' }}>
        <Table aria-label="standard-results-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Usage (kWh)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Cost ($)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ padding: '0' }}>
              <TableCell>
                {round(results?.existingFans?.energyTotal, 1)}
              </TableCell>
              <TableCell>
                {`$${round(results?.existingFans?.totalCost, 100)}`}
              </TableCell>
            </TableRow>
            <TableRow sx={{ padding: '0' }}>
              <TableCell>{`${round(
                results?.existingFans?.usagePerDay
              )} / Day`}</TableCell>
              <TableCell>{`$${round(
                results?.existingFans?.costPerDay
              )} / Day`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default ExistingFansCostTable
