import { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { AppBarSpacer } from '../layout/helpers/styled'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import RegionPickerTile from 'components/RegionPickerTile'
import ProductivitySavings from './components/ProductivitySavings'
import Info from './components/Info'
import InputsTile from './components/InputsTile'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { useProjectResults } from 'hooks/useProjectResults'
import { usePermissions } from 'hooks/usePermissions'
import { useEmployeeSavingsState } from 'store'
import { axiosHelper } from 'utils'

import EmployeeSavingsCalcPdf from './EmployeeSavingsCalcPdf'

const REGIONS = {
  '1A': 1,
  '2A': 2,
  '2B': 3,
  '3A': 4,
  '3BLA': 5,
  '3BVegas': 6,
  '3C': 7,
  '4A': 8,
  '4B': 9,
  '4C': 10,
  '5A': 11,
  '5B': 12,
  '6A': 13,
  '6B': 14,
  '7A': 15,
}

const EmployeeSavingsCalc = ({ standalone }: { standalone?: boolean }) => {
  const { user } = usePermissions()
  const employeeSavingsState = useEmployeeSavingsState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const {
    region,
    airSpeedCoolingEffect,
    coolingSetPoint,
    heatingSetPoint,
    radiantHeatEffect,
    leadSaved,
    coolingWeatherData,
    heatingWeatherData,
    results,
    numOfEmployees,
    averageHours,
    averageWage,
    solution,
  } = employeeSavingsState.get()
  const [loading, setLoading] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const componentRef = useRef<HTMLDivElement>(null)
  const hasWeatherData = coolingWeatherData || heatingWeatherData || results
  const resultsDisabled =
    !numOfEmployees || !averageHours || !averageWage || !solution

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      employeeSavingsState.loadState(newState)
    }
  }, [projectResults, employeeSavingsState, resultsLoaded])

  // Get weather data
  useEffect(() => {
    if (region && !hasWeatherData && !loading) {
      const key = REGIONS[region as keyof typeof REGIONS]
      if (!key) return
      axiosHelper({
        url: '/weather-data-points/find',
        method: 'post',
        data: {
          classification_id: 3,
          climate_zone_id: key,
        },
        success: (values: any) => {
          employeeSavingsState.setCoolingWeatherData(values?.data || [])
        },
      })
      axiosHelper({
        url: '/weather-data-points/find',
        method: 'post',
        data: {
          classification_id: 12,
          climate_zone_id: key,
        },
        success: (values: any) => {
          employeeSavingsState.setHeatingWeatherData(values?.data || [])
        },
      })
    }
  }, [region, hasWeatherData, employeeSavingsState, loading])

  const TITLE = 'Employee Savings Calculator'

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          employeeSavingsState.setResults()
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => employeeSavingsState.resetState()}
          toolState={{
            ...employeeSavingsState.get(),
            coolingWeatherData: [],
            heatingWeatherData: [],
            toolName: TITLE,
          }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          valid={!resultsDisabled}
          canSave={results}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => employeeSavingsState.setLeadSaved(true)}
          onLeadsModalClose={() => {
            if (!leadSaved) employeeSavingsState.resetData()
          }}
          printContent={() => componentRef.current}
          renderAfterActionButtons={() => (
            <Button
              variant="text"
              disableElevation
              disabled={resultsDisabled}
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => employeeSavingsState.setResults()}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-Employee-Savings-Calc',
                        email,
                        airSpeedCoolingEffect,
                        coolingSetPoint,
                        heatingSetPoint,
                        radiantHeatEffect,
                        region,
                        results,
                        numOfEmployees,
                        averageHours,
                        averageWage,
                        solution,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<EmployeeSavingsCalcPdf />}
                    fileName="BAF-Employee-Savings-Calc.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />
        <Box ref={componentRef}>
          <PrintHeader title={TITLE} />
          <style type="text/css">
            {'@media print{@page {size: 10.5in 8in}}'}
          </style>

          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <InputsTile region={region} />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <RegionPickerTile
                state={employeeSavingsState}
                styles={{ mt: mediumBreakpoint ? 6 : 0 }}
                region={region}
                onZipChange={value => {
                  employeeSavingsState.setZip(value)
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <ProductivitySavings />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <Info />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default EmployeeSavingsCalc
