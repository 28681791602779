import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { grey } from '@mui/material/colors'
import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlined from '@mui/icons-material/CircleOutlined'

import { ListDataObj } from '../helpers/interfaces'

const BulletedList = ({ data, indentLevel }: ListDataObj) => (
  <List dense disablePadding>
    {data.map((point, index) => (
      <ListItem
        key={index}
        disableGutters
        alignItems="flex-start"
        sx={{ mt: -1 }}
      >
        <ListItemIcon>
          {!indentLevel && (
            <CircleIcon sx={{ color: grey[900], fontSize: 11, ml: 2 }} />
          )}
          {indentLevel && (
            <CircleOutlined
              sx={{ color: grey[900], fontSize: 10, ml: 7, mt: 0.5 }}
            />
          )}
        </ListItemIcon>
        <ListItemText sx={{ ml: 3 * (indentLevel ?? 0) }} disableTypography>
          {point}
        </ListItemText>
      </ListItem>
    ))}
  </List>
)

export default BulletedList
