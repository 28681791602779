import { Text, View, Image, StyleSheet } from '@react-pdf/renderer'

const PdfEnvironmentalImpact = ({ results }: { results: any[] }) => {
  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const getIconUrl = (iconName: string) => {
    if (iconName === 'Co2') return '/pdfImages/co2.png'
    if (iconName === 'GasMeter') return '/pdfImages/board.png'
    if (iconName === 'Grain') return '/pdfImages/dots.png'
    if (iconName === 'DirectionsCar') return '/pdfImages/car.png'
    if (iconName === 'Lightbulb') return '/pdfImages/bulb.png'
    return '/pdfImages/tree.png'
  }

  const styles = StyleSheet.create({
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
  })

  return (
    <View>
      <Text style={{ ...styles.label, marginTop: '16px' }}>
        Annual Environmental Impact
      </Text>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        {results?.map((item: any) => (
          <View
            key={item?.title}
            style={{
              flexDirection: 'row',
              width: '50%',
              paddingVertical: '4px',
            }}
          >
            <View
              style={{
                width: '22px',
                height: '22px',
                border: '2px solid #FFC425',
                borderRadius: '4px',
                marginRight: '4px',
              }}
            >
              <Image style={{ padding: '1px' }} src={getIconUrl(item?.icon)} />
            </View>
            <View style={{ flexDirection: 'column' }}>
              <Text style={styles.value}>{item?.title}</Text>
              <Text style={styles.label}>{`${round(item?.value, 1)} ${
                item?.metric
              }`}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

export default PdfEnvironmentalImpact
