import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import useMediaQuery from '@mui/material/useMediaQuery'
import InfoIcon from '@mui/icons-material/Info'

import { useFanEnergyEstimatorState } from 'store'

const HOURS_PER_YEAR = 8760
const DAYS_PER_YEAR = 365

const DoeCostTable = ({ isAireyeMode = false }: { isAireyeMode: boolean }) => {
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const { results, electricCost } = fanEnergyEstimatorState.get()
  const data = results?.doeCost
  const totalLargeFanUsage = data?.largeFansUsage * DAYS_PER_YEAR
  const totalHssdFansUsage = data?.hssdFansUsage * DAYS_PER_YEAR
  const totalLssdFansUsage = data?.lssdFansUsage * DAYS_PER_YEAR

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  if (isAireyeMode) return null

  return (
    <Box width="100%">
      <Typography variant="h6" sx={{ mb: 1.5 }}>
        Annual Energy Usage & Energy Cost - Department of Energy Method
        <InfoIcon
          data-tooltip-id="standard-tooltip"
          data-tooltip-content={
            mediumBreakpoint
              ? 'Per CFR 10 Part 429 and 430, the Department of Energy dictates that HVLS and Small Diameter (LSSD/HSSD) fan energy must also be reported using the methodologies therin. Note that the BAF method accounts for the specific location and application selected above whereas the DOE method is a national average for all customers, locations, and applications.'
              : ''
          }
          sx={{ ml: 0.5, pt: 0.5, mb: -0.25 }}
        />
      </Typography>

      <Box sx={{ overflowX: 'auto' }}>
        <Table aria-label="standard-results-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Type
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Days
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Total Hours
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Fan Quantity
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Active Hours
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Standby Hours
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Usage (kWh)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Cost ($)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ padding: '0' }}>
              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                Large Diameter Fans (HVLS)
              </TableCell>
              <TableCell>{data?.largeFans?.length ? '365' : '-'}</TableCell>
              <TableCell>
                {data?.largeFans?.length ? HOURS_PER_YEAR : '-'}
              </TableCell>
              <TableCell>{data?.largeFans?.length || '-'}</TableCell>
              <TableCell>
                {data?.largeFans?.length ? data?.activeLargeFanHours : '-'}
              </TableCell>
              <TableCell>
                {data?.largeFans?.length ? data?.activeLargeFanHours : '-'}
              </TableCell>
              <TableCell>
                {data?.largeFans?.length
                  ? `${round(totalLargeFanUsage, 1)} (${round(
                      data?.largeFansUsage,
                      10
                    )} / Day) `
                  : '-'}
              </TableCell>
              <TableCell>
                {data?.largeFans?.length
                  ? `$${round(totalLargeFanUsage * electricCost, 100).toFixed(
                      2
                    )} ($${round(
                      (totalLargeFanUsage * electricCost) / DAYS_PER_YEAR,
                      100
                    ).toFixed(2)} / Day) `
                  : '-'}
              </TableCell>
            </TableRow>

            <TableRow sx={{ padding: '0' }}>
              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                HSSD Fans (Haiku/Res)
              </TableCell>
              <TableCell>{data?.hssdFans?.length ? '365' : '-'}</TableCell>
              <TableCell>
                {data?.hssdFans?.length ? HOURS_PER_YEAR : '-'}
              </TableCell>
              <TableCell>{data?.hssdFans?.length || '-'}</TableCell>
              <TableCell>
                {data?.hssdFans?.length ? data?.activeHssdFanHours : '-'}
              </TableCell>
              <TableCell>
                {data?.hssdFans?.length ? data?.activeHssdFanHours : '-'}
              </TableCell>
              <TableCell>
                {data?.hssdFans?.length
                  ? `${round(totalHssdFansUsage, 1)} (${round(
                      data?.hssdFansUsage,
                      10
                    )} / Day) `
                  : '-'}
              </TableCell>
              <TableCell>
                {data?.hssdFans?.length
                  ? `$${round(totalHssdFansUsage * electricCost, 100).toFixed(
                      2
                    )} ($${round(
                      (totalHssdFansUsage * electricCost) / DAYS_PER_YEAR,
                      100
                    ).toFixed(2)} / Day) `
                  : '-'}
              </TableCell>
            </TableRow>

            <TableRow sx={{ padding: '0' }}>
              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                LSSD Fans (es6/Res)
              </TableCell>
              <TableCell>{data?.lssdFans?.length ? '365' : '-'}</TableCell>
              <TableCell>
                {data?.lssdFans?.length ? HOURS_PER_YEAR : '-'}
              </TableCell>
              <TableCell>{data?.lssdFans?.length || '-'}</TableCell>
              <TableCell>
                {data?.lssdFans?.length
                  ? `${data?.activeLssdFanHoursHigh} / ${data?.activeLssdFanHoursLow}`
                  : '-'}
              </TableCell>
              <TableCell>
                {data?.lssdFans?.length ? data?.standbyLssdFanHours : '-'}
              </TableCell>
              <TableCell>
                {data?.lssdFans?.length
                  ? `${round(totalLssdFansUsage, 1)} (${round(
                      data?.lssdFansUsage,
                      10
                    )} / Day) `
                  : '-'}
              </TableCell>
              <TableCell>
                {data?.lssdFans?.length
                  ? `$${round(totalLssdFansUsage * electricCost, 100).toFixed(
                      2
                    )} ($${round(
                      (totalLssdFansUsage * electricCost) / DAYS_PER_YEAR,
                      100
                    ).toFixed(2)} / Day) `
                  : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default DoeCostTable
