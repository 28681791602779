import { useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import ArticleIcon from '@mui/icons-material/Article'
import CancelIcon from '@mui/icons-material/Cancel'

import {
  useScheduleBuilderState,
  APIProduct,
  APIProductData,
  APIProductSizeData,
} from 'store/scheduleBuilderState'
import { axiosHelper } from 'utils'
import ProductSelect from 'components/ProductSelect'
import { printedNotesRange, usePrintedNotes } from '../hooks/usePrintedNotes'

interface APIProductSize {
  id: number
  size: string
}
interface TableProps {
  id: number
  products: APIProduct[]
  onNoteClick: (id: number) => void
  showHeader: boolean
  combineNotes: boolean
}
interface APIScheduleBuilderNote {
  id: number
  note: string
}

const DataTable = ({
  id,
  products = [],
  onNoteClick,
  showHeader = true,
  combineNotes = false,
}: TableProps) => {
  const scheduleBuilderState = useScheduleBuilderState()
  const { schedules } = scheduleBuilderState.getState()
  const num_schedules = Object.keys(schedules).length
  const product = schedules?.[id]?.product
  const sizeData = schedules?.[id]?.sizeData
  const productDetails = schedules?.[id]?.productDetails
  const productData = schedules?.[id]?.productData
  const voltageData = schedules?.[id]?.voltageData
  const mountData = schedules?.[id]?.mountData
  const sizes = productData?.sizes
  const schedule_builder_notes = productData?.schedule_builder_notes
  const num_airfoils = productData?.num_airfoils
  const schedule_builder_controller = productData?.schedule_builder_controller
  const {
    tag,
    size,
    sizeId,
    voltage,
    validated,
    notes_start,
    currNotes,
    prevNotes,
  } = productDetails.value
  const { notes } = usePrintedNotes()
  const notesRange = printedNotesRange({ notes, id: product?.id })

  const sizeOptions = sizes
    .map((s: APIProductSize) => s.size)
    .filter(
      (o: any) =>
        (product.name === 'CB6' && o !== "12'") ||
        (product.name === 'Powerfoil 8' && o !== "8'" && o !== "10'") ||
        (product.name !== 'Powerfoil 8' && product.name !== 'CB6')
          ? o
          : false
    ).sort((a: string, b: string) => parseInt(a) - parseInt(b))
  const [voltageOptions, setVoltageOptions] = useState([])
  const firstNotes = schedules[0].productData.schedule_builder_notes.map(
    (n: APIScheduleBuilderNote, index: number) => index + 1
  )
  const whiteUnderline = {
    borderBottom: 'white',
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
  }
  const tableCell = {
    textAlign: 'center',
    border: 1,
    borderColor: 'lightgray',
    fontSize: '0.8rem',
    lineHeight: '1rem',
    p: 0.5,
  }

  useEffect(() => {
    if (combineNotes) {
      if (id > 0) {
        scheduleBuilderState.setFormValue(
          num_schedules - 1,
          'productDetails',
          'notes_start',
          schedules[num_schedules - 2].productDetails.value.notes_start +
            schedules[num_schedules - 2].productData.schedule_builder_notes
              .length
        )
        for (let i = 0; i < num_schedules - 1; i++) {
          let compareNotes = JSON.parse(
            JSON.stringify(schedules[i].productData.schedule_builder_notes)
          )
          if (i !== id) {
            for (let j = 0; j < schedule_builder_notes.length; j++) {
              if (
                j ===
                JSON.parse(JSON.stringify(schedule_builder_notes)).length - 1
              )
                break
              let note = JSON.parse(JSON.stringify(schedule_builder_notes))[j]
              if (!!note) {
                let index = compareNotes.findIndex(
                  (n: APIScheduleBuilderNote) => n.id === note.id
                )
                // found same note in previous product's notes
                if (index !== -1) {
                  scheduleBuilderState.removeNote(id, j) // remove from current notes
                  let joined = prevNotes.concat(
                    index + schedules[i].productDetails.value.notes_start
                  )
                  scheduleBuilderState.setFormValue(
                    id,
                    'productDetails',
                    'prevNotes',
                    joined
                  ) // add other note index to list
                }
              }
            }
          }
        }
        let current = schedule_builder_notes.map(
          (n: APIScheduleBuilderNote, index: number) => index + notes_start
        )
        scheduleBuilderState.setFormValue(
          id,
          'productDetails',
          'currNotes',
          current
        )
      } else if (currNotes.length === 0) {
        scheduleBuilderState.setFormValue(
          id,
          'productDetails',
          'currNotes',
          firstNotes
        )
      }
    }

    if (sizes.length === 1) {
      scheduleBuilderState.setFormValue(
        id,
        'productDetails',
        'size',
        sizes[0].size
      )
      scheduleBuilderState.setFormValue(
        id,
        'productDetails',
        'sizeId',
        sizes[0].id
      )
      const sizeSuccess = ({ data }: { data: APIProductSizeData }) => {
        if (data) scheduleBuilderState.setSizeData(data, id)
        sizeData?.voltages &&
          setVoltageOptions(sizeData?.voltages.map((v: any) => v.voltage))
      }
      axiosHelper({
        url: '/product-sizes/find',
        method: 'post',
        data: { product_id: product.id, size_id: sizes[0].id },
        success: sizeSuccess,
      })
    } else if (size) {
      const sizeSuccess = ({ data }: { data: APIProductSizeData }) => {
        scheduleBuilderState.setSizeData(data, id)
        sizeData?.voltages &&
          setVoltageOptions(sizeData?.voltages.map((v: any) => v.voltage))
      }
      sizeId &&
        axiosHelper({
          url: '/product-sizes/find',
          method: 'post',
          data: { product_id: product.id, size_id: sizeId },
          success: sizeSuccess,
        })
    }
    if (voltageOptions.length === 1) {
      scheduleBuilderState.setFormValue(
        id,
        'productDetails',
        'voltage',
        voltageOptions[0]
      )
      scheduleBuilderState.setVoltageData(
        sizeData?.voltages.find((v: any) => v.voltage === voltageOptions[0]),
        id
      )
    }
    if (product.template === 'Haiku' && sizeData?.size !== '') {
      scheduleBuilderState.setVoltageData(sizeData?.voltages[0], id)
      if (product.name !== 'Haiku L' && product.name !== 'i6') {
        scheduleBuilderState.setMountData(
          sizeData?.mounts.find(
            (m: any) =>
              m.mount === 'Universal' &&
              m.drop_tube === '7 in' &&
              m.material.label === 'Aluminum'
          ),
          id
        )
      } else if (product.name === 'i6') {
        scheduleBuilderState.setMountData(
          sizeData?.mounts.find(
            (m: any) =>
              m.mount === 'Standard' &&
              m.drop_tube === '6 in' &&
              m.material.label === 'Aluminum'
          ),
          id
        )
      }
    }
    if (product.name === 'Yellow Jacket' && sizeData?.size !== '') {
      scheduleBuilderState.setMountData(
        sizeData?.mounts.find((m: any) => m.mount === 'Standard'),
        id
      )
    }
    // eslint-disable-next-line
  }, [
    size,
    sizeId,
    sizes?.length,
    sizeData?.voltages?.length,
    product?.id,
    num_schedules,
  ])

  return (
    <>
      <TableHead>
        <TableRow
          sx={{
            display: `${showHeader ? 'table-row' : 'none'}`,
          }}
        >
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
            }}
          >
            Tag
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 120,
            }}
          >
            Manufacturer
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 190,
            }}
          >
            Model
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 100,
            }}
          >
            Size
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 140,
            }}
          >
            Volt/PH
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 80,
            }}
          >
            # of Airfoils
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 80,
            }}
          >
            Weight (lbs)
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 100,
            }}
          >
            Sound Level (dBA)
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 70,
            }}
          >
            Motor
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 50,
            }}
          >
            RPM
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 110,
            }}
          >
            Control
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 130,
            }}
          >
            {voltage &&
            !voltageData?.max_amps &&
            !voltageData?.full_load_amperage
              ? 'Max Wattage (W)'
              : 'Full Load Current (Amps)'}
          </TableCell>
          <TableCell
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 100,
            }}
          >
            Circuit Size (Amps)
          </TableCell>
          <TableCell
            className="printable-view-notes-cell"
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 50,
            }}
          >
            Notes
          </TableCell>
          <TableCell
            className="printable-remove-notes-cell"
            sx={{
              ...tableCell,
              fontWeight: 600,
              color: 'gray',
              fontSize: 11,
              width: 50,
            }}
          >
            Remove
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell sx={{ ...tableCell, width: 100, minWidth: 80 }}>
            <TextField
              variant="standard"
              sx={whiteUnderline}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              value={tag}
              onChange={event => {
                const tag = event.target.value
                scheduleBuilderState.setFormValue(
                  id,
                  'productDetails',
                  'tag',
                  tag
                )
              }}
            />
          </TableCell>
          <TableCell sx={tableCell}>Big Ass Fans</TableCell>
          <TableCell
            sx={{ ...tableCell, textTransform: 'none', textAlign: 'left' }}
          >
            <ProductSelect
              clearIcon={null}
              styles={{ minWidth: 190 }}
              value={product.name}
              inputValue={product.name ?? ''}
              onChange={value => {
                scheduleBuilderState.setProduct(
                  products.find(p => p.name === value)!,
                  id
                )
                const productSuccess = ({ data }: { data: APIProductData }) => {
                  scheduleBuilderState.setProductData(data, id)
                }
                axiosHelper({
                  url: `/products/relations/${product.id}`,
                  success: productSuccess,
                })
              }}
            />
          </TableCell>
          <TableCell sx={tableCell}>
            <Autocomplete
              disablePortal
              size="small"
              options={sizeOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  variant="standard"
                  error={validated && size === ''}
                  sx={whiteUnderline}
                />
              )}
              sx={{ minWidth: 80 }}
              value={size}
              onChange={(event, newSize) => {
                event.preventDefault()
                scheduleBuilderState.setFormValue(
                  id,
                  'productDetails',
                  'size',
                  newSize
                )
                scheduleBuilderState.setFormValue(
                  id,
                  'productDetails',
                  'sizeId',
                  sizes.find((s: APIProductSize) => s.size === newSize)?.id
                )
                scheduleBuilderState.setFormValue(
                  id,
                  'productDetails',
                  'voltage',
                  null
                )
              }}
              inputValue={size ?? ''}
            />
          </TableCell>
          <TableCell sx={tableCell}>
            {sizeData?.voltages && (
              <Autocomplete
                disablePortal
                size="small"
                options={voltageOptions}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    error={validated && voltage === null}
                    sx={whiteUnderline}
                  />
                )}
                sx={{ minWidth: 140 }}
                value={voltage}
                onChange={(event, newVolt) => {
                  event.preventDefault()
                  scheduleBuilderState.setFormValue(
                    id,
                    'productDetails',
                    'voltage',
                    newVolt
                  )
                  scheduleBuilderState.setVoltageData(
                    sizeData?.voltages.find((v: any) => v.voltage === newVolt),
                    id
                  )
                }}
                inputValue={voltage ?? ''}
              />
            )}
          </TableCell>
          <TableCell sx={tableCell}>
            {num_airfoils === -1 ? '' : num_airfoils ?? 'N/A'}
          </TableCell>
          <TableCell sx={tableCell}>
            {sizeData?.size !== '' && sizeData?.weight ? sizeData?.weight : ''}
            {sizeData?.size !== '' && mountData?.weight ? mountData?.weight : ''}
          </TableCell>
          <TableCell sx={tableCell}>
            {sizeData?.size !== '' && sizeData?.max_sound?.includes('dBA')
              ? sizeData?.max_sound?.substring(
                  0,
                  sizeData?.max_sound?.indexOf('dBA')
                )
              : '---'}
          </TableCell>
          <TableCell sx={tableCell}>
            {(sizeData?.size !== '' &&
              (sizeData?.motor || sizeData?.schedule_builder_motor) &&
              sizeData?.schedule_builder_motor) ??
              sizeData?.motor}
          </TableCell>
          <TableCell sx={tableCell}>
            {(sizeData?.size !== '' && voltage && sizeData?.rpm) ??
              voltageData?.rpm ??
              mountData?.rpm ??
              'N/A'}
          </TableCell>
          <TableCell sx={tableCell}>
            {schedule_builder_controller ?? 'N/A'}
          </TableCell>
          <TableCell sx={tableCell}>
            {(sizeData?.size !== '' &&
              voltage &&
              (voltageData?.max_amps || voltageData?.full_load_amperage) &&
              voltageData?.max_amps) ??
              voltageData?.full_load_amperage}
            {(sizeData?.size !== '' &&
              voltage &&
              !voltageData?.max_amps &&
              !voltageData?.full_load_amperage &&
              mountData?.max_watts) ??
              voltageData?.max_watts}
          </TableCell>
          <TableCell sx={tableCell}>
            {(sizeData?.size !== '' &&
              voltage &&
              voltageData.supply_circuit_size) ??
              'N/A'}
          </TableCell>
          <TableCell sx={tableCell}>
            <Button
              className="printable-view-notes-button"
              disableElevation
              size="large"
              sx={{ border: 'none', p: 0.5 }}
              onClick={() => onNoteClick && onNoteClick(id)}
            >
              <ArticleIcon />
            </Button>
            <Box
              className="printable-view-notes-range"
              sx={{ display: 'none' }}
            >
              {notesRange}
            </Box>
          </TableCell>
          <TableCell sx={tableCell} className="printable-remove-notes-cell">
            <Button
              disableElevation
              size="large"
              sx={{ border: 'none', p: 0.5 }}
              onClick={() => scheduleBuilderState.removeSchedule(id)}
            >
              <CancelIcon />
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  )
}

export default DataTable
