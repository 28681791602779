import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import ProjectDetails from '../components/ProjectDetails'
import ProductDetails from '../components/ProductDetails'
import Logo from '../components/Logo'
import Notes from '../components/Notes'
import MountingHardware from '../components/MountingHardware'
import Warranty from '../components/Warranty'
import CriticalDimensions from '../components/CriticalDimensions'
import Additions from '../components/Additions'
import AppearanceOptions from '../components/AppearanceOptions'
import Features from '../components/Features'
import TechnicalDrawings from '../components/TechnicalDrawings'
import Links from 'components/Links'
import { useSubmittalsState } from 'store/salesToolsState'

const SweatBeeTemplate = () => {
  const { fan, productData, sizeData, productDetails, mountingHardware } =
    useSubmittalsState().getState()
  const { size } = productDetails.value
  const mount = mountingHardware.value.mount
  return (
    <Stack spacing={0} display="block">
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Logo
          name={
            fan.name === 'Sweat Bee Swivel'
              ? fan.name
              : `${fan.name} ${size ?? ''}`
          }
        />
        <Links links={productData.links} mt={0} />
      </Stack>
      <Grid container spacing={2}>
        <ProjectDetails />
        {productData.id !== -1 && <ProductDetails />}
        {productData.id !== -1 && <Features />}
        {sizeData.size !== '' && <MountingHardware />}
        {sizeData.size !== '' && mount && <CriticalDimensions />}
        {productData.id !== -1 && <AppearanceOptions />}
        {sizeData.size !== '' && <TechnicalDrawings />}
        {productData.id !== -1 && <Additions />}
        {productData.id !== -1 && <Warranty />}
        {productData.id !== -1 && <Notes />}
      </Grid>
    </Stack>
  )
}

export default SweatBeeTemplate
