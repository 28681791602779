import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from 'config/constants'

const SIZE_MULTIPLIER = 0.75

export interface DestratToolState {
  leadSaved: boolean
  results: any

  // Data
  productsData: any

  // Input values
  isMetric: boolean
  isBasedOnInputs: boolean
  airfoilHeight: number
  designAirTurnovers: number
  floorToCeilingHeight: number
  length: number
  width: number
  bafContact: string
  projectName: string
  projectDate: string
  installLocation: string
  product: any
  fanQuantities: number[]
}

const initialDestratToolState = {
  leadSaved: false,
  results: null,

  // Data
  productsData: [],
  fanQuantities: [] as number[],

  // Input values
  isMetric: false,
  isBasedOnInputs: false,
  airfoilHeight: 0,
  designAirTurnovers: 0,
  floorToCeilingHeight: 0,
  length: 0,
  width: 0,
  projectDate: '',
  bafContact: '',
  projectName: '',
  installLocation: '',
  product: null,
}

const destratToolState = createState(initialDestratToolState)

const wrapDestratToolState = (s: State<DestratToolState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialDestratToolState,
      productsData: p.productsData,
    })),
  setProjectName: (value: string) => {
    s.set((p: any) => ({
      ...p,
      projectName: value,
      results: null,
    }))
  },
  resetFanQauntities: () => {
    s.set((p: any) => ({
      ...p,
      fanQuantities: [],
    }))
  },
  setBafContact: (value: string) => {
    s.set((p: any) => ({ ...p, bafContact: value, results: null }))
  },
  setLength: (value: number) => {
    s.set((p: any) => ({ ...p, length: value, results: null }))
  },
  setAirfoilHeight: (value: number) => {
    s.set((p: any) => ({ ...p, airfoilHeight: value, results: null }))
  },
  setDesignAirTurnovers: (value: number) => {
    s.set((p: any) => ({ ...p, designAirTurnovers: value, results: null }))
  },
  setWidth: (value: number) => {
    s.set((p: any) => ({ ...p, width: value, results: null }))
  },
  setProductsData: (value: any) => {
    s.set((p: any) => ({ ...p, productsData: value, results: null }))
  },
  setProduct: (value: any) => {
    s.set((p: any) => ({ ...p, product: value, results: null }))
  },
  setFloorToCeilingHeight: (value: number) => {
    s.set((p: any) => ({ ...p, floorToCeilingHeight: value, results: null }))
  },
  setProjectDate: (value: string) => {
    s.set((p: any) => ({ ...p, projectDate: value, results: null }))
  },
  setIsMetric: (value: boolean) => {
    s.set((p: any) => ({ ...p, isMetric: value, results: null }))
  },
  setIsBasedOnInputs: (value: boolean) => {
    s.set((p: any) => ({ ...p, isBasedOnInputs: value, results: null }))
  },
  setInstallLocation: (value: string) => {
    s.set((p: any) => ({ ...p, installLocation: value, results: null }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setFanQuantity: (value: number, index: number) => {
    s.set((p: any) => {
      const newFanQauntities = [...p?.fanQuantities]
      newFanQauntities[index] = value
      return { ...p, fanQuantities: newFanQauntities as number[] }
    })
  },
  setResults: () => {
    s.set((p: any) => {
      // Get volume of area
      const floorArea = p?.length * p?.width
      const airVolume = floorArea * p?.floorToCeilingHeight
      const volumeConversion = p?.isMetric ? airVolume * 35.3147 : airVolume
      const heightConversion = p?.isMetric
        ? p?.airfoilHeight * 3.28084
        : p?.airfoilHeight

      // Get product data for selected product
      const productId = p?.product.id
      const productData = p?.productsData?.filter(
        (product: any) => product?.product_id === productId
      )

      // Calculate results per product size
      const productResults = productData?.map((product: any, index: number) => {
        const fanQty20Percent =
          (volumeConversion * p?.designAirTurnovers) /
          (product?.cfm_20_percent * 60)
        const fanQty40Percent =
          (volumeConversion * p?.designAirTurnovers) /
          (product?.cfm_40_percent * 60)

        // Get same size fan values
        const nominal =
          heightConversion < product?.decision_height
            ? fanQty20Percent
            : fanQty40Percent
        const proxCheck = nominal - Math.floor(nominal)
        const roundUp = Math.ceil(nominal)
        const report = proxCheck < 0.05 ? Math.floor(nominal) : roundUp

        // Get diff size fan values
        const decisionHeight = product?.decision_height || 0
        const reportedCfm =
          heightConversion < decisionHeight
            ? product?.cfm_20_percent
            : product?.cfm_40_percent
        const fanQuantity = p?.isBasedOnInputs
          ? p?.fanQuantities[index] || 0
          : report
        const cfmSubTot = fanQuantity * reportedCfm || 0

        // Get clearance
        const minClearance = product?.min_clearance || 0
        const heightDiff = p?.floorToCeilingHeight - p?.airfoilHeight
        const deckClearanceConversion = p?.isMetric
          ? heightDiff * 3.28084
          : heightDiff

        // Get min height
        const fanSize = parseInt(product?.size?.replace("'", ''))
        const minHeight = Math.max(
          product?.ul_height,
          fanSize * SIZE_MULTIPLIER
        )

        // Check if fan is within clearance
        const isSizeOkay = deckClearanceConversion >= minClearance
        const isSizeOkayHeightAFF = volumeConversion >= minHeight
        const meetsSizeRequirements = isSizeOkay && isSizeOkayHeightAFF

        // Get value based on clearance
        const value = p?.isBasedOnInputs
          ? fanQuantity
          : isSizeOkay
          ? report
          : 'Clearance Guidelines Unsatisfied'

        return {
          name: `${product?.size} ${p?.product?.name}`,
          value,
          cfmSubTot,
          meetsSizeRequirements,
        }
      })

      // Get delivered air turnovers per hour
      const totalCfmPerHour =
        productResults
          ?.map((result: any) => result?.cfmSubTot)
          .reduce((a: number, b: number) => a + b, 0) * 60
      const turnovers =
        Math.round((totalCfmPerHour / volumeConversion) * 10) / 10

      // Get initial fan quantities
      const fanQuantities = p?.isBasedOnInputs
        ? productResults?.map((product: any) => product.value)
        : []

      // Build results
      const results = {
        productResults,
        floorArea,
        airVolume,
        turnovers,
        totalCfmPerHour,
      }

      return { ...p, fanQuantities, results }
    })
  },
})

export const accessDestratToolState = () =>
  wrapDestratToolState(destratToolState)

export const useDestratToolState = () => {
  const state = useState(destratToolState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-destrat-tool`))
  return wrapDestratToolState(state)
}
