import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import StyledTextField from '../../../components/StyledTextField'
import QuantityField from './QuantityField'
import { useSubmittalsState, APIProductSizeData } from 'store/salesToolsState'
import { axiosHelper } from '../../../utils'
import { useState, useEffect } from 'react'

interface ProductDetailsProps {
  finish?: string | null
}

interface APIProductSize {
  id: number
  size: string
}

const ProductDetails = ({ finish }: ProductDetailsProps) => {
  const submittalsState = useSubmittalsState()
  const {
    fan,
    productData,
    sizeData,
    productDetails,
    mountingHardware,
    controllerFeatures,
    validated,
    numCols,
    fuelData,
    mountData,
    voltageData,
  } = submittalsState.getState()
  const { size, sizeId, voltage, model, controlQty, fuelType } =
    productDetails.value
  const mount = mountingHardware.value.mount
  const singleMulti = controllerFeatures.value.singleMulti
  const sizes = productData.sizes
  const sizeOptions = sizes
    .map((s: APIProductSize) => s.size)
    .filter((o: any) => {
      if (fan.name === 'CB6') {
        return o !== "12'"
      } else if (fan.name === 'Powerfoil 8') {
        return o !== "8'" && o !== "10'"
      } else {
        return true
      }
    })
    .sort((a: string, b: string) => parseInt(a) - parseInt(b))
  const [voltageOptions, setVoltageOptions] = useState([] as string[])
  const [fuelOptions, setFuelOptions] = useState([] as string[])
  const [prevFuel, setPrevFuel] = useState(fuelType)
  const [prevVoltage, setPrevVoltage] = useState(voltage)
  const [prevMount, setPrevMount] = useState(mount)
  useEffect(() => {
    if (sizes.length === 1) {
      submittalsState.setFormValue('productDetails', 'size', sizes[0].size)
      submittalsState.setFormValue('productDetails', 'sizeId', sizes[0].id)
      const sizeSuccess = ({ data }: { data: APIProductSizeData }) => {
        submittalsState.setSizeData(data)
        sizeData.voltages &&
          setVoltageOptions(sizeData.voltages.map((v: any) => v.voltage))
        sizeData.fuels && setFuelOptions(sizeData.fuels.map((f: any) => f.fuel))
      }
      axiosHelper({
        url: '/product-sizes/find',
        method: 'post',
        data: { product_id: fan.id, size_id: sizes[0].id },
        success: sizeSuccess,
      })
    } else if (size || model) {
      const sizeSuccess = ({ data }: { data: APIProductSizeData }) => {
        submittalsState.setSizeData(data)
        let voltageOpts = sizeData.voltages.map((v: any) => v.voltage)
        let fuelOpts = sizeData.fuels.map((f: any) => f.fuel)
        let mountOpts = sizeData.mounts.map((m: any) => m.mount)
        sizeData.voltages && setVoltageOptions(voltageOpts)
        sizeData.fuels && setFuelOptions(fuelOpts)
        if (!voltageOpts.includes(prevVoltage))
          submittalsState.setFormValue('productDetails', 'voltage', null)
        if (!fuelOpts.includes(prevFuel))
          submittalsState.setFormValue('productDetails', 'fuelType', null)
        if (!mountOpts.includes(prevMount))
          submittalsState.setFormValue('mountingHardware', 'mount', null)
      }
      sizeId &&
        axiosHelper({
          url: '/product-sizes/find',
          method: 'post',
          data: { product_id: fan.id, size_id: sizeId },
          success: sizeSuccess,
        })
    }
    if (sizeData.fuels.length === 1) {
      submittalsState.setFormValue(
        'productDetails',
        'fuelType',
        sizeData.fuels[0].fuel
      )
      submittalsState.setFuelData(
        sizeData.fuels.find((f: any) => f.fuel === sizeData.fuels[0].fuel)
      )
    }
    if (sizeData.voltages.length === 1) {
      submittalsState.setFormValue(
        'productDetails',
        'voltage',
        sizeData.voltages[0].voltage
      )
      submittalsState.setVoltageData(
        sizeData.voltages.find(
          (v: any) => v.voltage === sizeData.voltages[0].voltage
        )
      )
    }
    // eslint-disable-next-line
  }, [sizeId, fan.id])
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Product Details
        </Typography>
        {fan.template === 'BAFCon' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={['Single', 'Multiple']}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Fan Control"
                    error={validated && singleMulti === null}
                  />
                )}
                value={singleMulti}
                onChange={(event, newVal) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'controllerFeatures',
                    'singleMulti',
                    newVal
                  )
                }}
                inputValue={singleMulti ?? ''}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <TextField
                required
                label="Quantity"
                size="small"
                type="number"
                name="Qty"
                value={controlQty}
                onChange={e => {
                  submittalsState.setFormValue(
                    'productDetails',
                    'controlQty',
                    e.target.value
                  )
                }}
                error={validated && controlQty < 1}
                margin="dense"
                variant="standard"
                sx={{ width: '100%', mt: 0 }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
        )}
        {fan.template === 'Cool-Space' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={voltageOptions}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Voltage/Phase"
                    error={validated && voltage === null}
                  />
                )}
                value={voltage}
                onChange={(event, newVolt) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'voltage',
                    newVolt
                  )
                  submittalsState.setVoltageData(
                    sizeData.voltages.find((v: any) => v.voltage === newVolt)
                  )
                }}
                inputValue={voltage ?? ''}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Model"
                name="model"
                value={fan.name}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Motor"
                name="motor"
                value={`${sizeData.motor}`}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Standard Weight"
                name="weight"
                value={`${sizeData.weight} lbs`}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Water Capacity"
                name="waterCap"
                value={`${productData?.water_capacity} gal`}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Inlet Water Pressure"
                name="waterPressure"
                value={`${productData?.water_pressure} PSI Max`}
              />
            </Grid>
            {voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Supply Circuit Size"
                  name="supplyCircuitSize"
                  value={`${voltageData.supply_circuit_size} A`}
                />
              </Grid>
            )}
            {voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Amperage"
                  name="maxAmp"
                  value={`${voltageData.max_amps} A`}
                />
              </Grid>
            )}
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Airflow"
                name="airflow"
                value={`${sizeData.airflow}`}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Coverage Area"
                name="coverage"
                value={`${productData?.coverage_area} ft.²`}
              />
            </Grid>
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Max Sound Level"
                name="maxSound"
                value={`${sizeData.max_sound}`}
              />
            </Grid>
          </Grid>
        )}
        {fan.template === 'E Series' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={sizeOptions}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Size"
                    error={validated && size === ''}
                  />
                )}
                value={size}
                onChange={(event, newSize) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'sizeId',
                    sizes.find((s: APIProductSize) => s.size === newSize)?.id
                  )
                  submittalsState.setFormValue(
                    'productDetails',
                    'size',
                    newSize
                  )
                }}
                inputValue={size ?? ''}
              />
            </Grid>
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={voltageOptions}
                  sx={{ pt: 1, mt: -1 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      variant="standard"
                      label="Voltage/Phase"
                      error={validated && voltage === null}
                    />
                  )}
                  value={voltage}
                  onChange={(event, newVolt) => {
                    event.preventDefault()
                    submittalsState.setFormValue(
                      'productDetails',
                      'voltage',
                      newVolt
                    )
                    submittalsState.setVoltageData(
                      sizeData.voltages.find((v: any) => v.voltage === newVolt)
                    )
                  }}
                  inputValue={voltage ?? ''}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Standard Weight"
                  name="stdWeight"
                  value={`${sizeData.weight} lbs`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max RPM"
                  name="maxRPM"
                  value={`${sizeData.rpm} RPM`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Supply Circuit Size"
                  name="supplyCircuitSize"
                  value={`${voltageData.supply_circuit_size} A`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Full Load Amperage"
                  name="fullLoadAmp"
                  value={`${voltageData.full_load_amperage} A`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Sound Level"
                  name="maxSound"
                  value={`${sizeData.max_sound}`}
                />
              </Grid>
            )}
          </Grid>
        )}
        {fan.template === 'Haiku' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={sizeOptions}
                getOptionLabel={option => option.toString()}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Size"
                    error={validated && size === ''}
                  />
                )}
                value={size}
                onChange={(event, newSize) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'size',
                    newSize
                  )
                  setPrevMount(mount)
                  submittalsState.setFormValue(
                    'productDetails',
                    'sizeId',
                    sizes.find((s: APIProductSize) => s.size === newSize)?.id
                  )
                }}
                inputValue={size ?? ''}
              />
            </Grid>
            {sizeData.size !== '' && mount && finish && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max RPM"
                  name="maxRPM"
                  value={`${sizeData.rpm ?? mountData.rpm} RPM`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && mount && finish && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Airflow"
                  name="maxAirflow"
                  value={`${mountData.airflow} CFM`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && mount && finish && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Standard Weight"
                  name="stdWeight"
                  value={`${sizeData.weight ?? mountData.weight} lbs`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && mount && finish && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Wattage"
                  name="maxWatt"
                  value={`${
                    sizeData.voltages[0].max_watts ?? mountData.max_watts
                  }`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Voltage/Phase"
                  name="voltage"
                  value={sizeData.voltages[0].voltage}
                />
              </Grid>
            )}
          </Grid>
        )}
        {fan.template === 'Radiant Heaters' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={sizeOptions}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Model"
                    error={validated && model === null}
                  />
                )}
                value={model}
                onChange={(event, newModel) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'model',
                    newModel
                  )
                  setPrevFuel(fuelType)
                  submittalsState.setFormValue(
                    'productDetails',
                    'sizeId',
                    sizes.find((s: APIProductSize) => s.size === newModel)?.id
                  )
                }}
                inputValue={model ?? ''}
              />
            </Grid>
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={fuelOptions}
                  sx={{ pt: 1, mt: -1 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      variant="standard"
                      label="Fuel Type"
                      error={validated && fuelType === null}
                    />
                  )}
                  value={fuelType}
                  onChange={(event, newType) => {
                    event.preventDefault()
                    submittalsState.setFormValue(
                      'productDetails',
                      'fuelType',
                      newType
                    )
                    submittalsState.setFuelData(
                      sizeData.fuels.find((f: any) => f.fuel === newType)
                    )
                  }}
                  inputValue={fuelType ?? ''}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Alum. Steel Tubing Length"
                  name="tubeLength"
                  value={`${sizeData.radiant_heater.tube_length}'`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Input [Btu/h]"
                  name="input"
                  value={`${sizeData.radiant_heater.input} MBH`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && fuelType && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Inlet Pressure Min. [in wc]"
                  name="minPressure"
                  value={fuelData?.inlet_pressure_min}
                />
              </Grid>
            )}
            {sizeData.size !== '' && fuelType && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Inlet Pressure Max. [in wc]"
                  name="maxPressure"
                  value={fuelData?.inlet_pressure_max}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Gas Connection"
                  name="gasConnection"
                  value={sizeData.radiant_heater.gas_connection}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Combustion Air Inlet"
                  name="combustionAirInlet"
                  value={`${sizeData.radiant_heater.combustion_air_inlet}" diam.`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Exhaust Flue"
                  name="exhaustFlue"
                  value={`${sizeData.radiant_heater.exhaust_flue}" diam.`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Electrical Rating"
                  name="electricalRating"
                  value={sizeData.radiant_heater.electrical_rating}
                />
              </Grid>
            )}
          </Grid>
        )}
        {fan.template === 'Large Fans' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={sizeOptions}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Size"
                    error={validated && size === ''}
                  />
                )}
                value={size}
                onChange={(event, newSize) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'size',
                    newSize
                  )
                  setPrevVoltage(voltage)
                  submittalsState.setFormValue(
                    'productDetails',
                    'sizeId',
                    sizes.find((s: APIProductSize) => s.size === newSize)?.id
                  )
                }}
                inputValue={size ?? ''}
              />
            </Grid>
            {sizeData.voltages && (
              <Grid item xs={12 / numCols}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={voltageOptions}
                  sx={{ pt: 1, mt: -1 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      variant="standard"
                      label="Voltage/Phase"
                      error={validated && voltage === null}
                    />
                  )}
                  value={voltage}
                  onChange={(event, newVolt) => {
                    event.preventDefault()
                    submittalsState.setFormValue(
                      'productDetails',
                      'voltage',
                      newVolt
                    )
                    submittalsState.setVoltageData(
                      sizeData.voltages.find((v: any) => v.voltage === newVolt)
                    )
                  }}
                  inputValue={voltage ?? ''}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (sizeData.weight || mount) && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Standard Weight"
                  name="stdWeight"
                  value={`${sizeData.weight ?? mountData.weight} lbs`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && sizeData.motor && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Motor"
                  name="motor"
                  value={`${sizeData.motor}`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max RPM"
                  name="maxRPM"
                  value={`${sizeData.rpm ?? voltageData.rpm} RPM`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && sizeData.airflow && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Airflow"
                  name="maxAirflow"
                  value={`${sizeData.airflow}`}
                />
              </Grid>
            )}
            {sizeData.size !== '' &&
              voltage &&
              voltageData.supply_circuit_size && (
                <Grid item xs={12 / numCols}>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    label="Supply Circuit Size"
                    name="suppyCircuitSize"
                    value={`${voltageData.supply_circuit_size} A`}
                  />
                </Grid>
              )}
            {sizeData.size !== '' &&
              voltage &&
              voltageData.full_load_amperage && (
                <Grid item xs={12 / numCols}>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    label="Full Load Amperage"
                    name="fullLoadAmp"
                    value={`${voltageData.full_load_amperage} A`}
                  />
                </Grid>
              )}
            {sizeData.size !== '' && voltage && voltageData.max_amps && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Amperage"
                  name="maxAmp"
                  value={`${voltageData.max_amps} A`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && voltage && voltageData.max_watts && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Wattage"
                  name="maxWatt"
                  value={`${voltageData.max_watts} W`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Sound Level"
                  name="maxSound"
                  value={`${sizeData.max_sound}`}
                />
              </Grid>
            )}
          </Grid>
        )}
        {fan.template === 'Sweat Bee' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={sizeOptions}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Size"
                    error={validated && size === ''}
                  />
                )}
                value={size}
                onChange={(event, newSize) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'size',
                    newSize
                  )
                  setPrevVoltage(voltage)
                  submittalsState.setFormValue(
                    'productDetails',
                    'sizeId',
                    sizes.find((s: APIProductSize) => s.size === newSize)?.id
                  )
                }}
                inputValue={size ?? ''}
              />
            </Grid>
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={voltageOptions}
                  getOptionLabel={option => option.toString()}
                  sx={{ pt: 1, mt: -1 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      variant="standard"
                      label="Voltage/Phase"
                      error={validated && voltage === null}
                    />
                  )}
                  value={voltage}
                  onChange={(event, newVolt) => {
                    event.preventDefault()
                    submittalsState.setFormValue(
                      'productDetails',
                      'voltage',
                      newVolt
                    )
                    submittalsState.setVoltageData(
                      sizeData.voltages.find((v: any) => v.voltage === newVolt)
                    )
                  }}
                  inputValue={voltage ?? ''}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Motor"
                  name="motor"
                  value={`${sizeData.motor}`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max RPM"
                  name="maxRPM"
                  value={`${sizeData.rpm} RPM`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Sound Level"
                  name="maxSound"
                  value={`${sizeData.max_sound}`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Amperage"
                  name="maxAmps"
                  value={`${voltageData.max_amps} A`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && mount && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Standard Weight"
                  name="weight"
                  value={`${sizeData.weight ?? mountData.weight} lbs`}
                />
              </Grid>
            )}
            {voltage && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Supply Circuit Size"
                  name="supplyCircuit"
                  value={`${voltageData.supply_circuit_size} A`}
                />
              </Grid>
            )}
          </Grid>
        )}
        {fan.template === 'Unit Heaters' && (
          <Grid container rowSpacing={0} columnSpacing={1} id="productDetails">
            <Grid item xs={12 / numCols}>
              <QuantityField />
            </Grid>
            <Grid item xs={12 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={sizeOptions}
                sx={{ pt: 1, mt: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Model"
                    error={validated && model === null}
                  />
                )}
                value={model}
                onChange={(event, newModel) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'productDetails',
                    'sizeId',
                    sizes.find((s: APIProductSize) => s.size === newModel)?.id
                  )
                  submittalsState.setFormValue(
                    'productDetails',
                    'model',
                    newModel
                  )
                }}
                inputValue={model ?? ''}
              />
            </Grid>
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Input [Btu/h]"
                  name="input"
                  value={`${sizeData.unit_heater.input} MBH`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Output [Btu/h]"
                  name="output"
                  value={`${sizeData.unit_heater.output} MBH`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Weight"
                  name="weight"
                  value={`${sizeData.weight} lbs`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Supply Circuit Size"
                  name="supplyCircuit"
                  value={`${sizeData.unit_heater.supply_circuit_size} A`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Max Amperage"
                  name="maxAmps"
                  value={`${sizeData.unit_heater.max_amps} A`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Fan Diameter"
                  name="fanDiam"
                  value={`${sizeData.fan_diameter}"`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Gas Connection"
                  name="gasConnection"
                  value={`${sizeData.unit_heater.gas_connection}`}
                />
              </Grid>
            )}
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Vent Connection"
                  name="ventConnection"
                  value={`${sizeData.unit_heater.vent_connection}" dia.`}
                />
              </Grid>
            )}
            <Grid item xs={12 / numCols}>
              <StyledTextField
                inputProps={{ readOnly: true }}
                label="Input Power"
                name="inputPower"
                value={'115 V, 60 Hz'}
              />
            </Grid>
            {sizeData.size !== '' && (
              <Grid item xs={12 / numCols}>
                <StyledTextField
                  inputProps={{ readOnly: true }}
                  label="Airflow"
                  name="airflow"
                  value={`${sizeData.airflow}`}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Card>
    </Grid>
  )
}

export default ProductDetails
