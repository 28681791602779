import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfInputSection from 'components/pdf/PdfInputSection'
import PdfNotesSection from 'components/pdf/PdfNotesSection'
import { useExtensionTubeState } from 'store/extensionTubeState'

const ExtensionTubeCalcPdf = () => {
  const extensionTubeState = useExtensionTubeState()
  const {
    product,
    buildingWidth,
    eaveHeight,
    peakHeight,
    diameter,
    results,
    isMetric,
    deckHeight,
    mountingStructureHeight,
    roofPitch,
  } = extensionTubeState.getState()

  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  })

  const requiresFieldModification = (value: string): boolean => {
    const ignoreList = ["2'", "5'", "10'", 'Direct Mount']
    return (
      !ignoreList.includes(value) && product?.category === 'Industrial HVLS'
    )
  }

  const slopeMap = {
    none: 'Unknown',
    0: 'Flat',
    0.083: '1/12',
    0.167: '2/12',
    0.25: '3/12',
    0.333: '4/12',
    0.417: '5/12',
    0.5: '6/12',
    0.583: '7/12',
    0.667: '8/12',
    0.75: '9/12',
    0.833: '10/12',
    0.917: '11/12',
    1: '12/12',
  }

  const notes = [`(!) Products marked require field modification.`]

  const pitchKnown = roofPitch !== 'none'
  const inputs = [
    { title: 'Product', value: product?.name },
    { title: 'Fan Diameter', value: diameter },
    {
      title: 'Ceiling / Deck Height AFF',
      value: `${deckHeight} ${isMetric ? 'm' : 'ft'}`,
    },
    {
      title: 'Mounting Structure Height AFF',
      value: `${mountingStructureHeight} ${isMetric ? 'm' : 'ft'}`,
    },
    {
      title: 'Roof Pitch',
      value: !roofPitch
        ? 'Flat'
        : slopeMap[`${roofPitch}` as keyof typeof slopeMap],
    },
    {
      title: 'Building Width',
      value: pitchKnown ? 'N/A' : `${buildingWidth} ${isMetric ? 'm' : 'ft'}`,
    },
    {
      title: 'Eave Height (AFF)',
      value: pitchKnown ? 'N/A' : `${eaveHeight} ${isMetric ? 'm' : 'ft'}`,
    },
    {
      title: 'Peak Height (AFF)',
      value: pitchKnown ? 'N/A' : `${peakHeight} ${isMetric ? 'm' : 'ft'}`,
    },
  ]

  const getProductImage = (product: string) => {
    let image
    switch (product) {
      case 'Essence':
        image = (
          <Image
            style={{
              marginLeft: '-50px',
              padding: '1px',
              width: '300px',
              height: '127px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Essence.png"
          />
        )
        break
      case 'Basic 6':
        image = (
          <Image
            style={{
              marginLeft: '-50px',
              padding: '1px',
              width: '300px',
              height: '125px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Basic+6.png"
          />
        )
        break
      case 'es6':
        image = (
          <Image
            style={{
              marginTop: '-10px',
              padding: '1px',
              width: '150px',
              height: '112px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/es6.png"
          />
        )
        break
      case 'Powerfoil 8':
        image = (
          <Image
            style={{
              marginLeft: '-50px',
              marginTop: '-10px',
              padding: '1px',
              width: '300px',
              height: '125px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Powerfoil+8.png"
          />
        )
        break
      case 'Powerfoil D':
        image = (
          <Image
            style={{
              marginLeft: '-50px',
              marginTop: '10px',
              padding: '1px',
              width: '300px',
              height: '168px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Powerfoil+D.png"
          />
        )
        break
      case 'Powerfoil X4':
        image = (
          <Image
            style={{
              marginLeft: '-50px',
              marginTop: '10px',
              padding: '1px',
              width: '300px',
              height: '147px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Powerfoil+X4.png"
          />
        )
        break
      case 'Haiku':
        image = (
          <Image
            style={{
              marginTop: '-15px',
              padding: '1px',
              width: '125px',
              height: '125px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Haiku.png"
          />
        )
        break
      case 'Haiku L':
        image = (
          <Image
            style={{
              marginTop: '-40px',
              padding: '1px',
              width: '125px',
              height: '125px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/Haiku+L.png"
          />
        )
        break
      case 'i6':
        image = (
          <Image
            style={{
              padding: '1px',
              width: '200px',
              height: '112px',
            }}
            src="https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/products/i6.png"
          />
        )
        break
      default:
        image = <View></View>
        break
    }

    return image
  }

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '8px',
        marginHorizontal: '16px',
        marginVertical: '8px',
        borderRadius: '8px',
      }}
    >
      <Text style={{ ...styles.sectionTitle, borderBottom: '0' }}>Results</Text>

      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ width: '50%' }}>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ ...styles.label, marginBottom: '4px' }}>
                Standard Extension Tube Lengths
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ ...styles.label, marginBottom: '4px' }}>
                {`Airfoil Height AFF (${isMetric ? 'm' : 'ft'})`}
              </Text>
            </View>
          </View>
          {results?.standard?.map((result: any) => (
            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View
                style={{
                  width: '50%',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text style={{ ...styles.value, marginBottom: '4px' }}>
                  {result?.tube}
                </Text>
                {requiresFieldModification(result?.tube) && (
                  <Image
                    style={{
                      width: '12px',
                      height: '12px',
                      marginTop: '-5px',
                      marginLeft: '2px',
                    }}
                    src="/pdfImages/marker.png"
                  />
                )}
              </View>
              <View style={{ width: '50%' }}>
                <Text style={{ ...styles.value, marginBottom: '4px' }}>
                  {result?.airfoilHeight}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {getProductImage(product?.name)}
        </View>
      </View>

      <Text
        style={{ ...styles.sectionTitle, borderBottom: '0', marginTop: '16px' }}
      >
        Custom Extension Tube Lengths
      </Text>
      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.label, marginBottom: '4px' }}>
            Calculation Type (If Applicable)
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.label, marginBottom: '4px' }}>
            ET Length (Feet-Inches)
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.label, marginBottom: '4px' }}>
            {`Airfoil Height AFF (${isMetric ? 'm' : 'ft'})`}
          </Text>
        </View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.value, marginBottom: '4px' }}>
            Absolute Minimum Tube Length:
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.value, marginBottom: '4px' }}>
            {results?.custom?.minTubeEtLength}
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.value, marginBottom: '4px' }}>
            {results?.custom?.minTubeHeightAff}
          </Text>
        </View>
      </View>

      <View style={{ width: '100%', flexDirection: 'row' }}>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.value, marginBottom: '4px' }}>
            Absolute Maximum Tube Length:
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.value, marginBottom: '4px' }}>
            {results?.custom?.maxTubeEtLength}
          </Text>
        </View>
        <View style={{ width: '33%' }}>
          <Text style={{ ...styles.value, marginBottom: '4px' }}>
            {results?.custom?.maxTubeHeightAff}
          </Text>
        </View>
      </View>
    </View>
  )

  return (
    <Document>
      <Page size="A4">
        <PdfHeader title="Extension Tube Calculator" />
        <PdfInputSection inputs={inputs} />
        {!!results?.standard?.length && renderResultsSection()}
        <PdfNotesSection notes={notes} />
      </Page>
    </Document>
  )
}

export default ExtensionTubeCalcPdf
