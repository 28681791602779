import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import ProjectDetails from '../components/ProjectDetails'
import ProductDetails from '../components/ProductDetails'
import Logo from '../components/Logo'
import Notes from '../components/Notes'
import Warranty from '../components/Warranty'
import DimensionTable from '../components/DimensionTable'
import Additions from '../components/Additions'
import Features from '../components/Features'
import TechnicalDrawings from '../components/TechnicalDrawings'
import TechnicalNotes from '../components/TechnicalNotes'
import Links from 'components/Links'
import { useSubmittalsState } from 'store/salesToolsState'

const UnitHeaterTemplate = () => {
  const { fan, productData, sizeData, productDetails } =
    useSubmittalsState().getState()
  const model = productDetails.value.model
  return (
    <Stack spacing={0} display="block">
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Logo name={model ? `${fan.name}-${model}` : fan.name} />
        <Links links={productData.links} mt={0} />
      </Stack>
      <Grid container spacing={2}>
        <ProjectDetails />
        {productData.id !== -1 && <ProductDetails />}
        {productData.id !== -1 && <Features />}
        {sizeData.size !== '' && <DimensionTable />}
        <TechnicalDrawings />
        {productData.id !== -1 && <Warranty />}
        {productData.id !== -1 && <Additions />}
        {productData.id !== -1 && <Notes />}
        {productData.id !== -1 && <TechnicalNotes />}
      </Grid>
    </Stack>
  )
}

export default UnitHeaterTemplate
