import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from 'config/constants'
import { toMetric, toStandard } from 'utils'

export interface FormState {
  value: any
}
export interface APIProduct {
  id: number
  name: string
  category: string
  template: string
}

export interface ExtensionTubeState {
  buildingWidth: number
  deckHeight: number
  diameter: string
  eaveHeight: number
  isMetric: boolean
  mountingStructureHeight: number
  peakHeight: number
  product: any
  results: any
  roofPitch: any
  focusedInput: any
  leadSaved: boolean
  productsData: any
  dropTubeData: any
  dropTubeOptionsData: any
  customDropDimensionsData: any
  mountToBottomDimensionsData: any
  mountToWingletDimensionsData: any
}

export interface WrappedExtensionTubeState {
  getState: () => ExtensionTubeState
  reset: () => void
  setProduct: (product: APIProduct) => void
  setDiameter: (diameter: number) => void
}

export const extensionTubeCalcInitialState = {
  buildingWidth: 0,
  deckHeight: 0,
  diameter: '',
  eaveHeight: 0,
  isMetric: false,
  mountingStructureHeight: 0,
  peakHeight: 0,
  product: null,
  roofPitch: 0,
  results: null,
  focusedInput: null,
  leadSaved: false,
  productsData: null,
  dropTubeData: null,
  dropTubeOptionsData: null,
  customDropDimensionsData: null,
  mountToBottomDimensionsData: null,
  mountToWingletDimensionsData: null,
}

const extensionTubeState = createState(extensionTubeCalcInitialState)

const wrapExtensionTubeState = (s: State<ExtensionTubeState>) => ({
  getState: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  reset: () =>
    s.set((p: any) => {
      return {
        ...extensionTubeCalcInitialState,
        isMetric: p.isMetric, // Keep metric setting on reset
      }
    }),
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setDiameter: (value: string) =>
    s.set((p: any) => ({ ...p, diameter: value })),
  setDeckHeight: (value: number) =>
    s.set((p: any) => ({ ...p, deckHeight: value })),
  setMountingStructureHeight: (value: number) =>
    s.set((p: any) => ({ ...p, mountingStructureHeight: value })),
  setIsMetric: (value: boolean) =>
    s.set((p: any) => ({ ...p, isMetric: value })),
  setCustomDropDimensionsData: (value: boolean) =>
    s.set((p: any) => ({ ...p, customDropDimensionsData: value })),
  setMountToBottomDimensionsData: (value: boolean) =>
    s.set((p: any) => ({ ...p, mountToBottomDimensionsData: value })),
  setProductsData: (value: boolean) =>
    s.set((p: any) => ({ ...p, productsData: value })),
  setDropTubeData: (value: boolean) =>
    s.set((p: any) => ({ ...p, dropTubeData: value })),
  setDropTubeOptionsData: (value: boolean) =>
    s.set((p: any) => ({ ...p, dropTubeOptionsData: value })),
  setMountToWingletDimensionsData: (value: boolean) =>
    s.set((p: any) => ({ ...p, mountToWingletDimensionsData: value })),
  setRoofPitch: (value: any) => s.set((p: any) => ({ ...p, roofPitch: value })),
  setBuildingWidth: (value: number) =>
    s.set((p: any) => ({ ...p, buildingWidth: value })),
  setEaveHeight: (value: number) =>
    s.set((p: any) => ({ ...p, eaveHeight: value })),
  setPeakHeight: (value: number) =>
    s.set((p: any) => ({ ...p, peakHeight: value })),
  setProduct: (value: any) => s.set((p: any) => ({ ...p, product: value })),
  resetResults: () => s.set((p: any) => ({ ...p, results: null })),
  setFocusedInput: (value: any) =>
    s.set((p: any) => ({ ...p, focusedInput: value })),
  setResults: () =>
    s.set((p: any) => {
      const getDiameter = (diameter: string): number => {
        const parsedDiameter = Number(
          diameter?.replace("'", '')?.replace('"', '')
        )
        // If inches convert to feet
        if (diameter?.includes('"')) {
          return Math.round(parsedDiameter * 0.083 * 10) / 10
        }
        return Math.round(parsedDiameter * 10) / 10
      }

      // Get product radius
      const parsedDiameter = getDiameter(p.diameter)
      const radius = Math.round((parsedDiameter / 2) * 10) / 10

      // Get product data
      const productData = p?.productsData?.find((fan: any) => {
        const isFan = fan?.name === p?.product?.name
        const productSize = p?.isMetric
          ? toMetric(fan?.diameter)
          : fan?.diameter
        const isSize = parseFloat(productSize) === parsedDiameter
        return isFan && isSize
      })

      // Get product clearance
      const deckClearance = parseFloat(productData?.deck_clearance)
      const productDeckClearance = p?.isMetric
        ? toMetric(deckClearance)
        : deckClearance
      const structureClearance = parseFloat(productData?.structure_clearance)
      const productStructureClearance = p?.isMetric
        ? toMetric(structureClearance)
        : structureClearance

      // Get product min height AFF
      const minHeightAFF = parseFloat(productData?.min_height_aff)
      const productMinHeightAFF = p?.isMetric
        ? toMetric(minHeightAFF)
        : minHeightAFF

      // Get product drop tube options
      const productTubeOptions = p?.dropTubeOptionsData?.find(
        (data: any) => data?.name === p?.product?.name
      )
      const tubeIds = productTubeOptions?.drop_tube_ids?.split(',')
      const tubes = tubeIds?.map(
        (tubeId: string) =>
          p?.dropTubeData?.find(
            (tubeData: any) => tubeData?.id === parseInt(tubeId)
          )?.label
      )

      // Get product measurement data
      const wingletData = p?.mountToWingletDimensionsData?.filter(
        (data: any) => data?.name === p?.product?.name
      )
      const bottomData = p.mountToBottomDimensionsData?.filter(
        (data: any) => data?.name === p?.product?.name
      )
      const customDropData = p.customDropDimensionsData?.find(
        (data: any) => data?.name === p?.product?.name
      )
      const standardTube = parseFloat(customDropData?.standard_tube || 0)
      const mountToWingletTop = parseFloat(
        customDropData?.mount_to_winglet_top || 0
      )
      const minTube = parseFloat(customDropData?.min_tube || 0)
      const maxTube = parseFloat(customDropData?.max_tube || 0)
      const mountToBottom = parseFloat(customDropData?.mount_to_bottom || 0)

      // Get product drop tube measurements
      const customDropStandardTube = p?.isMetric
        ? toMetric(standardTube)
        : standardTube
      const customDropMountToWingletTop = p?.isMetric
        ? toMetric(mountToWingletTop)
        : mountToWingletTop
      const customDropMinTube = p?.isMetric ? toMetric(minTube) : minTube
      const customDropMaxTube = p?.isMetric ? toMetric(maxTube) : maxTube
      const customDropMountToBottom = p?.isMetric
        ? toMetric(mountToBottom)
        : mountToBottom

      // Get roof pitch
      const customPitch =
        (p?.peakHeight - p?.eaveHeight) / (p?.buildingWidth / 2)
      const pitch = p?.roofPitch === 'none' ? customPitch : p?.roofPitch

      // Get evaluation heights
      const deckEvalHeight =
        pitch === 0 ? p?.deckHeight : p?.deckHeight - radius * pitch
      const structureEvalHeight =
        pitch === 0
          ? p?.mountingStructureHeight
          : p?.mountingStructureHeight - radius * pitch

      // Map standard extension tube lengths results
      const standardResults = tubes?.map((tube: string) => {
        const wingletSizeData =
          wingletData?.find((data: any) => data?.tube === tube)?.size || 0
        const wingletSize = parseFloat(wingletSizeData)
        const wingletSizeValue = p?.isMetric
          ? toMetric(wingletSize)
          : wingletSize
        const bottomSize =
          bottomData?.find((data: any) => data?.tube === tube)?.size || 0
        const bottomSizeValue = p?.isMetric ? toMetric(bottomSize) : bottomSize

        // Test winglet deck clearance
        const wingletDeckClearance =
          wingletSizeValue + (deckEvalHeight - p?.mountingStructureHeight)
        const deckClearanceValid =
          !wingletSize || wingletDeckClearance >= productDeckClearance

        // Test winglet structure clearance
        const wingletStructureClearance =
          wingletSizeValue + (structureEvalHeight - p?.mountingStructureHeight)
        const structureClearanceValid =
          !wingletSize || wingletStructureClearance >= productStructureClearance

        // Test height AFF clearance
        const airfoilHeight = p?.mountingStructureHeight - bottomSizeValue
        const airfoilHeightValid = airfoilHeight > productMinHeightAFF

        const isValid =
          deckClearanceValid && structureClearanceValid && airfoilHeightValid

        return {
          tube,
          airfoilHeight: Math.round(airfoilHeight * 10) / 10,
          isValid,
        }
      })

      // Get min custom hight AFF
      const forStructureClearance =
        customDropStandardTube +
        (productStructureClearance -
          (structureEvalHeight -
            p?.mountingStructureHeight +
            customDropMountToWingletTop))
      const forStructureClearanceValue = p?.isMetric
        ? toStandard(forStructureClearance)
        : forStructureClearance
      const forDeckClearance =
        customDropStandardTube +
        (productDeckClearance -
          (deckEvalHeight -
            p?.mountingStructureHeight +
            customDropMountToWingletTop))
      const forDeckClearanceValue = p?.isMetric
        ? toStandard(forDeckClearance)
        : forDeckClearance
      const maxOfMin = Math.max(
        forStructureClearanceValue,
        forDeckClearanceValue,
        customDropMinTube
      )
      const minTubeHeightAff =
        p?.mountingStructureHeight -
        (maxOfMin - customDropStandardTube + customDropMountToBottom)

      // Get min ET length results
      const maxOfMinStandard = Math.max(
        forStructureClearanceValue,
        forDeckClearanceValue,
        minTube
      )
      const minFeet = Math.floor(
        Math.max(forStructureClearanceValue, forDeckClearanceValue, minTube)
      )
      const minInches = Math.ceil((maxOfMinStandard - minFeet) * 12)
      const minTubeEtLength = `${minFeet}'-${Math.round(minInches)}"`

      // Get max custom hight AFF
      const forMinAff =
        p?.mountingStructureHeight -
        customDropMountToBottom -
        productMinHeightAFF +
        customDropStandardTube
      const forMinAffValue = p?.isMetric ? toStandard(forMinAff) : forMinAff
      const minOfMax = Math.min(forMinAffValue, customDropMaxTube)
      const maxTubeHeightAff =
        p?.mountingStructureHeight -
        (minOfMax - customDropStandardTube + customDropMountToBottom)

      // Get max ET length results
      const minOfMaxStandard = Math.min(forMinAffValue, maxTube)
      const maxFeet = Math.floor(Math.min(forMinAffValue, maxTube))
      const maxInches = (minOfMaxStandard - maxFeet) * 12
      const maxTubeEtLength = `${maxFeet}'-${Math.round(maxInches)}"`

      const filteredResults = standardResults?.filter(
        (result: any) => result?.isValid
      )

      const results = {
        standard: filteredResults,
        custom: {
          maxTubeHeightAff: Math.round(maxTubeHeightAff * 10) / 10,
          maxTubeEtLength,
          minTubeHeightAff: Math.round(minTubeHeightAff * 10) / 10,
          minTubeEtLength,
        },
      }

      return { ...p, results }
    }),
})

export const accessExtensionTubeState = () =>
  wrapExtensionTubeState(extensionTubeState)

export const useExtensionTubeState = () => {
  const state = useState(extensionTubeState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-extensionTubeCalc`))
  return wrapExtensionTubeState(state)
}
