import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from '../config/constants'

export interface TimestampState {
  lastUpdated: number
}

const initialTimestampState = {
  lastUpdated: new Date().getTime(),
}

const timestampState = createState(initialTimestampState)

const wrapTimestampState = (s: State<TimestampState>) => ({
  get: () => s.value,
  setLastUpdated: () =>
    s.set(p => ({ ...p, lastUpdated: new Date().getTime() })),
})

export const accessTimestampState = () => wrapTimestampState(timestampState)

export const useTimestampState = () => {
  const state = useState(timestampState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-timestamp`))
  return wrapTimestampState(state)
}
