import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store'

interface APIController {
  id: number
  label: string
  features: APIControllerFeature[]
}

interface APIControllerFeature {
  id: number
  feature: string
}

const ControllerFeatures = () => {
  const { productData, controllerFeatures } = useSubmittalsState().getState()
  const controller = controllerFeatures.value.controller
  const features = productData.controllers
    .find((c: APIController) => c.label === controller)
    .features.map((f: APIControllerFeature) => f.feature)
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography
          variant="h5"
          sx={{ mt: -1, mb: -0.5 }}
        >{`${controller} Features`}</Typography>
        <Table size="small">
          <TableBody>
            {features.map((f: string, index: number) => (
              <TableRow key={index}>
                <TableCell
                  sx={{ p: 0, '@media print': { fontSize: '0.8rem' } }}
                  id={index.toString()}
                >
                  {f}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </Grid>
  )
}

export default ControllerFeatures
