import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { useScheduleBuilderState } from 'store/scheduleBuilderState'

interface UseNotesReturn {
  notes: any[]
}

export function usePrintedNotes(): UseNotesReturn {
  const scheduleBuilderState = useScheduleBuilderState()
  const { schedules } = scheduleBuilderState.getState()
  const notes = printedNotes(schedules)

  return { notes }
}

export function printedNotes(schedules: any) {
  const categories = uniq(
    Object.keys(schedules)?.map(index => schedules[index]?.product?.category)
  ).sort()

  // This gets all of the schedules and puts them together by category
  // and with a number in front of the note for each category of products.
  //
  // For example:
  // [{category: 'Commercial HVLS', id: 8, note: '1. This is the note.'}]
  //
  const _notes = categories.map((category: string) => {
    const _initialNotes = Object.values(schedules)
      .filter((s: any) => s?.product?.category === category)
      .map((s: any) => ({
        category,
        id: s?.product?.id,
        notes: s?.productData?.schedule_builder_notes,
        links: s?.productData?.schedule_builder_links,
      }))
      .map((notes: any) => {
        return notes.notes.map((note: any) => ({
          category: notes.category,
          id: notes.id,
          note: note.note,
          link: notes.links,
        }))
      })
      .flat()

    // This is to handle the case where there are multiple products
    // with the same model and duplicate notes.
    const uniqueNotes = uniqBy(_initialNotes, ({ id, note }) => `${id}${note}`)

    return uniqueNotes.map((note: any, index: number) => ({
      ...note,
      note: `${index + 1}. ${note.note}`,
    }))
  })

  return flatten(_notes)
}

export function printedNotesRange({ notes, id }: { notes: any[]; id: number }) {
  const productNotesNumbers = notes
    .filter((n: any) => n.id === id)
    .map((n: any) => n.note.split('.')[0])
  const productNotesRange = `${
    productNotesNumbers[0]
  }-${productNotesNumbers.slice(-1)}`

  return productNotesRange
}
