import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store'
import { useState, useEffect } from 'react'

interface APIMount {
  id: number
  mount_id: number
  mount: string
  drop_tube?: string
  mount_diameter?: string
  mount_to_hub?: string
  weight?: string
  depth?: string
  height?: string
  width?: string
  rpm?: number
  airflow?: number
  max_watts?: string
}

interface APITube {
  id: number
  tube_id: number
  tube: string
  mount_to_hub: string
  foil_to_mount: string
}

const MountingHardware = () => {
  const submittalsState = useSubmittalsState()
  const { fan, sizeData, numCols, mountingHardware, validated, colorDetails } =
    submittalsState.getState()
  const { mount, extensionTube } = mountingHardware.value
  const { material } = colorDetails.value
  const mountOptions = sizeData.mounts
    .map((m: APIMount) => m.mount)
    .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
  const [tubeOptions, setTubeOptions] = useState([] as string[])
  useEffect(() => {
    if (mountOptions.length === 1) {
      submittalsState.setFormValue('mountingHardware', 'mount', mountOptions[0])
      submittalsState.setMountData(
        sizeData.mounts.find((m: any) => m.mount === mountOptions[0])
      )
    }
    if (mount) {
      setTubeOptions(
        sizeData.mounts
          .map((m: APIMount) => {
            if (m.mount === mount && m.drop_tube) {
              return m.drop_tube
            } else return null
          })
          .filter((n: any) => n)
          .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
      )
      if (extensionTube && material && fan.template === 'Haiku') {
        submittalsState.setMountData(
          sizeData.mounts.find(
            (m: any) =>
              m.mount === mount &&
              m.drop_tube === extensionTube &&
              m.material.label === material
          )
        )
      }
      if (mount === 'Low-Profile' && material && fan.template === 'Haiku') {
        submittalsState.setMountData(
          sizeData.mounts.find(
            (m: any) => m.mount === mount && m.material.label === material
          )
        )
      }
    }
    if (sizeData.drop_tubes.length > 0) {
      setTubeOptions(
        sizeData.drop_tubes
          .map((t: APITube) => t.tube)
          .filter((n: any) => n)
          .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
      )
    }
    // eslint-disable-next-line
  }, [
    mount,
    sizeData.mounts.length,
    sizeData.drop_tubes.length,
    extensionTube,
    material,
  ])
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
          Mounting Hardware
        </Typography>
        <Grid container rowSpacing={0} columnSpacing={1} id="mountingHardware">
          {sizeData.mounts.length > 0 && (
            <Grid item xs={24 / numCols}>
              <Autocomplete
                disablePortal
                size="small"
                options={mountOptions}
                getOptionLabel={option => option.toString()}
                sx={{ pt: 0 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Type"
                    error={validated && mount === null}
                  />
                )}
                value={mount}
                onChange={(event, newMount) => {
                  event.preventDefault()
                  submittalsState.setFormValue(
                    'mountingHardware',
                    'mount',
                    newMount
                  )
                  fan.template !== 'Haiku' &&
                    submittalsState.setMountData(
                      sizeData.mounts.find((m: any) => m.mount === newMount)
                    )
                  submittalsState.setFormValue(
                    'mountingHardware',
                    'extensionTube',
                    null
                  )
                }}
                inputValue={mount ?? ''}
              />
            </Grid>
          )}
          {(mount || sizeData.drop_tubes.length > 0) &&
            tubeOptions.length > 0 && (
              <Grid item xs={24 / numCols}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={tubeOptions}
                  sx={{ pt: 0 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      variant="standard"
                      label="Extension Tube"
                      error={validated && extensionTube === null}
                    />
                  )}
                  value={extensionTube}
                  onChange={(event, newTube) => {
                    event.preventDefault()
                    submittalsState.setFormValue(
                      'mountingHardware',
                      'extensionTube',
                      newTube
                    )
                    submittalsState.setDropTubeData(
                      sizeData.drop_tubes.find((et: any) => et.tube === newTube)
                    )
                    fan.template === 'Haiku' &&
                      submittalsState.setMountData(
                        sizeData.mounts.find(
                          (m: any) =>
                            m.mount === mount && m.drop_tube === newTube
                        )
                      )
                  }}
                  inputValue={extensionTube ?? ''}
                />
              </Grid>
            )}
        </Grid>
      </Card>
    </Grid>
  )
}

export default MountingHardware
