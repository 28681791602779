import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from 'config/constants'
import { toStandard } from 'utils'

interface EnvironmentData {
  type: string
}

interface LocationData {
  location: string
  city: string
  annual_1_db_air_temp: string
  annual_1_mcwb_air_temp: string
  annual_2_db_air_temp: string
  annual_2_mcwb_air_temp: string
  monthly_2_db_air_temp: string
  monthly_2_mcwb_air_temp: string
  monthly_5_db_air_temp: string
  monthly_5_mcwb_air_temp: string
  monthly_10_db_air_temp: string
  monthly_10_mcwb_air_temp: string
  solar_intensity: string
}

export interface EvapSizingToolState {
  leadSaved: boolean
  results: any
  environmentData: EnvironmentData[]
  locationData: LocationData[]
  isMetric: boolean
  isCustom: boolean
  hasWindows: boolean
  length: number
  dbAirTemp: number
  mcwbAirTemp: number
  solarIntensity: number
  spaceType: string
  weatherDataType: string
  insulationLevel: string
  location: string
  safetyFactor: number
  width: number
  deckHeight: number
  maxAirTempReduction: number
  spaceAirTempReduction: number
}

const CFM_UNITS = [1700, 2700, 3400, 6700, 22800]
const ROOF_INSULATIONS = {
  Low: 0.093,
  Average: 0.048,
  High: 0.032,
}
const WALL_INSULATIONS = {
  Low: 0.089,
  Average: 0.089,
  High: 0.064,
}
const LOAD_MULTIPLIER = 3.412
const WINDOW_MULTIPLIER = 5.0
const AIR_TEMP_DIFF_MULTIPLIER = 0.85
const AIR_FLOW_DIVISOR = 1.085

const initialEvapSizingToolState = {
  leadSaved: false,
  results: null,

  // Data
  environmentData: [] as EnvironmentData[],
  locationData: [] as LocationData[],

  // Inputs
  isMetric: false,
  isCustom: false,
  spaceType: '',
  weatherDataType: '',
  location: '',
  insulationLevel: '',
  safetyFactor: 0,
  hasWindows: false,
  length: 0,
  width: 0,
  deckHeight: 0,
  dbAirTemp: 0,
  mcwbAirTemp: 0,
  solarIntensity: 0,
  maxAirTempReduction: 0,
  spaceAirTempReduction: 0,
}

function calculateMaxAirTempReduction(dbAirTemp: number, mcwbAirTemp: number) {
  return Math.round((dbAirTemp - mcwbAirTemp) * 0.85) - 5
}

const getWeatherData = (state: EvapSizingToolState, type: string) => {
  // Get weather data based on location
  const weatherData = state?.locationData?.find(
    (location: any) => location?.location === state?.location
  )

  // Update weather values for the selected location
  let newDbAirTemp = 0 as number | string
  let newMcwbAirTemp = 0 as number | string
  const newSolarIntensity = weatherData?.solar_intensity || 0

  switch (type) {
    case 'Annual 2%':
      newDbAirTemp = weatherData?.annual_2_db_air_temp || 0
      newMcwbAirTemp = weatherData?.annual_2_mcwb_air_temp || 0
      break
    case 'Monthly 2%':
      newDbAirTemp = weatherData?.monthly_2_db_air_temp || 0
      newMcwbAirTemp = weatherData?.monthly_2_mcwb_air_temp || 0
      break
    case 'Monthly 5%':
      newDbAirTemp = weatherData?.monthly_5_db_air_temp || 0
      newMcwbAirTemp = weatherData?.monthly_5_mcwb_air_temp || 0
      break
    case 'Monthly 10%':
      newDbAirTemp = weatherData?.monthly_10_db_air_temp || 0
      newMcwbAirTemp = weatherData?.monthly_10_mcwb_air_temp || 0
      break
    default:
      newDbAirTemp = weatherData?.annual_1_db_air_temp || 0
      newMcwbAirTemp = weatherData?.annual_1_mcwb_air_temp || 0
  }

  return {
    dbAirTemp: newDbAirTemp,
    mcwbAirTemp: newMcwbAirTemp,
    maxAirTempReduction: calculateMaxAirTempReduction(newDbAirTemp as number, newMcwbAirTemp as number),
    solarIntensity: newSolarIntensity,
  }
}

const evapSizingToolState = createState(initialEvapSizingToolState)

const wrapEvapSizingToolState = (s: State<EvapSizingToolState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialEvapSizingToolState,
      environmentData: p?.environmentData,
      locationData: p?.locationData,
    })),
  setEnvironmentData: (value: any) => {
    s.set((p: any) => ({ ...p, environmentData: value }))
  },
  setLocationData: (value: any) => {
    s.set((p: any) => ({ ...p, locationData: value }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setHasWindows: (value: boolean) => {
    s.set((p: any) => ({ ...p, hasWindows: value, results: null }))
  },
  setIsMetric: (value: boolean) => {
    s.set((p: any) => ({ ...p, isMetric: value, results: null }))
  },
  setIsCustom: (value: boolean) => {
    s.set((p: any) => ({ ...p, isCustom: value, results: null }))
  },
  setDbAirTemp: (value: number) => {
    s.set((p: any) => ({
      ...p,
      dbAirTemp: value,
      maxAirTempReduction: calculateMaxAirTempReduction(value, s.value.mcwbAirTemp),
      results: null 
    }))
  },
  setMcwbAirTemp: (value: number) => {
    s.set((p: any) => ({
      ...p,
      mcwbAirTemp: value,
      maxAirTempReduction: calculateMaxAirTempReduction(s.value.dbAirTemp, value),
      results: null
    }))
  },
  setSolarIntensity: (value: number) => {
    s.set((p: any) => ({ ...p, solarIntensity: value, results: null }))
  },
  setMaxAirTempReduction: (value: number) => {
    s.set((p: any) => ({ ...p, maxAirTempReduction: value, results: null }))
  },
  setSpaceAirTempReduction: (value: number) => {
    s.set((p: any) => ({ ...p, spaceAirTempReduction: value, results: null }))
  },
  setWidth: (value: number) => {
    s.set((p: any) => ({ ...p, width: value, results: null }))
  },
  setLength: (value: number) => {
    s.set((p: any) => ({ ...p, length: value, results: null }))
  },
  setDeckHeight: (value: number) => {
    s.set((p: any) => ({ ...p, deckHeight: value, results: null }))
  },
  setSpaceType: (value: string) => {
    s.set((p: any) => ({ ...p, spaceType: value, results: null }))
  },
  setWeatherDataType: (value: string) => {
    s.set((p: any) => {
      const weatherData = getWeatherData(p, value)
      return { ...p, ...weatherData, weatherDataType: value, results: null }
    })
  },
  setLocation: (value: string) => {
    s.set((p: any) => {
      // Set a default weather data value if not present
      const newWeatherDataType = p?.weatherDataType || 'Annual 1%'
      const locationState = { ...p, location: value }
      const weatherData = getWeatherData(locationState, newWeatherDataType)

      // Update max air temp reduction based on new location
      const dbAirTemp = parseFloat(weatherData?.dbAirTemp as string)
      const mcwbAirTemp = parseFloat(weatherData?.mcwbAirTemp as string)
      const maxAirTempReduction = calculateMaxAirTempReduction(dbAirTemp, mcwbAirTemp)

      return {
        ...p,
        ...weatherData,
        maxAirTempReduction,
        weatherDataType: newWeatherDataType,
        location: value,
        results: null,
      }
    })
  },
  setSafetyFactor: (value: number) => {
    s.set((p: any) => ({ ...p, safetyFactor: value, results: null }))
  },
  setInsulationLevel: (value: string) => {
    s.set((p: any) => ({ ...p, insulationLevel: value, results: null }))
  },
  setResults: () => {
    s.set((p: any) => {
      const length = p?.isMetric ? toStandard(p?.length) : p?.length
      const width = p?.isMetric ? toStandard(p?.width) : p?.width
      const deckHeight = p?.isMetric ? toStandard(p?.deckHeight) : p?.deckHeight

      // Get corresponding weather and environmental data
      const weatherData = p?.locationData?.find(
        (location: any) => location?.location === p?.location
      )
      const environmentData = p?.environmentData?.find(
        (datum: any) => datum?.type === p?.spaceType
      )

      const totals = CFM_UNITS?.map((cfm: number) => {
        // Get area dimensions
        const spaceFloorArea = length * width
        const roofArea = spaceFloorArea
        const wallArea = length * deckHeight * 2 + width * deckHeight * 2

        // Get space u factors
        const roofUfactor =
          ROOF_INSULATIONS?.[
            p?.insulationLevel as keyof typeof ROOF_INSULATIONS
          ]
        const wallUfactor =
          WALL_INSULATIONS?.[
            p?.insulationLevel as keyof typeof WALL_INSULATIONS
          ]

        // Get equipment heat gain
        const miscLoads = environmentData?.misc_loads || 0
        const equipmentHeatGain = (miscLoads * spaceFloorArea) / 1000

        // Get people heat gain
        const peopleDensity = environmentData?.people_density || 0
        const btuPerPerson = environmentData?.btu_sensible_per_person || 0
        const peopleHeatGain =
          (((peopleDensity * spaceFloorArea) / 1000) * btuPerPerson) / 1000

        // Get lighting heat gain
        const roofHeatGainNonSolar =
          (roofArea * roofUfactor * p?.spaceAirTempReduction) / 1000
        const wallHeatGainNonSolar =
          (wallArea * wallUfactor * p?.spaceAirTempReduction) / 1000
        const roofHeatGainSolar =
          (weatherData?.solar_intensity / 2) * roofHeatGainNonSolar
        const glassSolar = p?.hasWindows
          ? wallHeatGainNonSolar * WINDOW_MULTIPLIER
          : 0
        const lightingPowerDensity = environmentData?.lighting_power_density
        const lightingHeatGain = (lightingPowerDensity * spaceFloorArea) / 1000

        // Get total load
        const totalLoad =
          lightingHeatGain * LOAD_MULTIPLIER +
          peopleHeatGain +
          equipmentHeatGain * LOAD_MULTIPLIER +
          roofHeatGainNonSolar +
          wallHeatGainNonSolar +
          roofHeatGainSolar +
          glassSolar

        // Get design quantity
        const totalWithSafetyFactor = totalLoad * p?.safetyFactor
        const airTempDiff = p?.dbAirTemp - p?.mcwbAirTemp
        const requiredAirflow =
          (totalWithSafetyFactor * 1000) /
          AIR_FLOW_DIVISOR /
          (airTempDiff * AIR_TEMP_DIFF_MULTIPLIER)
        const designQty = requiredAirflow / cfm
        return designQty
      })

      // Get CBE tool inputs
      const dbAirTemp = parseFloat(p?.dbAirTemp)
      const comfortToolInputs = [
        {
          title: 'Air Temperature',
          existing: dbAirTemp,
          evapCooler: dbAirTemp - p?.spaceAirTempReduction,
          hvls: dbAirTemp,
          evapHvls: dbAirTemp - p?.spaceAirTempReduction,
        },
        {
          title: 'Mean Radiant Temperature',
          existing: dbAirTemp,
          evapCooler: (dbAirTemp + (dbAirTemp - p?.spaceAirTempReduction)) / 2,
          hvls: dbAirTemp,
          evapHvls: (dbAirTemp + (dbAirTemp - p?.spaceAirTempReduction)) / 2,
        },
        {
          // Unknown magic numbers
          title: 'Air Speed',
          existing: p?.isMetric ? 0.15 : 30,
          evapCooler: p?.isMetric ? 0.4 : 80,
          hvls: p?.isMetric
            ? environmentData?.design_air_speed / 200
            : environmentData?.design_air_speed,
          evapHvls: p?.isMetric
            ? environmentData?.design_air_speed / 200
            : environmentData?.design_air_speed,
        },
        {
          title: 'Air Wet Bulb Temperature',
          existing: p?.mcwbAirTemp,
          evapCooler: p?.mcwbAirTemp,
          hvls: p?.mcwbAirTemp,
          evapHvls: p?.mcwbAirTemp,
        },
        {
          title: 'Metabolic Rate',
          existing: environmentData?.met,
          evapCooler: environmentData?.met,
          hvls: environmentData?.met,
          evapHvls: environmentData?.met,
        },
        {
          title: 'Clothing Level',
          existing: environmentData?.clothing_insulation,
          evapCooler: environmentData?.clothing_insulation,
          hvls: environmentData?.clothing_insulation,
          evapHvls: environmentData?.clothing_insulation,
        },
      ]

      // Return empty results if no valid data
      const spaceTotals = totals?.map((total: number) => Math.ceil(total))
      const isInvalidResults = !spaceTotals?.filter(Boolean)?.length
      if (isInvalidResults) return { ...p, results: null }

      const results = {
        coolSpaceQty: spaceTotals,
        comfortToolInputs,
      }

      return { ...p, results }
    })
  },
})

export const accessEvapSizingToolState = () =>
  wrapEvapSizingToolState(evapSizingToolState)

export const useEvapSizingToolState = () => {
  const state = useState(evapSizingToolState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-evap-sizing-tool`))
  return wrapEvapSizingToolState(state)
}
