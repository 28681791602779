import { useRef, useEffect } from 'react'
import sum from 'lodash/sum'
import * as d3 from 'd3'
import Box from '@mui/material/Box'
import { useHeatIndexState } from 'store/heatIndexState'

const SCENARIOS = ['Fans + Evap Coolers', 'Fans Only', 'No Fans']
const HEAT_TYPES = ['Neutral', 'Very Warm', 'Hot', 'Very Hot', 'Extremely Hot']

const BarChart = () => {
  const svgRef = useRef(null)
  const heatIndexState = useHeatIndexState()
  const { needsUpdate } = heatIndexState.get()
  const region = heatIndexState.get()?.projectDetails?.value?.climateZone
  const value = heatIndexState.get()?.hourlyHeatIndexDistribution?.value || []
  const { hours = {}, scenarios = {}, risks = {} } = value
  const noFans = scenarios?.['No Fans'] || {}
  const fansOnly = scenarios?.['Fans Only'] || {}
  const fansEvap = scenarios?.['Fans + Evap Coolers'] || {}
  const margin = { top: 10, right: 0, bottom: 20, left: 10 }
  const defaultWidth = 900 - margin.left - margin.right
  const defaultHeight = 175 - margin.top - margin.bottom
  const defaultRatio = defaultWidth / defaultHeight
  const defaultLegendRatio = 900 / 40

  useEffect(() => {
    let width = defaultWidth,
      height = defaultHeight,
      legendWidth = 900,
      legendHeight = 70
    let currentWidth = window.innerWidth * 0.45,
      currentHeight = window.innerHeight * 0.45,
      currentLegendWidth = window.innerWidth * 0.45,
      currentLegendHeight = window.innerHeight * 0.1
    let currentRatio = currentWidth / currentHeight
    if (currentRatio > defaultRatio) {
      height = currentHeight
      legendHeight = currentLegendHeight
      width = currentHeight * defaultRatio
      legendWidth = currentLegendHeight * defaultLegendRatio
    } else {
      width = currentWidth
      legendWidth = currentLegendWidth
      height = currentWidth / defaultRatio
      legendHeight = currentLegendWidth / defaultLegendRatio
    }
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${height * 6} ${width / 4}`)
      .attr('preserveAspectRatio', 'xMinYMax meet')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // Sum each scenario type to determine max bar chart height
    const getChartMaxHeight = (hours: any) => {
      const totals =
        SCENARIOS?.map((scenario: string) => {
          const heatValues = HEAT_TYPES?.map(
            (type: string) => hours?.[scenario]?.[type]
          )
          const heatTotal = sum(heatValues)
          return heatTotal
        }) || []
      return Math.max(...totals)
    }

    if (region) {
      let groups: string[] = []
      Object.keys(hours)?.map(value => scenarios?.[value] && groups.push(value))
      const data: any = []
      groups.forEach(group => {
        let item = { products: group }
        HEAT_TYPES.forEach(subgroup => {
          let num = hours?.[group]?.[subgroup]
          item = { ...item, [subgroup]: num }
        })
        data.push(item)
      })

      // remove existing data
      d3.selectAll('g').remove()

      // add x axis
      let x = d3
        .scaleBand()
        .domain(groups)
        .range([0, width - 50])
        .padding(0.4)
      svg
        .append('g')
        .attr('transform', `translate(20, ${height})`)
        .call(d3.axisBottom(x).tickSizeOuter(0))

      // add y axis
      svg
        .append('text')
        .attr('text-anchor', 'end')
        .attr('y', 0)
        .attr('dy', '0.7rem')
        .attr('dx', -10)
        .attr('transform', 'rotate(-90)')
        .text('Hours per Year')
        .attr('font-family', 'Lato')
        .attr('font-size', '0.7rem')
      let y = d3
        .scaleLinear()
        .domain([0, getChartMaxHeight(hours)])
        .range([height, 0])
      svg
        .append('g')
        .attr('transform', 'translate(55, 0)')
        .call(d3.axisLeft(y).ticks(4))
      d3.selectAll('.tick')
        .filter(d => d === 0)
        .remove()
      svg
        .append('g')
        .attr('transform', 'translate(55, 0)')
        .call(
          d3
            .axisLeft(y)
            .tickFormat(() => '')
            .ticks(8)
        )

      // colors
      let color = d3
        .scaleOrdinal()
        .domain(HEAT_TYPES)
        .range(['limegreen', 'gold', 'darkorange', 'red', 'firebrick'])

      // stack the data
      let stackedData = d3.stack().keys(HEAT_TYPES)(data)

      // show the bars
      svg
        .append('g')
        .selectAll('g')
        // enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .join('g')
        .attr('fill', d => color(d?.key) as string)
        .selectAll('.bar')
        .data(d => d)
        .join('rect')
        .attr('x', d => x(d?.data?.products?.toString()) as number)
        .attr('y', d => y(d[1]))
        .attr('transform', `translate(20, 0)`)
        .attr('height', d => y(d[0]) - y(d[1]))
        .attr('width', x?.bandwidth())
        .attr('class', 'bar')
      // add text to bars
      svg
        .append('g')
        .selectAll('g')
        .data(stackedData)
        .join('g')
        .selectAll('.barText')
        .data(d => d)
        .join('text')
        .text(d => (d[1] - d[0] < 400 ? '' : d[1] - d[0]))
        .style('text-anchor', 'middle')
        .attr('font-size', '0.6rem')
        .attr(
          'x',
          d =>
            (x(d?.data?.products?.toString()) as number) +
            x?.bandwidth() / 2 +
            20
        )
        .attr('y', d => y(d[1]) + (y(d[0]) - y(d[1])) / 2 + 4)
        .attr('class', 'barText')

      // create the legend
      let legend = d3
        .select('#legend')
        .attr('viewBox', `0 0 ${legendHeight * 34} ${legendWidth / 10}`)
        .attr('preserveAspectRatio', 'xMinYMax meet')
        .attr('transform', 'translate(0,-10)')

      let size = width / 25

      legend
        .selectAll('.legendSquare')
        .data(Object.keys(risks))
        .join('rect')
        .attr('x', (d, i) => 25 + i * 3 * (size + 32))
        .attr('y', 5)
        .attr('width', size)
        .attr('height', size)
        .attr('fill', d => color(d) as string)
        .attr('class', 'legendSquare')

      legend
        .selectAll('.legendLabel')
        .data(Object.keys(risks))
        .join('text')
        .attr('font-family', 'Lato')
        .attr('font-size', '0.85rem')
        .attr('x', (d, i) => 25 + i * 3 * (size + 32) + size + 5)
        .attr('y', 13)
        .text(d => d)
        .attr('class', 'legendLabel')

      legend
        .selectAll('.legendLabelRisk1')
        .data(Object.keys(risks))
        .join('text')
        .attr('font-family', 'Lato')
        .attr('font-size', '0.7rem')
        .attr('x', (d, i) => 25 + i * 3 * (size + 32) + size + 5)
        .attr('y', 28)
        .text(d => risks[d as keyof typeof risks][0])
        .attr('class', 'legendLabelRisk1')

      legend
        .selectAll('.legendLabelRisk2')
        .data(Object.keys(risks))
        .join('text')
        .attr('font-family', 'Lato')
        .attr('font-size', '0.7rem')
        .attr('x', (d, i) => 25 + i * 3 * (size + 32) + size + 5)
        .attr('y', 42)
        .text(d => risks[d as keyof typeof risks][1] ?? '')
        .attr('class', 'legendLabelRisk2')

      legend
        .selectAll('.legendLabelRisk3')
        .data(Object.keys(risks))
        .join('text')
        .attr('font-family', 'Lato')
        .attr('font-size', '0.7rem')
        .attr('x', (d, i) => 25 + i * 3 * (size + 32) + size + 5)
        .attr('y', 57)
        .text(d => risks[d as keyof typeof risks][2] ?? '')
        .attr('class', 'legendLabelRisk3')
    }
    // eslint-disable-next-line
  }, [
    noFans,
    fansOnly,
    fansEvap,
    region,
    needsUpdate,
    risks?.length,
    window.innerHeight,
    window.innerWidth,
  ])

  return (
    <Box sx={{ mb: -1.5, p: 0 }}>
      <svg ref={svgRef} />
      <svg id="legend" />
    </Box>
  )
}

export default BarChart
