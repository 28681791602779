import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Modal from '@mui/material/Modal'
import Box from '@mui/system/Box'

import HubspotForm from 'components/HubSpotForm'

import { usePermissions } from 'hooks/usePermissions'

interface Props {
  onClose: () => void
  onSuccess: () => void
  open: boolean
  tool: string
}

const modalStyle = {
  bgcolor: 'background.paper',
  borderRadius: '3%',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
}

const LeadsModal = ({
  onClose = () => {},
  onSuccess = () => {},
  open,
  tool,
}: Props) => {
  const { loginWithRedirect } = useAuth0()
  const { isGuest } = usePermissions()

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose()
      }}
    >
      <Box
        component="form"
        sx={modalStyle}
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
        }}
      >
        <Typography variant="h4">Save your results</Typography>

        <Box sx={{ mb: 4, mt: 1, display: 'flex', alignItems: 'center' }}>
          To make this easier in the future,
          <Link
            sx={{ ml: 0.5, cursor: 'pointer' }}
            onClick={() => loginWithRedirect()}
          >
            create an account
          </Link>
        </Box>

        {isGuest && <HubspotForm />}

        {isGuest && (
          <Box sx={{ position: 'absolute', bottom: '36px', left: '125px' }}>
            <Link sx={{ cursor: 'pointer' }} onClick={() => onClose()}>
              Never mind - Take me back
            </Link>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default LeadsModal
