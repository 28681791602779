import kebabCase from 'lodash/kebabCase'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import Link from '@mui/material/Link'

import { SectionProps } from '../helpers/interfaces'
import SidebarSubSection from './SidebarSubSection'
import { useGlobalState } from 'store'

const Section = ({ sectionObj, headerPrefix, path }: SectionProps) => {
  const header = `${headerPrefix} - ${sectionObj.sidebarContent}`
  const label = kebabCase(header)

  const globalState = useGlobalState()
  const globalPath = globalState.getDesignGuidePath(path)
  const globalDesignGuidePaths = globalState.get().designGuidePaths

  const handleChange = () => {
    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObj = { ...pathObject }
      if (newPathObj.path === path) newPathObj.opened = !newPathObj.opened
      return newPathObj
    })

    globalState.setDesignGuidePaths(newPaths)
  }

  const handleClick = () => {
    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObj = { ...pathObject }
      if (newPathObj.path === path) {
        newPathObj.active = true
      } else {
        newPathObj.active = false
      }
      return newPathObj
    })

    globalState.setDesignGuidePaths(newPaths)
  }

  const mappedSidebarSections =
    sectionObj.subSections &&
    sectionObj.subSections.map((subSection, index) => (
      <SidebarSubSection
        key={index}
        subSectionObj={subSection}
        headerPrefix={`${headerPrefix}.${index + 1}`}
        path={`${path}-${index + 1}`}
      />
    ))

  return (
    <Accordion
      disableGutters
      expanded={globalPath?.opened}
      elevation={0}
      sx={{ bgcolor: 'transparent', color: 'primary.light' }}
    >
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              '&:hover': { backgroundColor: 'primary.main' },
              borderRadius: 5,
            }}
          >
            <ExpandMoreIcon
              sx={{
                color: 'grey',
              }}
              onClick={handleChange}
            />
          </Box>
        }
        aria-controls={`${label}-content`}
        id={`${label}-header`}
        sx={{ pr: 0 }}
      >
        <Link
          onClick={handleClick}
          underline="none"
          sx={{
            color: 'primary.light',
            '&:hover': { backgroundColor: 'primary.main' },
          }}
        >
          {header}
        </Link>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, ml: 2 }}>
        <Box>
          <nav aria-label={`${label}-nav`}>
            <List component="nav" aria-label={`${label}-list`} sx={{ p: 0 }}>
              {mappedSidebarSections}
            </List>
          </nav>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default Section
