import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store'

const Additions = () => {
  const submittalsState = useSubmittalsState()
  const { fan, productData, sizeData, numCols, additions, numAdditions } =
    submittalsState.getState()
  const addons = productData.addons.map((a: any) => a.addon)
  addons.unshift('None')
  const full =
    (fan.template === 'Large Fans' &&
      sizeData.mounts.length === 0 &&
      productData.controllers.length === 0) ||
    (fan.template === 'Large Fans' &&
      productData.appearances.length === 0 &&
      productData.controllers.length === 0)
  return (
    <Grid item md={full ? 12 : 6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" sx={{ mt: -0.5 }}>
            Additions
          </Typography>
          {productData.addons.length > 1 && (
            <Grid item xs={(full ? 12 : 24) / numCols}>
              <Button
                variant="outlined"
                size="large"
                onClick={submittalsState.addAddition}
                sx={{
                  ml: 2,
                  pt: 0,
                  pb: 0,
                  '@media print': { display: 'none' },
                }}
              >
                Add Another Addition
              </Button>
            </Grid>
          )}
        </Box>
        <Grid container rowSpacing={0} columnSpacing={1} id="additions">
          {Array.from(Array(numAdditions)).map((o, index) => (
            <Grid item xs={(full ? 12 : 24) / numCols} key={index}>
              <Autocomplete
                disablePortal
                size="small"
                options={addons}
                sx={{ pb: 1, mt: -0.5 }}
                renderInput={params => (
                  <TextField {...params} variant="standard" label="Addition" />
                )}
                value={additions[index]['addition']}
                onChange={(event, newAddition: string | null) => {
                  submittalsState.editAddition(index, newAddition ?? '')
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Card>
    </Grid>
  )
}

export default Additions
