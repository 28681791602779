import { useState, useRef } from 'react'
import uniq from 'lodash/uniq'
import { CSVLink } from 'react-csv'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table'
import Typography from '@mui/material/Typography'
import GridOnIcon from '@mui/icons-material/GridOn'

import ProductSelect from 'components/ProductSelect'

import {
  APIProduct,
  APIProductData,
  useScheduleBuilderState,
} from 'store/scheduleBuilderState'
import { useProducts } from 'hooks/useProducts'
import { axiosHelper } from 'utils'
import buildCsv from './utils/build-csv'

import { AppBarSpacer } from '../layout/helpers/styled'
import DataTable from './components/Table'
import NotesModal from './components/NotesModal'
import PrintedNotes from './components/PrintedNotes'
import './styles.css'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

const FanScheduleBuilder = ({
  showVersion,
  standalone,
}: {
  showVersion?: boolean
  standalone?: boolean
}) => {
  const scheduleBuilderState = useScheduleBuilderState()
  const { schedules, leadSaved } = scheduleBuilderState.getState()
  const [loading, setLoading] = useState<boolean>(false)
  const [valid, setValid] = useState<boolean>(false)
  const [activeNoteId, setActiveNoteId] = useState<number>()
  const componentRef = useRef<HTMLDivElement>(null)
  const { products } = useProducts()

  // Unique categories in the current schedules
  const categories = uniq(
    Object.keys(schedules)?.map(index => schedules[index]?.product?.category)
  ).sort()

  // Renders a single category table with all the corresponding schedules
  const renderScheduleCategory = ({
    category,
    combineNotes,
  }: {
    category: string
    combineNotes: boolean
  }) => {
    const matchedSchedules = Object.keys(schedules)?.filter(
      index => schedules[index]?.product?.category === category
    )
    return (
      <Box key={category}>
        <Typography variant="h4" sx={{ mb: 0 }}>
          {category}
        </Typography>
        <Box sx={{ width: '100%', overflowX: 'scroll' }}>
          <Table sx={{ mt: 2, mb: 4 }}>
            {matchedSchedules.map((key: string, index: number) => (
              <DataTable
                id={Number(key)}
                key={key}
                products={products}
                showHeader={!index}
                onNoteClick={(id: number) => setActiveNoteId(id)}
                combineNotes={combineNotes}
              />
            ))}
          </Table>
        </Box>
        <Box className="printable-notes" sx={{ display: 'none' }}>
          <PrintedNotes category={category} />
        </Box>
      </Box>
    )
  }

  const TITLE = 'Fan Schedule Builder'

  // Render the fan select when there are no schedules
  const hasSchedules = Object.keys(schedules).some(
    key => schedules?.[key]?.product?.name
  )
  if (!hasSchedules) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 16,
        }}
      >
        <Typography variant="h2" sx={{ mb: 4 }}>
          {TITLE}
        </Typography>
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <ProductSelect
            clearOnBlur={true}
            styles={{
              width: '300px',
              border: '1px solid',
              borderColor: 'rgb(188, 190, 192)',
              borderRadius: '4px',
              p: 1,
            }}
            onChange={async value => {
              const newProduct = products.find(
                (p: APIProduct) => p.name === value
              )!
              const productSuccess = ({ data }: { data: APIProductData }) => {
                scheduleBuilderState.setProduct(newProduct, 0)
                scheduleBuilderState.setProductData(data, 0)
                setLoading(false)
              }
              setLoading(true)
              axiosHelper({
                url: `/products/relations/${newProduct?.id}`,
                success: productSuccess,
              })
            }}
          />
        )}
      </Box>
    )
  }

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          setValid(true)
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => {
            scheduleBuilderState.reset()
            setValid(false)
          }}
          onSave={() => scheduleBuilderState.setValidated()}
          valid={valid}
          printContent={() => componentRef.current}
          renderBeforeActionButtons={() => (
            <ProductSelect
              clearOnBlur={true}
              styles={{
                width: '300px',
                border: '1px solid',
                borderColor: 'rgb(188, 190, 192)',
                borderRadius: '4px',
                p: 1,
                pt: 1.5,
              }}
              onChange={value => {
                const index = Object.keys(schedules)?.length || 0
                const newProduct = products.find(p => p.name === value)!
                scheduleBuilderState.setProduct(newProduct, index)
                const productSuccess = ({ data }: { data: APIProductData }) => {
                  scheduleBuilderState.setProductData(data, index)
                }
                axiosHelper({
                  url: `/products/relations/${newProduct?.id}`,
                  success: productSuccess,
                })
                setValid(false)
              }}
            />
          )}
          saveActions={[
            {
              icon: () => <GridOnIcon fontSize="small" />,
              node: () => (
                <Box className="fsb-menu-link">
                  <CSVLink
                    filename="BAF-fan-schedule-builder.csv"
                    data={buildCsv(schedules, categories) || []}
                  >
                    <Box sx={{ color: 'black' }}>Export CSV</Box>
                  </CSVLink>
                </Box>
              ),
            },
          ]}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => scheduleBuilderState.setLeadSaved(true)}
          onLeadsModalClose={() => {
            if (!leadSaved) {
              scheduleBuilderState.setValid(false)
              setValid(false)
            }
          }}
        />
        <Box ref={componentRef}>
          <>
            <style type="text/css">
              {
                '@media print{@page {size: 10.5in 8in} .printable-notes-description {opacity: 1} .printable-notes {display: block} .printable-id-cell {display: block} .printable-view-notes-button {display: none} .printable-view-notes-range {display: block} .printable-remove-notes-cell {display: none}}'
              }
            </style>
            <PrintHeader title={TITLE} />
            {categories?.map((category: any) =>
              renderScheduleCategory({ category, combineNotes: false })
            )}
          </>
        </Box>
      </Box>
      <NotesModal
        id={Number(activeNoteId)}
        onClose={() => setActiveNoteId(undefined)}
      />
    </Container>
  )
}

export default FanScheduleBuilder
