import React, { useState, useRef, useEffect } from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'

import Form from './components/Form'

import { AppBarSpacer } from '../layout/helpers/styled'
import {
  useSubmittalsState,
  APIProduct,
  APIProductData,
} from 'store/salesToolsState'
import { axiosHelper } from 'utils'
import ToolHeader from 'components/ToolHeader'

const Submittals = ({
  showVersion,
  standalone,
}: {
  showVersion?: boolean
  standalone?: boolean
}) => {
  const submittalsState = useSubmittalsState()
  const { valid, numCols, fan, leadSaved } = submittalsState.getState()
  const [value, setValue] = useState<any>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState([] as APIProduct[])

  useEffect(() => {
    const success = ({ data }: { data: APIProduct[] }) => setProducts(data)
    axiosHelper({ url: '/products', success })
  }, [])

  const componentRef = useRef<HTMLDivElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function renderProductSelect() {
    return (
      <Autocomplete
        disablePortal
        size="small"
        options={products
          .filter(
            p =>
              p.name !== 'ShopFan' &&
              p.name !== 'Powerfoil 8 Plus' &&
              p.name !== 'Powerfoil Breeze'
                ? p
                : false // TODO: do this in the db instead https://github.com/bigassdata/sales-tools-api/issues/41
          )
          .sort((a, b) => {
            if (a.category === b.category) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            }
            return a.category > b.category ? 1 : -1
          })
          .map(p => p.name)}
        groupBy={option => products.find(p => p.name === option)!.category}
        sx={{ width: 300 }}
        renderInput={params => (
          <TextField {...params} label="Choose a Product" />
        )}
        value={value}
        onChange={(event, newValue) => {
          event.preventDefault()
          setValue(newValue)
          if (newValue) {
            submittalsState.setFan(products.find(p => p.name === newValue)!)
            const productSuccess = ({ data }: { data: APIProductData }) => {
              submittalsState.setProductData(data)
            }
            axiosHelper({
              url: `/products/relations/${fan.id}`,
              success: productSuccess,
            })
          }
        }}
      />
    )
  }

  if (!value)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 16,
        }}
      >
        <Typography variant="h2" sx={{ mb: 4 }}>
          Submittals
        </Typography>
        {renderProductSelect()}
      </Box>
    )

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          submittalsState.setValid(true)
        }}
      >
        <ToolHeader
          title="Submittals"
          onReset={() => setValue(null)}
          onSave={() => submittalsState.setValidated()}
          valid={valid}
          printContent={() => componentRef.current}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => submittalsState.setLeadSaved(true)}
          onLeadsModalClose={() => {
            if (!leadSaved) submittalsState.setValid(false)
          }}
          renderBeforeActionButtons={() => renderProductSelect()}
          renderAfterActionButtons={() =>
            value && (
              <Box>
                <IconButton onClick={handleClick}>
                  <SettingsIcon fontSize="medium" />
                </IconButton>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  disableScrollLock
                >
                  <ListItem>
                    <ListItemText>Number of Columns:</ListItemText>
                    <TextField
                      label=""
                      size="small"
                      type="number"
                      value={numCols}
                      onChange={e => {
                        submittalsState.setNumCols(parseInt(e.target.value))
                      }}
                      margin="dense"
                      variant="standard"
                      InputProps={{ inputProps: { min: 4, max: 6 } }}
                      sx={{ pl: 2, pb: 1, width: 50 }}
                    />
                  </ListItem>
                </Popover>
              </Box>
            )
          }
        />
        {value && <Form ref={componentRef} />}
      </Box>
      {value && (
        <Typography sx={{ my: 4 }}>
          Full warranty information can be found in the product catalog.
        </Typography>
      )}
    </Container>
  )
}

export default Submittals
