import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useAcSavingsState } from 'store'

const AnnualSavings = () => {
  const acSavingsState = useAcSavingsState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const { results } = acSavingsState.get()

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const totalSavings = round(
    results?.savings?.acSavings + results?.savings?.fanEnergySavingsCost,
    1
  )
  const acSavings = round(results?.savings?.acSavings, 1) || 0
  const fanEnergySavingsCost =
    round(results?.savings?.fanEnergySavingsCost, 1) || 0

  return (
    <Box sx={{ width: 'fit-content', color: 'primary.dark' }}>
      <Typography variant="h6" sx={{ pb: 1 }}>
        Annual Savings
      </Typography>
      <Box
        sx={{
          width: '100%',
          border: '1px solid rgb(188, 190, 192)',
          borderRadius: '10px',
          pt: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            borderBottom: '1px solid rgb(188, 190, 192)',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                fontSize: mediumBreakpoint ? '48px' : '32px',
                px: 1,
                width: '100%',
              }}
            >
              {`$${totalSavings || 0}`}
              <Box
                sx={{
                  fontSize: mediumBreakpoint ? '24px' : '16px',
                  fontWeight: '700',
                  ml: 1,
                  mt: mediumBreakpoint ? 3 : 2,
                }}
              >
                per year
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: mediumBreakpoint ? '18px' : '16px',
                px: 1,
                pb: 2,
                mt: -1,
                color: 'primary.main',
              }}
            >
              Total Energy Savings
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', pt: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                fontSize: mediumBreakpoint ? '24px' : '16px',
                px: 1,
                fontWeight: 700,
              }}
            >
              {`$${acSavings}`}
              <Box
                sx={{
                  textWrap: 'nowrap',
                  fontSize: mediumBreakpoint ? '16px' : '12px',
                  ml: 1,
                  mt: mediumBreakpoint ? 1 : 0.5,
                  whiteSpace: 'nowrap',
                }}
              >
                per year
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: mediumBreakpoint ? '16px' : '12px',
                px: 1,
                pb: 1,
                color: 'primary.main',
              }}
            >
              Air Conditioner Savings
            </Box>
          </Box>

          <Box
            sx={{
              fontSize: mediumBreakpoint ? '24px' : '16px',
              fontWeight: '700',
              px: 1,
              mt: 2,
              color: 'primary.main',
            }}
          >
            +
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                fontSize: mediumBreakpoint ? '24px' : '16px',
                px: 1,
                fontWeight: 700,
              }}
            >
              {`$${fanEnergySavingsCost}`}
              <Box
                sx={{
                  fontSize: mediumBreakpoint ? '16px' : '12px',
                  ml: 1,
                  mt: mediumBreakpoint ? 1 : 0.5,
                  whiteSpace: 'nowrap',
                }}
              >
                per year
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: mediumBreakpoint ? '16px' : '12px',
                px: 1,
                pb: 1,
                color: 'primary.main',
              }}
            >
              Fan Energy Savings / Cost
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AnnualSavings
