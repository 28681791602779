import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store/salesToolsState'

interface Module {
  id: number
  label: string
  features: ModuleFeature[]
}
interface ModuleFeature {
  id: number
  label: string
  description: string
}

const LightingModule = () => {
  const { productData } = useSubmittalsState().getState()
  return productData.modules.map((m: Module) => (
    <Grid
      item
      md={6}
      xs={productData.modules.length > 1 ? 6 : 12}
      sx={{
        '@media print': {
          flexBasis: '100%',
          maxWidth: '100%',
        },
      }}
    >
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <>
          <Typography variant="h5" sx={{ mt: -1, mb: -0.5 }}>
            {m.label}
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                {m.features.map(
                  (moduleFeature: ModuleFeature, index) =>
                    index < (productData.modules.length > 1 ? 3 : 6) && (
                      <TableCell
                        sx={{
                          fontWeight: 700,
                          p: 0,
                          pr: 0.1,
                          lineHeight: '1rem',
                        }}
                        key={moduleFeature.id}
                      >
                        {moduleFeature.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {m.features.map(
                  (moduleFeature: ModuleFeature, index) =>
                    index < (productData.modules.length > 1 ? 3 : 6) && (
                      <TableCell
                        sx={{ p: 0, pr: 0.1, lineHeight: '1rem' }}
                        id={index.toString()}
                        key={index}
                      >
                        {moduleFeature.description}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableBody>
            {productData.modules.length > 1 && (
              <>
                <TableHead>
                  <TableRow>
                    {m.features.map(
                      (moduleFeature: ModuleFeature, index) =>
                        index > 2 &&
                        index < 5 && (
                          <TableCell
                            sx={{
                              fontWeight: 700,
                              p: 0,
                              pr: 0.1,
                              lineHeight: '1rem',
                            }}
                            key={moduleFeature.id}
                          >
                            {moduleFeature.label}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {m.features.map(
                      (moduleFeature: ModuleFeature, index) =>
                        index > 2 &&
                        index < 5 && (
                          <TableCell
                            sx={{ p: 0, pr: 0.1, lineHeight: '1rem' }}
                            id={index.toString()}
                            key={index}
                          >
                            {moduleFeature.description}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableBody>
                <TableHead>
                  <TableRow>
                    {m.features.map(
                      (moduleFeature: ModuleFeature, index) =>
                        index > 6 && (
                          <TableCell
                            sx={{
                              fontWeight: 700,
                              p: 0,
                              pr: 0.1,
                              lineHeight: '1rem',
                            }}
                            key={moduleFeature.id}
                          >
                            {moduleFeature.label}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {m.features.map(
                      (moduleFeature: ModuleFeature, index) =>
                        index > 6 && (
                          <TableCell
                            sx={{ p: 0, pr: 0.1, lineHeight: '1rem' }}
                            id={index.toString()}
                            key={index}
                          >
                            {moduleFeature.description}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableBody>
              </>
            )}
          </Table>
        </>
      </Card>
    </Grid>
  ))
}

export default LightingModule
