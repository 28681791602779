import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import FormCard from 'components/FormCard'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useDestratToolState } from 'store'

const Results = () => {
  const destratToolState = useDestratToolState()
  const { results, isBasedOnInputs, fanQuantities, isMetric } =
    destratToolState.get()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  const renderResultsTable = () => (
    <Table aria-label="results-table" size="small" sx={{ mb: 3 }}>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              fontWeight: 700,
            }}
          >
            Fan & Size
          </TableCell>
          <TableCell
            sx={{
              fontSize: mediumBreakpoint ? 'medium' : 'small',
              fontWeight: 700,
            }}
          >
            Quantity
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {results?.productResults?.map((result: any, index: number) => (
          <TableRow
            sx={{
              padding: '0',
              backgroundColor: result?.meetsSizeRequirements
                ? '#fff'
                : '#A9A9A9',
            }}
            key={`${results?.name}-${index}`}
          >
            <TableCell key={`${results?.name}-name`}>
              <Box sx={{ width: 'auto' }}>{result?.name}</Box>
            </TableCell>
            <TableCell key={`${results?.name}-value`}>
              {isBasedOnInputs && result?.meetsSizeRequirements ? (
                <TextField
                  required
                  variant="standard"
                  sx={{ width: '50px' }}
                  type="number"
                  label=""
                  name="qauntity"
                  value={fanQuantities?.[index] || 0}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault()
                    const value = parseFloat(e?.target?.value)
                    destratToolState.setFanQuantity(value, index)
                    destratToolState.setResults()
                  }}
                />
              ) : (
                <Box sx={{ width: '200px' }}>{result?.value}</Box>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  const renderCalculatedValues = () => (
    <Box sx={{ display: 'flex', mb: 4 }}>
      <Box sx={{ flexDirection: 'column', mr: 3 }}>
        <Typography variant="h6" sx={{ mb: -1 }}>
          Floor Area
        </Typography>
        <Typography variant="h5">{Math.round(results?.floorArea)}</Typography>
        <Typography variant="body1" sx={{ mt: -0.5 }}>
          {`Square ${isMetric ? 'meters' : 'feet'}`}
        </Typography>
      </Box>
      <Box sx={{ flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ mb: -1 }}>
          Air Volume
        </Typography>
        <Typography variant="h5">{Math.round(results?.airVolume)}</Typography>
        <Typography variant="body1" sx={{ mt: -0.5 }}>
          {`Cubic ${isMetric ? 'meters' : 'feet'}`}
        </Typography>
      </Box>
    </Box>
  )

  return (
    <FormCard width="100%">
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        Results
      </Typography>

      <Typography variant="h6" sx={{ mb: 1.5 }}>
        CALCULATED VALUES
      </Typography>
      {renderCalculatedValues()}

      <Typography variant="h6" sx={{ mb: 1.5 }}>
        EQUIPMENT REQUIREMENTS
      </Typography>

      {renderResultsTable()}

      {isBasedOnInputs && (
        <Box sx={{ flexDirection: 'column' }}>
          <Typography variant="h6" sx={{ mb: -0.5 }}>
            Delivered Air Turnovers per Hour (AT/HR)
          </Typography>
          <Typography variant="h3">{results?.turnovers}</Typography>
        </Box>
      )}
    </FormCard>
  )
}

export default Results
