import { useState, useRef } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import ReactToPrint from 'react-to-print'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DownloadIcon from '@mui/icons-material/Download'
import ListItemIcon from '@mui/material/ListItemIcon'
import PrintIcon from '@mui/icons-material/Print'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'

import { AppBarSpacer } from '../layout/helpers/styled'

// Import pdf worker
import 'react-pdf/dist/esm/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

const OperationalLoads = ({ standalone }: { standalone?: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const componentRef = useRef<HTMLDivElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const pdfUrl =
    'https://digital-toolbox-bucket.s3.us-east-2.amazonaws.com/BAF+Operational+Weights+%26+Loads.pdf'

  // Style breakpoints
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const smallBreakpoint = useMediaQuery('(min-width:768px)')
  const xsmallBreakpoint = useMediaQuery('(min-width:512px)')

  const TITLE = 'Operational Loads'

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: mediumBreakpoint ? 'row' : 'column',
              width: '100%',
              justifyContent: mediumBreakpoint ? 'space-between' : 'center',
              alignItems: mediumBreakpoint ? 'space-between' : 'center',
              mb: 3,
            }}
          >
            <Typography variant={mediumBreakpoint ? 'h2' : 'h4'}>
              {TITLE}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Button
                variant="outlined"
                sx={{ mt: -0.5, py: 0.75, px: 2 }}
                id="action-save-button"
                aria-haspopup="true"
                onClick={e => setAnchorEl(e?.currentTarget || '')}
                endIcon={<ArrowDropDownIcon sx={{ mt: 0 }} />}
                type="button"
              >
                Save
              </Button>
              <Menu
                id="action-buttons-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'action-save-button',
                }}
              >
                <ReactToPrint
                  trigger={() => (
                    <MenuItem>
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Print</ListItemText>
                    </MenuItem>
                  )}
                  content={() => componentRef.current}
                />
                <a
                  href={pdfUrl}
                  target="blank"
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    opacity: 0.8,
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <DownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Download</ListItemText>
                  </MenuItem>
                </a>
              </Menu>
            </Box>
          </Box>
          <Box
            ref={componentRef}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'auto',
            }}
          >
            <Document file={pdfUrl}>
              <Page
                scale={
                  mediumBreakpoint
                    ? 1.3
                    : smallBreakpoint
                    ? 1
                    : xsmallBreakpoint
                    ? 0.6
                    : 0.4
                }
                pageNumber={1}
              />
            </Document>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default OperationalLoads
