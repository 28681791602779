import Typography from '@mui/material/Typography'

import FormCard from 'components/FormCard'
import EnvironmentalImpactTable from 'components/EnvironmentalImpactTable'

import { useHeatSavingsState } from 'store'

import AnnualSavings from './AnnualSavings'

const Results = () => {
  const heatSavingsState = useHeatSavingsState()
  const { results, existingFans } = heatSavingsState.get()

  return (
    <FormCard width="100%">
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        Results
      </Typography>
      <AnnualSavings />
      <EnvironmentalImpactTable
        environmentalImpact={results?.environmentalImpact}
        existingFans={existingFans}
        width="100%"
      />
    </FormCard>
  )
}

export default Results
