import { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppBarSpacer } from '../layout/helpers/styled'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import Results from './components/Results'
import Notes from './components/Notes'
import InputsTile from './components/InputsTile'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { useProjectResults } from 'hooks/useProjectResults'
import { usePermissions } from 'hooks/usePermissions'
import { useDestratToolState } from 'store'
import { axiosHelper } from 'utils'

import DestratToolPdf from './DestratToolPdf'

const DestratTool = ({ standalone }: { standalone?: boolean }) => {
  const { user } = usePermissions()
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const destratToolState = useDestratToolState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const componentRef = useRef<HTMLDivElement>(null)
  const {
    leadSaved,
    results,
    productsData,

    // inputs
    isMetric,
    isBasedOnInputs,
    airfoilHeight,
    designAirTurnovers,
    floorToCeilingHeight,
    length,
    width,
    projectDate,
    bafContact,
    projectName,
    installLocation,
    product,
  } = destratToolState.get()

  // Data loading states
  const [loadingProductsData, setLoadingProductsData] = useState(false)

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      destratToolState.loadState(newState)
    }
  }, [projectResults, destratToolState, resultsLoaded])

  // Get product data points
  useEffect(() => {
    if (!productsData?.length && !loadingProductsData) {
      setLoadingProductsData(true)
      axiosHelper({
        url: '/product-sizes',
        method: 'get',
        success: (values: any) => {
          setLoadingProductsData(false)
          destratToolState.setProductsData(values?.data)
        },
      })
    }
  }, [destratToolState, productsData, loadingProductsData])

  const loading = false

  const TITLE = 'Destrat Tool'

  const hasValidInputs = !!projectName

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          destratToolState.resetFanQauntities()
          destratToolState.setResults()
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => destratToolState.resetState()}
          toolState={{ ...destratToolState.get(), toolName: TITLE }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          valid={hasValidInputs}
          canSave={!!results}
          leadSaved={leadSaved}
          onLeadsModalSuccess={() => destratToolState.setLeadSaved(true)}
          onLeadsModalClose={() => {}}
          printContent={() => componentRef.current}
          renderAfterActionButtons={() => (
            <Button
              variant="text"
              disableElevation
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => {
                if (hasValidInputs) {
                  destratToolState.resetFanQauntities()
                  destratToolState.setResults()
                }
              }}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-Destrat-Tool',
                        email,
                        results,
                        isMetric,
                        isBasedOnInputs,
                        airfoilHeight,
                        designAirTurnovers,
                        floorToCeilingHeight,
                        length,
                        width,
                        projectDate,
                        bafContact,
                        projectName,
                        installLocation,
                        product,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<DestratToolPdf />}
                    fileName="BAF-Destrat-Tool.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />
        <Box ref={componentRef}>
          <PrintHeader title={TITLE} />
          <Box
            display="flex"
            sx={{ flexDirection: mediumBreakpoint ? 'row' : 'column' }}
          >
            <Stack sx={{ width: '100%' }}>
              <InputsTile loading={loading} />
            </Stack>
          </Box>

          {!!results && (
            <Box
              display="flex"
              sx={{ flexDirection: mediumBreakpoint ? 'row' : 'column' }}
            >
              <Stack sx={{ width: mediumBreakpoint ? '50%' : '100%' }}>
                <Results />
              </Stack>
              <Stack
                sx={{
                  ml: mediumBreakpoint ? 1 : 0,
                  width: mediumBreakpoint ? '50%' : '100%',
                }}
              >
                <Notes />
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default DestratTool
