import cloneDeep from 'lodash/cloneDeep'
import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from 'config/constants'

export interface Product {
  name: string | undefined
  mechanical_warranty: string
  electrical_warranty: string
  replacement_motor_10_year: number
  replacement_motor_15_year: number
  replacement_motor_10_year_baf_install: number
  replacement_motor_15_year_baf_install: number
  motor_labor_10_year: number
  motor_labor_15_year: number
  motor_labor_10_year_baf_installed: number
  motor_labor_15_year_baf_installed: number
  replacement_drive_10_year: number
  replacement_drive_15_year: number
  drive_labor_10_year: number
  drive_labor_15_year: number
  lift_cost_10_year: number
  lift_cost_15_year: number
  down_time_10_year: number | null
  down_time_15_year: number | null
  fan_cost: number
  labor_time: number
  baf_product?: boolean
}

export interface CostOfOwnershipState {
  bafFans: Product[]
  existingFans: Product[]
  leadSaved: boolean
  results: any

  // Data
  products: Product[]

  // Input values
  bafInstalled: boolean
  bafProvidedLift: boolean
  compareCompetitors: boolean
  downTimeCost: number
  evaluationYearPeriod: string
  fanQuantity: number
}

const initialCostOfOwnershipState = {
  bafFans: [{ name: 'Powerfoil X4', fan_cost: 10830 }] as Product[],
  existingFans: [] as Product[],
  leadSaved: false,
  results: null,

  // Data
  products: [] as Product[],

  // Input values
  compareCompetitors: false,
  bafInstalled: false,
  bafProvidedLift: false,
  downTimeCost: 500,
  fanQuantity: 1,
  evaluationYearPeriod: '10 Year',
}

const costOfOwnershipState = createState(initialCostOfOwnershipState)

const wrapCostOfOwnershipState = (s: State<CostOfOwnershipState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialCostOfOwnershipState,
      // bafFans: [p?.products?.[0]],
    })),
  setBafFan: (value: any, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index] = value
      return { ...p, bafFans: fans, results: null }
    })
  },
  setBafFanCost: (cost: number, index: number) => {
    s.set((p: any) => {
      const fans = cloneDeep(p?.bafFans)
      fans[index].fan_cost = cost
      return { ...p, bafFans: fans, results: null }
    })
  },
  removeBafFan: (index: number) => {
    s.set((p: any) => {
      const fans = [...p?.bafFans]
      delete fans[index]
      return { ...p, bafFans: fans.filter(Boolean), results: null }
    })
  },
  setProducts: (value: Product[]) => {
    s.set((p: any) => ({ ...p, products: value, results: null }))
  },
  setDownTimeCost: (value: number) => {
    s.set((p: any) => ({ ...p, downTimeCost: value, results: null }))
  },
  setFanQuantity: (value: number) => {
    s.set((p: any) => ({ ...p, fanQuantity: value, results: null }))
  },
  setEvaluationYearPeriod: (value: string) => {
    s.set((p: any) => ({ ...p, evaluationYearPeriod: value, results: null }))
  },
  setCompareCompetitors: (value: boolean) => {
    s.set((p: any) => ({ ...p, compareCompetitors: value, results: null }))
  },
  setBafInstalled: (value: boolean) => {
    s.set((p: any) => ({ ...p, bafInstalled: value, results: null }))
  },
  setBafProvidedLift: (value: boolean) => {
    s.set((p: any) => ({ ...p, bafProvidedLift: value, results: null }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setResults: () => {
    s.set((p: any) => {
      const is10Year = p?.evaluationYearPeriod === '10 Year'

      // Get fan data
      const competitorFans = p?.compareCompetitors
        ? p?.products?.filter((fan: Product) => !fan?.baf_product)
        : []
      const bafFans = p?.bafFans?.map((bafFan: Product) => {
        const productData = p?.products?.find(
          (fan: Product) => fan?.name === bafFan?.name
        )
        return {
          ...productData,
          ...bafFan,
        }
      })

      // Get model (column) names
      const products = [...bafFans, ...competitorFans].filter(Boolean)
      const columns = products?.map((fan: Product) => fan?.name)

      // Get product info
      const mechanicalWarranty = products?.map(
        (fan: Product) => fan?.mechanical_warranty || ''
      )
      const electricalWarranty = products?.map(
        (fan: Product) => fan?.electrical_warranty || ''
      )
      const purchasePrice = products?.map(
        (fan: Product) => fan?.fan_cost * p?.fanQuantity
      )

      // Get replacement parts and labor costs
      const motorReplacementCost = products?.map((fan: Product) => {
        if (p?.bafInstalled) {
          return is10Year
            ? fan?.replacement_motor_10_year_baf_install * p?.fanQuantity
            : fan?.replacement_motor_15_year_baf_install * p?.fanQuantity
        }
        return is10Year
          ? fan?.replacement_motor_10_year * p?.fanQuantity
          : fan?.replacement_motor_15_year * p?.fanQuantity
      })
      const motorLaborCost = products?.map((fan: Product) => {
        if (p?.bafInstalled)
          return is10Year
            ? fan?.motor_labor_10_year_baf_installed * p?.fanQuantity
            : fan?.motor_labor_15_year_baf_installed * p?.fanQuantity

        return is10Year
          ? fan?.motor_labor_10_year * p?.fanQuantity
          : fan?.motor_labor_15_year * p?.fanQuantity
      })
      const driveReplacementCost = products?.map((fan: Product) =>
        is10Year
          ? fan?.replacement_drive_10_year * p?.fanQuantity
          : fan?.replacement_drive_15_year * p?.fanQuantity
      )
      const driveLaborCost = products?.map((fan: Product) =>
        is10Year
          ? fan?.drive_labor_10_year * p?.fanQuantity
          : fan?.drive_labor_15_year * p?.fanQuantity
      )
      const liftCost = products?.map((fan: Product) =>
        !p?.bafProvidedLift
          ? 0
          : is10Year
          ? fan?.lift_cost_10_year * p?.fanQuantity
          : fan?.lift_cost_15_year * p?.fanQuantity
      )
      const downTime = products?.map((fan: Product) =>
        is10Year
          ? (fan?.down_time_10_year || 0) * p?.fanQuantity
          : (fan?.down_time_15_year || 0) * p?.fanQuantity
      )
      const downTimeCost = products?.map((fan: Product) =>
        is10Year
          ? (fan?.down_time_10_year || 0) * p?.downTimeCost * p?.fanQuantity
          : (fan?.down_time_15_year || 0) * p?.downTimeCost * p?.fanQuantity
      )

      // Get total costs
      const totals = products?.map(
        (fan: Product, index: number) =>
          purchasePrice[index] +
            motorReplacementCost[index] +
            motorLaborCost[index] +
            driveReplacementCost[index] +
            driveLaborCost[index] +
            liftCost[index] +
            downTimeCost[index] || ''
      )

      // Build results
      const results = {
        models: {
          title: 'Models',
          data: columns,
          tooltip: '',
        },
        mechanicalWarranty: {
          title: 'Mechanical Warranty',
          data: mechanicalWarranty,
          tooltip: 'As published by manufacturer',
        },
        electricalWarranty: {
          title: 'Electrical Warranty',
          data: electricalWarranty,
          tooltip: 'As published by manufacturer',
        },
        purchaseCost: {
          title: 'Purchase Price',
          data: purchasePrice,
          tooltip: 'MSRP unless otherwise noted',
        },
        motorReplacementCost: {
          title: 'Motor Replacement Cost',
          data: motorReplacementCost,
          tooltip: 'Average aftermarket replacement cost for all components',
        },
        motorLaborCost: {
          title: 'Motor Labor Cost',
          data: motorLaborCost,
          tooltip: 'Average aftermarket labor cost to replace the motor',
        },
        driveReplacementCost: {
          title: 'Drive Replacement Cost',
          data: driveReplacementCost,
          tooltip: 'Average aftermarket replacement cost for all components',
        },
        driveLaborCost: {
          title: 'Drive Labor Cost',
          data: driveLaborCost,
          tooltip: 'Average aftermarket labor cost to replace the drive',
        },
        liftCost: {
          title: 'Lift Cost',
          data: liftCost,
          tooltip: 'Average cost of lift if not owned by the customer',
        },
        downTime: {
          title: 'Down Time Hours',
          data: downTime,
          tooltip: 'Total hours of production downtime to complete repairs',
        },
        downTimeCost: {
          title: 'Down Time Cost',
          data: downTimeCost,
          tooltip: 'Total cost based on estimated hours and input cost/hr',
        },
        totalCost: {
          title: 'Totals',
          data: totals,
          tooltip: '',
        },
      }

      return { ...p, results }
    })
  },
})

export const accessCostOfOwnershipState = () =>
  wrapCostOfOwnershipState(costOfOwnershipState)

export const useCostOfOwnershipState = () => {
  const state = useState(costOfOwnershipState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-cost-of-ownership-calc`))
  return wrapCostOfOwnershipState(state)
}
