import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Link from '@mui/material/Link'

import SidebarSection from './SidebarSection'
import kebabCase from 'lodash/kebabCase'
import { ChapterProps } from '../helpers/interfaces'
import { useGlobalState } from 'store'

const SidebarChapter = ({ chapterObj, headerPrefix, path }: ChapterProps) => {
  const header = `Chapter ${headerPrefix} - ${chapterObj.sidebarContent}`
  const label = kebabCase(header)

  const mappedSidebarSections = chapterObj.sections.map((section, index) => (
    <SidebarSection
      key={index}
      sectionObj={section}
      headerPrefix={`${headerPrefix}.${index + 1}`}
      path={`${path}-${index + 1}`}
    />
  ))

  const globalState = useGlobalState()
  const globalPath = globalState.getDesignGuidePath(path)
  const globalDesignGuidePaths = globalState.get().designGuidePaths

  const handleChange = () => {
    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObj = { ...pathObject }
      if (newPathObj.path === path) newPathObj.opened = !newPathObj.opened
      return newPathObj
    })

    globalState.setDesignGuidePaths(newPaths)
  }

  const handleClick = () => {
    const newPaths = globalDesignGuidePaths.map(pathObject => {
      const newPathObj = { ...pathObject }
      if (newPathObj.path === path) {
        newPathObj.active = true
      } else {
        newPathObj.active = false
      }
      return newPathObj
    })

    globalState.setDesignGuidePaths(newPaths)
  }

  const { hash } = useLocation()
  useEffect(() => {
    if (hash.length < 1) {
      const newPaths = globalDesignGuidePaths.map(pathObject => {
        const newPathObject = { ...pathObject }
        if (newPathObject?.active) newPathObject.active = false
        if (newPathObject?.opened) newPathObject.opened = false
        return newPathObject
      })

      globalState.setDesignGuidePaths(newPaths)
    } else {
      const newPath = hash.substring(1)
      const newPaths = globalDesignGuidePaths.map(pathObject => {
        const newPathObject = { ...pathObject }
        if (newPathObject?.active) newPathObject.active = false
        if (newPathObject.path === newPath) newPathObject.active = true
        return newPathObject
      })

      globalState.setDesignGuidePaths(newPaths)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Accordion
      disableGutters
      expanded={globalPath?.opened}
      elevation={0}
      sx={{ bgcolor: 'transparent', color: 'primary.light' }}
    >
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              '&:hover': { backgroundColor: 'primary.main' },
              borderRadius: 5,
            }}
          >
            <ExpandMoreIcon
              sx={{
                color: 'secondary.main',
              }}
              onClick={handleChange}
            />
          </Box>
        }
        aria-controls={`${label}-content`}
        id={`${label}-header`}
        sx={{ p: 0 }}
      >
        <Link
          onClick={handleClick}
          underline="none"
          sx={{
            color: 'primary.light',
            '&:hover': { backgroundColor: 'primary.main' },
          }}
        >
          {header}
        </Link>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{mappedSidebarSections}</AccordionDetails>
    </Accordion>
  )
}

export default SidebarChapter
