import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { AppBarProps } from './interfaces'
import { DRAWER_WIDTH } from 'config/constants'

// TODO:if we have a hidden drawer sidebar the entire time, it could
// eliminate the choppy transition when we leave from /design-guide to /home

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'push',
})<AppBarProps>(({ theme, open, push }) => ({
  transition: theme.transitions.create(['padding'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    push && {
      paddingLeft: `calc(${DRAWER_WIDTH}px + 8px) !important`,
      transition: theme.transitions.create(['padding'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}))

const AppBarSpacer = styled('div')(({ theme }) => ({
  height: 120,
}))

export { AppBar, AppBarSpacer }
