import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import FormCard from '../../../components/FormCard'
import { useSubmittalsState } from 'store'

const DimensionTable = () => {
  const uh = useSubmittalsState().getState().sizeData.unit_heater
  return (
    <FormCard>
      <Typography variant="h5" sx={{ mt: -1, mb: -1 }}>
        Critical Dimensions
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. A
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. B
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. C
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. D
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. E
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. F
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. M
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. N
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. P
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. Q
              </TableCell>
              <TableCell sx={{ fontWeight: 700, p: 0 }} align="center">
                Dim. R
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.a}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.b}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.c}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.d}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.e}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.f}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.m}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.n}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.p}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.q}
              </TableCell>
              <TableCell sx={{ p: 0 }} align="center">
                {uh.r}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormCard>
  )
}

export default DimensionTable
