import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { useSubmittalsState } from 'store'

const TechnicalDrawings = () => {
  const { fan, sizeData } = useSubmittalsState().getState()
  const twoImages = ['BAFCon', 'Haiku', 'Haiku L', 'es6']
  return (
    <Grid
      item
      md={
        fan.name === 'BAFCon' ||
        fan.name === 'UH' ||
        fan.name === 'IRH' ||
        fan.name === 'Pivot 2.0'
          ? 12
          : 6
      }
      xs={12}
    >
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        {!twoImages.includes(fan.name) && fan.template !== 'Sweat Bee' && (
          <Box
            component="img"
            sx={{
              objectFit: 'contain',
              maxWidth: fan.name === 'UH' ? '60%' : '80%',
              maxHeight:
                fan.name === 'UH' ||
                fan.name === 'IRH' ||
                fan.template === 'Cool-Space' ||
                fan.name === 'Pivot 2.0'
                  ? '100%'
                  : 130,
            }}
            alt={`${fan.name} Technical Drawing`}
            src={`https://design-guide-images.s3.us-east-2.amazonaws.com/${fan.name}.png`}
          />
        )}
        {fan.name === 'BAFCon' && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              component="img"
              sx={{
                width: '50%',
                height: '50%',
              }}
              alt={`${fan.name} 0 Technical Drawing`}
              src={`https://design-guide-images.s3.us-east-2.amazonaws.com/${fan.name}0.png`}
            />
            <Box
              component="img"
              sx={{
                maxWidth: '40%',
              }}
              alt={`${fan.name} 1 Technical Drawing`}
              src={`https://design-guide-images.s3.us-east-2.amazonaws.com/${fan.name}1.png`}
            />
          </Box>
        )}
        {fan.name !== 'BAFCon' && twoImages.includes(fan.name) && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              component="img"
              sx={{
                maxWidth: '50%',
              }}
              alt={`${fan.name} 0 Technical Drawing`}
              src={`https://design-guide-images.s3.us-east-2.amazonaws.com/${fan.name}0.png`}
            />
            <Box
              component="img"
              sx={{
                maxWidth: '35%',
                maxHeight: '70%',
              }}
              alt={`${fan.name} 1 Technical Drawing`}
              src={`https://design-guide-images.s3.us-east-2.amazonaws.com/${fan.name}1.png`}
            />
          </Box>
        )}
        {fan.template === 'Sweat Bee' && (
          <Box
            component="img"
            sx={{
              maxWidth: '85%',
            }}
            alt={`${fan.name} ${sizeData.size.substring(
              0,
              2
            )} Technical Drawing`}
            src={`https://design-guide-images.s3.us-east-2.amazonaws.com/${
              fan.name === 'Sweat Bee Swivel'
                ? fan.name
                : fan.name + ' ' + sizeData.size.substring(0, 2)
            }.png`}
          />
        )}
      </Card>
    </Grid>
  )
}

export default TechnicalDrawings
