import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfInputSection from 'components/pdf/PdfInputSection'
import { useCostOfOwnershipState } from 'store'

const CostOfOwnershipCalcPdf = () => {
  const costOfOwnershipState = useCostOfOwnershipState()
  const {
    bafInstalled,
    bafProvidedLift,
    evaluationYearPeriod,
    downTimeCost,
    results,
  } = costOfOwnershipState.get()

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })

  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontSize: '8px',
    },
    row: {
      flexDirection: 'row',
      marginBottom: '2px',
    },
    tableHeaderLabel: {
      fontSize: '8px',
    },
  })

  const inputs = [
    { title: 'BAF Installed', value: bafInstalled ? 'Yes' : 'No' },
    { title: 'BAF Provided Lift', value: bafProvidedLift ? 'Yes' : 'No' },
    { title: 'Evaluation Period', value: evaluationYearPeriod },
    {
      title: 'Cost of Down Time/Hr',
      value: formatCurrency.format(downTimeCost),
    },
  ]

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '4px',
        margin: '16px',
        borderRadius: '8px',
      }}
    >
      <Text style={styles.sectionTitle}>Results</Text>

      <View style={styles.row}>
        <Text
          style={{
            ...styles.tableHeaderLabel,
            flex: 2,
            fontFamily: 'Helvetica-Bold',
          }}
        >
          Model
        </Text>
        {results?.models?.data?.map((column: string, index: number) => (
          <Text
            key={`${column}-${index}`}
            style={{
              ...styles.value,
              flex: 1,
              fontFamily: 'Helvetica-Bold',
            }}
          >
            {column}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Mechanical Warranty
        </Text>
        {results?.mechanicalWarranty?.data?.map(
          (value: string, index: number) => (
            <Text
              key={`${results?.mechanicalWarranty?.title}-${index}`}
              style={{ ...styles.value, flex: 1 }}
            >
              {value}
            </Text>
          )
        )}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Electrical Warranty
        </Text>
        {results?.electricalWarranty?.data?.map(
          (value: string, index: number) => (
            <Text
              key={`${results?.electricalWarranty?.title}-${index}`}
              style={{ ...styles.value, flex: 1 }}
            >
              {value}
            </Text>
          )
        )}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Purchase Price
        </Text>
        {results?.purchaseCost?.data?.map((value: number, index: number) => (
          <Text
            key={`${results?.purchaseCost?.title}-${index}`}
            style={{ ...styles.value, flex: 1 }}
          >
            {formatCurrency.format(value)}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Motor Replacement Cost
        </Text>
        {results?.motorReplacementCost?.data?.map(
          (value: number, index: number) => (
            <Text
              key={`${results?.motorReplacementCost?.title}-${index}`}
              style={{ ...styles.value, flex: 1 }}
            >
              {formatCurrency.format(value)}
            </Text>
          )
        )}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Motor Labor Cost
        </Text>
        {results?.motorLaborCost?.data?.map((value: number, index: number) => (
          <Text
            key={`${results?.motorLaborCost?.title}-${index}`}
            style={{ ...styles.value, flex: 1 }}
          >
            {formatCurrency.format(value)}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Drive Replacement Cost
        </Text>
        {results?.driveReplacementCost?.data?.map(
          (value: number, index: number) => (
            <Text
              key={`${results?.driveReplacementCost?.title}-${index}`}
              style={{ ...styles.value, flex: 1 }}
            >
              {formatCurrency.format(value)}
            </Text>
          )
        )}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Drive Labor Cost
        </Text>
        {results?.driveLaborCost?.data?.map((value: number, index: number) => (
          <Text
            key={`${results?.driveLaborCost?.title}-${index}`}
            style={{ ...styles.value, flex: 1 }}
          >
            {formatCurrency.format(value)}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>Lift Cost</Text>
        {results?.liftCost?.data?.map((value: number, index: number) => (
          <Text
            key={`${results?.liftCost?.title}-${index}`}
            style={{ ...styles.value, flex: 1 }}
          >
            {formatCurrency.format(value)}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>Down Time</Text>
        {results?.downTime?.data?.map((value: string, index: number) => (
          <Text
            key={`${results?.downTime?.title}-${index}`}
            style={{ ...styles.value, flex: 1 }}
          >
            {value}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text style={{ ...styles.tableHeaderLabel, flex: 2 }}>
          Down Time Cost
        </Text>
        {results?.downTimeCost?.data?.map((value: number, index: number) => (
          <Text
            key={`${results?.downTimeCost?.title}-${index}`}
            style={{ ...styles.value, flex: 1 }}
          >
            {formatCurrency.format(value)}
          </Text>
        ))}
      </View>

      <View style={styles.row}>
        <Text
          style={{
            ...styles.tableHeaderLabel,
            flex: 2,
            fontFamily: 'Helvetica-Bold',
            fontSize: '10px',
          }}
        >
          Totals
        </Text>
        {results?.totalCost?.data?.map((value: number, index: number) => (
          <Text
            key={`${results?.totalCost?.title}-${index}`}
            style={{
              ...styles.value,
              flex: 1,
              fontFamily: 'Helvetica-Bold',
              fontSize: '10px',
            }}
          >
            {formatCurrency.format(value)}
          </Text>
        ))}
      </View>
    </View>
  )

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <PdfHeader title="Cost Of Ownership Calculator" />
        <PdfInputSection inputs={inputs} />
        {renderResultsSection()}
      </Page>
    </Document>
  )
}

export default CostOfOwnershipCalcPdf
