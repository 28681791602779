import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'
import cloneDeep from 'lodash/cloneDeep'

import { LOCAL_STORAGE_KEY } from '../config/constants'

export interface FormState {
  value: any
}
export interface InfoProps {
  data: {
    header: string
    bodies: string[]
  }[]
}
export interface DataValue {
  value: string
  id: number
}
export interface Link {
  id: number
  label: string
  url: string
}
export interface HeatIndexState {
  projectDetails: FormState
  productivitySavings: FormState
  hourlyHeatIndexDistribution: FormState
  info: InfoProps
  links: Link[]
  leadSaved: boolean
  validated: boolean
  needsUpdate: boolean
  valid: boolean
  printing: boolean
  zip: any
}

const deepClone = (value: any) => JSON.parse(JSON.stringify(value))

const initialHeatIndexState = {
  projectDetails: {
    value: {
      projectName: '',
      projectNum: '---',
      preparedBy: '',
      date: new Date().toLocaleDateString(),
      climateZone: '',
      regionName: '',
      zip: '',
      schedule: '24/7 Occupancy',
      customSchedule: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
        start: {
          label: '',
          value: -1,
        },
        end: {
          label: '',
          value: -1,
        },
      },
    },
  },
  productivitySavings: {
    value: {
      hourlyWage: '',
      numEmployees: '',
      pmvNoFans: [],
      pmvFansOnly: [],
      pmvFansEvap: [],
      ppdNoFans: [],
      ppdFansOnly: [],
      ppdFansEvap: [],
      productivity: {
        'No Fans': [],
        'Fans Only': [],
        'Fans + Evap Coolers': [],
      },
      annualProductivitySavings: {
        'Fans Only': 0,
        'Fans + Evap Coolers': 0,
      },
      totalProductivitySavings: {
        'Fans Only': 0,
        'Fans + Evap Coolers': 0,
      },
      reductionInProductivityLosses: {
        'Fans Only': 0,
        'Fans + Evap Coolers': 0,
      },
      annualLostWages: {
        'No Fans': 0,
        'Fans Only': 0,
        'Fans + Evap Coolers': 0,
      },
    },
  },
  hourlyHeatIndexDistribution: {
    value: {
      scenarios: {
        'No Fans': true,
        'Fans Only': false,
        'Fans + Evap Coolers': false,
      },
      hours: {
        'No Fans': {
          Neutral: -1,
          'Very Warm': -1,
          Hot: -1,
          'Very Hot': -1,
          'Extremely Hot': -1,
          Sum: 0,
        },
        'Fans Only': {
          Neutral: -1,
          'Very Warm': -1,
          Hot: -1,
          'Very Hot': -1,
          'Extremely Hot': -1,
          Sum: 0,
        },
        'Fans + Evap Coolers': {
          Neutral: -1,
          'Very Warm': -1,
          Hot: -1,
          'Very Hot': -1,
          'Extremely Hot': -1,
          Sum: 0,
        },
      },
      drybulb: [],
      relHum: [],
      ceNoFans: [],
      ceFansOnly: [],
      ceFansEvap: [],
      risks: {
        'Neutral (<80F)': ['Heat/Sunstroke Unlikely'],
        'Very Warm (80F-89F)': ['Fatigue Possible'],
        'Hot (90F-104F)': ['Sunstroke Possible', 'Heat Exhaustion Possible'],
        'Very Hot (105F-129F)': [
          'Sunstroke Likely',
          'Heat Exhaustion Likely',
          'Heatstroke Possible',
        ],
        'Extremely Hot (>130F)': ['Heat/Sunstroke Highly Likely'],
      },
    },
  },
  info: {
    data: [
      {
        header: 'About This Calculation',
        bodies: [
          'An energy model of a representative 47,000 sqft. unconditioned warehouse was built using the DOE Commercial Prototype Buildings. The energy model was used to calculate the outdoor dry bulb temperature, outdoor wet bulb temperature, indoor dry bulb temperature, and indoor relative humidity for each hour of the year using the DOE2 weather files for each ASHRAE climate zone. The CBE Thermal Comfort Tool was used to calculate the cooling effect under each scenario. It was assumed that an average air speed of 170 fpm was achieved throughout the space. A met rate of 1.7 and clo value of 0.57 was assumed. Using the weather data and the cooling effect the heat index temperature was calculated for each hour and categorized in the corresponding Heat Index Classification. The chart above shows the reduction in uncomfortable hours due to the addition of HVLS fans and evaporative coolers.',
        ],
      },
      {
        header: 'Disclaimer',
        bodies: [
          'Calculations are based on averages for the information selected and are only an estimate of actual savings. Actual loss and savings will vary depending on weather conditions, usage, location and business type. This information is intended as an example for comparison purposes only. BAF does not guarantee the accuracy of these calculations. No promise of performance is implied by us or should be inferred by you.',
        ],
      },
    ],
  },
  leadSaved: false,
  links: [
    {
      id: 0,
      label: 'Overhead Fans',
      url: 'https://bigassfans.com/overhead-fans/',
    },
    {
      id: 1,
      label: 'Directional Fans',
      url: 'https://bigassfans.com/directional-fans/',
    },
    {
      id: 2,
      label: 'Evaporative Coolers',
      url: 'https://www.bigassfans.com/evaporative-coolers/',
    },
  ],
  validated: false,
  valid: false,
  needsUpdate: true,
  printing: false,
  zip: '',
}
const heatIndexState = createState(initialHeatIndexState)
const wrapHeatIndexState = (s: State<HeatIndexState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...initialHeatIndexState,
      projectDetails: {
        value: {
          ...initialHeatIndexState?.projectDetails?.value,
          ...newState?.projectDetails?.value,
        },
      },
      productivitySavings: {
        value: {
          ...initialHeatIndexState?.productivitySavings?.value,
          ...newState?.productivitySavings?.value,
        },
      },
      hourlyHeatIndexDistribution: {
        value: {
          ...initialHeatIndexState?.hourlyHeatIndexDistribution?.value,
          ...newState?.hourlyHeatIndexDistribution?.value,
        },
      },
    })),
  resetState: () => s.set(cloneDeep(initialHeatIndexState)),
  setValidated: () => {
    s.set((p: any) => ({ ...p, validated: true }))
  },
  setValid: (value: boolean) => {
    s.set((p: any) => ({ ...p, valid: value }))
  },
  setNeedsUpdate: (value: boolean) => {
    s.set((p: any) => ({ ...p, needsUpdate: value }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setPrinting: (value: boolean) => {
    s.set((p: any) => ({ ...p, printing: value }))
  },
  setZip: (value: boolean) => {
    s.set((p: any) => ({ ...p, zip: value }))
  },
  setRegion: (region: string) => {
    s.projectDetails.set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        climateZone: region,
      },
    }))
  },
  setRegionName: (regionName: string) => {
    s.projectDetails.set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        regionName,
      },
    }))
  },
  setScenario: (scenario: string, value: boolean) => {
    s.hourlyHeatIndexDistribution.set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        scenarios: {
          ...p.value.scenarios,
          [scenario]: value,
        },
      },
    }))
  },
  setFormValue: (
    stateKey: keyof HeatIndexState,
    valueKey: string,
    value: any
  ) => {
    s[stateKey].set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        [valueKey]: value,
      },
    }))
  },
  setSchedule: (valueKey: string, value: any) => {
    s.needsUpdate.set(true)
    s.projectDetails.set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        customSchedule: {
          ...p.value.customSchedule,
          [valueKey]: value,
        },
      },
    }))
  },
  reset: () => {
    s.set(() => {
      return deepClone(initialHeatIndexState)
    })
  },
  heatIndex: (drybulb: number, relHum: number) => {
    if (0.5 * (drybulb + 61 + (drybulb - 68) * 1.2 + relHum * 0.094) > 80) {
      return (
        -42.279 +
        2.04901523 * drybulb +
        10.14333127 * relHum -
        0.22475541 * drybulb * relHum -
        0.00683783 * drybulb * drybulb -
        0.05481717 * relHum * relHum +
        0.00122874 * drybulb * drybulb * relHum +
        0.00085282 * drybulb * relHum * relHum -
        0.00000199 * drybulb * drybulb * relHum * relHum
      )
    } else return 0.5 * (drybulb + 61 + (drybulb - 68) * 1.2 + relHum * 0.094)
  },
  iadbEvapTempReduction: (drybulbF: number, relHum: number) => {
    let drybulbC = Number((5 / 9) * (drybulbF - 32))
    let iawb =
      (-5.806 +
        0.672 * drybulbC -
        0.006 * drybulbC * drybulbC +
        (0.061 + 0.004 * drybulbC + 0.000099 * drybulbC * drybulbC) * relHum +
        (-0.000033 - 0.000005 * drybulbC - 0.0000001 * drybulbC * drybulbC) *
          relHum *
          relHum) *
        1.8 +
      32
    let maxReduction = Math.max(
      0,
      Math.round((drybulbF - Number(iawb)) * 0.5) - 5
    )
    return drybulbF - maxReduction
  },
  hourlyProductivity: (pmv: DataValue[], products: string) => {
    let productivity: number[] = []
    for (let i = 0; i < pmv?.length; i++) {
      let prod =
        Number(pmv?.[i]?.value) > 0
          ? 99.91 -
            0.796 * Number(pmv?.[i]?.value) -
            1.843 * Number(pmv?.[i]?.value) * Number(pmv?.[i]?.value)
          : 0
      productivity?.push(prod)
    }
    s?.productivitySavings?.set((p: any) => ({
      ...p,
      value: {
        ...p?.value,
        productivity: {
          ...p?.value?.productivity,
          [products]: productivity,
        },
      },
    }))
  },
  calcLostWages: (productivity: number[], products: string) => {
    let sum = 0
    for (let i = 0; i < productivity?.length; i++) {
      let lostWage =
        productivity?.[i] === 0
          ? 0
          : ((100 - productivity?.[i]) / 100) *
            Number(s?.productivitySavings?.value?.value?.hourlyWage)
      sum += Number(lostWage)
    }
    s.productivitySavings.set((p: any) => ({
      ...p,
      value: {
        ...p?.value,
        annualLostWages: {
          ...p?.value?.annualLostWages,
          [products]: Number(sum / 2),
        },
      },
    }))
  },
  setAnnualSavings: (products: string) => {
    s.productivitySavings?.set((p: any) => ({
      ...p,
      value: {
        ...p?.value,
        annualProductivitySavings: {
          ...p?.value?.annualProductivitySavings,
          [products]:
            p?.value?.annualLostWages?.['No Fans'] -
            p?.value?.annualLostWages?.[products],
        },
      },
    }))
    s.productivitySavings.set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        totalProductivitySavings: {
          ...p?.value?.totalProductivitySavings,
          [products]:
            (p?.value?.annualLostWages?.['No Fans'] -
              p?.value?.annualLostWages?.[products]) *
            Number(p?.value?.numEmployees),
        },
      },
    }))
  },
  setReductionInLosses: (products: string) => {
    s.productivitySavings.set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        reductionInProductivityLosses: {
          ...p?.value?.reductionInProductivityLosses,
          [products]: (
            (p?.value?.annualProductivitySavings?.[products] /
              p?.value?.annualLostWages?.['No Fans']) *
            100
          )?.toFixed(2),
        },
      },
    }))
  },
  countHeatIndex: (
    dbs: DataValue[],
    rhs: DataValue[],
    ceNoFans: DataValue[],
    ceProducts: DataValue[],
    index: string,
    products: string,
    heatIndex: Function,
    iadbEvapTempReduction: Function
  ) => {
    switch (products) {
      case 'No Fans': {
        let hi = []
        for (let i = 0; i < dbs?.length; i++) {
          hi.push(
            Number(
              heatIndex(
                Number(dbs?.[i]?.value),
                Number(rhs?.[i]?.value)
              ).toFixed(8)
            )
          )
        }
        switch (index) {
          case 'Neutral': {
            let n = hi?.filter(value => value < 80)?.length
            if (
              n !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products][
                index
              ]
            )
              s.hourlyHeatIndexDistribution.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: n,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + n,
                    },
                  },
                },
              }))
            return n
          }
          case 'Very Warm':
            let vw = hi?.filter(value => value >= 80 && value < 90)?.length
            if (
              vw !==
              s.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s.hourlyHeatIndexDistribution.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: vw,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours[
                          products
                        ]?.Sum + vw,
                    },
                  },
                },
              }))
            return vw
          case 'Hot':
            let h = hi?.filter(value => value >= 90 && value < 105)?.length
            if (
              h !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: h,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours[
                          products
                        ]?.Sum + h,
                    },
                  },
                },
              }))
            return h
          case 'Very Hot':
            let vh = hi?.filter(value => value >= 105 && value < 130)?.length
            if (
              vh !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: vh,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours[
                          products
                        ]?.Sum + vh,
                    },
                  },
                },
              }))
            return vh
          case 'Extremely Hot':
            let eh = hi?.filter(value => value >= 130)?.length
            if (
              eh !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours[products],
                      [index]: eh,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours[
                          products
                        ]?.Sum + eh,
                    },
                  },
                },
              }))
            return eh
          default:
            return 0
        }
      }
      case 'Fans Only': {
        let hi = []
        for (let i = 0; i < dbs?.length; i++) {
          hi.push(
            Number(
              heatIndex(
                Number(
                  (
                    Number(dbs?.[i]?.value) -
                    (Number(ceProducts?.[i]?.value) -
                      Number(ceNoFans?.[i]?.value))
                  ).toFixed(2)
                ),
                Number(rhs?.[i]?.value)
              ).toFixed(8)
            )
          )
        }
        switch (index) {
          case 'Neutral': {
            let n = hi?.filter(value => value < 80)?.length
            if (
              n !==
              s.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: n,
                      Sum:
                        s.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + n,
                    },
                  },
                },
              }))
            return n
          }
          case 'Very Warm':
            let vw = hi?.filter(value => value >= 80 && value < 90)?.length
            if (
              vw !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours[products],
                      [index]: vw,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours[
                          products
                        ]?.Sum + vw,
                    },
                  },
                },
              }))
            return vw
          case 'Hot':
            let h = hi?.filter(value => value >= 90 && value < 105)?.length
            if (
              h !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: h,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + h,
                    },
                  },
                },
              }))
            return h
          case 'Very Hot':
            let vh = hi?.filter(value => value >= 105 && value < 130)?.length
            if (
              vh !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: vh,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours[
                          products
                        ]?.Sum + vh,
                    },
                  },
                },
              }))
            return vh
          case 'Extremely Hot':
            let eh = hi?.filter(value => value >= 130)?.length
            if (
              eh !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: eh,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + eh,
                    },
                  },
                },
              }))
            return eh
          default:
            return 0
        }
      }
      case 'Fans + Evap Coolers': {
        let hi = []
        for (let i = 0; i < dbs?.length; i++) {
          hi.push(
            Number(
              heatIndex(
                Number(
                  (
                    iadbEvapTempReduction(
                      Number(dbs?.[i]?.value),
                      Number(rhs?.[i]?.value)
                    ) -
                    (Number(ceProducts?.[i]?.value) -
                      Number(ceNoFans?.[i]?.value))
                  ).toFixed(2)
                ),
                Number(rhs?.[i]?.value)
              ).toFixed(8)
            )
          )
        }
        switch (index) {
          case 'Neutral': {
            let n = hi?.filter(value => value < 80)?.length
            if (
              n !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: n,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + n,
                    },
                  },
                },
              }))
            return n
          }
          case 'Very Warm':
            let vw = hi?.filter(value => value >= 80 && value < 90)?.length
            if (
              vw !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: vw,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + vw,
                    },
                  },
                },
              }))
            return vw
          case 'Hot':
            let h = hi?.filter(value => value >= 90 && value < 105)?.length
            if (
              h !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: h,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + h,
                    },
                  },
                },
              }))
            return h
          case 'Very Hot':
            let vh = hi?.filter(value => value >= 105 && value < 130)?.length
            if (
              vh !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: vh,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + vh,
                    },
                  },
                },
              }))
            return vh
          case 'Extremely Hot':
            let eh = hi?.filter(value => value >= 130)?.length
            if (
              eh !==
              s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[products]?.[
                index
              ]
            )
              s?.hourlyHeatIndexDistribution?.set((p: any) => ({
                ...p,
                value: {
                  ...p?.value,
                  hours: {
                    ...p?.value?.hours,
                    [products]: {
                      ...p?.value?.hours?.[products],
                      [index]: eh,
                      Sum:
                        s?.hourlyHeatIndexDistribution?.value?.value?.hours?.[
                          products
                        ]?.Sum + eh,
                    },
                  },
                },
              }))
            return eh
          default:
            return 0
        }
      }
    }
  },
})
export const accessHeatIndexState = () => wrapHeatIndexState(heatIndexState)
export const useHeatIndexState = () => {
  const state = useState(heatIndexState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-heat-index`))
  return wrapHeatIndexState(state)
}
