import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'
import cloneDeep from 'lodash/cloneDeep'

import { LOCAL_STORAGE_KEY } from '../config/constants'

export interface FormState {
  value: any
}
export interface APIProduct {
  id: number
  name: string
  category: string
  template: string
}
export interface APIProductData {
  id: number
  name: string
  template: string
  category: string
  coverage_area: number
  water_capacity: number
  water_pressure: number
  num_airfoils: number
  addons: any
  warranty: any
  links: any
  technicalNotes: any
  modules: any
  controllers: any
  features: any
  appearances: any
  sizes: any
}
export interface APIProductSizeData {
  size: string
  weight?: string
  motor?: string
  rpm?: number
  max_sound?: string
  height?: string
  width?: string
  depth?: string
  cage_diameter?: string
  cage_depth?: string
  fan_diameter?: string
  airflow?: string
  fuels: any
  voltages: any
  drop_tubes: any
  mounts: any
  radiant_heater: any
  unit_heater: any
}

interface APIVoltageData {
  id: number
  voltage_id: number
  voltage: string
  supply_circuit_size?: number
  full_load_amperage?: string
  max_amps?: string
  max_watts?: string
  rpm?: number
}

interface APIMountData {
  id: number
  mount_id: number
  mount: string
  drop_tube?: string
  mount_diameter?: string
  mount_to_hub?: string
  weight?: string
  depth?: string
  height?: string
  width?: string
  rpm?: number
  airflow?: number
  max_watts?: string
}

interface APIFuelData {
  id: number
  fuel: string
  inlet_pressure_min: string
  inlet_pressure_max: string
}

interface APIDropTubeData {
  id: number
  tube_id: number
  tube: string
  mount_to_hub?: string
  foil_to_mount?: string
}
export interface SubmittalsState {
  valid: boolean
  validated: boolean
  fan: APIProduct
  productData: APIProductData
  sizeData: APIProductSizeData
  mountData: APIMountData
  voltageData: APIVoltageData
  fuelData: APIFuelData
  dropTubeData: APIDropTubeData
  projectDetails: FormState
  productDetails: FormState
  colorDetails: FormState
  mountingHardware: FormState
  controllerFeatures: FormState
  numAppOpts: number
  appearanceOpts: any // update
  numAdditions: number
  additions: any // update
  notes: string
  numCols: number
  leadSaved: boolean
}
export interface SalesToolsState {
  submittals: SubmittalsState
}
export interface WrappedSubmittalsState {
  getState: () => SubmittalsState
  reset: () => void
  resetState: () => void
  setValidated: () => void
  setValid: (value: boolean) => void
  setFan: (fan: APIProduct) => void
  setProductData: (data: APIProductData) => void
  setSizeData: (data: APIProductSizeData) => void
  setMountData: (data: APIMountData) => void
  setVoltageData: (data: APIVoltageData) => void
  setFuelData: (data: APIFuelData) => void
  setDropTubeData: (data: APIDropTubeData) => void
  setFormValue: (
    stateKey: keyof SubmittalsState,
    valueKey: string,
    value: any
  ) => void
  addTag: (value: string) => void
  setNotes: (content: string) => void
  addAppearanceOption: () => void
  editAppearancePart: (index: number, part: string) => void
  editAppearanceColor: (index: number, color: string) => void
  addAddition: () => void
  editAddition: (index: number, addition: string) => void
  setNumCols: (num: number) => void
}

const deepClone = (value: any) => JSON.parse(JSON.stringify(value))

const salesToolsInitialState = {
  // info for all tools here
  submittals: {
    leadSaved: false,
    valid: false,
    validated: false,
    fan: {
      id: -1,
      name: '',
      category: '',
      template: '',
    },
    // store data from backend queries
    productData: {
      id: -1,
      name: '',
      category: '',
      template: '',
      coverage_area: -1,
      water_capacity: -1,
      water_pressure: -1,
      num_airfoils: -1,
      addons: [],
      warranty: [],
      links: [],
      technicalNotes: [],
      modules: [],
      controllers: [],
      features: [],
      appearances: [],
      sizes: [],
    },
    sizeData: {
      size: '',
      fuels: [],
      voltages: [],
      drop_tubes: [],
      mounts: [],
      radiant_heater: {},
      unit_heater: {},
    } as APIProductSizeData,
    mountData: {
      id: -1,
      mount_id: -1,
      mount: '',
    } as APIMountData,
    dropTubeData: {
      id: -1,
      tube_id: -1,
      tube: '',
    } as APIDropTubeData,
    voltageData: {
      id: -1,
      voltage_id: -1,
      voltage: '',
    } as APIVoltageData,
    fuelData: {
      id: -1,
      fuel: '',
      inlet_pressure_min: '',
      inlet_pressure_max: '',
    } as APIFuelData,
    // store inputted values from form
    projectDetails: {
      value: {
        project: '',
        location: '',
        date: new Date().toLocaleDateString(),
        submitter: '',
        architect: '',
        engineer: '',
        contractor: '',
        tags: '',
      },
    },
    productDetails: {
      value: {
        quantity: '',
        size: null,
        sizeId: null,
        voltage: null,
        model: null,
        controlQty: '',
        fuelType: null,
        tubeLength: null,
      },
    },
    colorDetails: {
      value: {
        hardwareFinish: null,
        finish: null,
        material: null,
      },
    },
    mountingHardware: {
      value: {
        extensionTube: null,
        mount: null,
      },
    },
    controllerFeatures: {
      value: {
        controller: null,
        singleMulti: 'Single',
      },
    },
    numAppOpts: 1,
    appearanceOpts: {
      0: { part: 'None', color: null },
    },
    numAdditions: 1,
    additions: {
      0: { addition: 'None' },
    },
    notes: '',
    numCols: 4,
  },
}

const salesToolsState = createState(salesToolsInitialState)

const wrapSalesToolsState = (s: State<SalesToolsState>) => ({
  get: () => s.value,
  reset: () => s.set(cloneDeep(salesToolsInitialState)),
})

const wrapSubmittalsState = (s: State<SalesToolsState>) => ({
  getState: () => s.value.submittals,
  resetState: () => s.set(cloneDeep(salesToolsInitialState)),

  setValid: (value: boolean) =>
    s.submittals.set((p: any) => ({ ...p, valid: value })),
  setValidated: () => s.submittals.set((p: any) => ({ ...p, validated: true })),
  setLeadSaved: (value: boolean) => {
    s.submittals.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setFan: (fan: APIProduct) => {
    s.set((p: any) => {
      let copy = deepClone(salesToolsInitialState) // reset state
      copy.submittals.numCols = s.submittals.numCols.value // keep num columns
      return copy
    })
    s.submittals.set((p: any) => ({ ...p, fan: fan }))
  },
  setProductData: (data: APIProductData) => {
    s.submittals.set((p: any) => ({ ...p, productData: data }))
  },
  setSizeData: (data: APIProductSizeData) => {
    s.submittals.set((p: any) => ({ ...p, sizeData: data }))
  },
  setMountData: (data: APIMountData) => {
    s.submittals.set((p: any) => ({ ...p, mountData: data }))
  },
  setVoltageData: (data: APIVoltageData) => {
    s.submittals.set((p: any) => ({ ...p, voltageData: data }))
  },
  setFuelData: (data: APIFuelData) => {
    s.submittals.set((p: any) => ({ ...p, fuelData: data }))
  },
  setDropTubeData: (data: APIDropTubeData) => {
    s.submittals.set((p: any) => ({ ...p, dropTubeData: data }))
  },
  setFormValue: (
    stateKey: keyof SubmittalsState,
    valueKey: string,
    value: any
  ) => {
    s.submittals.set((p: any) => ({ ...p, valid: false }))
    s.submittals[stateKey].set((p: any) => ({
      ...p,
      value: {
        ...p.value,
        [valueKey]: value,
      },
    }))
  },
  setNotes: (content: string) =>
    s.submittals.set((p: any) => ({ ...p, notes: content })),
  addAppearanceOption: () => {
    s.submittals.appearanceOpts.set((p: any) => ({
      ...p,
      [s.submittals.numAppOpts.value]: {
        part: null,
        color: null,
      },
    }))
    s.submittals.set((p: any) => ({
      ...p,
      numAppOpts: s.submittals.numAppOpts.value + 1,
    }))
  },
  editAppearancePart: (index: number, part: string) => {
    s.submittals.appearanceOpts.set((p: any) => ({
      ...p,
      [index]: {
        ...p[index],
        part: part,
      },
    }))
  },
  editAppearanceColor: (index: number, color: string) => {
    s.submittals.appearanceOpts.set((p: any) => ({
      ...p,
      [index]: {
        ...p[index],
        color: color,
      },
    }))
  },
  addAddition: () => {
    s.submittals.additions.set((p: any) => ({
      ...p,
      [s.submittals.numAdditions.value]: { addition: null },
    }))
    s.submittals.set((p: any) => ({
      ...p,
      numAdditions: s.submittals.numAdditions.value + 1,
    }))
  },
  editAddition: (index: number, addition: string | null) => {
    s.submittals.additions.set((p: any) => ({
      ...p,
      [index]: {
        ...p[index],
        addition: addition,
      },
    }))
  },
  setNumCols: (num: number) =>
    s.submittals.set((p: any) => ({
      ...p,
      numCols: num,
    })),
})

export const accessSalesToolsState = () => wrapSalesToolsState(salesToolsState)

export const useSubmittalsState = () => {
  const state = useState(salesToolsState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-sales-tools`))
  return wrapSubmittalsState(state)
}
