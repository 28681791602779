import { useRef, useState, useEffect } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useSearchParams } from 'react-router-dom'
import { AppBarSpacer } from '../layout/helpers/styled'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import InputsTile from './components/InputsTile'
import Results from './components/Results'
import ToolHeader from 'components/ToolHeader'
import PrintHeader from 'components/PrintHeader'

import { usePermissions } from 'hooks/usePermissions'
import { useProjectResults } from 'hooks/useProjectResults'
import { useCostOfOwnershipState } from 'store'
import { axiosHelper } from 'utils'

import CostOfOwnershipPDF from './CostOfOwnershipCalcPdf'

const CostOfOwnershipCalc = ({ standalone }: { standalone?: boolean }) => {
  const { user } = usePermissions()
  const costOfOwnershipState = useCostOfOwnershipState()
  const {
    leadSaved,
    results,
    products,
    bafInstalled,
    bafProvidedLift,
    evaluationYearPeriod,
    downTimeCost,
  } = costOfOwnershipState.get()
  const [loading, setLoading] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const componentRef = useRef<HTMLDivElement>(null)

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const newState = JSON.parse(projectResults?.data)
      costOfOwnershipState.loadState(newState)
    }
  }, [projectResults, costOfOwnershipState, resultsLoaded])

  // Get product data
  useEffect(() => {
    if (!products?.length && !loading) {
      axiosHelper({
        url: '/product-ownership-costs',
        method: 'get',
        success: (values: any) => {
          costOfOwnershipState.setProducts(values?.data || [])
        },
      })
    }
  }, [products, loading, costOfOwnershipState])

  const TITLE = 'Cost of Ownership Calculator'

  return (
    <Container maxWidth="xl">
      {!standalone && <AppBarSpacer />}
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault()
          setLoading(true)
          setTimeout(() => setLoading(false), 1000)
          costOfOwnershipState.setResults()
        }}
      >
        <ToolHeader
          title={TITLE}
          onReset={() => costOfOwnershipState.resetState()}
          valid={true}
          canSave={results}
          leadSaved={leadSaved}
          toolState={{ ...costOfOwnershipState.get(), toolName: TITLE }}
          onProjectsModalSuccess={() => {
            setAlertMessage('Saved!')
            setAlertOpen(true)
          }}
          onLeadsModalSuccess={() => costOfOwnershipState.setLeadSaved(true)}
          onLeadsModalClose={() => {
            // if (!leadSaved) costOfOwnershipState.resetData()
          }}
          printContent={() => componentRef.current}
          renderAfterActionButtons={() => (
            <Button
              variant="text"
              disableElevation
              disabled={false}
              type="submit"
              size="large"
              sx={{
                backgroundColor: 'secondary.main',
                py: 1.5,
                mt: 1.5,
                fontFamily: 'industry-inc',
                '&:hover': {
                  backgroundColor: '#A9A9A9',
                  color: '#fff',
                },
              }}
              onClick={() => costOfOwnershipState.setResults()}
            >
              View Results
            </Button>
          )}
          saveActions={[
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box
                  onClick={() => {
                    const email = user?.email
                    if (!email) return
                    axiosHelper({
                      url: '/save_emails',
                      method: 'post',
                      data: {
                        toolName: 'BAF-Cost-of-Ownership-Calc',
                        email,
                        bafInstalled,
                        bafProvidedLift,
                        evaluationYearPeriod,
                        downTimeCost,
                        results,
                      },
                      success: () => {
                        setAlertMessage('Email Sent!')
                        setAlertOpen(true)
                      },
                    })
                  }}
                >
                  Email PDF
                </Box>
              ),
            },
            {
              icon: () => <PictureAsPdfIcon fontSize="small" />,
              node: () => (
                <Box>
                  <PDFDownloadLink
                    document={<CostOfOwnershipPDF />}
                    fileName="BAF-Cost-of-Ownership-Calc.pdf"
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      opacity: 0.87,
                    }}
                  >
                    {({ blob, url, loading, error }) => 'Download PDF'}
                  </PDFDownloadLink>
                </Box>
              ),
            },
          ]}
        />
        <Box ref={componentRef}>
          <PrintHeader title={TITLE} />
          <style type="text/css">
            {'@media print{@page {size: 10.5in 8in}}'}
          </style>

          <Grid container spacing={2}>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <InputsTile />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <Results />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default CostOfOwnershipCalc
