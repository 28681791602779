import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import LaunchIcon from '@mui/icons-material/Launch'

interface APILink {
  id: number
  label: string
  url: string
}
interface LinksProps {
  links: APILink[]
  mt: number
}

const Links = ({ links, mt }: LinksProps) => {
  return (
    <Box sx={{ mt: mt }}>
      {links && (
        <Stack direction={{ sx: 'column', md: 'row' }} py={4} spacing={4}>
          {links.map((link: APILink) => (
            <Button
              href={link.url}
              target="_blank"
              rel="noopener"
              key={link.id}
              startIcon={<LaunchIcon fontSize="small" />}
            >
              {link.label}
            </Button>
          ))}
        </Stack>
      )}
    </Box>
  )
}

export default Links
