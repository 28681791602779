import { useEffect } from 'react'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import SidebarChapter from './SidebarChapter'
import { DRAWER_WIDTH } from 'config/constants'
import { useGlobalState } from 'store'
import { data } from '../helpers/data'

const Sidebar = () => {
  const globalState = useGlobalState()
  const open = globalState.getDesignGuideSidebarOpened()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  // Hide the sidebar on mobile:
  useEffect(() => {
    globalState.setDesignGuideSidebarOpened(mediumBreakpoint)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Handle clicking on the drawer overlay on mobile
  const handleDrawerToggle = () =>
    globalState.setDesignGuideSidebarOpened(!open)

  const mappedSidebarChapters = data.map((chapter, index) => (
    <SidebarChapter
      key={index}
      chapterObj={chapter}
      headerPrefix={`${index + 1}`}
      path={`chapter-${index + 1}`}
    />
  ))
  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          bgcolor: 'primary.dark',
          p: 4,
        },
        '& .MuiDrawer-paper::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      variant={mediumBreakpoint ? 'persistent' : 'temporary'}
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
    >
      <Typography
        variant="h5"
        sx={{
          color: 'secondary.main',
        }}
      >
        Table of Contents
      </Typography>

      {mappedSidebarChapters}
    </Drawer>
  )
}

export default Sidebar
