import { Fragment, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import StyledTextField from '../../../components/StyledTextField'
import ScheduleModal from './ScheduleModal'

import { useHeatIndexState } from 'store/heatIndexState'
import { useProjectResults } from 'hooks/useProjectResults'
import { axiosHelper } from '../../../utils'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const ProjectDetails = () => {
  const heatIndexState = useHeatIndexState()
  const { projectDetails, validated } = heatIndexState.get()
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    heatIndexState.setFormValue('projectDetails', name, value)
  }
  const regions = {
    '1A': 1,
    '2A': 2,
    '2B': 3,
    '3A': 4,
    '3BLA': 5,
    '3BVegas': 6,
    '3C': 7,
    '4A': 8,
    '4B': 9,
    '4C': 10,
    '5A': 11,
    '5B': 12,
    '6A': 13,
    '6B': 14,
    '7A': 15,
  }
  const [city, setCity] = useState<string>()
  const [region, setRegion] = useState<string>(projectDetails.value.climateZone)
  const handleChange = (newDate: Date | null) => {
    heatIndexState.setFormValue(
      'projectDetails',
      'date',
      newDate === null ? '' : newDate.toLocaleDateString()
    )
  }

  const mapData = (datum: any) => ({
    id: datum?.id || '',
    value: datum?.value,
    h: datum?.operation_hour_id,
    d: datum?.operation_day_id,
  })

  useEffect(() => {
    const dbsuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'hourlyHeatIndexDistribution',
        'drybulb',
        mappedData
      )
    }
    const rhsuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'hourlyHeatIndexDistribution',
        'relHum',
        mappedData
      )
    }
    const pmvNFSuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'productivitySavings',
        'pmvNoFans',
        mappedData
      )
    }
    const pmvFOSuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'productivitySavings',
        'pmvFansOnly',
        mappedData
      )
    }
    const pmvFESuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'productivitySavings',
        'pmvFansEvap',
        mappedData
      )
    }
    const ppdNFSuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'productivitySavings',
        'ppdNoFans',
        mappedData
      )
    }
    const ppdFOSuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'productivitySavings',
        'ppdFansOnly',
        mappedData
      )
    }
    const ppdFESuccess = (values: any) => {
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      heatIndexState.setFormValue(
        'productivitySavings',
        'ppdFansEvap',
        mappedData
      )
    }
    if (projectDetails.value.climateZone) {
      axiosHelper({
        url: '/weather-data-points/find',
        method: 'post',
        data: {
          classification_id: 3,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: dbsuccess,
      })
      axiosHelper({
        url: '/weather-data-points/find',
        method: 'post',
        data: {
          classification_id: 4,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: rhsuccess,
      })
      axiosHelper({
        url: '/productivity-data-points/find',
        method: 'post',
        data: {
          classification_id: 6,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: pmvNFSuccess,
      })
      axiosHelper({
        url: '/productivity-data-points/find',
        method: 'post',
        data: {
          classification_id: 8,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: pmvFOSuccess,
      })
      axiosHelper({
        url: '/productivity-data-points/find',
        method: 'post',
        data: {
          classification_id: 10,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: pmvFESuccess,
      })
      axiosHelper({
        url: '/productivity-data-points/find',
        method: 'post',
        data: {
          classification_id: 7,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: ppdNFSuccess,
      })
      axiosHelper({
        url: '/productivity-data-points/find',
        method: 'post',
        data: {
          classification_id: 9,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: ppdFOSuccess,
      })
      axiosHelper({
        url: '/productivity-data-points/find',
        method: 'post',
        data: {
          classification_id: 11,
          climate_zone_id:
            regions[projectDetails.value.climateZone as keyof typeof regions],
        },
        success: ppdFESuccess,
      })
      const climateZoneSuccess = (value: any) => {
        const city = value.data.city
        const region = value.data.name
        setCity(city)
        setRegion(region)
        heatIndexState.setRegionName(`${region}` + (city ? ` (${city})` : ''))
      }
      if (
        projectDetails.value.climateZone !== '3BLA' &&
        projectDetails.value.climateZone !== '3BVegas'
      ) {
        axiosHelper({
          url: `/climate-zones/name/${projectDetails.value.climateZone}`,
          success: climateZoneSuccess,
        })
      } else if (projectDetails.value.climateZone === '3BLA') {
        axiosHelper({
          url: `/climate-zones/id/5`,
          success: climateZoneSuccess,
        })
      } else {
        axiosHelper({
          url: `/climate-zones/id/6`,
          success: climateZoneSuccess,
        })
      }
    } else {
      setRegion(projectDetails.value.climateZone)
      setCity('')
    }
    // eslint-disable-next-line
  }, [projectDetails.value.climateZone])

  // Load project results if available
  const [queryParameters] = useSearchParams()
  const projectResultsId = queryParameters.get('resultId')
  const { projectResults } = useProjectResults({ id: projectResultsId })
  const [resultsLoaded, setResultsLoaded] = useState(false)
  useEffect(() => {
    if (projectResults && !resultsLoaded) {
      setResultsLoaded(true)
      const results = JSON.parse(projectResults?.data)
      const newState = {
        ...results,
        projectDetails: {
          value: {
            climateZone: '',
            ...results?.projectDetails?.value,
          },
        },
      }

      // First load project results and clear region data
      setTimeout(() => {
        heatIndexState.loadState(newState)
        heatIndexState.setRegion('')
        setRegion('')
      }, 2000)

      // Then update the region from saved project results
      setTimeout(() => {
        heatIndexState.setRegion(results?.projectDetails?.value?.climateZone)
      }, 3000)
    }
  }, [projectResults, heatIndexState, resultsLoaded])

  return (
    <Card variant="outlined" sx={{ p: 4, mb: 2 }}>
      <Box display="inline-flex">
        <Typography variant="h5" sx={{ mb: 2 }}>
          Project Details
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledTextField
            required
            helperText="Select from the map or search your ZIP code on the right"
            label="Climate Zone"
            name="climateZone"
            value={`${region}` + (city ? ` (${city})` : '')}
            inputProps={{ readOnly: true }}
            error={validated && projectDetails.value.climateZone === ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            required
            label="Project Name"
            name="projectName"
            value={projectDetails.value.projectName}
            onChange={handleInput}
            error={validated && projectDetails.value.projectName === ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            required
            label="Project #"
            name="projectNum"
            value={projectDetails.value.projectNum}
            onChange={handleInput}
            error={validated && projectDetails.value.projectNum === ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            required
            label="Prepared by"
            name="preparedBy"
            value={projectDetails.value.preparedBy}
            onChange={handleInput}
            error={validated && projectDetails.value.preparedBy === ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              format="MM/dd/yyyy"
              value={new Date(projectDetails.value.date)}
              onChange={handleChange}
              disablePast
              minDate={new Date()}
              slots={{
                textField: TextField,
              }}
              slotProps={{
                textField: {
                  required: true,
                  size: 'small',
                  margin: 'dense',
                  type: 'text',
                  variant: 'standard',
                  sx: { width: '100%', mt: 0 },
                  error: validated && projectDetails.value.date === '',
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            disableClearable
            options={['24/7 Occupancy', 'Custom Schedule']}
            value={projectDetails.value.schedule}
            onChange={(e, newVal) => {
              heatIndexState.setFormValue('projectDetails', 'schedule', newVal)
              heatIndexState.setNeedsUpdate(true)
            }}
            inputValue={projectDetails.value.schedule}
            renderInput={params => (
              <TextField
                {...params}
                required
                label="Schedule"
                name="schedule"
                variant="standard"
                margin="dense"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                error={
                  validated &&
                  projectDetails.value.schedule === 'Custom Schedule' &&
                  ((!projectDetails.value.customSchedule.Sunday &&
                    !projectDetails.value.customSchedule.Monday &&
                    !projectDetails.value.customSchedule.Tuesday &&
                    !projectDetails.value.customSchedule.Wednesday &&
                    !projectDetails.value.customSchedule.Thursday &&
                    !projectDetails.value.customSchedule.Friday &&
                    !projectDetails.value.customSchedule.Saturday) ||
                    (projectDetails.value.customSchedule.start.value === -1 &&
                      projectDetails.value.customSchedule.end.value === -1))
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {projectDetails.value.schedule === 'Custom Schedule' && (
                        <ScheduleModal />
                      )}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default ProjectDetails
