import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfInputSection from 'components/pdf/PdfInputSection'
import PdfNotesSection from 'components/pdf/PdfNotesSection'
import { useEvapSizingToolState } from 'store'

const EvapSizingToolPdf = () => {
  const evapSizingToolState = useEvapSizingToolState()
  const {
    length,
    isMetric,
    width,
    deckHeight,
    spaceType,
    insulationLevel,
    location,
    hasWindows,
    weatherDataType,
    dbAirTemp,
    mcwbAirTemp,
    solarIntensity,
    maxAirTempReduction,
    spaceAirTempReduction,
    safetyFactor,
    results,
  } = evapSizingToolState.get()

  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
    productRow: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: '4px',
    },
  })

  const PRODUCTS = [
    'Cool-Space 200',
    'Cool-Space 300',
    'Cool-Space 350',
    'Cool-Space 400',
    'Cool-Space 500',
  ]

  const notes = [
    'The space temp reduction is applied to the entire design space assuming that the cool air from the evaporative units is evenly distributed by HVLS fans. Use the destratification calculator to determine the number of fans required to mix the air in the space. The fans will also provide additional cooling effect via elevated airspeed.',
  ]

  const inputs = [
    { title: 'Length', value: `${length} ${isMetric ? 'm' : 'Feet'}` },
    { title: 'Width', value: `${width} ${isMetric ? 'm' : 'Feet'}` },
    {
      title: 'Deck Height',
      value: `${deckHeight} ${isMetric ? 'm' : 'Feet'}`,
    },
    { title: 'Space Type', value: spaceType },
    { title: 'Insulation Level', value: insulationLevel },
    { title: 'Location', value: location },
    { title: 'Significant Windows', value: hasWindows ? 'Yes' : 'No' },
  ]

  const weatherInputs = [
    { title: 'Weather Data', value: weatherDataType },
    { title: 'DB Air Temp', value: `${dbAirTemp} °F` },
    { title: 'MCWB Air Temp', value: `${mcwbAirTemp} °F` },
    { title: 'Solar Intensity', value: solarIntensity },
    { title: 'Max Air Temp Reduction', value: `${maxAirTempReduction} °F` },
    { title: 'Space Air Temp Reduction', value: `${spaceAirTempReduction} °F` },
    {
      title: 'Safety Factor',
      value:
        safetyFactor === 2.0
          ? 'Standard - 2.0'
          : safetyFactor === 2.5
          ? 'Conservative - 2.5'
          : 'Very Conservative - 3.0',
    },
  ]

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '8px',
        marginHorizontal: '16px',
        marginVertical: '8px',
        borderRadius: '8px',
      }}
    >
      <Text style={{ ...styles.sectionTitle, borderBottom: '0' }}>Results</Text>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          gap: '4px',
        }}
      >
        <View style={{ width: '35%' }}>
          <View style={{ flexDirection: 'row', marginBottom: '6px' }}>
            <View style={{ width: '75%' }}>
              <Text style={styles.label}>Type</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text style={styles.label}>Units</Text>
            </View>
          </View>
          {PRODUCTS?.map((product: string, index: number) => (
            <View key={product} style={styles.productRow}>
              <View style={{ width: '75%' }}>
                <Text style={styles.value}>{product}</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text
                  style={styles.label}
                >{`${results?.coolSpaceQty?.[index]}`}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ width: '65%', flexDirection: 'row' }}>
          <Image
            style={{
              padding: '1px',
              width: '175px',
              height: '116px',
              marginTop: '-15px',
            }}
            src="/pdfImages/coldfront_500.jpg"
          />
          <Image
            style={{
              padding: '1px',
              width: '175px',
              height: '116px',
              marginTop: '-15px',
            }}
            src="/pdfImages/coldfront_200.jpg"
          />
        </View>
      </View>
    </View>
  )

  return (
    <Document>
      <Page size="A4">
        <PdfHeader title="Evaporative Cooler Design Tool" />
        <PdfInputSection inputs={inputs} title="Area" />
        <PdfInputSection
          inputs={weatherInputs}
          title="Weather / Unit Selection"
        />
        {renderResultsSection()}
        <PdfNotesSection notes={notes} />
      </Page>
    </Document>
  )
}

export default EvapSizingToolPdf
