import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

const Logo: React.FC = () => {
  const smallBreakpoint = useMediaQuery('(min-width:600px)')
  const xSmallBreakpoint = useMediaQuery('(min-width:400px)')

  return (
    <Button component={Link} to="/">
      <img
        src="https://ba-web.s3.us-east-2.amazonaws.com/global/BAF-Logo.svg"
        alt="Big Ass Fans Logo Home Button"
        width={smallBreakpoint ? '250px' : xSmallBreakpoint ? '220px' : '170px'}
      />
    </Button>
  )
}

export default Logo
