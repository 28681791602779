import TextField from '@mui/material/TextField'
import { useSubmittalsState } from 'store'

const QuantityField = () => {
  const submittalsState = useSubmittalsState()
  const { validated, productDetails } = submittalsState.getState()
  const quantity = productDetails.value.quantity
  return (
    <TextField
      required
      label="Quantity"
      size="small"
      type="number"
      name="quantity"
      value={quantity}
      onChange={e => {
        submittalsState.setFormValue(
          'productDetails',
          'quantity',
          e.target.value
        )
      }}
      error={validated && (quantity === '' || quantity < 1)}
      margin="dense"
      variant="standard"
      sx={{ width: '100%', mt: 0 }}
      InputProps={{ inputProps: { min: 1 } }}
    />
  )
}

export default QuantityField
