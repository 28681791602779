import { useRef, useLayoutEffect } from 'react'

import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'

import { NestedSubSectionProps } from '../helpers/interfaces'
import BulletedList from './BulletedList'
import IndentedParagraph from './IndentedParagraph'
import Paragraph from './Paragraph'
import Pdf from './Pdf'
import Table from './table'
import Image from './Image'
import Hyperlink from './Hyperlink'
import OverlayedImage from './OverlayedImage'
import { useGlobalState } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'

const NestedSubSection = ({
  nestedSubSectionObj,
  headerPrefix,
  path,
}: NestedSubSectionProps) => {
  const headerRef = useRef<HTMLDivElement>(null)

  const globalState = useGlobalState()
  const globalPath = globalState.getDesignGuidePath(path)

  const navigate = useNavigate()

  const moveRefToTop = () => {
    if (headerRef.current) {
      const y =
        headerRef.current.getBoundingClientRect().top + window.pageYOffset - 95
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const { pathname } = useLocation()
  useLayoutEffect(() => {
    if (pathname.includes('#')) moveRefToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMounted = useRef(false)
  // used to only trigger on change, not on mount
  useLayoutEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      if (globalPath?.active) {
        moveRefToTop()
        navigate(`#${path}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalPath?.active])

  const header = `${headerPrefix} ${nestedSubSectionObj.mainContent}`

  const mappedData = nestedSubSectionObj.data.map((dataObj, index) => {
    let element
    switch (dataObj.type) {
      case 'image':
        element = (
          <Image key={index} {...dataObj.imageData!} path={headerPrefix} />
        )
        break
      case 'indent':
        element = <IndentedParagraph key={index} {...dataObj.indentData!} />
        break
      case 'link':
        element = <Hyperlink key={index} {...dataObj.urlData!} />
        break
      case 'list':
        element = <BulletedList key={index} {...dataObj.listData!} />
        break
      case 'overlay':
        element = (
          <OverlayedImage key={index} {...dataObj.overlayedImageData!} />
        )
        break
      case 'paragraph':
        element = <Paragraph key={index} content={dataObj.content!} />
        break
      case 'pdf':
        element = <Pdf key={index} {...dataObj.pdfData!} />
        break
      case 'table':
        element = (
          <Table key={index} {...dataObj.tableData!} path={headerPrefix} />
        )
        break
      default:
        element = <>error - type not found</>
        break
    }
    return element
  })

  return (
    <Box sx={{ ml: 6 }}>
      <div ref={headerRef}>
        <Typography variant="h6" sx={{ mt: 1 }}>
          {header}
        </Typography>
      </div>
      {mappedData}
    </Box>
  )
}

export default NestedSubSection
