import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import ProjectDetails from '../components/ProjectDetails'
import ProductDetails from '../components/ProductDetails'
import Logo from '../components/Logo'
import Notes from '../components/Notes'
import Features from '../components/Features'
import TechnicalDrawings from '../components/TechnicalDrawings'
import Links from 'components/Links'
import { useSubmittalsState } from 'store/salesToolsState'

const BAFConTemplate = () => {
  const { fan, productData } = useSubmittalsState().getState()
  return (
    <Stack spacing={0} display="block">
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Logo name={fan.name} />
        <Links links={productData.links} mt={0} />
      </Stack>
      <Grid container spacing={2}>
        <ProjectDetails />
        {productData.id !== -1 && <ProductDetails />}
        {productData.id !== -1 && <Features />}
        {productData.id !== -1 && <Notes />}
        <TechnicalDrawings />
      </Grid>
    </Stack>
  )
}

export default BAFConTemplate
