import groupBy from 'lodash/groupBy'

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const filterCoolingEffectData = (data: any, schedule: any): any => {
  const coolingEffectData = [] as any
  const endHour = schedule?.end?.value
  const startHour = schedule?.start?.value

  // Filter out times out of schedule
  const filteredDataByTime = data?.filter(
    (datum: any) =>
      endHour === startHour || (datum?.h >= startHour && datum?.h < endHour)
  )

  // Group data by days
  const groupedByDayData = groupBy(filteredDataByTime, 'd')
  DAYS?.forEach((day: string, index: number) => {
    if (schedule?.[day] && groupedByDayData?.[index + 1])
      coolingEffectData.push(...groupedByDayData?.[index + 1])
  })

  return coolingEffectData
}

export { filterCoolingEffectData as default }
