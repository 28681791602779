import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfNotesPage from 'components/pdf/PdfNotesSection'
import PdfInputSection from 'components/pdf/PdfInputSection'
import { useEmployeeSavingsState } from 'store'

const EmployeeSavingsCalcPdf = () => {
  const employeeSavingsState = useEmployeeSavingsState()
  const {
    airSpeedCoolingEffect,
    coolingSetPoint,
    heatingSetPoint,
    radiantHeatEffect,
    region,
    results,
    numOfEmployees,
    averageHours,
    averageWage,
    solution,
  } = employeeSavingsState.get()

  const styles = StyleSheet.create({
    cell: {
      flexDirection: 'column',
      width: '33%',
    },
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    productRow: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: '4px',
    },
    tableHeaderLabel: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  })

  const notes = [
    `Calculations are based on averages for the information selected and are only an estimate of actual savings. Actual loss and savings will vary depending on weather conditions, usage, location and business type. This information is intended as an example for comparison purposes only. BAF does not guarantee the accuracy of these calculations. No promise of performance is implied by us or should be inferred by you.`,
  ]

  const inputs = [
    { title: 'Climate Zone', value: region },
    { title: 'Solution', value: solution },
    { title: 'Number of Employees', value: numOfEmployees },
    { title: 'Average Hourly Wage', value: `$${averageWage}` },
    { title: 'Average Operating Hours', value: averageHours },
    {
      title: 'Elevated Air Speed Cooling Effect',
      value: `${airSpeedCoolingEffect} °F`,
    },
    {
      title: 'Cooling Set Point',
      value: coolingSetPoint ? `${coolingSetPoint} °F` : 'N/A',
    },
    { title: 'Radiant Heaters Effect', value: `${radiantHeatEffect} °F` },
    {
      title: 'Heating Set Point',
      value: heatingSetPoint ? `${heatingSetPoint} °F` : 'N/A',
    },
  ]

  const renderResultsCard = (amount: number, title: string) => (
    <View style={{ flexDirection: 'column', marginTop: '16px' }}>
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={{ fontWeight: 'extrabold', fontSize: '14px' }}
        >{`${amount}`}</Text>
        <Text
          style={{
            color: 'gray',
            fontSize: '10px',
            marginTop: '4px',
            marginLeft: '3px',
          }}
        >
          per year
        </Text>
      </View>
      <Text style={{ fontSize: '12px', fontWeight: 'normal' }}>{title}</Text>
    </View>
  )

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '8px',
        margin: '16px',
        borderRadius: '8px',
      }}
    >
      <Text style={{ ...styles.sectionTitle, borderBottom: '0' }}>
        Employee Impacts
      </Text>

      <View style={{ flexDirection: 'row', marginTop: '-12px' }}>
        <View style={{ flexDirection: 'column', width: '40%' }}>
          {renderResultsCard(
            results?.employeeTurnoverSavings,
            'Employee Turnover Savings'
          )}
          {renderResultsCard(
            results?.coolingSavings,
            'Cooling Productivity Savings'
          )}
          {renderResultsCard(
            results?.heatingSavings,
            'Heating Productivity Savings'
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            border: '4px solid black',
            marginTop: '24px',
            height: '75px',
            padding: '16px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{ fontWeight: 'extrabold', fontSize: '16px' }}
            >{`${results?.total}`}</Text>
            <Text
              style={{
                color: 'gray',
                fontSize: '10px',
                marginTop: '4px',
                marginLeft: '3px',
              }}
            >
              per year
            </Text>
          </View>
          <Text style={{ fontSize: '12px', fontWeight: 'normal' }}>
            Total Employee Savings
          </Text>
        </View>
      </View>
    </View>
  )

  return (
    <Document>
      <Page size="A4">
        <PdfHeader title="Employee Savings Calculator" />
        <PdfInputSection inputs={inputs} />
        {renderResultsSection()}
        <PdfNotesPage notes={notes} />
      </Page>
    </Document>
  )
}

export default EmployeeSavingsCalcPdf
