import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Logo from 'components/Logo'

interface Props {
  title: string
}

const PrintHeader: React.FC<Props> = ({ title }: Props) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      m={4}
      sx={{ display: 'none', '@media print': { display: 'flex' } }}
    >
      <Logo />
      <Typography
        variant="h3"
        sx={{
          borderLeft: '1px solid',
          borderColor: 'lightgray',
          paddingLeft: 3,
        }}
      >
        {title}
      </Typography>
    </Stack>
    // ...
  )
}

export default PrintHeader
