import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store/salesToolsState'
import { BAF_COLOR_OPTIONS } from 'config/constants'

interface APIAppearanceOption {
  id: number
  part?: {
    id: number
    part: string
  }
  color?: {
    id: number
    color: string
  }
  finish?: {
    id: number
    finish: string
  }
  hardware_finish?: {
    id: number
    hardware_finish: string
  }
  material?: {
    id: number
    material: string
  }
}

const AppearanceOptions = () => {
  const submittalsState = useSubmittalsState()
  const { productData, numCols, appearanceOpts, numAppOpts } =
    submittalsState.getState()
  const [opt1, setOpt1] = useState<string | null>(null)
  const parts = productData.appearances
    .map((a: APIAppearanceOption) => a.part?.part)
    .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
  parts.unshift('None')
  const colors = (index: number) => {
    let colorOpts = [] as string[]
    productData.appearances.forEach(function (a: APIAppearanceOption) {
      if (a.part?.part === appearanceOpts[index].part) {
        colorOpts.push(a.color?.color ?? '')
      }
    })
    return colorOpts
  }
  return (
    <Grid item md={6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ mt: -0.5 }}>
            Appearance Options
          </Typography>
          {opt1 !== 'Standard' && opt1 !== 'Monochrome' && (
            <Grid item xs={24 / numCols}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => submittalsState.addAppearanceOption()}
                sx={{
                  ml: 2,
                  pt: 0,
                  pb: 0,
                  '@media print': { display: 'none' },
                }}
              >
                Add Another Option
              </Button>
            </Grid>
          )}
        </Box>
        <Grid container rowSpacing={0} columnSpacing={1} id="appearanceOpts">
          <Grid item xs={24 / numCols}>
            <Box sx={{ pb: 0 }}>
              <Autocomplete
                disablePortal
                size="small"
                options={parts}
                sx={{ pt: 0, mb: -1 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    margin="dense"
                    label="Part"
                  />
                )}
                value={appearanceOpts[0].part}
                onChange={(event, newVal) => {
                  setOpt1(newVal as string)
                  submittalsState.editAppearancePart(0, newVal as string)
                }}
                inputValue={appearanceOpts[0].part ?? ''}
              />
              {appearanceOpts[0].part !== 'None' &&
                colors(0).filter((n: any) => n).length > 0 && (
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={colors(0)
                      .filter((n: any) => n)
                      .filter(
                        (v: string, i: number, a: string[]) =>
                          a.indexOf(v) === i
                      )}
                    renderOption={(props, option: string) => (
                      <li {...props}>
                        {option !== 'Alpine White' && (
                          <CircleIcon
                            sx={{
                              fill:
                                BAF_COLOR_OPTIONS[
                                  option as keyof typeof BAF_COLOR_OPTIONS
                                ] ?? '#ffffff',
                              m: 1,
                            }}
                            fontSize="large"
                          />
                        )}
                        {option === 'Alpine White' && (
                          <CircleOutlinedIcon
                            sx={{
                              fill: BAF_COLOR_OPTIONS[
                                option as keyof typeof BAF_COLOR_OPTIONS
                              ],
                              m: 1,
                            }}
                            fontSize="large"
                          />
                        )}
                        {option}
                      </li>
                    )}
                    sx={{ pt: 0, mb: -0.5 }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        margin="dense"
                        label="Style"
                      />
                    )}
                    value={appearanceOpts[0].color}
                    onChange={(event, newVal) => {
                      submittalsState.editAppearanceColor(0, newVal as string)
                    }}
                    inputValue={appearanceOpts[0].color ?? ''}
                  />
                )}
            </Box>
          </Grid>
          {opt1 !== 'Monochrome' &&
            opt1 !== 'Standard' &&
            Array.from(Array(numAppOpts - 1)).map((o, index) => (
              <Grid item xs={24 / numCols} key={index + 1}>
                <Box sx={{ pb: 0 }}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={parts.filter(
                      (n: any) => n !== 'Standard' && n !== 'Monochrome'
                    )}
                    sx={{ pt: 0, mb: -1 }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        margin="dense"
                        label="Part"
                      />
                    )}
                    value={appearanceOpts[index + 1].part}
                    onChange={(event, newVal) => {
                      submittalsState.editAppearancePart(
                        index + 1,
                        newVal as string
                      )
                    }}
                    inputValue={appearanceOpts[index + 1].part ?? ''}
                  />
                  {appearanceOpts[index + 1].part && (
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={colors(index + 1)
                        .filter((n: any) => n)
                        .filter(
                          (v: string, i: number, a: string[]) =>
                            a.indexOf(v) === i
                        )}
                      renderOption={(props, option: string) => (
                        <li {...props}>
                          {option !== 'Alpine White' && (
                            <CircleIcon
                              sx={{
                                fill:
                                  BAF_COLOR_OPTIONS[
                                    option as keyof typeof BAF_COLOR_OPTIONS
                                  ] ?? '#ffffff',
                                m: 1,
                              }}
                              fontSize="large"
                            />
                          )}
                          {option === 'Alpine White' && (
                            <CircleOutlinedIcon
                              sx={{
                                fill: BAF_COLOR_OPTIONS[
                                  option as keyof typeof BAF_COLOR_OPTIONS
                                ],
                                m: 1,
                              }}
                              fontSize="large"
                            />
                          )}
                          {option}
                        </li>
                      )}
                      sx={{ pt: 0, mb: -0.5 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          margin="dense"
                          label="Style"
                        />
                      )}
                      value={appearanceOpts[index + 1].color}
                      onChange={(event, newVal) => {
                        submittalsState.editAppearanceColor(
                          index + 1,
                          newVal as string
                        )
                      }}
                      inputValue={appearanceOpts[index + 1].color ?? ''}
                    />
                  )}
                </Box>
              </Grid>
            ))}
        </Grid>
      </Card>
    </Grid>
  )
}

export default AppearanceOptions
