import { Text, View, StyleSheet } from '@react-pdf/renderer'

const PdfNotesSection = ({ notes = [] }: { notes: string[] }) => {
  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
  })

  return (
    <View style={{ ...styles.section, marginTop: '16px' }}>
      <Text style={styles.sectionTitle}>Notes</Text>
      {notes?.map((note: string) => (
        <View style={styles.row}>
          <Text style={styles.label}>{note}</Text>
        </View>
      ))}
    </View>
  )
}

export default PdfNotesSection
