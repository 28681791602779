import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'
import { AppBarSpacer } from './helpers/styled'

const NoMatch = () => {
  return (
    <Container maxWidth="xl">
      <AppBarSpacer />
      <Typography variant="h2">Page Not Found</Typography>
      <Typography variant="body1" fontSize={16}>
        <p>We're sorry, but we could not find the page you requested.</p>
      </Typography>
      <Typography variant="body1" fontSize={16} color="primary">
        <p>
          <Link component={RouterLink} to="/">
            Go to the home page
          </Link>
        </p>
      </Typography>
    </Container>
  )
}

export default NoMatch
