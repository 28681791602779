import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Hyperlink from './Hyperlink'
import { grey } from '@mui/material/colors'
import { OverlayedImageDataObj } from '../helpers/interfaces'

const OverlayedImage = ({ src, alt, content, url }: OverlayedImageDataObj) => (
  <Card
    sx={{
      maxWidth: 800,
      height: 350,
      borderRadius: 2,
      backgroundImage: `url("${src}")`,
      backgroundSize: 'cover',
      position: 'relative',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 0,
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        p: 5,
        pr: [5, 30],
      }}
    >
      <Typography variant="h6" sx={{ color: grey[50], mb: 2 }}>
        {content}
      </Typography>
      <Box
        sx={{
          color: 'secondary',
        }}
      >
        <Hyperlink url={url} content="View Case Study" styled="true" />
      </Box>
    </Box>
  </Card>
)

export default OverlayedImage
