import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material/styles'

import './index.css'

import 'react-tooltip/dist/react-tooltip.css'
import Home from 'features/home'
import DestratTool from 'features/destrat-tool'
import DesignGuide from 'features/design-guide'
import FanScheduleBuilder from 'features/fan-schedule-builder'
import ExtensionTubeCalc from 'features/extension-tube-calc'
import FanEnergyEstimator from 'features/fan-energy-estimator'
import EvapSizingTool from 'features/evap-sizing-tool'
import CostOfOwnershipCalc from 'features/cost-of-ownership-calc'
import AcSavingsCalc from 'features/ac-savings-calc'
import Submittals from 'features/submittals'
import HeatIndexCalc from 'features/heat-index-calc'
import OperationalLoads from 'features/operational-loads'
import EmployeeSavingsCalc from 'features/employee-savings-calc'
import HeatSavingsEstimator from 'features/heat-savings-estimator'
import ProductDimensions from 'features/product-dimensions'
import { ArchivedProjects, Projects, ProjectResults } from 'features/projects'
import Layout, { NoMatch } from 'features/layout'

import theme from './config/theme'

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        cacheLocation="localstorage"
        useCookiesForTransactions={true}
        useRefreshTokens={true}
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/standalone/">
              <Route
                path="fan-schedule-builder"
                element={<FanScheduleBuilder standalone />}
              />
              <Route path="submittals" element={<Submittals standalone />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="design-guide" element={<DesignGuide />} />
              <Route
                path="fan-schedule-builder"
                element={<FanScheduleBuilder showVersion />}
              />
              <Route path="submittals" element={<Submittals />} />
              <Route path="heat-index-hours-calculator" element={<HeatIndexCalc />} />
              <Route path="heat-index-calculator" element={<HeatIndexCalc />} />
              <Route
                path="extension-tube-calc"
                element={<ExtensionTubeCalc />}
              />
              <Route
                path="employee-savings-calc"
                element={<EmployeeSavingsCalc />}
              />
              <Route path="evap-sizing-tool" element={<EvapSizingTool />} />
              <Route
                path="fan-energy-estimator"
                element={<FanEnergyEstimator />}
              />
              <Route
                path="heat-savings-estimator"
                element={<HeatSavingsEstimator />}
              />
              <Route path="destrat-tool" element={<DestratTool />} />
              <Route
                path="cost-of-ownership-calc"
                element={<CostOfOwnershipCalc />}
              />
              <Route
                path="product-dimensions"
                element={<ProductDimensions />}
              />
              <Route path="operational-loads" element={<OperationalLoads />} />
              <Route path="ac-savings-calc" element={<AcSavingsCalc />} />
              <Route path="projects" element={<Projects />} />
              <Route path="projects/:id" element={<ProjectResults />} />
              <Route path="projects/archived" element={<ArchivedProjects />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Auth0Provider>
    </ThemeProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
