import { Text, View, StyleSheet } from '@react-pdf/renderer'

const PdfFansSection = ({
  bafFans = [],
  existingFans = [],
}: {
  bafFans: any
  existingFans: any
}) => {
  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    productRow: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: '4px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'heavy',
      fontSize: '10px',
    },
  })

  return (
    <View style={styles.section}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.sectionTitle}>Big Ass Fans Products</Text>
        <Text style={styles.sectionTitle}>Existing Products</Text>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <View style={{ width: '50%' }}>
          {!!bafFans?.length && (
            <View style={{ flexDirection: 'row', marginBottom: '6px' }}>
              <View style={{ width: '75%' }}>
                <Text style={styles.label}>Type</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={styles.label}>Qty</Text>
              </View>
            </View>
          )}
          {bafFans?.map((fan: any) => (
            <View key={fan?.name} style={styles.productRow}>
              <View style={{ width: '75%' }}>
                <Text style={styles.value}>{fan?.name}</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={styles.label}>{fan?.count || 1}</Text>
              </View>
            </View>
          ))}
        </View>

        <View style={{ width: '50%' }}>
          {!!existingFans?.length && (
            <View style={{ flexDirection: 'row', marginBottom: '6px' }}>
              <View style={{ width: '75%' }}>
                <Text style={styles.label}>Type</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={styles.label}>Qty</Text>
              </View>
            </View>
          )}
          {existingFans?.map((fan: any) => (
            <View key={fan?.name} style={styles.productRow}>
              <View style={{ width: '75%' }}>
                <Text style={styles.value}>{fan?.name}</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={styles.label}>{fan?.count || 1}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}

export default PdfFansSection
