import sum from 'lodash/sum'
import { createState, useState, State } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

import { LOCAL_STORAGE_KEY } from '../config/constants'

const COST_OF_REPLACEMENT = 13996
const TURNOVER_RATE_NO_FANS = 2.0
const TURNOVER_RATE_WITH_FANS = 1.75
const WEEKS_PER_YEAR = 52
const HOURS_PER_YEAR = 8760
const HEAT_GAIN = 3

export interface EmployeeSavingsState {
  airSpeedCoolingEffect: number
  averageHours: number
  averageWage: number
  isCooled: boolean
  isHeated: boolean
  leadSaved: boolean
  numOfEmployees: number
  heatingSetPoint: number
  coolingSetPoint: number
  printing: boolean
  radiantHeatEffect: number
  region: string
  results: any
  solution: string
  valid: boolean
  validated: boolean
  coolingWeatherData: any
  heatingWeatherData: any
  zip: number
}

const initialEmployeeSavingsState = {
  airSpeedCoolingEffect: 0,
  averageHours: 0,
  averageWage: 0,
  heatingSetPoint: 0,
  coolingSetPoint: 0,
  isCooled: false,
  isHeated: false,
  leadSaved: false,
  numOfEmployees: 0,
  printing: false,
  radiantHeatEffect: 0,
  region: '',
  results: null,
  solution: '',
  valid: false,
  validated: false,
  coolingWeatherData: null,
  heatingWeatherData: null,
  zip: 0,
}

const initialDataState = {
  results: null,
  heatingWeatherData: null,
  coolingWeatherData: null,
}

const employeeSavingsState = createState(initialEmployeeSavingsState)

const wrapEmployeeSavingsState = (s: State<EmployeeSavingsState>) => ({
  get: () => s.value,
  loadState: (newState: any) =>
    s.set((p: any) => ({
      ...p,
      ...newState,
    })),
  resetState: () =>
    s.set((p: any) => ({
      ...initialEmployeeSavingsState,
      leadSaved: p.leadSaved,
    })),
  setValidated: () => {
    s.set((p: any) => ({ ...p, validated: !!s.results }))
  },
  resetData: () => {
    s.set((p: any) => ({
      ...p,
      ...initialDataState,
    }))
  },
  setLeadSaved: (value: boolean) => {
    s.set((p: any) => ({ ...p, leadSaved: value }))
  },
  setSolution: (value: string) => {
    s.set((p: any) => ({ ...p, solution: value, results: null }))
  },
  setZip: (value: string) => {
    s.set((p: any) => ({ ...p, zip: value, results: null }))
  },
  setHeatingSetPoint: (value: number) => {
    s.set((p: any) => ({ ...p, heatingSetPoint: value, results: null }))
  },
  setCoolingSetPoint: (value: number) => {
    s.set((p: any) => ({ ...p, coolingSetPoint: value, results: null }))
  },
  setPrinting: (value: boolean) => {
    s.set((p: any) => ({ ...p, printing: value }))
  },
  setIsCooled: (value: boolean) => {
    s.set((p: any) => ({
      ...p,
      isCooled: value,
      coolingSetPoint: null,
      results: null,
    }))
  },
  setIsHeated: (value: boolean) => {
    s.set((p: any) => ({
      ...p,
      isHeated: value,
      heatingSetPoint: null,
      results: null,
    }))
  },
  setNumOfEmployees: (value: number) => {
    s.set((p: any) => ({ ...p, numOfEmployees: value, results: null }))
  },
  setAverageWage: (value: number) => {
    s.set((p: any) => ({ ...p, averageWage: value, results: null }))
  },
  setAverageHours: (value: number) => {
    s.set((p: any) => ({ ...p, averageHours: value, results: null }))
  },
  setAirSpeedCoolingEffect: (value: number) => {
    s.set((p: any) => ({ ...p, airSpeedCoolingEffect: value, results: null }))
  },
  setRadiantHeatEffect: (value: number) => {
    s.set((p: any) => ({ ...p, radiantHeatEffect: value, results: null }))
  },
  setCoolingWeatherData: (value: any) => {
    s.set((p: any) => ({ ...p, coolingWeatherData: value }))
  },
  setHeatingWeatherData: (value: any) => {
    s.set((p: any) => ({ ...p, heatingWeatherData: value }))
  },
  setRegion: (region: string) => {
    s.set((p: any) => ({ ...p, ...initialDataState, region }))
  },
  setResults: () => {
    s.set((p: any) => {
      // Algorithm from Emp Savings Calc docs. Magic numbers unknown
      const getTemp = (value: number): number =>
        (-0.000011715094483 * Math.pow(value, 3) +
          0.003083901533865 * Math.pow(value, 2) -
          0.260435775118699 * value +
          7.13827149764943) *
        100

      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      // Get total annual wages
      const totalAnnualWages =
        p?.numOfEmployees * p?.averageWage * p?.averageHours * WEEKS_PER_YEAR

      // Get employee turnover savings
      const turnoverCostNoFans =
        (p?.numOfEmployees * TURNOVER_RATE_NO_FANS * COST_OF_REPLACEMENT) / 100
      const turnoverCostWithFans =
        (p?.numOfEmployees * TURNOVER_RATE_WITH_FANS * COST_OF_REPLACEMENT) /
        100

      // Get cooling productivity savings
      const indoorTempNoFans = p?.coolingWeatherData?.map((datum: any) => {
        const value = Number(datum.value)
        if (!p?.isCooled) return value
        return value < p?.coolingSetPoint ? value : p?.coolingSetPoint
      })
      const lossNoFans = indoorTempNoFans?.map((datum: any) =>
        datum > 71 ? getTemp(datum) : 0
      )
      const annualLossNoFans = sum(lossNoFans) / HOURS_PER_YEAR
      const indoorTempWithFans = p?.coolingWeatherData?.map((datum: any) => {
        const value = Number(datum.value)
        if (!p?.isCooled) return value - p?.airSpeedCoolingEffect
        return value < p?.coolingSetPoint
          ? value - p?.airSpeedCoolingEffect
          : p?.coolingSetPoint - p?.airSpeedCoolingEffect
      })
      const lossWithFans = indoorTempWithFans?.map((datum: any) =>
        datum > 71 ? getTemp(datum) : 0
      )
      const annualLossWithFans = sum(lossWithFans) / HOURS_PER_YEAR
      const annualCostOfLossNoFans = annualLossNoFans * totalAnnualWages
      const annualCostOfLossWithFans = totalAnnualWages * annualLossWithFans

      // Get heating productivity savings
      const indoorTempNoHeaters = p?.heatingWeatherData?.map((datum: any) => {
        const value = Number(datum.value)
        return p?.isHeated && value < p?.heatingSetPoint - HEAT_GAIN
          ? p?.heatingSetPoint
          : value + HEAT_GAIN
      })
      const lossNoHeaters = indoorTempNoHeaters?.map((datum: any) =>
        datum < 71 ? getTemp(datum) : 0
      )
      const annualLossNoHeaters = sum(lossNoHeaters) / HOURS_PER_YEAR
      const indoorTempWithHeaters = p?.heatingWeatherData?.map((datum: any) => {
        const value = Number(datum.value)
        if (!p?.isHeated) return value + p?.radiantHeatEffect + HEAT_GAIN
        return value < p?.heatingSetPoint + p?.radiantHeatEffect - HEAT_GAIN
          ? p?.heatingSetPoint + p?.radiantHeatEffect
          : value + HEAT_GAIN
      })
      const lossWithHeaters = indoorTempWithHeaters?.map((datum: any) =>
        datum < 71 ? getTemp(datum) : 0
      )
      const annualLossWithHeaters = sum(lossWithHeaters) / HOURS_PER_YEAR
      const annualCostOfLossNoHeaters = annualLossNoHeaters * totalAnnualWages
      const annualCostOfLossWithHeaters =
        totalAnnualWages * annualLossWithHeaters

      // Get savings amounts
      const employeeTurnoverSavings = turnoverCostNoFans - turnoverCostWithFans
      const coolingSavings =
        p?.solution === 'Radiant Heaters'
          ? 0
          : ((annualCostOfLossNoFans - annualCostOfLossWithFans) * 0.5) / 100
      const heatingSavings =
        p?.solution === 'Fans'
          ? 0
          : ((annualCostOfLossNoHeaters - annualCostOfLossWithHeaters) * 0.5) /
            100
      const total = employeeTurnoverSavings + coolingSavings + heatingSavings

      const results = {
        employeeTurnoverSavings: currencyFormatter.format(
          Math.round(employeeTurnoverSavings)
        ),
        coolingSavings: currencyFormatter.format(Math.round(coolingSavings)),
        heatingSavings: currencyFormatter.format(Math.round(heatingSavings)),
        total: currencyFormatter.format(Math.round(total)),
      }

      return { ...p, results }
    })
  },
})

export const accessEmployeeSavingsState = () =>
  wrapEmployeeSavingsState(employeeSavingsState)

export const useEmployeeSavingsState = () => {
  const state = useState(employeeSavingsState)
  state.attach(Persistence(`${LOCAL_STORAGE_KEY}-employee-savings`))
  return wrapEmployeeSavingsState(state)
}
