import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { useSubmittalsState } from 'store/salesToolsState'

interface APITechnicalNote {
  id: number
  note: string
}

const TechnicalNotes = () => {
  const { productData } = useSubmittalsState().getState()
  const notes = productData.technicalNotes.map((n: APITechnicalNote) => n.note)
  const full = productData.technicalNotes.length > 1
  return (
    <Grid item md={full ? 12 : 6} xs={12}>
      <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
        <Typography variant="h5" sx={{ mt: -1, mb: 1 }}>
          Technical Notes
        </Typography>
        {!full && <Typography>{notes[0]}</Typography>}
        {full && (
          <Table size="small">
            <TableBody>
              <TableRow>
                {notes.map(
                  (note: string, index: number) =>
                    index < 2 && (
                      <TableCell
                        sx={{
                          p: 1,
                          width: 100,
                          lineHeight: '1rem',
                          '@media print': { fontSize: '0.8rem' },
                        }}
                        key={index}
                      >
                        {note}
                      </TableCell>
                    )
                )}
              </TableRow>
              <TableRow>
                {notes.map(
                  (note: string, index: number) =>
                    index > 1 &&
                    index < 4 && (
                      <TableCell
                        sx={{
                          p: 1,
                          width: 100,
                          lineHeight: '1rem',
                          '@media print': { fontSize: '0.8rem' },
                        }}
                        key={index}
                      >
                        {note}
                      </TableCell>
                    )
                )}
              </TableRow>
              <TableRow>
                {notes.map(
                  (note: string, index: number) =>
                    index > 3 && (
                      <TableCell
                        sx={{
                          p: 1,
                          width: 100,
                          lineHeight: '1rem',
                          '@media print': { fontSize: '0.8rem' },
                        }}
                        key={index}
                      >
                        {note}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Card>
    </Grid>
  )
}

export default TechnicalNotes
