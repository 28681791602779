import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import useMediaQuery from '@mui/material/useMediaQuery'
import InfoIcon from '@mui/icons-material/Info'

import { useFanEnergyEstimatorState } from 'store'

const AireyeBafCostTable = ({
  results,
  title,
  onModeChange = () => {},
}: {
  results: any
  title: string
  onModeChange?: (name: string, mode: string) => void
}) => {
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')

  // Fan mode select options
  const fanModeOptions = [
    { id: 'Off', value: 'Off' },
    { id: 'Max Cool', value: 'Max Cool' },
    { id: 'Cool', value: 'Cool' },
  ]

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const renderRow = (month: any) => (
    <TableRow key={month?.name} sx={{ padding: '0' }}>
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          fontWeight: `${month?.name === 'Total' ? 700 : 400}`,
          fontSize: `${month?.name === 'Total' ? '14px' : '12px'}`,
        }}
      >
        {month?.name}
      </TableCell>
      <TableCell>
        {month?.mode && (
          <Select
            required
            variant="standard"
            value={month?.mode}
            onChange={(e: any) => {
              const mode = e?.target?.value
              onModeChange && onModeChange(month.name, mode)
              fanEnergyEstimatorState.setResults()
            }}
            sx={{ width: '100%' }}
          >
            {fanModeOptions.map((mode: any) => (
              <MenuItem key={mode.value} value={mode.value}>
                {mode.value}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>
      <TableCell>{month?.days || '-'}</TableCell>
      <TableCell>{month?.hours ? round(month?.hours, 1) : '-'}</TableCell>
      <TableCell>{month?.usage ? round(month?.usage, 1) : '-'}</TableCell>
      <TableCell>
        {month?.cost ? `$${round(month?.cost, 100).toFixed(2)}` : '-'}
      </TableCell>
      <TableCell>
        {month?.usagePerDay ? round(month?.usagePerDay) : '-'}
      </TableCell>
      <TableCell>
        {month?.costPerDay
          ? `$${round(month?.costPerDay, 100).toFixed(2)}`
          : '-'}
      </TableCell>
    </TableRow>
  )

  return (
    <Box width="100%">
      <Typography variant="h6" sx={{ mt: 4, mb: 1.5 }}>
        {title}
        <InfoIcon
          data-tooltip-id="standard-tooltip"
          data-tooltip-content={
            mediumBreakpoint
              ? 'The fan energy cost displayed is calculated using the methods developed by BAF expertise which best accounts for how customers utilize existing Big Ass Fans products (HVLS, Residential, and Directional) as well as existing non-BAF legacy products in their spaces.'
              : ''
          }
          sx={{ ml: 0.5, pt: 0.5, mb: -0.25 }}
        />
      </Typography>

      <Box sx={{ overflowX: 'auto' }}>
        <Table aria-label="standard-results-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Month
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Fan Mode
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Days
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Hours
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Usage (kWh)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Cost ($)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Usage / Day
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  fontSize: mediumBreakpoint ? 'medium' : 'small',
                  width: '12.5%',
                }}
              >
                Cost / Day
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.monthsData?.map((month: any) => renderRow(month))}
            {renderRow(results?.monthsDataTotals)}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default AireyeBafCostTable
