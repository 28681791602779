import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import { useExtensionTubeState } from 'store/extensionTubeState'

const ImageCard = ({ focusedInput }: { focusedInput: string }) => {
  const extensionTubeState = useExtensionTubeState()
  const { roofPitch } = extensionTubeState.getState()
  const colors: {
    [key: string]: string
  } = {
    default: '#000',
    highlight: '#38b54b',
  }

  const flatImage = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="100%"
      height="100%"
      viewBox="106.5 0.5 420.5 157"
    >
      <path
        fill={`${focusedInput === 'a' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M520.5 14h2.5l-3-12.5-3 12.5h2.5v53.5h1V14Zm0 130V90.5h-1V144H517l3 12.5 3-12.5h-2.5Zm-6.091-58.5h1.477l1.17-3.296h4.706l1.17 3.296h1.477l-4.273-11.636h-1.454L514.409 85.5Zm6.909-4.546-1.863-5.25h-.091l-1.864 5.25h3.818Z"
        className="Top-A"
        clipRule="evenodd"
      ></path>
      <path
        fill={`${focusedInput === 'b' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M466.5 26h2.5l-3-12.5-3 12.5h2.5v47.5h1V26Zm-.5 117V97.5h-1V143h-2.5l3 12.5 3-12.5H466Zm-4.591-63.136V91.5h4.205c.916 0 1.661-.136 2.233-.41.572-.272.99-.641 1.255-1.107.265-.466.398-.99.398-1.574 0-.614-.121-1.133-.364-1.557a2.716 2.716 0 0 0-.903-.977c-.36-.227-.718-.352-1.074-.375v-.114c.333-.09.642-.23.926-.42.288-.19.519-.447.693-.773.178-.326.267-.739.267-1.239 0-.568-.128-1.085-.386-1.55-.257-.47-.65-.843-1.176-1.12-.527-.28-1.195-.42-2.006-.42h-4.068Zm4.205 10.386h-2.796v-4.046h2.864c.507 0 .947.103 1.318.307.371.201.657.47.858.807.201.334.301.697.301 1.091 0 .511-.195.947-.585 1.307-.387.356-1.04.534-1.96.534Zm-.182-5.273h-2.614v-3.863h2.659c.773 0 1.337.18 1.693.54.357.356.535.79.535 1.3 0 .41-.103.766-.307 1.069a2.012 2.012 0 0 1-.818.704c-.341.167-.724.25-1.148.25Z"
        className="Top-B"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#000"
        strokeOpacity=".1"
        d="m107 10 9-9M112.5 14l13-13M122 14l13-13M131.5 14 143 1M140 14l12.5-13M149.5 14l12-13M159 14l12-13M168 14l12-13M177 14l13-13M186.5 14 198 1M195.5 14 207 1M204.5 14 217 1M214.5 14 226 1M223.5 14l11-13M233 14l11.5-13M242 14l11-13M251 14l11-13M260.5 14 272 1M269 14l12-13M278 14l12.5-13M288 14l11.5-13M297 14l11.5-13M306 14l12-13M315.5 14 327 1M324 14l12-13M333 14l12.5-13M343 14l11.5-13M352 14l11.5-13M361 14l12-13M370.5 14 382 1M379.5 14 391 1M388 14l12.5-13M397.5 14 410 1M407 14l11.5-12.5M415.5 14l3.5-3"
      ></path>
      <path
        stroke="#000"
        d="M107 14h312.5M419 1v156M526.5 1H107v156h419.5M423 14h48.5"
      ></path>
      <path
        fill="#000"
        d="M339 18h4v-.5h.5V16h.5v-1.5h-2v.5h-2v-.5h-1.5v2h.5V18ZM340 26.5V18h2v8.5h-2Z"
      ></path>
      <path
        stroke="#000"
        d="M340 27v-.5h2.5v1h-1v1h1V28h.5v.5h.5v.5h-.5v1h2.5v.5h2V36h-2v.5H344v.5h-.5v.5h-.5v1h3v.5h-.5v1h.5v.5h-.5V42h28v-1h5v1h12v-1h.5v2h.5v.5h-.5v1h-.5v-1h-.5V43h-11.5v.5H374V43h-3.5v.5H368V43h-22v.5h-2.5v.5h-.5v-.5h-3v.5h-.5v-.5h-4V43H314v.5h-3V43h-3v.5h-4V43h-12v.5h-.5v1h-.5v-1h-.5V43h.5v-1h-.5v-.5h.5v-1h.5v1h.5v.5h11.5v-1h4.5v1h28.5v-1h-.5v-1h.5v-1h-.5v-1h1v-1h-.5v-5.5h-.5v-1h3V30h-.5v-.5h.5v-1h.5V27h.5Z"
      ></path>
      <path
        fill="#000"
        d="M337.5 33v-1h1.5v1h-1.5ZM342 29.5h-2v.5h.5v7h-.5v1h2v-8.5ZM343 35v-3.5h.5V31h1v.5h2V35H343ZM339.5 34H337v1.5h2.5V34ZM343 39.5h-6v.5h6v-.5ZM339 42.5V42h.5v.5h-.5ZM340 42v1h3v-1h-1v-.5h-.5v.5H340Z"
      ></path>
    </svg>
  )

  const slopedImage = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="100%"
      height="100%"
      viewBox="-0.5 172.5 527.5 225.5"
    >
      <g stroke="#000" opacity=".1">
        <path d="m107 213 10.5-10.5M113.5 215.5 129 200M125.5 213l14.5-15M137 210.5l14.5-15M148 208.5l16-15.5M160 206l16-15.5M171 204l15.5-15.5M183.5 201.5 198 186M195.5 199l14.5-15.5M206.5 197l14.5-15.5M218 195l14.5-16M228.5 192.5 244 177M240 190.5l15.5-16M252 188l13.5-14.5M263 186l10-11M270 187.5l11-11M278.5 189l10.5-10.5M286 190.5l10.5-10.5M294 192l9.5-10.5M301.5 193.5l10-11M308.5 195l11-10.5M316.5 196.5 327 186M324 198l11-10.5M332 199.5l10-10.5M339.5 201l10-10.5M347 202.5l10-10.5M355 204l10-10.5M362.5 205.5l10-10.5M370.5 207l10-10.5M377.5 208.5 388 198M386 210l9.5-10.5M393.5 211.5l10-10.5M401.5 213l10-10.5M408 214.5l10.5-10.5M415.5 216l3-2.5"></path>{' '}
      </g>
      <path
        fill={`${focusedInput === 'e' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M6.5 186H9l-3-12.5L3 186h2.5v67.5h1V186Zm0 161v-68.5h-1V347H3l3 12.5L9 347H6.5Zm-4.09-86.136V272.5h7.113v-1.25H3.818v-3.955h5.25v-1.25h-5.25v-3.931h5.614v-1.25H2.409Z"
        className="Bottom-E"
        clipRule="evenodd"
      ></path>
      <path
        fill={`${focusedInput === 'd' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M63.5 216H66l-3-12.5-3 12.5h2.5v53.5h1V216Zm0 131v-53.5h-1V347H60l3 12.5 3-12.5h-2.5Zm-5.09-59.5H62c1.182 0 2.186-.233 3.011-.699a4.617 4.617 0 0 0 1.887-2.011c.431-.875.648-1.919.648-3.131 0-1.204-.215-2.239-.643-3.102-.428-.868-1.043-1.532-1.846-1.994-.803-.466-1.77-.699-2.898-.699h-3.75V287.5Zm3.5-1.25h-2.092v-9.136h2.25c.91 0 1.669.183 2.279.551.61.363 1.068.886 1.375 1.568.306.678.46 1.487.46 2.426 0 .947-.156 1.763-.466 2.449a3.428 3.428 0 0 1-1.415 1.585c-.632.371-1.43.557-2.392.557Z"
        className="Bottom-D"
        clipRule="evenodd"
      ></path>
      <path
        fill={`${focusedInput === 'c' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M266.364 389.5h1.409a4.648 4.648 0 0 0-.58-1.597 4.303 4.303 0 0 0-1.045-1.193 4.541 4.541 0 0 0-1.404-.75 5.241 5.241 0 0 0-1.653-.255c-.993 0-1.875.242-2.648.727-.773.485-1.381 1.174-1.824 2.068-.443.894-.664 1.955-.664 3.182 0 1.227.221 2.288.664 3.182.443.894 1.051 1.583 1.824 2.068.773.485 1.655.727 2.648.727.587 0 1.138-.085 1.653-.256.519-.17.987-.418 1.404-.744a4.357 4.357 0 0 0 1.045-1.199c.28-.469.474-1.002.58-1.596h-1.409a2.97 2.97 0 0 1-1.188 1.846 3.244 3.244 0 0 1-.971.472 3.891 3.891 0 0 1-1.114.159 3.55 3.55 0 0 1-1.915-.534c-.568-.356-1.021-.881-1.358-1.574-.333-.693-.5-1.544-.5-2.551 0-1.008.167-1.858.5-2.551.337-.694.79-1.218 1.358-1.574a3.55 3.55 0 0 1 1.915-.534c.386 0 .757.053 1.114.159.356.106.679.265.971.477a2.95 2.95 0 0 1 1.188 1.841ZM119.5 392v-2.5l-12.5 3 12.5 3V393H251v-1H119.5Zm287 0v-2.5l12.5 3-12.5 3V393H275v-1h131.5Z"
        className="Bottom-C"
        clipRule="evenodd"
      ></path>
      <path
        fill={`${focusedInput === 'b' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M466.5 214h2.5l-3-12.5-3 12.5h2.5v53.5h1V214Zm0 132v-53.5h-1V346h-3l3 12.5 3-12.5h-2Zm-5.091-72.136V285.5h4.205c.916 0 1.661-.136 2.233-.409.572-.273.99-.642 1.255-1.108.265-.466.398-.991.398-1.574 0-.614-.121-1.132-.364-1.557-.242-.424-.543-.75-.903-.977-.36-.227-.718-.352-1.074-.375v-.114c.333-.091.642-.231.926-.42a2.07 2.07 0 0 0 .693-.773c.178-.326.267-.738.267-1.238 0-.569-.128-1.086-.386-1.552-.257-.469-.65-.842-1.176-1.119-.527-.28-1.195-.42-2.006-.42h-4.068Zm4.205 10.386h-2.796v-4.045h2.864c.507 0 .947.102 1.318.306.371.201.657.47.858.807.201.334.301.697.301 1.091 0 .511-.195.947-.585 1.307-.387.356-1.04.534-1.96.534Zm-.182-5.273h-2.614v-3.863h2.659c.773 0 1.337.18 1.693.539.357.356.535.79.535 1.302 0 .409-.103.765-.307 1.068a2.006 2.006 0 0 1-.818.704c-.341.167-.724.25-1.148.25Z"
        className="Bottom-B"
        clipRule="evenodd"
      ></path>
      <path
        fill={`${focusedInput === 'a' ? colors.highlight : colors.default}`}
        fillRule="evenodd"
        d="M520.5 202h2.5l-3-12.5-3 12.5h2.5v59.5h1V202Zm0 144v-60.5h-1V346H517l3 12.5 3-12.5h-2.5Zm-6.091-66.5h1.477l1.17-3.295h4.706l1.17 3.295h1.477l-4.273-11.636h-1.454l-4.273 11.636Zm6.909-4.545-1.863-5.25h-.091l-1.864 5.25h3.818Z"
        className="Bottom-A"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#000"
        d="M104.5 362v-5.5h5.5v5.5h-5.5ZM0 359.5h103M56.5 204h47"
      ></path>
      <path
        fill="#000"
        d="M109.256 319.227h1.008l2.017 3.395h.085l2.018-3.395h1.008l-2.628 4.276v2.997h-.88v-2.997l-2.628-4.276ZM141.307 350.227l1.875 3.026h.057l1.875-3.026h1.037l-2.287 3.637 2.287 3.636h-1.037l-1.875-2.969h-.057l-1.875 2.969h-1.037l2.344-3.636-2.344-3.637h1.037Z"
      ></path>
      <path
        stroke="#000"
        d="M0 173.5h258M107 397.5V364M419 397.5V364M423 359.5h103.5M344 201h127.5M345 188h181M107 204.5v155h311.5V204l-156-31L107 204.5Z"
      ></path>
      <path stroke="#000" d="m107.5 216.5 155-30.5 156 30.5"></path>{' '}
      <path
        fill="#000"
        d="M339 205h4v-.5h.5V203h.5v-1.5h-2v.5h-2v-.5h-1.5v2h.5v1.5ZM340 213.5V205h2v8.5h-2Z"
      ></path>
      <path
        stroke="#000"
        d="M340 214v-.5h2.5v1h-1v1h1v-.5h.5v.5h.5v.5h-.5v1h2.5v.5h2v5.5h-2v.5H344v.5h-.5v.5h-.5v1h3v.5h-.5v1h.5v.5h-.5v1.5h28v-1h5v1h12v-1h.5v2h.5v.5h-.5v1h-.5v-1h-.5v-.5h-11.5v.5H374v-.5h-3.5v.5H368v-.5h-22v.5h-2.5v.5h-.5v-.5h-3v.5h-.5v-.5h-4v-.5H314v.5h-3v-.5h-3v.5h-4v-.5h-12v.5h-.5v1h-.5v-1h-.5v-.5h.5v-1h-.5v-.5h.5v-1h.5v1h.5v.5h11.5v-1h4.5v1h28.5v-1h-.5v-1h.5v-1h-.5v-1h1v-1h-.5v-5.5h-.5v-1h3v-.5h-.5v-.5h.5v-1h.5V214h.5Z"
      ></path>
      <path
        fill="#000"
        d="M337.5 220v-1h1.5v1h-1.5ZM342 216.5h-2v.5h.5v7h-.5v1h2v-8.5ZM343 222v-3.5h.5v-.5h1v.5h2v3.5H343ZM343 226.5h-6v.5h6v-.5ZM340 229v1h3v-1h-1v-.5h-.5v.5H340Z"
      ></path>
    </svg>
  )

  return (
    <Card variant="outlined" sx={{ p: 4, height: '100%' }}>
      <Typography variant="h5">Preview</Typography>
      {roofPitch === 'none' ? slopedImage : flatImage}
    </Card>
  )
}

export default ImageCard
