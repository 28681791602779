import { Text, View, StyleSheet } from '@react-pdf/renderer'

const PdfBafTable = ({ results = {} }: { results: any }) => {
  const styles = StyleSheet.create({
    value: {
      fontWeight: 'bold',
      fontSize: '8px',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '8px',
      fontWeight: 'bold',
      color: '#525659',
    },
  })

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  const renderHeader = () => (
    <View style={{ flexDirection: 'row', width: '100%', marginBottom: '4px' }}>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Month</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Fan Mode</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Days</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Hours</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Usage (kWh)</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Cost ($)</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Usage (kWh)</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.tableHeaderLabel}>Cost / Day</Text>
      </View>
    </View>
  )

  const renderRow = (month: any) => (
    <View key={month?.name} style={{ flexDirection: 'row', width: '100%' }}>
      <View style={{ width: '12.5%' }}>
        <Text
          style={{
            ...styles.value,
            fontWeight: month?.name === 'Total' ? 'bold' : 'normal',
          }}
        >
          {month?.name}
        </Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{month?.mode}</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{month?.days}</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{round(month?.hours, 1)}</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{round(month?.usage)}</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{`$${round(month?.cost, 100).toFixed(
          2
        )}`}</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{round(month?.usagePerDay)}</Text>
      </View>
      <View style={{ width: '12.5%' }}>
        <Text style={styles.value}>{`$${round(month?.costPerDay, 100).toFixed(
          2
        )}`}</Text>
      </View>
    </View>
  )

  return (
    <View
      style={{ flexDirection: 'column', width: '100%', marginBottom: '16px' }}
    >
      <Text style={{ ...styles.label, marginBottom: '8px' }}>
        Annual Energy Usage & Energy Cost - BAF Method
      </Text>
      {renderHeader()}
      {results?.bafCost?.monthsData?.map((month: any) => renderRow(month))}
      {renderRow(results?.bafCost?.monthsDataTotals)}
    </View>
  )
}

export default PdfBafTable
