import { useState, useEffect, useCallback } from 'react'
import { alpha } from '@mui/material'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import BarChart from './BarChart'

import { useHeatIndexState } from 'store/heatIndexState'
import { axiosHelper } from '../../../utils'

import filterCoolingEffectData from 'utils/heatIndexUtils'

const HourlyHeatIndexDistribution = () => {
  const heatIndexState = useHeatIndexState()
  const [activeRegion, setActiveRegion] = useState()

  // Loading states for inputs
  const [noFansLoading, setNoFansLoading] = useState<boolean>(false)
  const [fansOnlyLoading, setFansOnlyLoading] = useState<boolean>(false)
  const [fansEvapLoading, setFansEvapLoading] = useState<boolean>(false)

  const region = heatIndexState.get()?.projectDetails?.value?.climateZone
  const { hourlyHeatIndexDistribution, projectDetails, needsUpdate } =
    heatIndexState.get()
  const value = hourlyHeatIndexDistribution?.value || []
  const {
    risks = {},
    drybulb,
    relHum,
    ceNoFans,
    ceFansOnly,
    ceFansEvap,
    scenarios = {},
    hours = {},
  } = value

  const colors = ['#32cd32', '#ffd700', '#ff8c00', '#ff0000', '#b22222']
  const indexes = ['Neutral', 'Very Warm', 'Hot', 'Very Hot', 'Extremely Hot']
  const weights = [6570, 4380, 4380, 2190, 1095]

  // Checkbox input state
  const [fansOnly, setFansOnly] = useState(
    hourlyHeatIndexDistribution?.value?.scenarios?.['Fans Only']
  )
  const [fansEvap, setFansEvap] = useState(
    hourlyHeatIndexDistribution?.value?.scenarios?.['Fans + Evap Coolers']
  )

  // Filtered Data
  const [ceNoFansFiltered, setCeNoFansFiltered] = useState([])
  const [ceFansOnlyFiltered, setCeFansOnlyFiltered] = useState([])
  const [ceFansEvapFiltered, setCeFansEvapFiltered] = useState([])
  const [drybulbFiltered, setDrybulbFiltered] = useState([])
  const [relHumFiltered, setRelHumFiltered] = useState([])

  const noData = !Object.keys(ceNoFans || [])?.length
  const isLoading = fansOnlyLoading || fansEvapLoading || noFansLoading

  const calculateIntensity = (
    products: string,
    heatIndex: string,
    weight: number
  ) => {
    let value = hours?.[products]?.[heatIndex] ?? 0
    let intensity = value / weight
    return Math.min(1, intensity)
  }

  // Filter based on schedule
  const schedule = projectDetails?.value?.customSchedule
  const isCustomSchedule = projectDetails?.value?.schedule === 'Custom Schedule'
  useEffect(() => {
    if (
      needsUpdate &&
      ceNoFans?.length &&
      ceFansOnly?.length &&
      ceFansEvap?.length
    ) {
      setCeFansOnlyFiltered(filterCoolingEffectData(ceFansOnly, schedule))
      setCeFansEvapFiltered(filterCoolingEffectData(ceFansEvap, schedule))
      setCeNoFansFiltered(filterCoolingEffectData(ceNoFans, schedule))
      setDrybulbFiltered(filterCoolingEffectData(drybulb, schedule))
      setRelHumFiltered(filterCoolingEffectData(relHum, schedule))
      heatIndexState.setNeedsUpdate(false)
    }
  }, [
    relHum,

    drybulb,
    setRelHumFiltered,
    drybulbFiltered,
    ceNoFans,
    ceNoFansFiltered,
    ceFansEvap,
    ceFansEvapFiltered,
    ceFansOnly,
    ceFansOnlyFiltered,
    schedule,
    needsUpdate,
    heatIndexState,
  ])

  const updateCoolingEffectData = useCallback(() => {
    if (!region) return
    const regions = {
      '1A': 1,
      '2A': 2,
      '2B': 3,
      '3A': 4,
      '3BLA': 5,
      '3BVegas': 6,
      '3C': 7,
      '4A': 8,
      '4B': 9,
      '4C': 10,
      '5A': 11,
      '5B': 12,
      '6A': 13,
      '6B': 14,
      '7A': 15,
    }

    const mapData = (datum: any) => ({
      value: datum?.value,
      h: datum?.operation_hour_id,
      d: datum?.operation_day_id,
    })

    const noFansSuccess = (values: any) => {
      setNoFansLoading(false)
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      return heatIndexState.setFormValue(
        'hourlyHeatIndexDistribution',
        'ceNoFans',
        mappedData
      )
    }
    const fansOnlySuccess = (values: any) => {
      setFansOnlyLoading(false)
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      return heatIndexState.setFormValue(
        'hourlyHeatIndexDistribution',
        'ceFansOnly',
        mappedData
      )
    }
    const fansEvapSuccess = (values: any) => {
      setFansEvapLoading(false)
      const mappedData = values.data?.map((datum: any) => mapData(datum))
      return heatIndexState.setFormValue(
        'hourlyHeatIndexDistribution',
        'ceFansEvap',
        mappedData
      )
    }
    axiosHelper({
      url: '/cooling-effect-data-points/find',
      method: 'post',
      data: {
        classification_id: 3,
        climate_zone_id: regions[region as keyof typeof regions],
      },
      success: noFansSuccess,
    })
    setNoFansLoading(true)
    axiosHelper({
      url: '/cooling-effect-data-points/find',
      method: 'post',
      data: {
        classification_id: 4,
        climate_zone_id: regions[region as keyof typeof regions],
      },
      success: fansOnlySuccess,
    })
    setFansOnlyLoading(true)
    axiosHelper({
      url: '/cooling-effect-data-points/find',
      method: 'post',
      data: {
        classification_id: 5,
        climate_zone_id: regions[region as keyof typeof regions],
      },
      success: fansEvapSuccess,
    })
    setFansEvapLoading(true)
    heatIndexState.setNeedsUpdate(true)
  }, [region, heatIndexState])

  // Update heat index state
  useEffect(() => {
    if (region) {
      if (drybulb?.length > 0 && relHum?.length > 0) {
        for (let i = 0; i < 5; i++) {
          heatIndexState.countHeatIndex(
            isCustomSchedule ? drybulbFiltered : drybulb,
            isCustomSchedule ? relHumFiltered : relHum,
            isCustomSchedule ? ceNoFansFiltered : ceNoFans,
            isCustomSchedule ? ceNoFansFiltered : ceNoFans,
            indexes[i],
            'No Fans',
            heatIndexState?.heatIndex,
            heatIndexState?.iadbEvapTempReduction
          )
        }
      }
      if (drybulb && relHum && scenarios?.['Fans Only']) {
        for (let i = 0; i < 5; i++) {
          heatIndexState.countHeatIndex(
            isCustomSchedule ? drybulbFiltered : drybulb,
            isCustomSchedule ? relHumFiltered : relHum,
            isCustomSchedule ? ceNoFansFiltered : ceNoFans,
            isCustomSchedule ? ceFansOnlyFiltered : ceFansOnly,
            indexes[i],
            'Fans Only',
            heatIndexState?.heatIndex,
            heatIndexState?.iadbEvapTempReduction
          )
        }
      }
      if (drybulb && relHum && scenarios?.['Fans + Evap Coolers']) {
        for (let i = 0; i < 5; i++) {
          heatIndexState.countHeatIndex(
            isCustomSchedule ? drybulbFiltered : drybulb,
            isCustomSchedule ? relHumFiltered : relHum,
            isCustomSchedule ? ceNoFansFiltered : ceNoFans,
            isCustomSchedule ? ceFansEvapFiltered : ceFansEvap,
            indexes[i],
            'Fans + Evap Coolers',
            heatIndexState?.heatIndex,
            heatIndexState?.iadbEvapTempReduction
          )
        }
      }
    }
    // eslint-disable-next-line
  }, [
    region,
    fansOnly,
    fansEvap,
    isCustomSchedule,
    drybulb?.length,
    relHum?.length,
    needsUpdate,
  ])

  // Update data when region changes
  useEffect(() => {
    if (region !== activeRegion) {
      updateCoolingEffectData()
      setActiveRegion(region)
    }
  }, [activeRegion, region, updateCoolingEffectData])

  const renderControls = () => (
    <Box sx={{ mt: -1 }}>
      <FormControlLabel
        label="No Fans"
        control={<Checkbox size="small" color="secondary" disabled checked />}
      />
      <FormControlLabel
        label="Fans Only"
        control={
          <Checkbox
            size="small"
            color="secondary"
            onChange={event => {
              setFansOnlyLoading(true)
              heatIndexState.setScenario('Fans Only', event.target.checked)
              setFansOnly(event.target.checked)
              setTimeout(() => setFansOnlyLoading(false), 1000)
            }}
            checked={scenarios?.['Fans Only']}
          />
        }
      />
      <FormControlLabel
        label="Fans + Evap Coolers"
        control={
          <Checkbox
            size="small"
            color="secondary"
            onChange={event => {
              setFansEvapLoading(true)
              heatIndexState.setScenario(
                'Fans + Evap Coolers',
                event.target.checked
              )
              setFansEvap(event.target.checked)
              setTimeout(() => setFansEvapLoading(false), 1000)
            }}
            checked={scenarios?.['Fans + Evap Coolers']}
          />
        }
      />
    </Box>
  )

  const renderLoading = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '290px',
      }}
    >
      <CircularProgress />
    </Box>
  )

  const renderTable = () =>
    !!region && !noData ? (
      <>
        <Table size="small" sx={{ mb: 1, mt: -1 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1 }}>
                Scenario
              </TableCell>
              {Object.keys(risks)?.map((value, index) => (
                <TableCell
                  sx={{ fontWeight: 700, pt: 0, pb: 0, pl: 1 }}
                  key={index}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ pt: 0, pb: 0, pl: 1 }}>No Fans</TableCell>
              {!!region &&
                indexes.map(
                  (o: string, index: number) =>
                    hours?.['No Fans']?.[o] !== -1 && (
                      <TableCell
                        sx={{
                          pt: 0,
                          pb: 0,
                          pl: 1,
                          backgroundColor: alpha(
                            colors[index],
                            calculateIntensity('No Fans', o, weights[index])
                          ),
                        }}
                        key={`${index}`}
                      >
                        {hours?.['No Fans']?.[o] ?? '-'}
                      </TableCell>
                    )
                )}
            </TableRow>
            {scenarios?.['Fans Only'] && (
              <TableRow>
                <TableCell sx={{ pt: 0, pb: 0, pl: 1, pr: 0 }}>
                  Fans Only
                </TableCell>
                {!!region &&
                  indexes.map(
                    (o: string, index: number) =>
                      hours?.['Fans Only']?.[o] !== -1 && (
                        <TableCell
                          sx={{
                            pt: 0,
                            pb: 0,
                            pl: 1,
                            backgroundColor: alpha(
                              colors[index],
                              calculateIntensity(
                                'Fans Only',
                                o,
                                weights?.[index]
                              )
                            ),
                          }}
                          key={`${index}`}
                        >
                          {hours?.['Fans Only']?.[o] ?? '-'}
                        </TableCell>
                      )
                  )}
              </TableRow>
            )}
            {scenarios?.['Fans + Evap Coolers'] && (
              <TableRow>
                <TableCell sx={{ pt: 0, pb: 0, pl: 1, pr: 0 }}>
                  Fans + Evap Coolers
                </TableCell>
                {!!region &&
                  indexes.map(
                    (o: string, index: number) =>
                      hours?.['Fans + Evap Coolers']?.[o] !== -1 && (
                        <TableCell
                          sx={{
                            pt: 0,
                            pb: 0,
                            pl: 1,
                            backgroundColor: alpha(
                              colors[index],
                              calculateIntensity(
                                'Fans + Evap Coolers',
                                o,
                                weights[index]
                              )
                            ),
                          }}
                          key={`${index}`}
                        >
                          {hours?.['Fans + Evap Coolers']?.[o] ?? '-'}
                        </TableCell>
                      )
                  )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        {region && <BarChart />}
      </>
    ) : (
      <Typography>Enter details to see results here.</Typography>
    )

  return (
    <Card variant="outlined" sx={{ p: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ mb: 2, flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Typography variant="h5" sx={{ mr: 4, width: { md: '75%' } }}>
          {`Hourly Heat Index Distribution ${region ? `- ${region}` : ''}`}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>{renderControls()}</Box>
      {isLoading ? renderLoading() : renderTable()}
    </Card>
  )
}

export default HourlyHeatIndexDistribution
