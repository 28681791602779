import { useEffect, useState } from 'react'
import { axiosHelper } from 'utils'
import { useAuth0 } from '@auth0/auth0-react'
import isEquaL from 'lodash/isEqual'

interface Props {
  needsUpdate?: boolean
  onUpdate?: any
}

interface Project {
  archived?: number
  name?: string
  created_at: number
  id: number
}

export function useProjects({ needsUpdate = false, onUpdate }: Props) {
  const { user } = useAuth0()
  const [projects, setProjects] = useState<Project[] | null>(null)

  // Get projects for user
  useEffect(() => {
    if (user?.email) {
      axiosHelper({
        url: '/projects/find',
        method: 'post',
        data: {
          // TODO: Use user id once we figure out how to get it out of auth0
          user_id: user?.email,
        },
        success: (values: any) => {
          if (!isEquaL(values?.data, projects)) {
            setProjects(values?.data)
            onUpdate && onUpdate(false)
          }
        },
      })
    }
  }, [projects, user, needsUpdate, onUpdate])

  return {
    projects,
  }
}
