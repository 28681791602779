import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Search from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

interface Props {
  setQuery: (search: string) => void
  onClick: () => void
}

const ProjectSearchBanner = ({ setQuery, onClick }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'primary.main',
        height: '120px',
        mt: 11,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'primary.main',
        }}
      >
        <Typography variant="h3" sx={{ py: 5, color: 'white' }}>
          Projects
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <TextField
            placeholder="Search..."
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ ml: 1, mt: '3px', color: '#BCBEC0' }} />
                </InputAdornment>
              ),
            }}
            onChange={e => setQuery(e.target.value || '')}
            variant="standard"
            sx={{
              border: '1px solid #BCBEC0',
              background: 'white',
              maxWidth: '475px',
              width: '100%',
              borderRadius: '16px',
              paddingBottom: 0,
              marginTop: 0,
              fontSize: '14px',
              height: '36px',
              ml: 1,
              '.MuiInput-input': {
                paddingTop: '6px',
                fontSize: '14px',
              },
            }}
          />
          <Button
            variant="text"
            disableElevation
            onClick={() => onClick()}
            size="large"
            sx={{
              backgroundColor: 'secondary.main',
              px: 0,
              ml: 1,
              height: '36px',
              fontSize: '12px',
              width: '190px',
              fontFamily: 'industry-inc',
              letterSpacing: '0.04em',
              '&:hover': {
                backgroundColor: '#A9A9A9',
                color: '#fff',
              },
            }}
          >
            New Project
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default ProjectSearchBanner
