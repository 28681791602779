import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

import PdfHeader from 'components/pdf/PdfHeader'
import PdfNotesSection from 'components/pdf/PdfNotesSection'
import PdfEnvironmentalImpact from 'components/pdf/PdfEnvironmentalImpact'
import PdfFanSection from 'components/pdf/PdfFanSection'
import PdfInputSection from 'components/pdf/PdfInputSection'
import PdfDivider from 'components/pdf/PdfDivider'
import PdfDoeTable from './components/PdfDoeTable'
import PdfBafTable from './components/PdfBafTable'
import PdfUsageTable from './components/PdfUsageTable'
import PdfSavingsEstimate from './components/PdfSavingsEstimate'
import { useFanEnergyEstimatorState } from 'store'

const FanEnergyEstimatorPdf = () => {
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const {
    bafFans,
    existingFans,
    results,
    hoursPerWeek,
    selectedState,
    facilityType,
    electricCost,
    fansLeftOn,
    breaks15Min,
    breaks60Min,
    aeosEquipped,
  } = fanEnergyEstimatorState.get()
  const isAireyeMode = bafFans?.some((fan: any) =>
    fan?.name?.includes('Aireye')
  )

  const styles = StyleSheet.create({
    section: {
      paddingHorizontal: '16px',
      paddingBottom: '8px',
    },
    sectionTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '8px',
      width: '100%',
      paddingBottom: '2px',
      borderBottom: '1px solid black',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    value: {
      fontWeight: 'bold',
      fontSize: '8px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '8px',
      fontWeight: 'bold',
      color: '#525659',
    },
  })

  const notes = [
    `(1) Calculations are based on population averages for the information selected and are only an estimate of actual savings. Actual Costs and savings will vary depending on weather conditions, usage, location and local utility rates. This information is intended as an example for comparison purposes only. BAF does not gurantee the accuracy of these calculations. No promise of performance is implied by us or should be inferred by you.`,
    `(2) Per CFR 10 Part 429 and 430, the Department of Energy dictates that HVLS and Small Diameter (LSSD/HSSD) fan energy must also be reported using the methodologies therin. Note that the BAF method accounts for the specific location and application selected above whereas the DOE method is a national average for all customers, locations, and applications.`,
    `(3) The fan energy cost displayed is calculated using the methods developed by BAF expertise which best accounts for how customers utilize existing Big Ass Fans products (HVLS, Residential, and Directional) as well as existing non-BAF legacy products in their spaces.`,
  ]

  const inputs = [
    { title: 'Operating Hours Per Week', value: hoursPerWeek },
    { title: 'Install Location', value: selectedState },
    { title: 'Facility Type', value: facilityType },
    { title: 'Electric Cost ($/kWh)', value: electricCost },
    {
      title: 'Left On During Unoccupied Hours',
      value: fansLeftOn ? 'Yes' : 'No',
    },
  ]

  if (isAireyeMode) {
    inputs.push({
      title: '15 Min Breaks Per Week',
      value: breaks15Min,
    })
    inputs.push({
      title: '60 Min Breaks Per Week',
      value: breaks60Min,
    })
    inputs.push({
      title: 'AEOS Equipped',
      value: aeosEquipped ? 'Yes' : 'No',
    })
  }

  const renderResultsSection = () => (
    <View
      style={{
        ...styles.section,
        border: '1px solid black',
        padding: '8px',
        marginHorizontal: '16px',
        marginVertical: '0px',
        borderRadius: '8px',
      }}
    >
      <Text style={{ ...styles.sectionTitle, borderBottom: '0' }}>Results</Text>
      <PdfDoeTable results={results} electricCost={electricCost} />
      <PdfDivider styles={{ marginBottom: '6px', marginTop: '-6px' }} />
      <PdfBafTable results={results} />
      <PdfDivider styles={{ marginBottom: '8px', marginTop: '-6px' }} />

      <View style={{ flexDirection: 'row' }}>
        <PdfUsageTable results={results} />
        <PdfSavingsEstimate results={results} />
      </View>

      <PdfDivider styles={{ marginBottom: '-4px', marginTop: '6px' }} />

      <PdfEnvironmentalImpact results={results?.environmentalImpact} />
    </View>
  )

  return (
    <Document>
      <Page size="A4">
        <PdfHeader title="Fan Energy Estimator" />
        <PdfInputSection inputs={inputs} />
        <PdfFanSection bafFans={bafFans} existingFans={existingFans} />
        {renderResultsSection()}
        <PdfNotesSection notes={notes} />
      </Page>
    </Document>
  )
}

export default FanEnergyEstimatorPdf
