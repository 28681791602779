import { Text, View, StyleSheet } from '@react-pdf/renderer'

const HOURS_PER_YEAR = 8760
const DAYS_PER_YEAR = 365

const PdfDoeTable = ({
  results = {},
  electricCost = 0,
}: {
  results: any
  electricCost: number
}) => {
  const styles = StyleSheet.create({
    value: {
      fontWeight: 'bold',
      fontSize: '8px',
    },
    label: {
      fontSize: '8px',
      color: '#525659',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    tableHeaderLabel: {
      fontSize: '8px',
      fontWeight: 'bold',
      color: '#525659',
    },
  })

  const totalLargeFanUsage = results?.doeCost?.largeFansUsage * DAYS_PER_YEAR
  const totalHssdFansUsage = results?.doeCost?.hssdFansUsage * DAYS_PER_YEAR
  const totalLssdFansUsage = results?.doeCost?.lssdFansUsage * DAYS_PER_YEAR

  const round = (value: number, digits: number = 10) =>
    Math.round(value * digits) / digits

  return (
    <View
      style={{ flexDirection: 'column', width: '100%', marginBottom: '16px' }}
    >
      <Text style={{ ...styles.label, marginBottom: '8px' }}>
        Annual Energy Usage & Energy Cost - Department of Energy Method
      </Text>

      <View
        style={{ flexDirection: 'row', width: '100%', marginBottom: '4px' }}
      >
        <View style={{ width: '22%' }}>
          <Text style={styles.tableHeaderLabel}>Type</Text>
        </View>
        <View style={{ width: '5%' }}>
          <Text style={styles.tableHeaderLabel}>Days</Text>
        </View>
        <View style={{ width: '9%' }}>
          <Text style={styles.tableHeaderLabel}>Total Hours</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.tableHeaderLabel}>Fan Quantity</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.tableHeaderLabel}>Active Hours</Text>
        </View>
        <View style={{ width: '12%' }}>
          <Text style={styles.tableHeaderLabel}>Standby Hours</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.tableHeaderLabel}>Usage (kWh)</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.tableHeaderLabel}>Cost ($)</Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '22%' }}>
          <Text style={styles.value}>Large Diameter Fans (HVLS)</Text>
        </View>
        <View style={{ width: '5%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.largeFans?.length ? '365' : '-'}
          </Text>
        </View>
        <View style={{ width: '9%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.largeFans?.length ? HOURS_PER_YEAR : '-'}
          </Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.largeFans?.length || '-'}
          </Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.largeFans?.length
              ? results?.doeCost?.activeLargeFanHours
              : '-'}
          </Text>
        </View>
        <View style={{ width: '12%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.largeFans?.length
              ? results?.doeCost?.activeLargeFanHours
              : '-'}
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.value}>
            {' '}
            {results?.doeCost?.largeFans?.length
              ? `${round(totalLargeFanUsage, 1)} (${round(
                  results?.doeCost?.largeFansUsage,
                  10
                )} / Day) `
              : '-'}
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.value}>
            {' '}
            {results?.doeCost?.largeFans?.length
              ? `$${round(totalLargeFanUsage * electricCost, 100).toFixed(
                  2
                )} ($${round(
                  (totalLargeFanUsage * electricCost) / DAYS_PER_YEAR,
                  100
                ).toFixed(2)} / Day) `
              : '-'}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '22%' }}>
          <Text style={styles.value}>HSSD Fans (Haiku/Res)</Text>
        </View>
        <View style={{ width: '5%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.hssdFans?.length ? '365' : '-'}
          </Text>
        </View>
        <View style={{ width: '9%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.hssdFans?.length ? HOURS_PER_YEAR : '-'}
          </Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.hssdFans?.length || '-'}
          </Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.hssdFans?.length
              ? results?.doeCost?.activeHssdFanHours
              : '-'}
          </Text>
        </View>
        <View style={{ width: '12%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.hssdFans?.length
              ? results?.doeCost?.activeHssdFanHours
              : '-'}
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.value}>
            {' '}
            {results?.doeCost?.hssdFans?.length
              ? `${round(totalHssdFansUsage, 1)} (${round(
                  results?.doeCost?.hssdFansUsage,
                  10
                )} / Day) `
              : '-'}
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.value}>
            {' '}
            {results?.doeCost?.hssdFans?.length
              ? `$${round(totalHssdFansUsage * electricCost, 100).toFixed(
                  2
                )} ($${round(
                  (totalHssdFansUsage * electricCost) / DAYS_PER_YEAR,
                  100
                ).toFixed(2)} / Day) `
              : '-'}
          </Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', width: '100%' }}>
        <View style={{ width: '22%' }}>
          <Text style={styles.value}>LSSD Fans (es6/Res)</Text>
        </View>
        <View style={{ width: '5%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.lssdFans?.length ? '365' : '-'}
          </Text>
        </View>
        <View style={{ width: '9%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.lssdFans?.length ? HOURS_PER_YEAR : '-'}
          </Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.lssdFans?.length || '-'}
          </Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.lssdFans?.length
              ? `${results?.doeCost?.activeLssdFanHoursHigh} / ${results?.doeCost?.activeLssdFanHoursLow}`
              : '-'}
          </Text>
        </View>
        <View style={{ width: '12%' }}>
          <Text style={styles.value}>
            {results?.doeCost?.lssdFans?.length
              ? results?.doeCost?.standbyLssdFanHours
              : '-'}
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.value}>
            {' '}
            {results?.doeCost?.lssdFans?.length
              ? `${round(totalLssdFansUsage, 1)} (${round(
                  results?.doeCost?.lssdFansUsage,
                  10
                )} / Day) `
              : '-'}
          </Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.value}>
            {' '}
            {results?.doeCost?.lssdFans?.length
              ? `$${round(totalLssdFansUsage * electricCost, 100).toFixed(
                  2
                )} ($${round(
                  (totalLssdFansUsage * electricCost) / DAYS_PER_YEAR,
                  100
                ).toFixed(2)} / Day) `
              : '-'}
          </Text>
        </View>
      </View>
    </View>
  )
}

export default PdfDoeTable
