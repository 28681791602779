// import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/system/Box'

const SiteSearch = () => {
  const { user, loginWithRedirect, logout } = useAuth0()
  const smallBreakpoint = useMediaQuery('(min-width:600px)')

  return (
    <Box>
      <Card
        sx={{
          display: 'flex',
          mt: 10,
          position: 'relative',
          height: '360px',
          backgroundImage:
            'url(https://design-guide-images.s3.us-east-2.amazonaws.com/BAF_Powerfoil_FullWidth_Revise2.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundImage:
              'linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0))',
          }}
        >
          <Box sx={{ maxWidth: '1200px', width: '100%' }}>
            <Box
              sx={{
                ml: smallBreakpoint ? 2 : 0,
                mt: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: smallBreakpoint ? 'flex-start' : 'center',
                width: smallBreakpoint ? '450px' : '100%',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: 'white',
                  backgroundColor: 'transparent',
                  fontSize: smallBreakpoint ? '48px' : '28px',
                  textAlign: smallBreakpoint ? 'left' : 'center',
                }}
              >
                DIGITAL TOOLBOX
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: 'white',
                  backgroundColor: 'transparent',
                  mt: 1,
                  fontFamily: 'proxima-nova',
                  fontSize: smallBreakpoint ? '24px' : '16px',
                  textAlign: smallBreakpoint ? 'left' : 'center',
                  width: '385px',
                }}
              >
                A collection of tools to help you make the most of your Big Ass
                Fans.
              </Typography>
              {!user && (
                <Box>
                  <Button
                    variant="text"
                    sx={{
                      backgroundColor: 'secondary.main',
                      width: smallBreakpoint ? '180px' : '150px',
                      py: 1.5,
                      mt: 1.5,
                      fontFamily: 'industry-inc',
                      '&:hover': {
                        backgroundColor: '#A9A9A9',
                        color: '#fff',
                      },
                    }}
                    onClick={() => {
                      loginWithRedirect()
                    }}
                  >
                    Create Account
                  </Button>
                  {!smallBreakpoint && (
                    <Button
                      variant="text"
                      sx={{
                        backgroundColor: 'primary.main',
                        width: '100px',
                        color: 'white',
                        border: '1px solid white',
                        py: 1.5,
                        ml: 1,
                        mt: 1.5,
                        '&:hover': {
                          backgroundColor: '#A9A9A9',
                          color: '#fff',
                        },
                      }}
                      onClick={() => {
                        if (user) {
                          logout({
                            logoutParams: {
                              returnTo: window.location.origin,
                            },
                          })
                        } else {
                          loginWithRedirect()
                        }
                      }}
                    >
                      {user ? 'Sign out' : 'Sign In'}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default SiteSearch
