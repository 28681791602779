import React, { useState } from 'react'
import { format } from 'date-fns-tz'
import { Link } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import RenameProjectResultsModal from './RenameProjectResultsModal'
import DeleteProjectResultModal from './DeleteProjectResultModal'

// Tool PDF files
import AcSavingsCalcPdf from 'features/ac-savings-calc/AcSavingsCalcPdf'
import CostOfOwnershipPdf from 'features/cost-of-ownership-calc/CostOfOwnershipCalcPdf'
import DestratToolPdf from 'features/destrat-tool/DestratToolPdf'
import EmployeeSavingsCalcPdf from 'features/employee-savings-calc/EmployeeSavingsCalcPdf'
import EvapSizingToolPdf from 'features/evap-sizing-tool/EvapSizingToolPdf'
import ExtensionTubeCalcPdf from 'features/extension-tube-calc/ExtensionTubeCalcPdf'
import FanEnergyEstimatorPdf from 'features/fan-energy-estimator/FanEnergyEstimatorPdf'
import HeatSavingsEstimatorPdf from 'features/heat-savings-estimator/HeatSavingsEstimatorPdf'

// Tool stores
import {
  useAcSavingsState,
  useCostOfOwnershipState,
  useDestratToolState,
  useEmployeeSavingsState,
  useEvapSizingToolState,
  useExtensionTubeState,
  useFanEnergyEstimatorState,
  useHeatSavingsState,
} from 'store'
import { usePermissions } from 'hooks/usePermissions'
import { useToolConfigs } from 'hooks/useToolConfigs'

import { axiosHelper } from 'utils'

interface Props {
  isLast?: boolean
  results: any
  onUpdate: (message: string) => void
}

const ProjectResultsRow = ({ results, onUpdate, isLast }: Props) => {
  const { tools } = useToolConfigs()
  const { user } = usePermissions()
  const [alertOpen, setAlertOpen] = useState(false)

  // Row menu open state
  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null)
  const menuOpen = Boolean(anchorEl)

  // Modal open state
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  // Get tool info
  const tool = tools?.find((tool: any) =>
    results?.tool_name?.includes(tool?.tool)
  )
  const toolIcon = tool?.icon || '/icons/fan-schedule-builder.svg'

  // Tool stores for loading PDF document results
  const acSavingsState = useAcSavingsState()
  const costOfOwnershipState = useCostOfOwnershipState()
  const destratToolState = useDestratToolState()
  const employeeSavingsState = useEmployeeSavingsState()
  const evapSizingToolState = useEvapSizingToolState()
  const extensionTubeState = useExtensionTubeState()
  const fanEnergyEstimatorState = useFanEnergyEstimatorState()
  const heatSavingsState = useHeatSavingsState()

  // Menu click handlers
  const handleClick = (event: React.MouseEvent<SVGElement>) => {
    setAnchorEl(event.currentTarget)
    loadStoreData(results?.tool_name)
  }
  const handleClose = () => setAnchorEl(null)

  // Get the PDF document based on tool type
  const getToolPdfDocument = (name: string): any => {
    if (name === 'AC Savings Calculator') return <AcSavingsCalcPdf />
    if (name === 'Cost of Ownership Calculator') return <CostOfOwnershipPdf />
    if (name === 'Destrat Tool') return <DestratToolPdf />
    if (name === 'Employee Savings Calculator')
      return <EmployeeSavingsCalcPdf />
    if (name === 'Evap Sizing Tool') return <EvapSizingToolPdf />
    if (name === 'Extension Tube Calculator') return <ExtensionTubeCalcPdf />
    if (name === 'Extension Tube Calculator') return <FanEnergyEstimatorPdf />
    if (name === 'Fan Energy Estimator') return <FanEnergyEstimatorPdf />
    if (name === 'Heat Savings Estimator') return <HeatSavingsEstimatorPdf />

    return null
  }
  const toolPdfDocument = getToolPdfDocument(results?.tool_name)

  // Load the tool's state for PDF export
  const toolState = JSON.parse(results?.data)
  const loadStoreData = (name: string): any => {
    if (name === 'AC Savings Calculator') acSavingsState.loadState(toolState)
    if (name === 'Cost of Ownership Calculator')
      costOfOwnershipState.loadState(toolState)
    if (name === 'Destrat Tool') destratToolState.loadState(toolState)
    if (name === 'Employee Savings Calculator')
      employeeSavingsState.loadState(toolState)
    if (name === 'Evap Sizing Tool') evapSizingToolState.loadState(toolState)
    if (name === 'Extension Tube Calculator')
      extensionTubeState.loadState(toolState)
    if (name === 'Fan Energy Estimator')
      fanEnergyEstimatorState.loadState(toolState)
    if (name === 'Heat Savings Estimator') heatSavingsState.loadState(toolState)
  }

  const toolPdfFileName = `BAF-${results?.tool_name?.replaceAll(' ', '-')}`

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: isLast ? 'none' : '1px solid lightgray',
        alignItems: 'center',
        p: 2,
      }}
      key={results?.name}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          component="img"
          sx={{ width: '48px', height: '48px', mt: 0.75, mr: 2 }}
          src={toolIcon}
        />

        <Box sx={{ mt: 1 }} key={results?.name}>
          <Link
            to={`${tool?.link}?resultId=${results?.id}`}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <Typography variant="h5" sx={{ mb: 0 }}>
              {results?.name}
            </Typography>
          </Link>
          <Typography
            variant="body2"
            sx={{ mt: 0, mb: 1, color: 'grey', fontSize: '12px' }}
          >
            {results?.tool_name} •&nbsp;
            {format(new Date(results?.created_at || Date.now()), 'Pp')?.replace(
              ',',
              ''
            )}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mr: 1, mt: 1, cursor: 'pointer' }}>
        <MoreHorizIcon
          sx={{ fontSize: '36px', color: 'grey' }}
          onClick={e => handleClick(e)}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={() => handleClose()}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* TODO: Update menu items during save scope work */}
        <MenuItem
          onClick={() => {
            setModalOpen(true)
            handleClose()
          }}
        >
          Rename
        </MenuItem>
        <Link
          to={`${tool?.link}?resultId=${results?.id}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <MenuItem
            onClick={() => handleClose()}
            sx={{ textDecoration: 'none' }}
          >
            View
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            setDeleteModalOpen(true)
            handleClose()
          }}
        >
          Delete
        </MenuItem>

        {!!toolPdfDocument && (
          <>
            <MenuItem>
              <PDFDownloadLink
                document={toolPdfDocument}
                fileName={`${toolPdfFileName}.pdf`}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  opacity: 0.87,
                }}
              >
                {({ blob, url, loading, error }) => 'Download PDF'}
              </PDFDownloadLink>
            </MenuItem>
            <MenuItem>
              <Box
                onClick={() => {
                  const email = user?.email
                  if (!email) return
                  axiosHelper({
                    url: '/save_emails',
                    method: 'post',
                    data: {
                      ...toolState,
                      email,
                      toolName: toolPdfFileName,
                    },
                    success: () => {
                      setAlertOpen(true)
                      handleClose()
                    },
                  })
                }}
              >
                Email PDF
              </Box>
            </MenuItem>
          </>
        )}
      </Menu>

      <RenameProjectResultsModal
        results={results}
        onUpdate={() => onUpdate && onUpdate('Renamed!')}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
      />

      <DeleteProjectResultModal
        projectResults={results}
        onUpdate={() => {
          onUpdate && onUpdate('Deleted!')
        }}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => {
          setAlertOpen(false)
        }}
      >
        <Alert severity="success">Email Sent!</Alert>
      </Snackbar>
    </Box>
  )
}

export default ProjectResultsRow
