import React from 'react'

import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Modal from '@mui/material/Modal'
import Box from '@mui/system/Box'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useEvapSizingToolState } from 'store'

interface Props {
  onClose: () => void
  open: boolean
}

const ThermalToolInputsModal = ({ onClose = () => {}, open }: Props) => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const evapSizingToolState = useEvapSizingToolState()
  const { results } = evapSizingToolState.get()

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose()
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '3%',
          boxShadow: 24,
          left: '50%',
          py: 4,
          px: mediumBreakpoint ? 4 : 1,
          position: 'absolute' as 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: mediumBreakpoint ? 600 : 'auto',
        }}
      >
        <CloseIcon
          sx={{
            position: 'absolute',
            right: '2%',
            top: '2%',
            cursor: 'pointer',
          }}
          onClick={() => onClose && onClose()}
        />
        <Box
          sx={{
            fontSize: mediumBreakpoint ? '24px' : '16px',
            fontWeight: 'bold',
          }}
        >
          CBE Thermal Comfort Tool Inputs
        </Box>
        <Box sx={{ mb: 4, fontSize: mediumBreakpoint ? '14px' : '10px' }}>
          Calculated data points for use in CBE Thermal Comfort Tool
        </Box>

        <Table aria-label="standard-results-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: '33%',
                  fontSize: mediumBreakpoint ? '12px' : '10px',
                  lineHeight: 'initial',
                  fontWeight: 'bold',
                }}
              >
                Thermal Comfort Tool Inputs
              </TableCell>
              <TableCell
                sx={{
                  fontSize: mediumBreakpoint ? '12px' : '10px',
                  fontWeight: 'bold',
                }}
              >
                Existing
              </TableCell>
              <TableCell
                sx={{
                  fontSize: mediumBreakpoint ? '12px' : '10px',
                  lineHeight: 'initial',
                  fontWeight: 'bold',
                }}
              >
                Evap Cooler
              </TableCell>
              <TableCell
                sx={{
                  fontSize: mediumBreakpoint ? '12px' : '10px',
                  lineHeight: 'initial',
                  fontWeight: 'bold',
                }}
              >
                HVLS
              </TableCell>
              <TableCell
                sx={{
                  fontSize: mediumBreakpoint ? '12px' : '10px',
                  lineHeight: 'initial',
                  fontWeight: 'bold',
                }}
              >
                Evap + HVLS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.comfortToolInputs?.map((result: any) => (
              <TableRow key={`${result?.title}-row`}>
                <TableCell>{result?.title || '-'}</TableCell>
                <TableCell>{result?.existing || '-'}</TableCell>
                <TableCell>{result?.evapCooler || '-'}</TableCell>
                <TableCell>{result?.hvls || '-'}</TableCell>
                <TableCell>{result?.evapHvls || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          variant="outlined"
          target="blank"
          sx={{ mt: 4 }}
          disableElevation
          size="large"
          href="https://comfort.cbe.berkeley.edu/"
        >
          Open CBE Tool
        </Button>
      </Box>
    </Modal>
  )
}

export default ThermalToolInputsModal
