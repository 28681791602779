import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import FormCard from 'components/FormCard'
import ThermalToolInputsModal from './ThermalToolInputsModal'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useEvapSizingToolState } from 'store'

const PRODUCTS = [
  'Cool-Space 200',
  'Cool-Space 300',
  'Cool-Space 350',
  'Cool-Space 400',
  'Cool-Space 500',
]

const Results = () => {
  const mediumBreakpoint = useMediaQuery('(min-width:1024px)')
  const evapSizingToolState = useEvapSizingToolState()
  const { results } = evapSizingToolState.get()
  const [showModal, setShowModal] = useState(false)

  if (!results) return null

  const renderBadge = (title: string, index: number) => (
    <Box
      key={title}
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid black',
        borderRadius: '10px',
        width: 'fit-content',
      }}
    >
      <Box
        sx={{ px: 1, display: 'flex', alignItems: 'center', height: '100%' }}
      >
        {title}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          //   justifyContent: '',
          borderLeft: '1px solid black',
          px: 1,
        }}
      >
        <Box sx={{ pt: 1, fontWeight: 700 }}>
          {results?.coolSpaceQty?.[index]}
        </Box>
        <Box sx={{ mt: -0.5, pb: 1 }}>units</Box>
      </Box>
    </Box>
  )

  return (
    <FormCard width={mediumBreakpoint ? '99.5%' : '100%'}>
      <Typography variant="h5" sx={{ mb: 1.5 }}>
        Results
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mb: 2,
          flexWrap: 'wrap',
        }}
      >
        {PRODUCTS?.map((product: string, index: number) =>
          renderBadge(product, index)
        )}
      </Box>

      <Box
        sx={{
          fontSize: mediumBreakpoint ? '18px' : '14px',
          display: 'flex',
          justifyContent: 'center',
          mb: 1,
          mt: 3,
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => setShowModal(true)}
      >
        CBE Thermal Comfort Tool Inputs
      </Box>

      <ThermalToolInputsModal
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </FormCard>
  )
}

export default Results
